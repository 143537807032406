import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { environment } from "src/environments/environment";
import { Store } from "@ngrx/store";
import { GenericActions } from "src/app/logic/generic";
import { UserService } from "src/app/logic/user/user.service";
@Injectable()
export class AsrAuthGuard implements CanActivate {


    constructor(
        public router: Router,
        public genericActions: GenericActions,
        public store: Store<any>,
        private userService: UserService
    ) { }


    canActivate (
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if ( environment.auth === false ) {
            return true;
        }
        let url = `${environment.accessManager}${environment.authorizePath}?response_type=${environment.response_type}&client_id=${environment.client_id}&redirect_uri=${environment.redirect_uri}&scope=${environment.scope}`;
        let modalAction = this.genericActions.showErrorModal({
            title: 'Attenzione',
            message: 'Non puoi eseguire questa operazione se non sei autenticato. Clicca su Accedi per procedere con l\'autenticazione.',
            buttons: [
                {id: 'login', label: 'Accedi', autoclose: true, action: this.genericActions.goExternalUrl(url)},
                { id: 'close', label: 'Chiudi', class: '', action: '', autoclose: true }]
            });
        let user = localStorage.getItem('user');
        let roles = localStorage.getItem('roles');
        if (user && roles) {
            let obj = JSON.parse(roles);
            if (obj['2'] !== 'authenticated user' || obj['5'] !== "validated user") {
                this.store.dispatch(modalAction);
                return false;
            } else {
                return true;
            }
        } else {
            this.store.dispatch(modalAction);
            this.router.navigate(['/home']);
            return false;
        }
    }
}