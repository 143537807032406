<section id="widget">
    <div class="container pt-4 mb-5">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-4 mb-4">
          <div class="p-3" id="dati-riepilogo">
            <h3>Dati di riepilogo</h3>
            <div class="row">
              <div class="col-12 col-md-6 col-lg-12 riepilogo pb-3">
                <i class="fa fa-bed float-left pr-4 mt-2 mb-2"></i>
                <div class="dato">
                  <strong>{{this.numStrutture$ | async}}</strong>
                  <span>Strutture</span>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-12 riepilogo pb-3">
                <i class="fa fa-building float-left pr-4 mt-3 mb-2"></i>
                <div class="dato">
                  <strong>{{this.numImprese$ | async}}</strong>
                  <span>Imprese</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-12 col-md-6 col-lg-4 mb-4">
          <h3><span class="underlined">Ultime pratiche</span></h3>
          <ul class="lista_custom lista--pratiche p-0 m-0">
            <li class="pt-1 pb-1">
              <div class="pratica p-1 d-flex align-items-baseline justify-content-between">
                <div class="text">
                  <h4 class="mb-0">Coco beach</h4>
                  <p class="m-0">stato <strong>Inviata</strong><br />
                    attività <strong>Modifica struttura</strong>
                  </p>
                </div>
                <div class="data text-right">
                  12 nov 2018
                </div>
              </div>
            </li>
            <li class="pt-1 pb-1">
              <div class="pratica p-1 d-flex align-items-baseline justify-content-between">
                <div class="text">
                  <h4 class="mb-0">Coco beach</h4>
                  <p class="m-0">stato <strong>Inviata</strong><br />
                    attività <strong>Modifica struttura</strong>
                  </p>
                </div>
                <div class="data text-right">
                  12 nov 2018
                </div>
              </div>
            </li>
            <li class="pt-1 pb-1">
              <div class="pratica p-1 d-flex align-items-baseline justify-content-between">
                <div class="text">
                  <h4 class="mb-0">Coco beach</h4>
                  <p class="m-0">stato <strong>Inviata</strong><br />
                    attività <strong>Modifica struttura</strong>
                  </p>
                </div>
                <div class="data text-right">
                  12 nov 2018
                </div>
              </div>
            </li>
          </ul>
        </div> -->
        <div class="col-12 col-md-6 col-lg-4 mb-4" *ngIf="(this.user$ | async) !== null ">
          <h3><span class="underlined">Le tue bozze</span></h3>
          <ul class="lista_custom lista--bozze p-0 m-0" *ngIf="(bozzeList$ | async).length > 0">
            <li class="pt-1 pb-1" *ngFor="let bozza of bozzeList$ | async" >
              <div class="bozza p-1 d-flex align-items-baseline justify-content-between" [routerLink]="['/edit']" [queryParams]="{type: 'strut', id: bozza.idStrut, step: 'dati_prelim'}">
                <div class="text">
                  <h4 class="mb-0">{{bozza.descrizioneidras ? bozza.descrizioneidras : 'Senza nome'}}</h4>
                  <!-- <p class="m-0">avanzamento <strong>60%</strong><br /></p> -->
                  <!-- <div class="progressbar">
                    <div class="bar _60"></div>
                  </div> -->
                </div>
                <div class="data text-right">
                  {{(bozza?.aggiornamento?.ultimoAgg + 'Z' | date: "yyyy-MM-dd hh:mm:ss")}}
                </div>
              </div>
            </li>
          </ul>
          <ul class="lista_custom lista--bozze p-0 m-0" *ngIf="(bozzeList$ | async).length === 0">
            <div class="text">
              Non hai bozze al momento.
            </div>
          </ul>
          <button type="button" class="btn btn-link" routerLink="/bozze" *ngIf="(bozzeList$ | async).length > 0" >Vedi tutte <i class="fa fa-angle-right"></i></button>
        </div>
      </div>
    </div>
  </section>
