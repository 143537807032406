<ng-container *ngIf="model.type === 'input' || model.type === 'number' || model.type === 'email'">
  <div class="form-group">
    <strong><label for="{{model.id}}">{{model.required ? model.label + ' *': model.label }}</label></strong>
    <p>{{model.control.value || '-'}}</p>
  </div>
</ng-container>

<ng-container *ngIf="model.type === 'textarea'">
  <div class="form-group">
    <strong><label for="{{model.id}}">{{model.required ? model.label + ' *': model.label }}</label></strong>
    <div class="col-12">
      <p>{{model.control.value || '-'}}</p>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="model.type === 'date'">
  <div class="form-group">
    <strong><label for="{{model.id}}">{{model.required ? model.label + ' *': model.label }}</label></strong>
    <p>{{parentController.dateFormatter.format(model.control.value) || '-'}}</p>
  </div>
</ng-container>

<ng-container *ngIf="model.type === 'radio' ">
  <div class="form-group">
    <strong><label for="{{model.id}}">{{model.required ? model.label + ' *': model.label }}</label></strong>
    <p>{{model.options[model.control.value] || '-'}}</p>
  </div>
</ng-container>

<ng-container *ngIf="model.type === 'select' ">
  <div class="form-group">
    <strong><label for="{{model.id}}">{{model.required ? model.label + ' *': model.label }}</label></strong>
    <p *ngIf="model.display">{{ (model.options[model.control.value] && model.options[model.control.value][model.display]) || '-'}}</p>
    <p *ngIf="!model.display">{{model.options[model.control.value] || '-'}}</p>
  </div>
</ng-container>

<ng-container *ngIf="model.type === 'checkbox' ">
  <div class="form-row mt-2 mb-1">
    <div class="col-12"><strong><label class="text-capitalize h4"
          for="{{model.id}}">{{model.required ? model.label + ' *': model.label }}</label></strong>
    </div>
    <ng-container *ngFor="let option of model?.control?.controls?.options?.controls | keyvalue">
      <div class="ml-3 col-md-3 col-sm-3"  *ngIf="option.value.value">
        <p>{{'-' + option.key}}</p>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf=" model.type === 'textfield' ">
  <div class="form-group position-relative">
    <strong><label for="{{model.id}}">{{model.required ? model.label + ' *': model.label }}</label></strong>
    <p>{{model.formatter(model.control.value) || '-'}}</p>
  </div>
</ng-container>

<ng-container *ngIf="model.type === 'section' ">
  <div class="{{model?.attributes?.class || 'col-12 col-md-6' }}">
    <p class="{{'h3 underlined' }}" *ngIf="model?.label">
      {{model?.label}}
    </p>
    <hr class="mx-0 mb-3" *ngIf="!model?.attributes?.hideSeparator">
    <div class="row">
      <ng-container *ngFor="let subforms of model.children | keyvalue:parentController.orderComparator">
        <ng-container *ngIf="(subforms.value.visible | async) === true">
          <div class="{{subforms?.value?.attributes?.class || 'col-12 col-md-6'}}" asr-summary [(model)]="subforms.value"
            [parentController]="parentController"></div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="model.type === 'container'">
  <ng-container *ngIf="(model.visible | async)">
    <ng-container *ngFor="let child of model.children | keyvalue: parentController.orderComparator">
      <div asr-container class="{{child.value.attributes.class}}" [(model)]="child.value"
        [parentController]="parentController">
      </div>
    </ng-container>
  </ng-container>
</ng-container>