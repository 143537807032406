export var helpers = {
    core_hasOwn: Object.prototype.hasOwnProperty,
    snapshot: function (store) {
        var state;
        store.take(1).subscribe(function (s) { return state = s; });
        return state;
    },
    // NOTE:
    //   immutAssign({}, {a: 2, b: 3}, {a: 1}) => {a: 1, b: 3}
    //   immutAssign({}, {a: 2, '<b': null}, {a: 1}) => {a: 1}
    //   immutAssign({}, {a: {a1: 1, a2: 2}, b: 3}, {a: {a1: 2}}) => {a: {a1: 2, a2: 2}, b: 3}
    //   immutAssign({}, {a: {a1: 1, a2: 2}, b: 3}, {'>a': {a1: 2}}) => {a: {a1: 2}, b: 3}
    immutAssign: function (dest, source, value) {
        var index, doOverwrite, result = Object.assign(dest, source);
        for (var i in value) {
            if (value.hasOwnProperty(i)) {
                if (i.substring(-1, 1) === '>') {
                    doOverwrite = true;
                    index = i.substr(1);
                }
                else {
                    index = i;
                }
                if (i.substring(-1, 1) === '<') {
                    delete result[i.substr(1)];
                    continue;
                }
                if (helpers.isObject(value[i]) &&
                    typeof result[index] !== 'undefined' &&
                    !doOverwrite) {
                    result[index] = helpers.immutAssign(helpers.isArray(value[i]) ?
                        [] : {}, source[index], value[i]);
                }
                else {
                    result[index] = value[i];
                }
            }
        }
        return result;
    },
    deepAssign: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var options, name, src, copy, copyIsArray, clone;
        var target = arguments[0] || {}, i = 1, length = arguments.length;
        if (typeof target !== 'object' && !helpers.isFunction(target)) {
            target = {};
        }
        if (length === i) {
            target = this;
            --i;
        }
        for (; i < length; i++) {
            options = arguments[i];
            if (options !== null) {
                for (name in options) {
                    if (options.hasOwnProperty(name)) {
                        src = target[name];
                        copy = options[name];
                        if (target === copy) {
                            continue;
                        }
                        copyIsArray = helpers.isArray(copy);
                        if (copy && (helpers.isPlainObject(copy) || copyIsArray)) {
                            if (copyIsArray) {
                                copyIsArray = false;
                                clone = src && helpers.isArray(src) ? src : [];
                            }
                            else {
                                clone = src && helpers.isPlainObject(src) ? src : {};
                            }
                            target[name] = helpers.deepAssign(clone, copy);
                        }
                        else if (copy !== undefined) {
                            target[name] = copy;
                        }
                    }
                }
            }
        }
        return target;
    },
    disabledEventPropagation: function (e) {
        if (e) {
            if (e.stopPropagation) {
                e.stopPropagation();
            }
            else if (window.event) {
                window.event.cancelBubble = true;
            }
        }
    },
    isEmptyObject: function (obj) {
        return Object.keys(obj).length === 0 &&
            obj.constructor === Object;
    },
    getObjectsByKey: function (key, values) {
        if (!helpers.isObject(values)) {
            return;
        }
        var valuesKeys = Object.keys(values);
        var currents = valuesKeys.reduce(function (res, k) {
            if (values[k][key]) {
                res.push(k);
            }
            return res;
        }, []);
        if (currents.length > 0) {
            return currents;
        }
    },
    getNewObjectInDeepCamelCase: function (oldObj, isArray) {
        var key, camelKey, newObj, keys = Object.keys(oldObj);
        var n = keys.length;
        if (Array.isArray(oldObj) && !isArray) {
            return helpers.getNewObjectInDeepCamelCase(oldObj, true);
        }
        if (!isArray) {
            newObj = {};
            while (n--) {
                key = keys[n];
                camelKey = key.replace(/_\w/g, function (m) { return m[1].toUpperCase(); });
                if (camelKey === 'idRicetta') {
                    camelKey = 'id';
                }
                if (typeof oldObj[key] === 'object') {
                    newObj[camelKey] = helpers.getNewObjectInDeepCamelCase(oldObj[key], Array.isArray(oldObj[key]));
                }
                else {
                    newObj[camelKey] = oldObj[key];
                }
            }
        }
        else {
            newObj = [];
            for (var i = 0; i < n; i++) {
                if (typeof oldObj[i] === 'object') {
                    newObj.push(helpers.getNewObjectInDeepCamelCase(oldObj[i], Array.isArray(oldObj[i])));
                }
                else {
                    newObj.push(oldObj[i]);
                }
            }
        }
        return newObj;
    },
    getNewObjectInCamelCase: function (oldObj) {
        var key, camelKey, keys = Object.keys(oldObj);
        var n = keys.length;
        var newObj = {};
        while (n--) {
            key = keys[n];
            camelKey = key.replace(/_\w/g, function (m) { return m[1].toUpperCase(); });
            newObj[camelKey] = oldObj[key];
        }
        return newObj;
    },
    isFunction: function (value) {
        return typeof value === 'function';
    },
    isObject: function (value) {
        // http://jsperf.com/isobject4
        return value !== null && typeof value === 'object';
    },
    isArray: function (value) {
        return Array.isArray(value);
    },
    isWindow: function (obj) {
        // In IE8 window.window !== window.window, so we allow == here.
        return obj !== null && obj === obj.window;
    },
    isPlainObject: function (obj) {
        if (!obj || typeof obj !== 'object' ||
            obj.nodeType || helpers.isWindow(obj)) {
            return false;
        }
        try {
            if (obj.constructor &&
                !helpers.core_hasOwn.call(obj, 'constructor') &&
                !helpers.core_hasOwn.call(obj.constructor.prototype, 'isPrototypeOf')) {
                return false;
            }
        }
        catch (e) {
            return false;
        }
        var key;
        for (key in obj) {
            if (key) { }
        }
        return key === undefined || helpers.core_hasOwn.call(obj, key);
    },
    pascalCaseToCamelCase: function (str) {
        return str.charAt(0).toLowerCase() + str.substring(1);
    },
    camelCaseToPascalCase: function (str) {
        return str.charAt(0).toUpperCase() + str.substring(1);
    },
    camelCaseToSpaces: function (str) {
        return str
            .replace(/([A-Z])/g, ' $1')
            .split(' ')
            .map(function (word) { return word.charAt(0).toLowerCase() + word.substring(1); })
            .join(' ');
    },
    dashCaseToCamelCase: function (str) {
        return str.replace(/-([a-z])/ig, function (all, letter) {
            return letter.toUpperCase();
        });
    },
    snakeCaseToCamelCase: function (str) {
        return str.replace(/_([a-z])/ig, function (all, letter) {
            return letter.toUpperCase();
        });
    },
    dashCaseToSpaces: function (str) {
        return this.camelCaseToSpaces(this.dashCaseToCamelCase(str));
    },
    generateShortUID: function () {
        return new Date().getTime() + Math.floor(Math.random() * 100000);
    },
    generateUID: function (separator) {
        separator = separator || '-';
        function S4() {
            return (((1 + Math.random()) * 0x10000) || 0).toString(16).substring(1);
        }
        return (S4() + S4() +
            separator + S4() +
            separator + S4() +
            separator + S4() +
            separator + S4() +
            S4() + S4());
    },
    differenceInSecondsBetweensDates: function (date1, date2) {
        return Math.abs(Math.round((date1.getTime() - date2.getTime()) / 1000));
    },
    mmssfromSecs: function (secs) {
        var hours = Math.floor(secs / 3600), minutes = Math.floor((secs - (hours * 3600)) / 60), seconds = secs - (hours * 3600) - (minutes * 60);
        seconds = Math.round(seconds * 100) / 100;
        // return (hours < 10 ? '0' + hours : hours) + ':' +
        return (minutes < 10 ? '0' + minutes : minutes) +
            ':' + (seconds < 10 ? '0' + seconds : seconds);
    },
    /**
     * Pads text
     *
     * @param text
     *   Text to pad.
     *
     * @param length
     *   Total lenght of output string(s).
     *
     * @param position
     *   Where to add fill char:
     *     - 'start'
     *     - 'end'
     *
     * @param method
     *   Padding method:
     *     - 'multiline'
     *     - 'truncate'
     *     - 'ellipsis' (implies 'truncate')
     * @param fillChar
     *   Fill character
     */
    padExtra: function (text, maxLength, position, method, fillChar) {
        if (!text || !text.length) {
            return '';
        }
        var parts, output = [], returnEmpty = false, _pad, length = Math.min(text.length, maxLength);
        position = typeof position === 'undefined' ? 'start' : position;
        method = typeof method === 'undefined' ? 'truncate' : method;
        fillChar = typeof fillChar === 'undefined' ? ' ' : fillChar;
        _pad = function (stringPart) {
            var pad = Array(Math.max(0, maxLength - stringPart.length) + 1).join(fillChar), resultString = stringPart;
            return position == 'start' ? pad + resultString : resultString + pad;
        };
        parts = text.match(new RegExp(".{1," + length + "}", "g"));
        switch (method) {
            case 'multiline':
                parts.forEach(function (part) { return output.push(_pad(part)); });
                break;
            case 'truncate':
                if (parts.length) {
                    output.push(_pad(parts[0]));
                }
                break;
            case 'elipsis':
                output.push(_pad(parts[0]).substr(0, length - 3) + '...');
                break;
            default:
                returnEmpty = true;
        }
        if (returnEmpty) {
            return [];
        }
        return output;
    },
    isEquivalentObj: function (a, b) {
        if ((typeof a === 'undefined' && typeof b === 'undefined') || (a === null && b === null)) {
            return true;
        }
        if ((typeof a === 'undefined' && typeof b !== 'undefined') || (typeof b === 'undefined' && typeof a !== 'undefined')) {
            return false;
        }
        if ((a === null && b !== null) || (a !== null && b === null)) {
            return false;
        }
        var aProps = Object.getOwnPropertyNames(a);
        var bProps = Object.getOwnPropertyNames(b);
        if (aProps.length !== bProps.length) {
            return false;
        }
        for (var i = 0; i < aProps.length; i++) {
            var propName = aProps[i];
            if (a[propName] !== b[propName]) {
                return false;
            }
        }
        return true;
    },
    getNestedProp: function (keys, obj) {
        if (keys.length === 0 || typeof obj === 'undefined' || typeof obj === 'undefined' || obj === null) {
            return undefined;
        }
        var key = keys.shift();
        if (keys.length === 0) {
            if (typeof obj[key] !== 'undefined') {
                return obj[key];
            }
            return undefined;
        }
        if (typeof obj[key] !== 'undefined') {
            return this.getNestedProp(keys, obj[key]);
        }
        return undefined;
    }
};
