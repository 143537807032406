<div class="container">
  <nav class="navbar navbar-expand-lg navbar-dark">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault"
      aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarsExampleDefault">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" [ngClass]="{ 'active': ( currentPage | async ) === 'home' }">
          <a class="nav-link" href="" tabindex="0" routerLink="/home" title="Home page">Home <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item"  [ngClass]="{ 'active': ( currentPage | async ) === 'new' }">
          <a class="nav-link" href="" tabindex="0" routerLink="/new" title="Nuova struttura">Nuova struttura</a>
        </li>
        <li class="nav-item" [ngClass]="{ 'active': ( currentPage | async ) === 'edit' }">
          <a class="nav-link" href="" tabindex="0" routerLink="/edit"  title="Modifica struttura" >Modifica struttura</a>
        </li>
        <li class="nav-item" [ngClass]="{ 'active': ( currentPage | async ) === 'edit_impresa' }">
          <a class="nav-link" href="" tabindex="0" routerLink="/edit_impresa" title="Modifica impresa">Modifica impresa</a>
        </li>
        <li class="nav-item" [ngClass]="{ 'active': ( currentPage | async ) === 'bozze' }">
          <a class="nav-link" href="" tabindex="0" routerLink="/bozze" title="Bozze">Bozze
            <span class="badge badge-primary badge-pill" *ngIf="(bozze$ | async).length">{{(bozze$ | async).length}}</span>
          </a>
        </li>
        <li class="nav-item" [ngClass]="{ 'active': ( currentPage | async ) === 'search_impresa' }">
          <a class="nav-link" href="" tabindex="0"  routerLink="/search_impresa" title="Ricerca">Ricerca impresa</a>
        </li>
        <li class="nav-item" [ngClass]="{ 'active': ( currentPage | async ) === 'search' }">
          <a class="nav-link" href="" tabindex="0"  routerLink="/search" title="Ricerca">Ricerca struttura</a>
        </li>
      </ul>
      <div class="user-area align-self-stretch nav-item" [ngClass]="{ 'active': ( currentPage | async ) === 'login' }">
        <i class="fas fa-user-circle pr-2 pt-1 float-left"></i>
        <span class="" *ngIf="(isAuth$ | async) === true">
          <span class="username">{{(user$ | async).id | slice:0:16}}</span><br />
          <a (click)="logout()" tabindex="0" title="Esci">Esci</a>
        </span>
            <span class="d-inline-block"  *ngIf="!(isAuth$ | async)">
          <a class="d-inline-block mt-2 pt-1 btn-login" (click)="redirectLogin()" tabindex="0" title="Accedi">Accedi</a>
        </span>
      </div>
  </div>
  </nav>