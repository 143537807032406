/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./asr-bozze.component";
import * as i4 from "../../logic/user/user.selectors";
var styles_AsrBozzeComponent = [];
var RenderType_AsrBozzeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AsrBozzeComponent, data: {} });
export { RenderType_AsrBozzeComponent as RenderType_AsrBozzeComponent };
function View_AsrBozzeComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "li", [["class", "pt-1 pb-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "a", [["tabindex", "0"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i0.ɵpod(3, { type: 0, step: 1, id: 2 }), i0.ɵpad(4, 1), (_l()(), i0.ɵeld(5, 0, null, null, 8, "div", [["class", "bozza p-1 d-flex align-items-baseline justify-content-between"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "h4", [["class", "mb-0"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "progressbar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "div", [["class", "bar _60"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "div", [["class", "data text-right text-dark"]], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, [" ", " "])), i0.ɵppd(13, 2)], function (_ck, _v) { var currVal_2 = _ck(_v, 3, 0, "strut", "dati_prelim", _v.context.$implicit.idStrut); var currVal_3 = _ck(_v, 4, 0, "/edit"); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = (_v.context.$implicit.descrizioneidras ? _v.context.$implicit.descrizioneidras : "Senza Nome"); _ck(_v, 8, 0, currVal_4); var currVal_5 = i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v.parent.parent, 0), (((_v.context.$implicit == null) ? null : ((_v.context.$implicit.aggiornamento == null) ? null : _v.context.$implicit.aggiornamento.ultimoAgg)) + "Z"), "yyyy-MM-dd hh:mm:ss")); _ck(_v, 12, 0, currVal_5); }); }
function View_AsrBozzeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [["class", "lista_custom lista--bozze lista--full-page gray-theme p-0 m-0"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AsrBozzeComponent_2)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.results; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AsrBozzeComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "p-5 text-center placeholder"], ["id", "dati-riepilogo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "col-12 col-md-6 col-lg-12 riepilogo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "fas fa-file-alt mt-2 mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "dato"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Non hai pratiche in bozza "]))], null, null); }
export function View_AsrBozzeComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.DatePipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 9, "main", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "row mt-4 justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 7, "section", [["class", "col-12 col-md-8"], ["id", "search-results"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "header mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h2", [["class", "d-inline"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AsrBozzeComponent_1)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AsrBozzeComponent_3)), i0.ɵdid(10, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.results.length > 0); _ck(_v, 8, 0, currVal_1); var currVal_2 = (_co.results.length === 0); _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.results.length === 1) ? (_co.results.length + " Bozza") : (_co.results.length + " Bozze")); _ck(_v, 6, 0, currVal_0); }); }
export function View_AsrBozzeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "asr-bozze", [], null, null, null, View_AsrBozzeComponent_0, RenderType_AsrBozzeComponent)), i0.ɵdid(1, 245760, null, 0, i3.AsrBozzeComponent, [i4.UserSelectors], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AsrBozzeComponentNgFactory = i0.ɵccf("asr-bozze", i3.AsrBozzeComponent, View_AsrBozzeComponent_Host_0, {}, {}, []);
export { AsrBozzeComponentNgFactory as AsrBozzeComponentNgFactory };
