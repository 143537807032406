<main>
<section class="container mt-4 mb-2" id="grid-menu">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="grid-menu_item d-flex align-items-center" routerLink="/new" id="nuova-struttura-link">
          <p>
            <i class="fa fa-plus-circle"></i><br />
            nuova struttura
          </p>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="row">
          <div class="col-12 col-md-12 col-lg-6">
            <div class="grid-menu_item d-flex align-items-center" routerLink="/edit" id="modifica-struttura-link">
              <p>
                <i class="fa fa-bed"></i><br />
                <small>modifica</small><br />
                struttura
              </p>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-6">
            <div class="grid-menu_item d-flex align-items-center" routerLink="/edit_impresa" id="modifica-impresa-link">
              <p>
                <i class="fa fa-building"></i><br />
                <small>modifica</small><br />
                impresa
              </p>
            </div>
          </div>
          <div class="col-12">
            <div class="grid-menu_item d-flex align-items-center" routerLink="/search" id="ricerca-link">
              <p>
                <i class="fa fa-search"></i><br />
                ricerca
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
<asr-recap></asr-recap>
</main>
<div class="row mt-4" *ngIf="(this.isLoading$ | async) === true">
    <ngx-loading [show]="(this.isLoading$ | async) === true" [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop: true }" [template]="customLoadingTemplate"></ngx-loading>
</div>
  
