import { FlowState } from "./app-flow.interface";
import { ActionWithPayload } from "src/app/app.interfaces";
import { AppFlowActions } from "./app-flow.actions";
import { helpers } from "src/app/helpers";

export const initialFlowState: FlowState = {
    steps: [],
    current_step: null,
    current_item: null,
};

export function flowReducer(state = initialFlowState, { type, payload, loadingMessage }: ActionWithPayload) {

    let nextState;
    switch (type) {
        case AppFlowActions.SET_CURRENT_SECTION: {
            nextState = helpers.immutAssign({}, state, {
                steps: payload.steps,
                current_step: payload.current_step || null,
                current_item: payload.current_item || null,
            });
            return nextState;
        }

        case AppFlowActions.GO_TO_NEXT_STEP: {
            let index = state.steps.indexOf(state.current_step);
            if (index < 0 || index >= (state.steps.length - 1)) {
                return state;
            }
            nextState = helpers.immutAssign({}, state, {
                current_step: state.steps[index + 1]
            });
            return nextState;
        }

        case AppFlowActions.GO_TO_PREVIOUS_STEP: {
            let index = state.steps.indexOf(state.current_step);
            if (index <= 0 || index > (state.steps.length - 1)) {
                return state;
            }
            nextState = helpers.immutAssign({}, state, {
                current_step: state.steps[index - 1]
            });
            return nextState;
        }

        case AppFlowActions.SET_CURRENT_ITEM: {
            nextState = helpers.immutAssign({}, state, {
                current_item: payload
            });
            return nextState;
        }

        case AppFlowActions.SET_CURRENT_STEP: {
            {
                let index = state.steps.indexOf(payload);
                if (index < 0 || index > (state.steps.length - 1)) {
                    return state;
                }
                nextState = helpers.immutAssign({}, state, {
                    current_step: payload
                });
                return nextState;
            }
        }

        case AppFlowActions.LOAD_STRUT_AND_MOVE : {
            nextState = helpers.immutAssign({}, state, {
                steps: payload.steps,
                current_step: 'ricerca',
                current_item: null
            });
            return nextState;
        }

        case AppFlowActions.LOAD_IMPRESA_AND_MOVE :
        case AppFlowActions.LOAD_STRUT_AND_MOVE : {
            nextState = helpers.immutAssign({}, state, {
                steps: payload.steps,
                current_step: 'ricerca',
                current_item: null
            });
            return nextState;
        }

        case AppFlowActions.LOAD_IMPRESA_AND_MOVE_SUCCESS :
        case AppFlowActions.LOAD_STRUT_AND_MOVE_SUCCESS : {
            nextState = helpers.immutAssign({}, state, {
                current_step: payload.current_step,
                current_item: payload.current_item,
            });
            return nextState;
        }


        case AppFlowActions.LOAD_IMPRESA_AND_MOVE_FAIL :
        case AppFlowActions.LOAD_STRUT_AND_MOVE_FAIL: {
            nextState = helpers.immutAssign({}, state, {
                current_step: state.steps[0],
                current_item: null,
        });
            return nextState;
        }
    }
    return state;
}


