<div class="container">
    <div class="modal-header">
        <h3 class="modal-title"> <strong>{{data?.title}}</strong></h3>
    </div>
    <div class="modal-body">
        <p *ngIf="data?.results?.length === 0"><strong>Sei sicuro di voler cancellare questa struttura?</strong><br></p>
        <div class="accordion mb-3" id="accordionExample">
            <div class="card bg-light shadow">
                    <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne">
                        <h5 class="mb-0 h4">
                            <strong>{{data?.strut?.nome}}</strong>
                            <!-- <i class="fas fa-angle-up float-right"></i> -->
                        </h5>
                        <p class="m-0">
                            {{data?.strut?.comune}} {{data?.strut?.provincia}} {{data?.strut?.indirizzo}}
                        </p>
                    </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary"
            [ngClass]=" modalButton.class ?  modalButton.class : ''" *ngFor="let modalButton of data?.buttons"
            (click)="clickedButton(modalButton)">{{modalButton.label}}</button>
    </div>
</div>