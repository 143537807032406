import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { GenericSelectors } from 'src/app/logic/generic/generic.selectors';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil, timeoutWith } from 'rxjs/operators';
import { UserActions } from 'src/app/logic/user';
import { GenericActions } from 'src/app/logic/generic';
import { SearchImpresaActions, SearchImpresaSelectors } from 'src/app/logic/search-impresa';
@Component({
  selector: 'asr-search-impresa',
  templateUrl: './asr-search-impresa.component.html',
  queries: {
    'scrollerRef': new ViewChild('scrollerRef')
  },
})
export class AsrSearchImpresaComponent implements OnInit, OnDestroy {

  collapseSort;
  listResults$;
  listFacets$;
  queryString$;
  listShownMore$;
  listCollapsed$;
  resultsNum$;
  numOfPages$;
  pager$;
  fullTextSearchString;
  currentPage$;
  currentSearchState$;
  searchIsFiltered$;
  searchIsSorted$;
  numImprese$;
  isLoading$;
  querySubriber$;
  toggledGeoSearch$;
  boundigBox$;
  sort$;
  destroy$: Subject<boolean> = new Subject<boolean>();
  private currentSort;
  private sortSelected;
  public scrollerRef!: ElementRef;
  constructor(
    private store: Store<any>,
    private genericActions: GenericActions,
    private userActions: UserActions,
    private searchActions: SearchImpresaActions,
    private searchSelectors: SearchImpresaSelectors,
    private genericSelectors: GenericSelectors
  ) { }

  queryStringObserver = {
    next: (x) => {
      this.fullTextSearchString = x;
    },
    error: err => console.error('ModalObserver got an error: ' + err),
    complete: () => {}
  };

  ngOnInit() {
    this.store.dispatch(this.searchActions.searchStart());
    this.listResults$ = this.searchSelectors.getSearchResultsList$();
    this.listFacets$ = this.searchSelectors.getSearchFacets$();
    this.queryString$ = this.searchSelectors.getQueryString$();
    this.resultsNum$ = this.searchSelectors.getSearchNumResults$();
    this.listShownMore$ = this.searchSelectors.getShownMore$();
    this.listCollapsed$ = this.searchSelectors.getCollapsed$();
    this.numOfPages$ = this.searchSelectors.getNumOfPages$();
    this.pager$ = this.searchSelectors.getPager$();
    this.currentPage$ = this.searchSelectors.getCurrentPage$();
    this.currentSearchState$ = this.searchSelectors.getCurrentSearchState$();
    this.searchIsFiltered$ = this.searchSelectors.searchIsFiltered$();
    this.searchIsSorted$ = this.searchSelectors.searchIsSorted$();
    this.numImprese$ = this.genericSelectors.getNumImprese$();
    this.isLoading$ = this.genericSelectors.isLoading$();
    this.searchSelectors.getQueryString$().pipe(takeUntil(this.destroy$)).subscribe(this.queryStringObserver);
    this.sort$ = this.searchSelectors.getSort$();
    this.searchSelectors.getSort$().pipe(takeUntil(this.destroy$)).subscribe((x) => {
      this.currentSort = x && JSON.parse(JSON.stringify(x));
      this.sortSelected = this.getSortSelected(this.currentSort);
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  setFilter(faceName, value, selected) {
    this.store.dispatch(this.searchActions.searchSetFilter({ facetName: faceName, value: value, selected: !selected }));
    this.resetScroller();
  }

  toggleShownMore(faceName) {
    this.store.dispatch(this.searchActions.searchToggleShowMore({ facetName: faceName }));
  }

  toggleCollapse(faceName) {
    this.store.dispatch(this.searchActions.searchToggleCollapse({ facetName: faceName }));
  }

  displayStrutModal(id, nome){
    this.store.dispatch(this.userActions.getStrutByImpresa({id: id, nome: nome}))
  }

  toggleCollapseSort(){
    this.collapseSort = !this.collapseSort;
  }

  fullTextKeyUp(event) {
    if (event.keyCode === 13 || event.which === 13) {
      this.store.dispatch(this.searchActions.searchFullText(this.fullTextSearchString));
      this.resetScroller();
    }
  }

  goToPage(page) {
    this.store.dispatch(this.searchActions.searchChangeResultsPage(page));
    this.resetScroller();
  }

  movePageBy(val) {
    this.store.dispatch(this.searchActions.searchChangeResultsPageBy(val));
    this.resetScroller();
  }

  doClearFilters() {
    this.store.dispatch(this.searchActions.searchClearFilters());
    this.resetScroller();
  }

  doClearSort() {
    this.store.dispatch(this.searchActions.searchSort({}));
    this.resetScroller();
  }

  searchAutocompleteFactory(facetList$: any): (text: Observable<string>) => Observable<any[]> {
    const debTime = 200;
    return (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(term => term.length < 2 ? []
          : facetList$.filter(v => v.label.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 5))
      );
  }

  formatter = (result) => result['label'].toUpperCase();

  onSelectItem(facet, facetsName) {
    this.setFilter(facetsName, facet.item.value, facet.item.selected);
    this.resetScroller();
  }

  setOrder(field, order) {
    let nextOrder = 'none';
    if (order === 'none') {
      nextOrder = 'asc';
    } else if (order === 'asc') {
      nextOrder = 'desc';
    }
    if (this.currentSort && this.currentSort[field]) {
      this.currentSort[field]['order'] = nextOrder;
    }
    this.store.dispatch(this.searchActions.searchSort(this.currentSort));
  }

  private resetScroller() {
    if (this.scrollerRef && this.scrollerRef.nativeElement) {
      this.scrollerRef.nativeElement.scrollTo(0, 0);
    }
  }

  private getSortSelected(sort) {
    let selected = {};
    if (sort) {
      for (const sortKey in sort) {
        if (sort.hasOwnProperty(sortKey)) {
          const element = sort[sortKey];
          if (element['order'] === 'desc' || element['order'] === 'asc') {
            selected[sortKey] = true;
          }
        }
      }
    }

    return selected;
  }

  onKeydownFacetItem(event, facetName, facetValue, facetSelected){
    if(event.code === 'Space'){
      this.setFilter(facetName,facetValue,facetSelected);
    }
  }

}
