export function getGenericStatsResponse() {
  return {
    numImp: 4862,
    numStrut: 7325
  };
};

export function getComuniResponse() {
  return {
    status: 'OK',
    payload: {
      response: {
        11111: 'comune1',
        11112: 'comune2',
        11113: 'comune3',
        11114: 'comune4',
        11115: 'comune5',
        11116: 'comune6',
        11117: 'comune7',
        11118: 'comune8',
        1111: 'comune11',
        1112: 'comune12',
        1113: 'comune13',
        1114: 'comune14',
        1115: 'comune15',
        1116: 'comune16',
        1117: 'comune17',
        1118: 'comune18',
      }
    }
  }
};

export function getProvincieResponse() {
  return {
    status: 'OK',
    payload: {
      response: {
        11111: 'provincia1',
        11112: 'provincia2',
        11113: 'provincia3',
        11114: 'provincia4',
        11115: 'provincia5',
        11116: 'provincia6',
        11117: 'provincia7',
        11118: 'provincia8',
      }
    }
  }
};

export function getAutocompleteImpresaResponse() {
  return {
    status: 'OK',
    payload: {
      results: [
        {id: 1,
         dataEntry: {
          rappresentante:{
            cognomeRappr: 'Rossi',
            nomeRappr: 'Mario',
            dataNascita: '10/11/2011',
            luogoNascita: 'ad',
            codiceFiscale: 'zzzzzasd0234',
            cittadinanza: 'italiana',
            provinciaResidenza: 'Nuoro',
            comuneResidenza: 'Nuoro',
            capResidenza: '01234',
            indirizzoResidenza: 'via fasulla 1',
            telefono: '1111-2222',
            email: 'email@mail.it'
          },
          ragioneSociale: 'Ragione sociale',
          formaGiuridica: {sigla: 'S.p.a'},
          codiceFiscale: 'az13ed6uj',
          partitaIva: '12345678',
          indirizzo: {codiceComuneIstat: 11111, provincia: 11111, indirizzo: 'via fasulla 2'},
          telefono: '123456',
          fax: '12345675423',
          email: 'emai@mail2.it',
          pec: 'pec@pec.it',
          note: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa'
         }
        },
        {id: 1,
          dataEntry: {
           rappresentante:{
             cognomeRappr: 'Rossi',
             nomeRappr: 'Mario',
             dataNascita: '10/11/2011',
             luogoNascita: 'ad',
             codiceFiscale: 'zzzzzasd0234',
             cittadinanza: 'italiana',
             provinciaResidenza: 'Nuoro',
             comuneResidenza: 'Nuoro',
             capResidenza: '01234',
             indirizzoResidenza: 'via fasulla 1',
             telefono: '1111-2222',
             email: 'email@mail.it'
           },
           ragioneSociale: 'Ragione sociale',
           formaGiuridica: {sigla: 'S.p.a'},
           codiceFiscale: 'az13ed6uj',
           partitaIva: '12345678',
           indirizzo: {codiceComuneIstat: 11111, provincia: 11111, indirizzo: 'via fasulla 2'},
           telefono: '123456',
           fax: '12345675423',
           email: 'emai@mail2.it',
           pec: 'pec@pec.it',
           note: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa'
          }
         },
         {id: 1,
          dataEntry: {
           rappresentante:{
             cognomeRappr: 'Rossi',
             nomeRappr: 'Mario',
             dataNascita: '10/11/2011',
             luogoNascita: 'ad',
             codiceFiscale: 'zzzzzasd0234',
             cittadinanza: 'italiana',
             provinciaResidenza: 'Nuoro',
             comuneResidenza: 'Nuoro',
             capResidenza: '01234',
             indirizzoResidenza: 'via fasulla 1',
             telefono: '1111-2222',
             email: 'email@mail.it'
           },
           ragioneSociale: 'Ragione sociale',
           formaGiuridica: {sigla: 'S.p.a'},
           codiceFiscale: 'az13ed6uj',
           partitaIva: '12345678',
           indirizzo: {codiceComuneIstat: 11111, provincia: 11111, indirizzo: 'via fasulla 2'},
           telefono: '123456',
           fax: '12345675423',
           email: 'emai@mail2.it',
           pec: 'pec@pec.it',
           note: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa'
          }
         }
      ]
    }
  };
}

