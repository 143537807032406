import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {APP_IMPORTS} from './app.import';
// import * as components from './components';
// import * as views from './views';
import {AsrFooterComponent,
  AsrHeaderComponent,
  AsrNavBarComponent,
  AsrRecapComponent,
  AsrContainerComponent,
  AsrSearchBarComponent,
  AsrSubSectionComponent,
  AsrMapContainer,
  AsrSectionComponent
} from './components';
import {
  AsrHomeComponent,
  AsrSearchComponent,
  AsrEditComponent,
  AsrEditImpresaComponent,
  AsrInsertComponent,
  AsrBozzeComponent,
  AsrSearchImpresaComponent
} from './views';
import { GenericActions,  GenericRequests, GenericSelectors} from './logic/generic';
import { UserActions, UserRequests, UserSelectors } from './logic/user';
import { SearchActions, SearchRequests, SearchSelectors } from './logic/search';
import {SafePipe} from './pipes';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from './components/asr-date-formatter/asr-date-formatter.service';
import { AsrAuthGuard } from './services';
import { ControlHandlerFactory } from './form-generator/control-handlers/control-handlers';
import { HttpService } from './form-generator/control-handlers/control-handler.interface';
import { AppFlowActions } from './logic/app-flow/app-flow.actions';
import { AppFlowSelectors } from './logic/app-flow/app-flow.selectors';
import { AsrSummaryComponent } from './components/asr-summary';
import { SearchImpresaActions, SearchImpresaSelectors, SearchImpresaRequests } from './logic/search-impresa';
import { AsrErrorModalComponent, AsrEditModalComponent } from './components/asr-modals';
import { AsrDuplicatesModalComponent } from './components/asr-modals/asr-duplicates-modal';
import { AsrStruttureModalComponent } from './components/asr-modals/asr-srutture-modal';
import { AsrDeleteModalComponent } from './components/asr-modals/asr-delete-modal';
import { AsrRenameModalComponent } from './components/asr-modals/asr-rename-modal';
import { AsrPreviewModalComponent } from './components/asr-modals/asr-preview-modal';
import { UserService } from './logic/user/user.service';
@NgModule({
  declarations: [
    AppComponent,
    // ...Object.keys(components).map(key => components[key]),
    // ...Object.keys(views).map(key => views[key])
    AsrFooterComponent,
    AsrHeaderComponent,
    AsrHomeComponent,
    AsrNavBarComponent,
    AsrRecapComponent,
    AsrSearchComponent,
    AsrSearchImpresaComponent,
    AsrEditComponent,
    AsrEditImpresaComponent,
    AsrSearchBarComponent,
    AsrContainerComponent,
    AsrSectionComponent,
    AsrSummaryComponent,
    AsrMapContainer,
    AsrSubSectionComponent,
    AsrInsertComponent,
    AsrBozzeComponent,
    AsrErrorModalComponent,
    AsrEditModalComponent,
    AsrDuplicatesModalComponent,
    AsrStruttureModalComponent,
    AsrDeleteModalComponent,
    AsrRenameModalComponent,
    AsrPreviewModalComponent,
    SafePipe,
  ],
  imports: APP_IMPORTS,
  providers: [
    SearchActions,
    SearchImpresaActions,
    AppFlowActions,
    AppFlowSelectors,
    SearchRequests,
    SearchSelectors,
    SearchImpresaRequests,
    SearchImpresaSelectors,
    GenericActions,
    GenericSelectors,
    GenericRequests,
    UserActions,
    UserRequests,
    UserSelectors,
    AsrAuthGuard,
    ControlHandlerFactory,
    HttpService,
    UserService,
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  ],
  bootstrap: [AppComponent],
  entryComponents: [AsrEditModalComponent, AsrRenameModalComponent, AsrDeleteModalComponent, AsrStruttureModalComponent, AsrDuplicatesModalComponent, AsrErrorModalComponent, AsrPreviewModalComponent]
})
export class AppModule { }
