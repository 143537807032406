<ng-container [ngSwitch]="model.type">
  <ng-template [ngSwitchCase]="'input'">
    <div class="form-group">
      <label for="{{model.id}}">{{model.required ? model.label + ' *': model.label }}</label>
      <input type="text" class="form-control" tabindex="0"
        [attr.disabled]="model.notEdit || this.model.control.disabled ? true : null"
        [ngClass]="{ 'is-invalid': (this.model.control?.invalid) && (this.model.control?.dirty || this.model.control?.touched) }"
        id="{{model.id}}"  [formControl]="this.model.control">
      <div class="invalid-feedback">
        <div *ngIf="this.model.control.errors && this.model.control.errors.required">
          {{model.label}} obbligatiorio.
        </div>
        <div *ngIf="this.model.control?.errors && this.model.control?.errors.pattern">
          Valore {{model.label}} non consentito.
        </div>
        <div *ngIf="this.model.control?.errors && this.model.control?.errors.maxlength">
          Il campo può avere una lunghezza massima di {{model.validators.maxLength}}.
        </div>
        <div *ngIf="this.model.control?.errors && this.model.control?.errors.minlength">
          Il campo deve avere una lunghezza minima di {{model.validators.minLength}}.
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'select'">
    <div class="form-group">
      <label for="{{model.id}}">{{model.required ? model.label + ' *': model.label }}</label>
      <select class="form-control" [formControl]="this.model.control" id="{{model.id}}" tabindex="0"
        [ngClass]="{ 'is-invalid': (this.model.control?.invalid) && (this.model.control?.dirty || this.model.control?.touched) }">
        <option *ngFor="let option of model.options | keyvalue: model?.sortOptions" [value]="option.key">
          {{ model.display && option.value ? option.value[model.display] : option.value}}</option>
        <option *ngFor="let option of model.fixedOptions | keyvalue" [value]="option.key">
          {{ model.display ? option.value[model.display] : option.value}}</option>
      </select>
      <div class="invalid-feedback">
        <div *ngIf="this.model.control?.errors && this.model.control?.errors.required">
          {{model.label}} obbligatiorio.
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'number'">
    <div class="form-group">
      <label for="{{model.id}}">{{model.required ? model.label + ' *': model.label }}</label>
      <input type='number' class="form-control" id="{{model.id}}"  [formControl]="this.model.control" tabindex="0"
        [attr.disabled]="model.notEdit || this.model.control.disabled ? true : null"
        [ngClass]="{ 'is-invalid': (this.model.control?.invalid) && (this.model.control?.dirty || this.model.control?.touched) }"
        step="any">
      <div class="invalid-feedback">
        <div *ngIf="this.model.control?.errors && this.model.control?.errors.required">
          {{model.label}} obbligatiorio.
        </div>
        <div
          *ngIf="this.model.control?.errors && (this.model.control?.errors.min || this.model.control?.errors.max || this.model.control?.errors.pattern)">
          Valori consentiti:
          ({{ model.validators.min || model.validators.min === 0 ? 'min: ' + model.validators.min: ''}}
          {{(model.validators.max || model.validators.max  === 0) && (model.validators.min || model.validators.min  === 0) ? ',' : '' }}
          {{ model.validators.max || model.validators.max  === 0 ? 'max: ' + model.validators.max + '' : '' }})
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'email'">
    <div class="form-group">
      <label for="{{model.id}}">{{model.required ? model.label + ' *': model.label }}</label>
      <input type="email" class="form-control" id="{{model.id}}" [formControl]="this.model.control" tabindex="0"
        [ngClass]="{ 'is-invalid': (this.model.control?.invalid) && (this.model.control?.dirty || this.model.control?.touched) }"
        email>
      <div class="invalid-feedback">
        <div *ngIf="this.model.control?.errors && this.model.control?.errors.required">
          {{model.label}} obbligatiorio.
        </div>
        <div *ngIf="this.model.control?.errors && this.model.control?.errors.email">
          Inserire una email valida.
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'radio'">
    <div class="form-group" tabindex="0">
      <label for="{{model.id}}"> {{model.required ? model.label + ' *': model.label }}</label>
      <ng-container *ngFor="let option of model.options | keyvalue: model?.sortOptions;  let isLast = last">
        <div class="form-check mb-1" >
          <input class="form-check-input" type="radio"  [value]="option.key" tabindex="0"
            [attr.disabled]="model.notEdit || this.model.control.disabled ? true : null"
            [formControl]="this.model.control" name="{{model.id}}" id="{{model.id+'-'+option.key}}">
          <label class="form-check-label" for="{{model.id+'-'+option.key}}">
            {{option.value}}
          </label>
          <div
            *ngIf="isLast && (this.model.control?.invalid) && (this.model.control?.dirty || this.model.control?.touched)"
            class="invalid-feedback d-block">
            <div *ngIf="this.model.control?.errors && this.model.control.errors.required">
              {{model.label}} obbligatiorio.
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'checkbox'">
    <div class="form-row mt-2 mb-1" [formGroup]="model.control">
      <h4 class="text-capitalize" (click)="model.collapsable && model.collapsed = !model.collapsed">
        {{model.required ? model.label + ' *': model.label }}
        <i class="fa float-right m-1" *ngIf="model.collapsable"
          [ngClass]="{ 'fa-angle-up': !model.collapsed, 'fa-angle-down': model.collapsed }"></i></h4>
      <ng-container *ngIf="!model.collapsable || !model.collapsed">
        <div class="form-check ml-3 d-inline col-12">
          <input class="form-check-input mb-1" type="checkbox" name="{{model.id+'-'+'selectall'}}" tabindex="0"
            [attr.disabled]="model.notEdit || this.model.control.disabled ? true : null"
            id="{{model.id+'-'+'selectall'}}" [formControl]="model.control.get('selectall')">
          <label class="form-check-label strong" for="{{model.id+'-'+'selectall'}}">
            {{ model.selectedAll ? 'Deseleziona tutto' : 'Seleziona tutto' }}
          </label>
        </div>

        <div class="ml-3 col-md-3 col-sm-3"  [formGroup]="model.control.get('options')"
          *ngFor="let option of model.options | keyvalue">
          <input class="form-check-input" type="checkbox" name="{{model.id+'-'+option.key}}"
            [attr.disabled]="model.notEdit || this.model.control.disabled ? true : null"
            id="{{model.id+'-'+option.key}}" formControlName="{{option.key}}">
          <label class="form-check-label" for="{{model.id+'-'+option.key}}">
            {{option.value}}
          </label>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'add'">
    <div class="form-group">
      <label for=" {{model.id}}">&nbsp;</label><br />
      <button class="btn btn-link mt-1" [(ngModel)]="model.value" (click)="model.value = !model.value" type="button"><i
          class="fas fa-plus-circle"></i>
        aggiungi</button>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'textfield'">
    <div class="form-group position-relative">
      <label for="{{model.id}}">{{model.required ? model.label + ' *': model.label }}</label>
      <input class="form-control" type="search" [editable]="false" [formControl]="model.control" tabindex="0"
        [ngbTypeahead]="model.search" [resultFormatter]="model.formatter" [inputFormatter]="model.formatter"
        [resultTemplate]="rt"
        
        [ngClass]="{ 'is-invalid': (model.control?.invalid) && (model.control?.dirty || model.control?.touched) }">
      <div class="invalid-feedback">
        <div *ngIf="model.control.errors && model.control.errors.required">
          {{model.label}} obbligatiorio.
        </div>
        <div *ngIf="model.control.errors && model.control.errors.invalidChoice">
          Selezionare uno degli elementi della lista.
        </div>
      </div>
    </div>

      <ng-template #rt let-r="result" let-t="term">
        <ngb-highlight [result]="model.formatter(r)"  [term]="t" *ngIf="!r.custom"></ngb-highlight>
        <span *ngIf="r.custom">{{model.formatter(r)}}</span>
      </ng-template>
  </ng-template>

  <ng-template [ngSwitchCase]="'search_step'">
    <section class="pt-4 pb-4 text-center" id="search-modifify">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-6">
          <h2>{{model.label}}</h2>
          <div class="search-container mt-3">
            <input class="form-control form-control-lg" placeholder="{{model.placeholder}}" type="search" tabindex="0"
              [editable]="false" [formControl]="model.control" [ngbTypeahead]="model.search"
              [resultFormatter]="model.formatter" [inputFormatter]="model.formatter" />
          </div>
        </div>
      </div>
    </section>
  </ng-template>

  <ng-template [ngSwitchCase]="'textarea'">
    <div class="form-group">
      <label for="{{model.id}}">{{model.required ? model.label + ' *': model.label }}</label>
      <textarea  class="form-control" id="{{model.id}}" rows="3" [formControl]="model.control" tabindex="0"
        [ngClass]="{ 'is-invalid': (model.control?.invalid) && (model.control?.dirty || model.control?.touched) }">
            </textarea>
      <div class="invalid-feedback">
        <div *ngIf="this.model.control.errors && this.model.control.errors.required">
          {{model.label}} obbligatiorio.
        </div>
        <div *ngIf="this.model.control?.errors && this.model.control?.errors.pattern">
          Valore {{model.label}} non consentito.
        </div>
        <div *ngIf="this.model.control?.errors && this.model.control?.errors.maxlength">
          Il campo può avere una lunghezza massima di {{model.validators.maxLength}}.
        </div>
        <div *ngIf="this.model.control?.errors && this.model.control?.errors.minlength">
          Il campo deve avere una lunghezza minima di {{model.validators.minLength}}.
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'date'">
    <div class="form-group">
      <label for="">{{model.required ? model.label + ' *': model.label }}</label>
      <div class="input-group mb-3">
        <input type="text"  class="form-control" placeholder="Scegli una data dd/mm/yyyy" ngbDatepicker tabindex="0"
          [minDate]="model.minDate" [maxDate]="model.maxDate" #d="ngbDatepicker" [formControl]="model.control"
          [ngClass]="{ 'is-invalid': (this.model.control?.invalid) && (this.model.control?.dirty || this.model.control?.touched) }">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" (click)="d.toggle()" tabindex="0"><i
              class="fas fa-calendar-alt"></i>
          </button>
        </div>
        <div class="invalid-feedback">
          <div *ngIf="model.control.errors && model.control.errors.required">
            {{model.label}} obbligatiorio.
          </div>
          <div *ngIf="model.control.errors && model.control.errors.ngbDate">
            Formato data errato. Usare il formato dd/mm/yyyy
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'info'">
    <div class="col-12 col-md-6">
      <p>
        <strong>{{model.label}}</strong><br />
        {{model.value ? model.value : '-'}}
      </p>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'map'">
    <div class="row">
      <div class="col-12 col-md-6 p-3">
        <div class="w-100" style="height: 400px" [leafletOptions]="model.mapOptions()"
          (leafletMapReady)="model._onMapReady($event)" [leafletLayer]="model._getLayers()" leaflet>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-3">
        <div class="form-group">
          <div class="form-check mb-1">
            <input class="form-check-input" type="checkbox" name="{{model.id+'-toggle'}}" tabindex="0" id="{{model.id + '-toggle'}}"
               [formControl]="model.toggle">
            <label class="form-check-label" for="{{model.id+'-toggle'}}">
              Geocoding
            </label>
          </div>

        </div>
        <div class="form-group" [formGroup]="model.control">
          <label for="{{model.id + '-lat'}}">Latitudine *</label>
          <input type="text" class="form-control" id="{{model.id + '-lat'}}"  formControlName="lat">
        </div>
        <div class="form-group" [formGroup]="model.control">
          <label for="{{model.id + '-lng'}}">Longitudine *</label>
          <input type="text" class="form-control" id="{{model.id + '-lng'}}"  formControlName="lng">
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'button'">
    <div class="btn btn-primary" (click)="parentController.openModal(model.target)"><i class="far fa-edit"></i>{{this.model.label}}</div>
  </ng-template>

  <ng-template [ngSwitchCase]="'date-range'">
    <div class="row">
      <div class="col-12 form-group">
        <h4 class="separatortitle">Periodo disponibilità </h4>
      </div>

      <ng-container *ngFor="let group of model.control.controls; first as isFirst; last as isLast, index as i">

        <div class="col-4 form-group " [formGroup]="group">
          <label for="{{model.it + '-dal'}}">dal</label>
          <div class="input-group">
            <input type="text"  class="form-control" placeholder="formato dd/mm/yyyy" ngbDatepicker tabindex="0"
              [maxDate]="group.controls.al.value"
              [ngClass]="{ 'is-invalid': (group.invalid) && (group.dirty || group.touched) }" #f="ngbDatepicker"
              formControlName="dal">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" type="button" (click)="f.toggle()" tabindex="0"><i
                  class="fas fa-calendar-alt"></i>
              </button>
            </div>

          </div>
        </div>
        <div class="col-4 form-group" [formGroup]="group">
          <label for="{{model.it + '-al'}}">al</label>
          <div class="input-group">
            <input type="text"  class="form-control" placeholder="formato dd/mm/yyyy" ngbDatepicker tabindex="0"
              [minDate]="group.controls.dal.value"
              [ngClass]="{ 'is-invalid': (group.invalid) && (group.dirty || group.touched) }" #t="ngbDatepicker"
              formControlName="al">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" type="button" (click)="t.toggle()" tabindex="0"><i
                  class="fas fa-calendar-alt"></i>
              </button>
            </div>
          </div>
        </div>

  
          <ng-container *ngIf="model.maxRepeat !== 0">
              <ng-container *ngIf="!model.maxRepeat || isFirst|| (i < model.maxRepeat )">
                  <button class="btn btn-link mt-1" (click)="model.addItem(model,i)" type="button"><i
                      class="fas fa-plus-circle"></i>
                    aggiungi</button>
                </ng-container>
                <ng-container *ngIf="!(isLast && isFirst)">
                  <button class="btn btn-link mt-1" (click)="model.removeItem(model,i)" type="button"><i
                      class="fas fa-plus-circle"></i>
                      elimina</button>
                </ng-container>    
          </ng-container>
    
      </ng-container>
    </div>
  </ng-template>

</ng-container>