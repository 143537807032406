import { Component, Input } from "@angular/core";
import { FormGeneratorInterface } from "src/app/form-generator";

@Component({
    selector: 'asr-subsection, [asr-subsection]',
    templateUrl: './asr-subsection.component.html',
  })
  export class AsrSubSectionComponent implements FormGeneratorInterface {
    @Input() model;
    @Input() parentController;
}

