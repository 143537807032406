import { Injectable } from '@angular/core';
import { Actions, Effect, } from '@ngrx/effects';
import {  of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom, debounceTime } from 'rxjs/operators';
import { SearchActions } from './search.actions';
import { SearchRequests } from './search.requests';
import { environment } from 'src/environments/environment';
import { getSearchResponse } from './search.mocks';
import { SearchSelectors } from './search.selectors';
import {merge} from 'rxjs';
import { GenericActions } from '../generic';


@Injectable()
export class SearchEffects {

  constructor(private actions$: Actions,
    private searchActions: SearchActions,
    private searchRequest: SearchRequests,
    private searchSelector: SearchSelectors,
    private genericActions: GenericActions
  ) {
    /*do nothing*/
  }

  @Effect() doSearch$ =
  merge(
    this.actions$.ofType(SearchActions.SEARCH_START),
    this.actions$.ofType(SearchActions.SEARCH_SET_FILTER),
    this.actions$.ofType(SearchActions.SEARCH_SORT),
    this.actions$.ofType(SearchActions.SEARCH_CHANGE_RESULTS_PAGE),
    this.actions$.ofType(SearchActions.SEARCH_CHANGE_RESULTS_PAGE_BY),
    this.actions$.ofType(SearchActions.SEARCH_SET_BOUNDING_BOX)
).pipe(
      map(action => action['payload']),
      withLatestFrom(this.searchSelector.getSearchRequest$()),
      switchMap(([, request]) => (environment.requests.search.mock ?
        of(this.searchActions.searchSuccess(getSearchResponse().payload)) :
        this.searchRequest.requestSearch$(request).pipe(
          switchMap(response => response['status'] === 'OK' ?
            of(this.searchActions.searchSuccess(response['payload'])) :
            of(this.searchActions.searchFail(response['payload']['message']))
          ),
        catchError( (err) => [this.genericActions.showErrorModal({
          title: 'Errore',
          message: 'Si è verificato un errore durante la ricerca',
          buttons: [{id: 'close', label: 'Chiudi', class: '', action: '' , autoclose: true }]})
          , this.searchActions.searchFail(err)]))
        )
        )
    );

   @Effect() doSearchFullText$ =  merge(
    this.actions$.ofType(SearchActions.SEARCH_FULL_TEXT),
    this.actions$.ofType(SearchActions.SEARCH_CLEAR_FILTERS)
    ).pipe(
       map(action => action['payload']),
       withLatestFrom(this.searchSelector.getFullTextSearchRequest$()),
       switchMap(([, payload]) => (environment.requests.search.mock ?
        of(this.searchActions.searchFullTextSuccess(getSearchResponse().payload)) :
        this.searchRequest.requestSearch$(payload).pipe(
         switchMap(response => response['status'] === 'OK' ?
           of(this.searchActions.searchFullTextSuccess(response['payload'])) :
           of(this.searchActions.searchFullTextFail(response['payload']['message']))
          ),
          catchError(err => [this.genericActions.showErrorModal({
            title: 'Errore',
            message: 'Si è verificato un errore durante la ricerca',
            buttons: [{id: 'close', label: 'Chiudi', autoclose: true }], code: 1}),
             this.searchActions.searchFail(err)])
         )))
      );

}
