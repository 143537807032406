import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { of } from "rxjs";
import { getFetchBozzeResponse } from "./user.mocks";

@Injectable()
export class UserRequests {
    httpOptions: {};
    constructor( private http: HttpClient ) {
        this.httpOptions = {
            headers: new HttpHeaders( {
                'Content-Type': 'application/json'
            } ),
            withCredentials: true
        };
    }

    requestLogin$( user ) {
        const url = environment.baseURL + environment.requests.login.path;
        return this.http.get( url + '?code=' + user, {withCredentials: true} );
    }

    requestIsLogged$() {
        const url = environment.baseURL + environment.requests.isLogged.path;
        return this.http.get(url, { withCredentials: true});
    }

    requestLogout$() {
        const url = environment.baseURL + environment.requests.logout.path;
        return this.http.get(url, {withCredentials: true});
    }

    requestPostFile$( data ) {
        let file: File = data;
        let formData: FormData = new FormData();
        formData.append( 'uploadFile', file );
        let httpOptions = {
            headers: new HttpHeaders( {
                'Accept': 'application/json'
            } ),
            withCredentials: true
        };

        return this.http.post( environment.baseURL + environment.requests.uploadFile.path, formData, httpOptions );
    }

    requestDownloadFile$( id ) {
        return this.http.get( environment.baseURL + environment.requests.downloadFile.path + '?id=' + id, { responseType: 'arraybuffer', withCredentials:true }, );
    }

    requestInsertStruttura$( dataEntry ) {
        return this.http.post( environment.baseURL + environment.requests.insertStruttura.path, dataEntry,this.httpOptions );
    }

    requestEditStruttura$( dataEntry ) {
        return this.http.post( environment.baseURL + environment.requests.modificaStruttura.path, dataEntry,  this.httpOptions );
    }

    requesDeleteStruttura$( dataEntry ) {
        return this.http.post( environment.baseURL + environment.requests.cancellaStruttura.path, dataEntry,  this.httpOptions );
    }

    requestEditImpresa$( dataEntry ) {
        return this.http.post( environment.baseURL + environment.requests.modificaImpresa.path, dataEntry,  this.httpOptions );
    }

    getBozze$( id ) {
        if ( environment.requests.fetchBozze.mock === true) {
            return of(getFetchBozzeResponse());
        }
        return this.http.get( environment.baseURL + environment.requests.fetchBozze.path+"?idUtente=" + id, this.httpOptions );
    }

    getStruttureByImpresa$( id ) {
        if ( environment.requests.fetchBozze.mock === true) {
            return of(getFetchBozzeResponse());
        }
        return this.http.get( environment.baseURL + environment.requests.fetchStrutByImpresa.path+"?id=" + id, this.httpOptions );
    }

    fetchDataEntryStruttura$( id, type ) {
        const path = environment.baseURL + environment.requests.fetchDataEntryStruttura.path;
        let param = new HttpParams();

        switch ( type ) {
            case 'agr':
                param = param.set( 'idAgriturismo', id );
                break;
            default:
                param = param.set( 'idStruct', id );
                break;
        }
        param = param.set( 'index', 'struttura' );
        return this.http.get( path, { params: param , withCredentials: true} );
    }

    fetchImpresa$( id ) {
        const path = environment.baseURL + environment.requests.fetchImpresa.path;
        let param = new HttpParams();
        param = param.set( 'id', id );
        param = param.set( 'index', 'impresa' );
        return this.http.get( path, { params: param , withCredentials: true} );
    }

    fetchDuplicati$( nome, comune ) {
        const path = environment.baseURL + environment.requests.fetchStruttureDuplicate.path;
        const data = { comune: nome, descrizioneidras: comune };
        return this.http.post( path, data, { withCredentials: true} );
    }
}