import { ActionWithPayload } from "src/app/app.interfaces";
import { GenericState } from "./generic.interfaces";
import { GenericActions } from "./generic.actions";
import { helpers } from "../../helpers";

export const initialGenericState: GenericState = {
    numImprese : 0,
    numStrutture : 0,
    isLoading: false,
    loadingMessage: '',
    errorModalResult: null,
    duplicatesModalResult: null,
    struttureModalResult: null,
    renameModalResult: null,
    deleteModalResult: null,
    editModalResult: null,
    previewModalResult: null,
    loadingQueue: 0,
};

export function genericReducer(state = initialGenericState, { type, payload, loadingMessage }: ActionWithPayload) {

    let nextState;
    switch (type) {
        case GenericActions.GENERIC_GET_INFO: {
            nextState = helpers.immutAssign({}, state, {
            });
            return nextState;
        }

        case GenericActions.GENERIC_GET_INFO_SUCCESS: {
            nextState = helpers.immutAssign( {}, state, {
                numStrutture: payload.numStrut,
                numImprese: payload.numImp,
                loadingMessage:''
            });
            return nextState;
        }

        case GenericActions.GENERIC_GET_INFO_FAIL: {
            nextState = helpers.immutAssign( {}, state, {
                numStrutture: 0,
                numImprese: 0,
            });
            return nextState;
        }

        case GenericActions.ERROR_MODAL_SHOW: {
            nextState = helpers.immutAssign({}, state, {
                errorModalResult: null,
                isLoading: false,
                loadingQueue: 0,
            });
            return nextState;
        }

        case GenericActions.ERROR_MODAL_HIDE: {
            nextState = helpers.immutAssign({}, state, {
                errorModalResult: payload && payload.result,
            });
            return nextState;
        }

        case GenericActions.DUPLICATES_MODAL_SHOW: {
            nextState = helpers.immutAssign({}, state, {
                duplicatesModalResult: null,
                isLoading: false,
                loadingQueue: 0,
            });
            return nextState;
        }

        case GenericActions.DUPLICATES_MODAL_HIDE: {
            nextState = helpers.immutAssign({}, state, {
                duplicatesModalResult: payload && payload.result,
            });
            return nextState;
        }

        case GenericActions.STRUTTURE_MODAL_SHOW: {
            nextState = helpers.immutAssign({}, state, {
                struttureModalResult: null,
                isLoading: false,
                loadingQueue: 0,
            });
            return nextState;
        }

        case GenericActions.STRUTTURE_MODAL_HIDE: {
            nextState = helpers.immutAssign({}, state, {
                struttureModalResult: payload && payload.result,
            });
            return nextState;
        }

        case GenericActions.DELETE_MODAL_SHOW: {
            nextState = helpers.immutAssign({}, state, {
                deleteModalResult: null,
                isLoading: false,
                loadingQueue: 0,
            });
            return nextState;
        }

        case GenericActions.DELETE_MODAL_HIDE: {
            nextState = helpers.immutAssign({}, state, {
                deleteModalResult: payload && payload.result,
            });
            return nextState;
        }

        case GenericActions.RENAME_MODAL_SHOW: {
            nextState = helpers.immutAssign({}, state, {
                renameModalResult: null,
                isLoading: false,
                loadingQueue: 0,
            });
            return nextState;
        }

        case GenericActions.RENAME_MODAL_HIDE: {
            nextState = helpers.immutAssign({}, state, {
                renameModalResult: payload && payload.result,
            });
            return nextState;
        }

        case GenericActions.EDIT_MODAL_SHOW: {
            nextState = helpers.immutAssign({}, state, {
                editModalResult: null,
                isLoading: false,
                loadingQueue: 0,
            });
            return nextState;
        }

        case GenericActions.EDIT_MODAL_HIDE: {
            nextState = helpers.immutAssign({}, state, {
                '>editModalResult': JSON.parse(JSON.stringify(payload)),
            });
            return nextState;
        }

        case GenericActions.PREVIEW_MODAL_SHOW: {
            nextState = helpers.immutAssign({}, state, {
                previewModalResult: null,
                isLoading: false,
                loadingQueue: 0,
            });
            return nextState;
        }

        case GenericActions.PREVIEW_MODAL_SHOW_SUCCESS: {
            nextState = helpers.immutAssign({}, state, {
                previewModalResult: null,
                isLoading: false,
                loadingQueue: 0,
            });
            return nextState;
        }

        case GenericActions.PREVIEW_MODAL_HIDE: {
            nextState = helpers.immutAssign({}, state, {
                previewModalResult: payload && payload.result,
            });
            return nextState;
        }

        case GenericActions.MODAL_BUTTON_CLICK: {
            nextState = helpers.immutAssign({}, state, { });
            return nextState;
        }

        case GenericActions.START_LOADING: {
            nextState = helpers.immutAssign({}, state, {
                isLoading: true,
                loadingMessage: '',
                loadingQueue: state.loadingQueue + 1
            });
            return nextState;
        }

        case GenericActions.STOP_LOADING: {
            nextState = helpers.immutAssign({}, state, {
                isLoading: state.loadingQueue > 1 ,
                loadingMessage: '',
                loadingQueue: state.loadingQueue > 0 ? (state.loadingQueue - 1) : 0
            });
            return nextState;
        }

        case GenericActions.RESET_MODAL: {
            nextState = helpers.immutAssign({}, state, {
                editModalResult: null,
                errorModalResult: null,
                renameModalResult: null,
                duplicatesModalResult: null,
                deleteModalResult: null
            });
            return nextState;
        }

        default:
            return state;
    }
}