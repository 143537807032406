export function getFetchBozzeResponse () {
    return {
        status: 'OK',
        payload: { dataEntries:[
            {
                descrizioneidras: 'bozza1',
                aggiornamento: { ultimoAgg: '2020-10-11 25:00:00'}
            },
            {
                descrizioneidras: 'bozza2',
                aggiornamento: { ultimoAgg: '2020-10-11 25:00:00'}
            },
            {
                descrizioneidras: 'bozza3',
                aggiornamento: { ultimoAgg: '2020-10-11 25:00:00'}
            }
        ]}

    }
}