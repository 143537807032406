<section class="container mt-3 mb-3" id="brand">
    <div class="row">
      <div class="col-6">
        <a href="http://operatori.sardegnaturismo.it/it" tabindex="-1" target="_blank" title="Home page - Area Operatori | Regione Autonoma della Sardegna">
          <img src="../assets/html-images/logo.png" alt="Logo Area Operatori | Regione Autonoma della Sardegna">
        </a>
      </div>
      <div class="col-6">
        <a class="float-right" tabindex="-1" href="http://www.regione.sardegna.it/" target="_blank" title="Home page - Regione Autonoma della Sardegna">
          <img src="../assets/html-images/logo-ras.png" alt="Logo Regione Autonoma della Sardegna">
        </a>
      </div>
    </div>
  </section>
