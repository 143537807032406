var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
function padNumber(value) {
    if (isNumber(value)) {
        return ("0" + value).slice(-2);
    }
    else {
        return '';
    }
}
function isNumber(value) {
    return !isNaN(toInteger(value));
}
function toInteger(value) {
    return parseInt("" + value, 10);
}
var NgbDateCustomParserFormatter = /** @class */ (function (_super) {
    __extends(NgbDateCustomParserFormatter, _super);
    function NgbDateCustomParserFormatter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NgbDateCustomParserFormatter.prototype.parse = function (value) {
        if (value && value.includes('/')) {
            var dateParts = value.trim().split('/');
            if (dateParts.length === 1 && isNumber(dateParts[0])) {
                return { year: toInteger(dateParts[0]), month: null, day: null };
            }
            else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
                return { year: toInteger(dateParts[1]), month: toInteger(dateParts[0]), day: null };
            }
            else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
                return { year: toInteger(dateParts[2]), month: toInteger(dateParts[1]), day: toInteger(dateParts[0]) };
            }
        }
        else if (value && value.includes('-')) {
            return this.parseOldFormat(value);
        }
        return null;
    };
    NgbDateCustomParserFormatter.prototype.format = function (date) {
        if (date && isNumber(date.day) && isNumber(date.month) && isNumber(date.year)) {
            return padNumber(date.day) + "/" + padNumber(date.month) + "/" + date.year;
        }
        return null;
    };
    NgbDateCustomParserFormatter.prototype.parseOldFormat = function (value) {
        if (value) {
            var dateParts = value.trim().split('-');
            if (dateParts.length === 1 && isNumber(dateParts[0])) {
                return { year: null, month: null, day: toInteger(dateParts[0]) };
            }
            else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
                return { year: null, month: toInteger(dateParts[1]), day: toInteger(dateParts[0]) };
            }
            else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
                return { year: toInteger(dateParts[0]), month: toInteger(dateParts[1]), day: toInteger(dateParts[2]) };
            }
        }
        return null;
    };
    return NgbDateCustomParserFormatter;
}(NgbDateParserFormatter));
export { NgbDateCustomParserFormatter };
