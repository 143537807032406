var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { GenericActions, GenericRequests, GenericSelectors, getGenericStatsResponse } from "./index";
import { Effect, Actions } from "@ngrx/effects";
import { environment } from "src/environments/environment";
import { switchMap, catchError, tap, map, withLatestFrom, delay } from "rxjs/operators";
import { of, merge } from "rxjs";
import { SearchActions } from "../search";
import { Router } from "@angular/router";
import { UserActions } from "../user";
import { SearchImpresaActions } from '../search-impresa';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AsrErrorModalComponent, AsrEditModalComponent } from 'src/app/components/asr-modals';
import { AsrDuplicatesModalComponent } from 'src/app/components/asr-modals/asr-duplicates-modal';
import { AsrDeleteModalComponent } from 'src/app/components/asr-modals/asr-delete-modal';
import { AsrStruttureModalComponent } from 'src/app/components/asr-modals/asr-srutture-modal';
import { AsrRenameModalComponent } from 'src/app/components/asr-modals/asr-rename-modal';
import { AsrPreviewModalComponent } from 'src/app/components/asr-modals/asr-preview-modal/asr-preview-modal.component';
var GenericEffects = /** @class */ (function () {
    function GenericEffects(actions$, genericActions, genericSelectors, router, modalService, genericRequests) {
        var _this = this;
        this.actions$ = actions$;
        this.genericActions = genericActions;
        this.genericSelectors = genericSelectors;
        this.router = router;
        this.modalService = modalService;
        this.genericRequests = genericRequests;
        this.doGet$ = this.actions$.ofType(GenericActions.GENERIC_GET_INFO).pipe(switchMap(function () { return environment.requests.generic.mock ?
            of(_this.genericActions.genericGetInfoSuccess(getGenericStatsResponse())) :
            _this.genericRequests.requestStats$()
                .pipe(switchMap(function (response) {
                return of(_this.genericActions.genericGetInfoSuccess(response));
            })); }), catchError(function (err) { return of(_this.genericActions.genericGetInfoFail(err)); }));
        this.startSpinner$ = merge(this.actions$.ofType(SearchActions.SEARCH_START), this.actions$.ofType(SearchActions.SEARCH_SET_FILTER), this.actions$.ofType(SearchActions.SEARCH_SORT), this.actions$.ofType(GenericActions.GENERIC_GET_INFO), this.actions$.ofType(SearchActions.SEARCH_CLEAR_FILTERS), this.actions$.ofType(SearchActions.SEARCH_FULL_TEXT), this.actions$.ofType(SearchActions.SEARCH_CHANGE_RESULTS_PAGE), this.actions$.ofType(SearchActions.SEARCH_CHANGE_RESULTS_PAGE_BY), this.actions$.ofType(SearchImpresaActions.SEARCH_START), this.actions$.ofType(SearchImpresaActions.SEARCH_SET_FILTER), this.actions$.ofType(SearchImpresaActions.SEARCH_CLEAR_FILTERS), this.actions$.ofType(SearchImpresaActions.SEARCH_FULL_TEXT), this.actions$.ofType(SearchImpresaActions.SEARCH_CHANGE_RESULTS_PAGE), this.actions$.ofType(SearchImpresaActions.SEARCH_CHANGE_RESULTS_PAGE_BY), this.actions$.ofType(UserActions.USER_LOGIN), this.actions$.ofType(UserActions.USER_LOGOUT), this.actions$.ofType(UserActions.USER_CHECK_LOGIN), this.actions$.ofType(UserActions.USER_UPLOAD_FILE), this.actions$.ofType(UserActions.USER_DOWNLOAD_FILE), this.actions$.ofType(UserActions.USER_INSERT_STRUTTURA), this.actions$.ofType(UserActions.USER_EDIT_IMPRESA), this.actions$.ofType(UserActions.EDIT_IMPRESA_FROM_MODAL), this.actions$.ofType(UserActions.USER_EDIT_STRUTTURA), this.actions$.ofType(UserActions.USER_DELETE_STRUTTURA), this.actions$.ofType(UserActions.USER_GET_BOZZE)).pipe(
        // map(action => action['type']),
        // tap(type=> console.log(type)),
        withLatestFrom(this.genericSelectors.isLoading$()), map(function () { return _this.genericActions.startLoading(''); }));
        this.stopSpinner$ = merge(this.actions$.ofType(SearchActions.SEARCH_SUCCESS), this.actions$.ofType(SearchActions.SEARCH_FAIL), this.actions$.ofType(SearchActions.SEARCH_FULL_TEXT_FAIL), this.actions$.ofType(SearchActions.SEARCH_FULL_TEXT_SUCCESS), this.actions$.ofType(SearchImpresaActions.SEARCH_SUCCESS), this.actions$.ofType(SearchImpresaActions.SEARCH_FAIL), this.actions$.ofType(SearchImpresaActions.SEARCH_FULL_TEXT_FAIL), this.actions$.ofType(SearchImpresaActions.SEARCH_FULL_TEXT_SUCCESS), this.actions$.ofType(GenericActions.GENERIC_GET_INFO_SUCCESS), this.actions$.ofType(GenericActions.GENERIC_GET_INFO_FAIL), this.actions$.ofType(UserActions.USER_LOGIN_FAIL), this.actions$.ofType(UserActions.USER_LOGIN_SUCCESS), this.actions$.ofType(UserActions.USER_LOGOUT_SUCCESS), this.actions$.ofType(UserActions.USER_LOGOUT_FAIL), this.actions$.ofType(UserActions.USER_CHECK_LOGIN_SUCCESS), this.actions$.ofType(UserActions.USER_CHECK_LOGIN_FAIL), this.actions$.ofType(UserActions.USER_UPLOAD_FILE_SUCCESS), this.actions$.ofType(UserActions.USER_UPLOAD_FILE_FAIL), this.actions$.ofType(UserActions.USER_DOWNLOAD_FILE_FAIL), this.actions$.ofType(UserActions.USER_DOWNLOAD_FILE_SUCCESS), this.actions$.ofType(UserActions.USER_INSERT_STRUTTURA_FAIL), this.actions$.ofType(UserActions.USER_INSERT_STRUTTURA_SUCCESS), this.actions$.ofType(UserActions.USER_EDIT_IMPRESA_SUCCESS), this.actions$.ofType(UserActions.USER_EDIT_IMPRESA_FAIL), this.actions$.ofType(UserActions.EDIT_IMPRESA_FROM_MODAL_SUCCESS), this.actions$.ofType(UserActions.EDIT_IMPRESA_FROM_MODAL_FAIL), this.actions$.ofType(UserActions.USER_EDIT_STRUTTURA_SUCCESS), this.actions$.ofType(UserActions.USER_EDIT_STRUTTURA_FAIL), this.actions$.ofType(UserActions.USER_DELETE_STRUTTURA_SUCCESS), this.actions$.ofType(UserActions.USER_DELETE_STRUTTURA_FAIL), this.actions$.ofType(UserActions.USER_GET_BOZZE_FAIL), this.actions$.ofType(UserActions.USER_GET_BOZZE_SUCCESS)).pipe(
        // map(action => action['type']),
        // tap(type=> console.log(type)),
        delay(200), withLatestFrom(this.genericSelectors.isLoading$()), map(function () { return _this.genericActions.stopLoading(); }));
        this.redirectPath$ = this.actions$.ofType(GenericActions.MODAL_BUTTON_CLICK).pipe(map(function (res) { return res['payload']; }), tap(function (path) { return _this.router.navigate([path]); }));
        this.redirectExternalUrl$ = this.actions$.ofType(GenericActions.NAVIGATE_EXTERNAL_URL).pipe(map(function (res) { return res['payload']; }), tap(function (path) { return window.location.href = path; }));
        this.showErrorModal$ = this.actions$.ofType(GenericActions.ERROR_MODAL_SHOW).pipe(map(function (res) { return res['payload']; }), tap(function (data) {
            var modalRef = _this.modalService.open(AsrErrorModalComponent, { centered: true, backdrop: 'static' });
            modalRef.componentInstance.data = data;
        }));
        this.hideErrorModal$ = this.actions$.ofType(GenericActions.ERROR_MODAL_HIDE).pipe(map(function (res) { return res['payload']; }), tap(function (data) {
            var modal = data.ref;
            modal.close(data.result);
        }));
        this.showDuplicatesModal$ = this.actions$.ofType(GenericActions.DUPLICATES_MODAL_SHOW).pipe(map(function (res) { return res['payload']; }), tap(function (data) {
            var modalRef = _this.modalService.open(AsrDuplicatesModalComponent, { size: 'lg', centered: true, keyboard: false, backdrop: 'static' });
            modalRef.componentInstance.data = data;
        }));
        this.hideDuplicatesModal$ = this.actions$.ofType(GenericActions.DUPLICATES_MODAL_HIDE).pipe(map(function (res) { return res['payload']; }), tap(function (data) {
            var modal = data.ref;
            modal.close(data.result);
        }));
        this.showDeleteModal$ = this.actions$.ofType(GenericActions.DELETE_MODAL_SHOW).pipe(map(function (res) { return res['payload']; }), tap(function (data) {
            var modalRef = _this.modalService.open(AsrDeleteModalComponent, { centered: true, keyboard: false, backdrop: 'static' });
            modalRef.componentInstance.data = data;
        }));
        this.hideDeleteModal$ = this.actions$.ofType(GenericActions.DELETE_MODAL_HIDE).pipe(map(function (res) { return res['payload']; }), tap(function (data) {
            var modal = data.ref;
            modal.close(data.result);
        }));
        this.showStruttureModal$ = this.actions$.ofType(GenericActions.STRUTTURE_MODAL_SHOW).pipe(map(function (res) { return res['payload']; }), tap(function (data) {
            var modalRef = _this.modalService.open(AsrStruttureModalComponent, { centered: true, keyboard: false, backdrop: 'static' });
            modalRef.componentInstance.data = data;
        }));
        this.hideStruttureModal$ = this.actions$.ofType(GenericActions.STRUTTURE_MODAL_HIDE).pipe(map(function (res) { return res['payload']; }), tap(function (data) {
            var modal = data.ref;
            modal.close(data.result);
        }));
        this.showRenameModal$ = this.actions$.ofType(GenericActions.RENAME_MODAL_SHOW).pipe(map(function (res) { return res['payload']; }), tap(function (data) {
            var modalRef = _this.modalService.open(AsrRenameModalComponent, { centered: true, backdrop: 'static' });
            modalRef.componentInstance.data = data;
        }));
        this.hideRenameModal$ = this.actions$.ofType(GenericActions.RENAME_MODAL_HIDE).pipe(map(function (res) { return res['payload']; }), tap(function (data) {
            var modal = data.ref;
            modal.close(data.result);
        }));
        this.showEditModal$ = this.actions$.ofType(GenericActions.EDIT_MODAL_SHOW).pipe(map(function (res) { return res['payload']; }), tap(function (data) {
            var modalRef = _this.modalService.open(AsrEditModalComponent, { size: 'lg', keyboard: false, backdrop: 'static', centered: true });
            modalRef.componentInstance.dataEntry = data.data;
            modalRef.componentInstance.field = data.field;
            modalRef.componentInstance.disableValidation = !!data.disableValidation;
        }));
        this.hideEditModal$ = this.actions$.ofType(GenericActions.EDIT_MODAL_HIDE).pipe(map(function (res) { return res['payload']; }), tap(function (data) {
            var modal = data.ref;
            modal.close({ result: data.result, field: data.field });
        }));
        this.fetchDataPreviewModal$ = this.actions$.ofType(GenericActions.PREVIEW_MODAL_SHOW).pipe(map(function (res) { return res['payload']; }), switchMap(function (payload) { return _this.genericRequests.fetchStruttura$(payload, null).pipe(switchMap(function (response) { return response['status'] === 'OK' ?
            of(_this.genericActions.showPreviewModalSuccess(response['payload'])) :
            of(_this.genericActions.showErrorModal({
                title: 'Errore',
                message: 'Impossibile recuperare i dati della struttura.',
                buttons: [{ id: 'close', label: 'Chiudi', class: '', action: '', autoclose: true }]
            })); })); }), catchError(function (err) { return [_this.genericActions.showErrorModal({
                title: 'Errore',
                message: 'Impossibile recuperare i dati della struttura.',
                buttons: [{ id: 'close', label: 'Chiudi', class: '', action: '', autoclose: true }]
            })]; }));
        this.showPreviewModal$ = this.actions$.ofType(GenericActions.PREVIEW_MODAL_SHOW_SUCCESS).pipe(map(function (res) { return res['payload']; }), tap(function (data) {
            var modalRef = _this.modalService.open(AsrPreviewModalComponent, { size: 'lg', keyboard: false, backdrop: 'static', centered: true });
            modalRef.componentInstance.data = data;
        }));
        this.hidePreviewModal$ = this.actions$.ofType(GenericActions.PREVIEW_MODAL_HIDE).pipe(map(function (res) { return res['payload']; }), tap(function (data) {
            var modal = data.ref;
            modal.close({ result: data.result, field: data.field });
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], GenericEffects.prototype, "doGet$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], GenericEffects.prototype, "startSpinner$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], GenericEffects.prototype, "stopSpinner$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], GenericEffects.prototype, "redirectPath$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], GenericEffects.prototype, "redirectExternalUrl$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], GenericEffects.prototype, "showErrorModal$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], GenericEffects.prototype, "hideErrorModal$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], GenericEffects.prototype, "showDuplicatesModal$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], GenericEffects.prototype, "hideDuplicatesModal$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], GenericEffects.prototype, "showDeleteModal$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], GenericEffects.prototype, "hideDeleteModal$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], GenericEffects.prototype, "showStruttureModal$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], GenericEffects.prototype, "hideStruttureModal$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], GenericEffects.prototype, "showRenameModal$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], GenericEffects.prototype, "hideRenameModal$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], GenericEffects.prototype, "showEditModal$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], GenericEffects.prototype, "hideEditModal$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], GenericEffects.prototype, "fetchDataPreviewModal$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], GenericEffects.prototype, "showPreviewModal$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], GenericEffects.prototype, "hidePreviewModal$", void 0);
    return GenericEffects;
}());
export { GenericEffects };
