import { Component, Input } from "@angular/core";
import { FormGeneratorInterface, FormGeneratorController } from "src/app/form-generator";

@Component({
    selector: '[asr-section]',
    templateUrl: './asr-section.component.html',
  })
  export class AsrSectionComponent implements FormGeneratorInterface  {
    @Input() model;
    @Input() parentController: FormGeneratorController;
}