import { Component} from '@angular/core';
import { GenericSelectors } from 'src/app/logic/generic/generic.selectors';
import { UserSelectors } from 'src/app/logic/user';

@Component({
  selector: 'asr-recap',
  templateUrl: './asr-recap.component.html'
})
export class AsrRecapComponent {

  numImprese$;
  numStrutture$;
  bozzeList$;
  user$;
  constructor(private genericSelector: GenericSelectors, private userSelectors: UserSelectors) {
    this.numImprese$ = this.genericSelector.getNumImprese$();
    this.numStrutture$ = this.genericSelector.getNumStrutture$();
    this.bozzeList$ = this.userSelectors.getBozzeList$();
    this.user$ = this.userSelectors.getUser$();
  }
}
