<main class="container">
  <div class="row mt-4 justify-content-center">
    <section class="col-12 col-md-8" id="search-results">
      <div class="header mb-3">
        <h2 class="d-inline">{{results.length === 1 ? results.length + ' Bozza' : results.length + ' Bozze'}}</h2>
      </div>
      <ul class="lista_custom lista--bozze lista--full-page gray-theme p-0 m-0" *ngIf="results.length > 0 ">
        <li class="pt-1 pb-1" *ngFor="let bozza of results" >
          <a [routerLink]="['/edit']" tabindex="0" [queryParams]="{type: 'strut', step: 'dati_prelim', id: bozza.idStrut}">
            <div class="bozza p-1 d-flex align-items-baseline justify-content-between">
              <div class="text">
                <h4 class="mb-0">{{bozza.descrizioneidras ? bozza.descrizioneidras : 'Senza Nome' }}</h4>
                <!-- <p class="m-0">avanzamento <strong>60%</strong><br /> -->
                <!-- </p> -->
                <div class="progressbar">
                  <div class="bar _60"></div>
                </div>
              </div>
              <div class="data text-right text-dark">
               {{(bozza?.aggiornamento?.ultimoAgg + 'Z' | date: "yyyy-MM-dd hh:mm:ss") }}
              </div>
            </div>
          </a>
        </li>
      </ul>
      <div class="p-5 text-center placeholder" id="dati-riepilogo" *ngIf="results.length === 0">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-12 riepilogo">
            <i class="fas fa-file-alt mt-2 mb-3"></i>
            <div class="dato">
              <span>Non hai pratiche in bozza
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- <nav aria-label="Page navigation example ">
          <ul class="pagination pagination-sm justify-content-center mt-3">
            <li class="page-item disabled">
              <a class="page-link" href="#" tabindex="-1">Previous</a>
            </li>
            <li class="page-item active"><a class="page-link" href="#">1</a></li>
            <li class="page-item"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item">
              <a class="page-link" href="#">Next</a>
            </li>
          </ul>
        </nav> -->
    </section>
  </div>
</main>