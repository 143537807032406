import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { FormGeneratorController, LEAF_TYPES, CONTAINER_TYPES } from 'src/app/form-generator/form-generator';
import { FormGeneratorInterface } from 'src/app/form-generator/form-generator.interface';
import { takeUntil, distinctUntilChanged, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { getInsertModel } from '../../form-model/form.model';
import { GenericSelectors, GenericActions } from 'src/app/logic/generic';
import { UserActions } from 'src/app/logic/user';
import { Store } from '@ngrx/store';
import { FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { AppFlowSelectors } from 'src/app/logic/app-flow/app-flow.selectors';
import { AppFlowActions } from 'src/app/logic/app-flow/app-flow.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/logic/user/user.service';
@Component({
  selector: 'asr-insert',
  templateUrl: './asr-insert.component.html'
})
export class AsrInsertComponent extends FormGeneratorController implements OnInit, OnDestroy, FormGeneratorInterface {

  constructor(
    private injector: Injector,
    private genericActions: GenericActions,
    private genericSelectors: GenericSelectors,
    private store: Store<any>,
    private flowSelectors$: AppFlowSelectors,
    private flowActions: AppFlowActions,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userActions: UserActions,
    private userService: UserService
    ) {
    super(injector);
  }

  isLoading$;
  destroyed$: Subject<any> = new Subject<any>();
  state = {
    currentSection: null,
    currentComune: null,
    currentDenominazione: null,
    lastComune: null,
    lastDenominazione: null,
    showDuplicateModal: false,
  };
  current_step;
  query_params;
  private steps;
  private currentSubsectionInView;
  private currentSectionInView;
  private queueSubsectionInView = [];
  private subsectioNoMoreInView = false;
  inViewportOptions = {
    rootMargin: '0px',
    threshold: 1.0
  };

  ngOnInit() {
    this.store.dispatch(this.genericActions.resetModal());
    this.dataEntryMap = {};
    this.events = {};
    this.isLoading$ = this.genericSelectors.isLoading$();
    this.model = getInsertModel(false);
    this.formGroup = new FormGroup({});
    this.initializeModel(this.model, this.formGroup);
    for (const fieldId in this.events) {
      if (this.events.hasOwnProperty(fieldId)) {
        this.onModelChange(this.containerMap[fieldId]);
      }
    }
    this.formGroup.markAsUntouched();
    this.flowSelectors$.getCurrentStep$().pipe(takeUntil(this.destroyed$)).subscribe({
      next: (x) => {
        this.current_step = x;
        this.router.navigate(
          [],
          {
            relativeTo: this.activatedRoute,
            queryParams: { step: x },
            queryParamsHandling: 'merge'
          });
      },
      error: (err) => { },
      complete: () => { }
    });
    this.steps = Object.getOwnPropertyNames(this.model);
    this.store.dispatch(this.flowActions.setCurrentSection({ steps: this.steps, current_step: 'dati_prelim' }));
    this.genericSelectors.getEditModalResult$().pipe(takeUntil(this.destroyed$),
      distinctUntilChanged(),
      filter(x => x !== null)).subscribe({
        next: (x) => {
          if (!!x && !!x['result'] && !!x['field']) {
            let container = this.containerMap[x['field']];
            let handler = this.controlHandlerFactory.getHandler(container.type);
            handler.setValue(container, x['result']);
          }
        },
        error: (error) => console.log(error),
        complete: () => {}
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  changeSection(section) {
    if (this.updateDuplicateParams()) {
      this.store.dispatch(
        this.userActions.userCheckDuplicati({
          comune: this.state.currentComune,
          nome: this.state.currentDenominazione,
          postAction: this.flowActions.setCurrentStep(section)
        }));
      this.state.showDuplicateModal = false;
    } else {
      this.markSectionAsTouched(this.model[this.current_step]);
      this.store.dispatch(this.flowActions.setCurrentStep(section));
      this.resetAffix();
    }
  }

  goToPreviousSection() {
    const currentSection = this.model[this.current_step];
    const currentWeight = currentSection.weight;
    for (const sectionId in this.model) {
      if (this.model.hasOwnProperty(sectionId)) {
        const section = this.model[sectionId];
        if (section.weight + 1 === currentWeight) {
          this.store.dispatch(this.flowActions.setCurrentStep(sectionId));
          this.markSectionAsTouched(currentSection);
          window.scrollTo(0, 0);
          this.resetAffix();
          break;
        }
      }
    }
  }

  markSectionAsTouched(element) {
    if (element.visible.getValue() === false) {
      return;
    }
    if (LEAF_TYPES.includes(element.type)) {
      let c: AbstractControl = element.control;
      c.markAsTouched({ onlySelf: true });
    } else if (CONTAINER_TYPES.includes(element.type)) {
      let c: AbstractControl = element.control;
      c.markAsTouched({ onlySelf: true });
      for (const child in element.children) {
        if (element.children.hasOwnProperty(child)) {
          this.markSectionAsTouched(element.children[child]);
        }
      }
    }
  }

  getQueryParams() {
    return {
      step: this.current_step
    };
  }

  goToNextSection() {
    const currentSection = this.model[this.current_step];
    const currentWeight = currentSection.weight;
    for (const sectionId in this.model) {
      if (this.model.hasOwnProperty(sectionId)) {
        const section = this.model[sectionId];
        if (section.weight - 1 === currentWeight) {
          this.store.dispatch(this.flowActions.setCurrentStep(sectionId));
          this.markSectionAsTouched(currentSection);
          window.scrollTo(0, 0);
          this.resetAffix();
        }
      }
    }
  }

  private openModal( targetKey) {
    let dataControl: FormControl = this.containerMap[targetKey].control;
    this.store.dispatch(this.genericActions.showEditModal({ data: dataControl.value, field: targetKey }));
  }

  updateDuplicateParams() {
    let comuneKey = "dati_prelim-localizzazione-comune";
    let descrizioneKey = "dati_prelim-classificazione-descrizioneidras";
    let currentComuneValue = this.containerMap[comuneKey].control.value;
    let currentNomeValue = this.containerMap[descrizioneKey].control.value;
    if (!!currentComuneValue && !!currentComuneValue.istat && !!currentNomeValue) {
      this.state.currentComune = currentComuneValue.istat;
      this.state.currentDenominazione = currentNomeValue;
      if (this.state.lastComune === null && this.state.lastDenominazione === null) {
        this.state.lastComune = currentComuneValue.istat;
        this.state.lastDenominazione = currentNomeValue;
        this.state.showDuplicateModal = true;
      } else {
        if (this.state.lastComune !== this.state.currentComune || this.state.lastDenominazione !== this.state.currentDenominazione) {
          this.state.lastComune = this.state.currentComune;
          this.state.lastDenominazione = this.state.currentDenominazione;
          this.state.showDuplicateModal = true;
        } else {
          this.state.showDuplicateModal = false;
        }
      }
    } else {
      this.state.showDuplicateModal = false;
    }
    return this.state.showDuplicateModal;
  }

  submitData(flag) {
    const dataEntry = {};
    this.recoverDataEntry(this.model, dataEntry);
    let tipologia_struttura = dataEntry['tipologia_struttura'];
    if (typeof dataEntry['gestore'] !== 'undefined') {
      if (dataEntry['inserisci_info_gestore'] === 'cerca') {
        let idImpresaGestElement = this.containerMap['dati_struttura-dati_gestore-id_gestore'];
        let idImpresa = idImpresaGestElement.control.value;
        dataEntry['gestore']['idImpresa'] = idImpresa;
        let stesseInfoEl = this.containerMap['dati_struttura-dati_gestore-stesse_info_gestore'];
        dataEntry['stesse_info_gestore'] = stesseInfoEl.control.value;
        if (dataEntry['stesse_info_gestore'] === 'No' && dataEntry['inserisci_info_proprietario'] === 'cerca') {
          let  id_path= 'dati_struttura-dati_proprietario-id_proprietario';
          let idImpresaPropElement = this.containerMap[id_path];
          let idImpresaProp = idImpresaPropElement.control.value;
          dataEntry['proprietario']['idImpresa'] = idImpresaProp;
        }
      }
    }
    // if (typeof dataEntry['proprietario'] !== 'undefined') {
    //   if (dataEntry['inserisci_info'] === 'no') {
    //     let idImpresaPropElement = this.containerMap['dati_struttura-dati_impresa_proprietaria-id_proprietario'];
    //     let idImpresa = idImpresaPropElement.control.value;
    //     dataEntry['proprietario']['idImpresa'] = idImpresa;
    //   }
    //   let stesseInfoEl = this.containerMap['dati_struttura-dati_impresa_proprietaria-stesse_info_gestore'];
    //   dataEntry['stesse_info_gestore'] = stesseInfoEl.control.value;
    //   if (dataEntry['stesse_info_gestore'] === 'No' && dataEntry['inserisci_info_gestore'] === 'cerca') {
    //     let idImpresaGestElement = this.containerMap['dati_struttura-dati_impresa_gestore-id_gestore'];
    //     let idImpresa = idImpresaGestElement.control.value;
    //     dataEntry['gestore']['idImpresa'] = idImpresa;
    //   }
    // }
    dataEntry['bozza'] = flag === true ? "1" : "0";
    dataEntry['idUtente'] = this.userService.getUserId();

    const action = this.userActions.insertStruttura(dataEntry);
    if (this.updateDuplicateParams()) {
      //TODO SHOW DUPLICATI ON CHANGE STEP
      this.store.dispatch(
        this.userActions.userCheckDuplicati({
          comune: this.state.currentComune,
          nome: this.state.currentDenominazione,
          postAction: action
        }));
      this.state.showDuplicateModal = false;
    } else {
      this.store.dispatch(action);
    }
  }

  onInViewportChange($event, element) {
    let currentSectionWeight = 0;
    let elementSectionWeight = 0;
    if (this.currentSectionInView) {
      let elementParentId = element.id.split('-');
      elementParentId.pop();
      elementParentId = elementParentId.join('-');
      if (this.currentSectionInView !== elementParentId) {
        currentSectionWeight = this.containerMap[this.currentSectionInView]['weight'];
        elementSectionWeight = this.containerMap[elementParentId]['weight'];
      }
    }
    if ($event) {
      if (!this.currentSubsectionInView) {
        this.currentSubsectionInView = element;
        element.inViewport = true;
        this.subsectioNoMoreInView = false;
      } else {
        if (this.subsectioNoMoreInView) {
          this.currentSubsectionInView.inViewport = false;
          this.markSectionAsTouched(this.currentSubsectionInView);
          this.currentSubsectionInView = undefined;
          this.currentSubsectionInView = element;
          this.currentSubsectionInView.inViewport = true;
          this.subsectioNoMoreInView = false;
        } else if (elementSectionWeight < currentSectionWeight) {
          this.currentSubsectionInView.inViewport = false;
          this.markSectionAsTouched(this.currentSubsectionInView);
          this.queueSubsectionInView.unshift(this.currentSubsectionInView);
          this.currentSubsectionInView = undefined;
          this.currentSubsectionInView = element;
          this.currentSubsectionInView.inViewport = true;
          this.subsectioNoMoreInView = false;
        } else if (element.weight < this.currentSubsectionInView.weight && elementSectionWeight === currentSectionWeight) {
          this.currentSubsectionInView.inViewport = false;
          this.markSectionAsTouched(this.currentSubsectionInView);
          this.queueSubsectionInView.unshift(this.currentSubsectionInView);
          this.currentSubsectionInView = undefined;
          this.currentSubsectionInView = element;
          this.currentSubsectionInView.inViewport = true;
          this.subsectioNoMoreInView = false;
        } else {
          this.queueSubsectionInView.push(element);
        }
      }
    } else {
      if (this.currentSubsectionInView && this.currentSubsectionInView === element) {
        if (this.queueSubsectionInView.length > 0) {
          element.inViewport = false;
          this.currentSubsectionInView = this.queueSubsectionInView.shift();
          this.currentSubsectionInView.inViewport = true;
          this.markSectionAsTouched(element);
        } else {
          this.subsectioNoMoreInView = true;
        }
      } else {
        let index = this.queueSubsectionInView.indexOf(element);
        if (index > -1) {
          this.queueSubsectionInView.splice(index, 1);
        }
      }
    }
    if (this.currentSubsectionInView) {
      let path = this.currentSubsectionInView.id.split('-');
      path.pop();
      path = path.join('-');
      this.currentSectionInView = path;
    }
  }

  private resetAffix() {
    if (this.currentSubsectionInView) {
      this.currentSubsectionInView.inViewport = false;
      this.currentSubsectionInView = undefined;
    }
    this.queueSubsectionInView.forEach(element => {
      element.inViewport = false;
    });
    this.queueSubsectionInView = [];
    this.subsectioNoMoreInView = false;
  }
}
