/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./asr-header.component";
var styles_AsrHeaderComponent = [];
var RenderType_AsrHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AsrHeaderComponent, data: {} });
export { RenderType_AsrHeaderComponent as RenderType_AsrHeaderComponent };
export function View_AsrHeaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "section", [["class", "container mt-3 mb-3"], ["id", "brand"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "a", [["href", "http://operatori.sardegnaturismo.it/it"], ["tabindex", "-1"], ["target", "_blank"], ["title", "Home page - Area Operatori | Regione Autonoma della Sardegna"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "img", [["alt", "Logo Area Operatori | Regione Autonoma della Sardegna"], ["src", "../assets/html-images/logo.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "a", [["class", "float-right"], ["href", "http://www.regione.sardegna.it/"], ["tabindex", "-1"], ["target", "_blank"], ["title", "Home page - Regione Autonoma della Sardegna"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "img", [["alt", "Logo Regione Autonoma della Sardegna"], ["src", "../assets/html-images/logo-ras.png"]], null, null, null, null, null))], null, null); }
export function View_AsrHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "asr-header", [], null, null, null, View_AsrHeaderComponent_0, RenderType_AsrHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i1.AsrHeaderComponent, [], null, null)], null, null); }
var AsrHeaderComponentNgFactory = i0.ɵccf("asr-header", i1.AsrHeaderComponent, View_AsrHeaderComponent_Host_0, {}, {}, []);
export { AsrHeaderComponentNgFactory as AsrHeaderComponentNgFactory };
