import { Component, Input, OnInit, OnDestroy, Injector, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { GenericActions } from 'src/app/logic/generic';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'asr-preview-modal',
  templateUrl: './asr-preview-modal.component.html'
})
export class AsrPreviewModalComponent {
  @Input() data;
  @ViewChild('reportContent') element: ElementRef;
  constructor(
    private store: Store<any>,
    private genericActions: GenericActions,
    private activeModal: NgbActiveModal) {
  }

  downloadPdf() {
    const ref = this.element.nativeElement;
    let name = typeof this.data.nome === 'string' ? this.data.nome : 'senza_nome';
    name = name.trim().replace(/\s/g, '');
    html2canvas(ref).then(canvas => {
      // Few necessary setting options
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save( name + '.pdf'); // Generated PDF
    });
  }

  close() {
    this.store.dispatch(this.genericActions.hidePreviewModal({ref: this.activeModal}));
  }

  print() {

  }
}
