import { Injectable } from "@angular/core";
import { FlowState } from "./app-flow.interface";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { getFlowState } from "..";
import { map } from "rxjs/operators";

@Injectable()
export class AppFlowSelectors {

    public flow$: Observable<FlowState>;

    constructor(private store: Store<any>) {
        this.flow$ = this.store.pipe(
            select(getFlowState),
            map(state => state)
        );
    }

    getCurrentItem$() {
        return this.store.pipe(
            select(getFlowState),
            map(state => state.current_item)
        );
    }

    getCurrentStep$() {
        return this.store.pipe(
            select(getFlowState),
            map(state => state.current_step)
        );
    }
}