<ng-container *ngIf="( model.visible | async ) === true">
  <div class="row">
    <h3 class="c mt-3 mb-3" snInViewport [inViewportOptions]="parentController.inViewportOptions"
      (inViewportChange)="parentController.onInViewportChange($event,model)"
      (click)="model.collapsable && model.collapsed = !model.collapsed" id={{this.model.id}}
      [ngClass]="{'col-12': !model.optional, 'col-8': model.optional }"><span
        class="underlined">{{this.model.label}}</span>
      <i class="fa m-1" *ngIf="model.collapsable"
        [ngClass]="{ 'fa-angle-up': !model.collapsed, 'fa-angle-down': model.collapsed }"></i>
    </h3>
    <div class="col-4 mt-3 mb-3 text-right" *ngIf="model.optional" >
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" [formControl]="model.toggleState" id="{{model.id+'-toggle'}}" tabindex="0">
        <label class="custom-control-label" for="{{model.id+'-toggle'}}">{{model.toggleState.value ? 'Compilazione attiva': 'Compilazione disattivata' }}</label>
      </div>
    </div>
    <ng-container *ngIf="!model.collapsed">
      <ng-container *ngFor="let forms of model.children | keyvalue:parentController.orderComparator">
        <ng-container *ngIf="(forms.value.visible | async) === true && forms.value.type !== 'section'">
          <div class="{{forms?.value?.attributes?.class || 'col-12 col-md-6' }}" asr-container [(model)]="forms.value"
            [parentController]="parentController"></div>
        </ng-container>
        <ng-container *ngIf="(forms.value.visible | async) === true && forms.value.type === 'section'">
          <div class="{{forms?.value?.attributes?.class || 'col-12 col-md-6' }}">
            <p class="{{forms?.value?.attributes?.title?.class || 'h3 underlined' }}"
             *ngIf="forms?.value?.label">
              {{forms?.value?.label}}
          </p>
            <hr class="mx-0 mb-3" *ngIf="!forms?.value?.attributes?.hideSeparator">
          </div>
          <ng-container *ngFor="let subforms of forms?.value.children | keyvalue:parentController.orderComparator">
            <ng-container *ngIf="(subforms.value.visible | async) === true">
              <div class="{{subforms?.value?.attributes?.class || 'col-12 col-md-6' }}" asr-container
              [(model)]="subforms.value" [parentController]="parentController"></div>
            </ng-container>
          </ng-container>
        </ng-container>

      </ng-container>
    </ng-container>
  </div>
</ng-container>