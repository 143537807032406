import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { FormGeneratorController, LEAF_TYPES, CONTAINER_TYPES } from 'src/app/form-generator/form-generator';
import { FormGeneratorInterface } from 'src/app/form-generator/form-generator.interface';
import { first, takeUntil, filter, distinctUntilChanged } from 'rxjs/operators';
import { GenericSelectors } from 'src/app/logic/generic';
import { UserSelectors, UserActions } from 'src/app/logic/user';
import { Store } from '@ngrx/store';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { AppFlowActions } from 'src/app/logic/app-flow/app-flow.actions';
import { AppFlowSelectors } from 'src/app/logic/app-flow/app-flow.selectors';
import { Router, ActivatedRoute, RouterEvent, NavigationEnd } from '@angular/router';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { getEditImpresaModel } from 'src/app/form-model';
import { UserService } from 'src/app/logic/user/user.service';

@Component({
  selector: 'asr-edit-impresa',
  templateUrl: './asr-edit-impresa.component.html'
})
export class AsrEditImpresaComponent extends FormGeneratorController implements FormGeneratorInterface, OnInit, OnDestroy {

  constructor(private injector: Injector,
    private genericSelectors: GenericSelectors,
    private flowSelectors$: AppFlowSelectors,
    private flowActions: AppFlowActions,
    private store: Store<any>,
    private userActions: UserActions,
    private router: Router,
    private dateFormatter: NgbDateParserFormatter,
    private activatedRoute: ActivatedRoute,
    private userService: UserService) {
    super(injector);
  }

  isLoading$;
  current_step;
  current_item;
  query_params;
  private steps;
  private currentSubsectionInView;
  private currentSectionInView;
  private queueSubsectionInView = [];
  private subsectioNoMoreInView = false;
  inViewportOptions = {
    rootMargin: '0px',
    threshold: 1.0
  };

  ngOnInit() {
    this.dataEntryMap = {};
    this.events = {};
    this.isLoading$ = this.genericSelectors.isLoading$();
    this.model = getEditImpresaModel(false);
    this.formGroup = new FormGroup({});
    this.initializeModel(this.model, this.formGroup);
    for (const fieldId in this.events) {
      if (this.events.hasOwnProperty(fieldId)) {
        this.onModelChange(this.containerMap[fieldId]);
      }
    }
    this.flowSelectors$.getCurrentStep$().pipe(takeUntil(this.destroyed$)).subscribe({
      next: (x) => {
      this.current_step = x;
        this.router.navigate(
          [],
          {
            relativeTo: this.activatedRoute,
            queryParams: { step: x },
            queryParamsHandling: 'merge'
          });
      },
      error: (err) => { },
      complete: () => { }
    });
    let id = this.activatedRoute.snapshot.queryParams['id'];
    let step = this.activatedRoute.snapshot.queryParams['step'] || 'ricerca';
    this.steps = Object.getOwnPropertyNames(this.model);
    if (!this.steps.includes(step)) {
      step = 'ricerca';
    }
    if (!id) {
      this.store.dispatch(this.flowActions.setCurrentSection({ steps: this.steps, current_step: 'ricerca' }));
    } else {
      this.store.dispatch(this.flowActions.loadImpresaAndMove({ id: id, current_step: step, steps: this.steps }));
    }
    let ricerca: FormControl = this.model['ricerca'].control;

    ricerca.valueChanges.pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe({
      next: (val) => { this.onSelectedImpresa(val); },
      error: () => { },
      complete: () => { }
    });

    this.flowSelectors$.getCurrentItem$().pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe({
      next: (x) => {
        this.current_item = x;
        if (x === null) {
          this.resetModel();
          this.formGroup.markAsUntouched();
          this.formGroup.markAsPristine();
        } else {
          // this.resetModel();
          this.patchModel(x, this.dataEntryMap);
          this.markModelAsTouched(this.model);
          ricerca.patchValue({ dataEntry: { ragioneSociale: x['ragioneSociale'] } }, { emitEvent: false });
          ricerca.markAsTouched();
        }
      },
      error: (err) => { },
      complete: () => { }
    });
  }

  getQueryParams() {
    if (this.current_item) {
      return {
        id: this.current_item  && this.current_item.idImpresa,
        step: this.current_step
      };
    }
  }

  markSectionAsTouched(element) {
    if (element.visible.getValue() === false) {
      return;
    }
    if (LEAF_TYPES.includes(element.type)) {
      let c: AbstractControl = element.control;
      c.markAsTouched({ onlySelf: true });
    } else if (CONTAINER_TYPES.includes(element.type)) {
      let c: AbstractControl = element.control;
      c.markAsTouched({ onlySelf: true });
      for (const child in element.children) {
        if (element.children.hasOwnProperty(child)) {
          this.markSectionAsTouched(element.children[child]);
        }
      }
    }
  }

  markModelAsTouched(model) {
    for (const key in model) {
      if (model.hasOwnProperty(key)) {
        const element = model[key];
        this.markSectionAsTouched(element);
      }
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onSelectedImpresa($event) {
    if (typeof $event !== 'undefined') {
      let next_step = 'dati_impresa';
      this.store.dispatch(this.flowActions.loadImpresaAndMove({ id: $event.id, current_step: next_step, steps: this.steps }));
    } else {
      this.store.dispatch(this.flowActions.setCurrentItem(null));
    }
  }

  changeSection(section) {
    if (this.current_item !== null) {
      this.markSectionAsTouched(this.model[this.current_step]);
      this.store.dispatch(this.flowActions.setCurrentStep(section));
      this.resetAffix();
    }
  }

  goToPreviousSection() {
    if (this.current_item == null || this.current_step == null) {
      return;
    }
    const currentSection = this.model[this.current_step];
    const currentWeight = currentSection.weight;
    for (const sectionId in this.model) {
      if (this.model.hasOwnProperty(sectionId)) {
        const section = this.model[sectionId];
        if (section.weight + 1 === currentWeight) {
          this.store.dispatch(this.flowActions.setCurrentStep(sectionId));
          this.markSectionAsTouched(currentSection);
          window.scrollTo(0, 0);
          this.resetAffix();
        }
      }
    }
  }

  submitData() {
    const dataEntry = {};
    this.recoverDataEntry(this.model, dataEntry);
    dataEntry['idImpresa'] = this.current_item.idImpresa;
    dataEntry['idUtente'] = this.userService.getUserId();
    if(dataEntry['ricerca']){
    delete dataEntry['ricerca'];
    }
    this.store.dispatch(this.userActions.editImpresa(dataEntry));
  }

  displayStrutModal(results){
    if (typeof results === 'undefined') {
      return;
    }
    this.store.dispatch(this.userActions.getStrutByImpresaSuccess({nome: this.current_item.ragioneSociale, results: results}));

  }

  onInViewportChange($event, element) {
    let currentSectionWeight = 0;
    let elementSectionWeight = 0;
    if (this.currentSectionInView) {
      let elementParentId = element.id.split('-');
      elementParentId.pop();
      elementParentId = elementParentId.join('-');
      if (this.currentSectionInView !== elementParentId) {
        currentSectionWeight = this.containerMap[this.currentSectionInView]['weight'];
        elementSectionWeight = this.containerMap[elementParentId]['weight'];
      }
    }
    if ($event) {
      if (!this.currentSubsectionInView) {
        this.currentSubsectionInView = element;
        element.inViewport = true;
        this.subsectioNoMoreInView = false;
      } else {
        if (this.subsectioNoMoreInView) {
          this.currentSubsectionInView.inViewport = false;
          this.markSectionAsTouched(this.currentSubsectionInView);
          this.currentSubsectionInView = undefined;
          this.currentSubsectionInView = element;
          this.currentSubsectionInView.inViewport = true;
          this.subsectioNoMoreInView = false;
        } else if (elementSectionWeight < currentSectionWeight) {
          this.currentSubsectionInView.inViewport = false;
          this.markSectionAsTouched(this.currentSubsectionInView);
          this.queueSubsectionInView.unshift(this.currentSubsectionInView);
          this.currentSubsectionInView = undefined;
          this.currentSubsectionInView = element;
          this.currentSubsectionInView.inViewport = true;
          this.subsectioNoMoreInView = false;
        } else if (element.weight < this.currentSubsectionInView.weight && elementSectionWeight === currentSectionWeight) {
          this.currentSubsectionInView.inViewport = false;
          this.markSectionAsTouched(this.currentSubsectionInView);
          this.queueSubsectionInView.unshift(this.currentSubsectionInView);
          this.currentSubsectionInView = undefined;
          this.currentSubsectionInView = element;
          this.currentSubsectionInView.inViewport = true;
          this.subsectioNoMoreInView = false;
        } else {
          this.queueSubsectionInView.push(element);
        }

      }
    } else {
      if (this.currentSubsectionInView && this.currentSubsectionInView === element) {
        if (this.queueSubsectionInView.length > 0) {
          element.inViewport = false;
          this.currentSubsectionInView = this.queueSubsectionInView.shift();
          this.currentSubsectionInView.inViewport = true;
          this.markSectionAsTouched(element);
        } else {
          this.subsectioNoMoreInView = true;
        }
      } else {
        let index = this.queueSubsectionInView.indexOf(element);
        if (index > -1) {
          this.queueSubsectionInView.splice(index, 1);
        }
      }
    }
    if (this.currentSubsectionInView) {
      let path = this.currentSubsectionInView.id.split('-');
      path.pop();
      path = path.join('-');
      this.currentSectionInView = path;
    }
  }

  private resetAffix() {
    if (this.currentSubsectionInView) {
      this.currentSubsectionInView.inViewport = false;
      this.currentSubsectionInView = undefined;
    }
    this.queueSubsectionInView.forEach(element => {
      element.inViewport = false;
    });
    this.queueSubsectionInView = [];
    this.subsectioNoMoreInView = false;
  }
}
