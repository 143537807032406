import { Component } from '@angular/core';
import { ActivatedRoute, UrlSegment, Router, NavigationEnd, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map, filter, debounceTime, tap } from 'rxjs/operators';
import { UserActions } from 'src/app/logic/user';
import { UserSelectors } from 'src/app/logic/user/user.selectors';
import { Store } from '@ngrx/store';
import { GenericActions } from 'src/app/logic/generic';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'asr-nav-bar',
  templateUrl: './asr-nav-bar.component.html',
})
export class AsrNavBarComponent {
  currentPage: Observable<String>;
  isAuth$;
  user$;
  bozze$;

  constructor(
    private router: Router,
    private userSelectors$: UserSelectors ,
    private genericActions: GenericActions,
    private store: Store<any> , 
    private userActions: UserActions) {
    this.isAuth$ = this.userSelectors$.isAuthenticated$();
    this.user$ = this.userSelectors$.getUser$();
    this.bozze$ = this.userSelectors$.getBozzeList$();
    this.currentPage = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((route) => {
        let result = 'home';
        const parts = route['urlAfterRedirects'].split('/');
        if (parts.length >= 1) {
         let params =  parts[1].split('?');
         if (params.length >= 1) {
          result = params[0];
         } else {
          result = parts[1];
         }
        }
        return result;
      })
    );
  }

  logout() {
    this.store.dispatch(this.userActions.userLogout());
  }

  redirectLogin(){
    let url = `${environment.accessManager}${environment.authorizePath}?response_type=${environment.response_type}&client_id=${environment.client_id}&redirect_uri=${environment.redirect_uri}&scope=${environment.scope}`;
    this.store.dispatch(this.genericActions.goExternalUrl(url));
  }
}
