import { ActionWithPayload } from '../../app.interfaces';
import { Injectable } from '@angular/core';

@Injectable()
export class SearchImpresaActions {

    static SEARCH_START = '[Search_Impresa] Start';
    searchStart(): ActionWithPayload {
        return {
            type: SearchImpresaActions.SEARCH_START
        };
    }

    static SEARCH_SUCCESS = '[Search_Impresa] SEARCH_SUCCESS';
    searchSuccess(rsp): ActionWithPayload {
        return {
            type: SearchImpresaActions.SEARCH_SUCCESS,
            payload: rsp
        };
    }

    static SEARCH_FAIL = '[Search_Impresa] SEARCH_FAIL';
    searchFail(err): ActionWithPayload {
        return {
            type: SearchImpresaActions.SEARCH_FAIL,
            payload: err
        };
    }

    static SEARCH_SET_FILTER = '[Search_Impresa] SEARCH_SET_FILTER';
    searchSetFilter(value): ActionWithPayload {
        return {
            type: SearchImpresaActions.SEARCH_SET_FILTER,
            payload: value
        };
    }

    static SEARCH_SORT = '[Search_Impresa] SEARCH_SORT';
    searchSort(value): ActionWithPayload {
        return {
            type: SearchImpresaActions.SEARCH_SORT,
            payload: value
        };
    }

    static SEARCH_CLEAR_FILTERS  = '[Search_Impresa] SEARCH_CLEAR_FILTERS';
    searchClearFilters(): ActionWithPayload {
        return {
            type: SearchImpresaActions.SEARCH_CLEAR_FILTERS
        };
    }

    static SEARCH_TOGGLE_SHOWN_MORE = '[Search_Impresa] SEARCH_TOGGLE_SHOWN_MORE';
    searchToggleShowMore(value): ActionWithPayload {
        return {
            type: SearchImpresaActions.SEARCH_TOGGLE_SHOWN_MORE,
            payload: value
        };
    }

    static SEARCH_TOGGLE_COLLAPSE = '[Search_Impresa] SEARCH_TOGGLE_COLLAPSE';
    searchToggleCollapse(value): ActionWithPayload {
        return {
            type: SearchImpresaActions.SEARCH_TOGGLE_COLLAPSE,
            payload: value
        };
    }

    static SEARCH_FULL_TEXT = '[Search_Impresa] SEARCH_SEARCH_FULL_TEXT';
    searchFullText(query): ActionWithPayload {
        return {
            type: SearchImpresaActions.SEARCH_FULL_TEXT,
            payload: (typeof query === 'undefined')  ? '' : query
        };
    }

    static SEARCH_FULL_TEXT_SUCCESS = '[Search_Impresa] SEARCH_FULL_TEXT_SUCCESS';
    searchFullTextSuccess(res): ActionWithPayload {
        return {
            type: SearchImpresaActions.SEARCH_FULL_TEXT_SUCCESS,
            payload: res
        };
    }

    static SEARCH_FULL_TEXT_FAIL = '[Search_Impresa] SEARCH_FULL_TEXT_FAIL';
    searchFullTextFail(err): ActionWithPayload {
        return {
            type: SearchImpresaActions.SEARCH_FULL_TEXT_FAIL,
            payload: err
        };
    }

    static SEARCH_CHANGE_RESULTS_PAGE = '[Search_Impresa] SEARCH_CHANGE_RESULTS_PAGE';
    searchChangeResultsPage(page): ActionWithPayload {
        return {
            type: SearchImpresaActions.SEARCH_CHANGE_RESULTS_PAGE,
            payload: page
        };
    }

    static SEARCH_CHANGE_RESULTS_PAGE_BY = '[Search_Impresa] SEARCH_CHANGE_RESULTS_PAGE_BY';
    searchChangeResultsPageBy(num): ActionWithPayload {
        return {
            type: SearchImpresaActions.SEARCH_CHANGE_RESULTS_PAGE_BY,
            payload: num
        };
    }

}
