import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { AsrAuthGuard } from './services/asr-auth-guard/auth-guard.service';
import { AsrBozzeComponent, AsrHomeComponent, AsrSearchComponent, AsrEditComponent, AsrEditImpresaComponent, AsrInsertComponent, AsrSearchImpresaComponent } from './views';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: AsrHomeComponent },
  { path: 'sso_return', component: AsrHomeComponent, pathMatch: 'full'},
  { path: 'search', component: AsrSearchComponent,  canActivate: [AsrAuthGuard] },
  { path: 'search_impresa', component: AsrSearchImpresaComponent,  canActivate: [AsrAuthGuard] },
  { path: 'edit', component: AsrEditComponent, canActivate: [AsrAuthGuard]},
  { path: 'edit_impresa', component: AsrEditImpresaComponent, canActivate: [AsrAuthGuard], runGuardsAndResolvers: 'always'},
  { path: 'new', component: AsrInsertComponent, canActivate: [AsrAuthGuard]},
  { path: 'bozze', component: AsrBozzeComponent, canActivate: [AsrAuthGuard]},
  { path: '**', redirectTo: '/home', pathMatch: 'full' }
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  useHash: false,
  onSameUrlNavigation: 'reload',
  // enableTracing: true
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
