import { helpers } from 'src/app/helpers';
import { BehaviorSubject, Subject, of, Observable } from 'rxjs';
import { KeyValue } from '@angular/common';
import { Injector, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, AbstractControl, Validators } from '@angular/forms';
import { ControlHandlerFactory } from './control-handlers/control-handlers';

export const LEAF_TYPES = ['input', 'button', 'search_step', 'select', 'date-range', 'radio', 'checkbox', 'textfield', 'number', 'email', 'textarea', 'date', 'file', 'map'];
export const CONTAINER_TYPES = ['container', 'section', 'summary'];
export const OPERATORS = [''];
export class FormGeneratorController implements OnInit, OnDestroy {
  parentController = this;
  model;
  formGroup: FormGroup;
  events;
  //map objects
  containerMap = {};
  dataEntryMap = {};
  http;

  controlHandlerFactory: ControlHandlerFactory;
  destroyed$: Subject<any>;
  constructor(
    private injectorObj: Injector) {
    this.http = <HttpClient>this.injectorObj.get(HttpClient);
    this.controlHandlerFactory = <ControlHandlerFactory>this.injectorObj.get(ControlHandlerFactory);
    this.destroyed$ = new Subject<any>();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  initializeModel(model, formGroup) {
    for (const key in model) {
      if (model.hasOwnProperty(key)) {
        const element = model[key];
        if (CONTAINER_TYPES.includes(element.type) && LEAF_TYPES.includes(element.type)) {
          throw new Error('Error! Unknown model type');
        }

        this.initializeHTMLAttributes(element);
        this.assignWeightToContainer(model, key);
        this.containerMap[element.id] = element;
        this.addEvents(element);
        element.default_visible = typeof element.visible !== 'boolean' ? true : element.visible;
        element.visible = new BehaviorSubject(element.default_visible);

        element.parentControl = formGroup;
        let handler = this.controlHandlerFactory.getHandler(element.type);
        handler.initialize(element);
        handler.onChange(element, this);
        handler.updateFromDS(element, null);
        if (LEAF_TYPES.includes(element.type)) {
          if (!Array.isArray(element.parents)) {
            element.parents = [];
          }
          this.updateDataEntryMap(element);
        } else if (!LEAF_TYPES.includes(element.type) &&
          CONTAINER_TYPES.includes(element.type) &&
          element.children) {
          if(element.optional){
            this.updateDataEntryMap(element);
          }
          this.initializeModel(element.children, element.control);
        }
      }
    }
  }

  assignWeightToContainer(parent, element) {
    if (typeof parent[element].weight === 'number') {
      return;
    } else {
      let maxW = 0;
      for (const key in parent) {
        if (parent.hasOwnProperty(key) && key !== element) {
          const e = parent[key];
          if (typeof e.weight !== 'undefined' && e.weight >= maxW) {
            maxW = e.weight + 1;
          }
        }
      }
      parent[element].weight = maxW;
    }
  }

  initializeHTMLAttributes(element) {
    if (typeof element.attributes === 'undefined') {
      element.attributes = {};
    }
    element.attributes.class = typeof element.attributes.class === 'undefined' ? undefined : element.attributes.class;
    element.attributes.style = typeof element.attributes.style === 'undefined' ? undefined : element.attributes.style;
    element.attributes.template = typeof element.attributes.template === 'undefined' ? undefined : element.attributes.template;
  }

  resetModel() {
    for (const key in this.containerMap) {
      if (this.containerMap.hasOwnProperty(key)) {
        let element = this.containerMap[key];
        if (LEAF_TYPES.includes(element.type) && element.id !== 'ricerca') {
          let controlHandler = this.controlHandlerFactory.getHandler(element.type);
          controlHandler.reset(element);
        }
      }
    }
  }

  private getStateIds(exp: []){
      let ids = [];
      const reducer = (acc: [], currentValue)=>{
        if(typeof currentValue === 'string' && currentValue.startsWith('{{') && currentValue.endsWith('}}')  ){
          let tmp = currentValue.slice(2,-2);
          let id = tmp;
          return !!acc.find(el => id === el) ? acc : [...acc, id];
        } else if(Array.isArray(currentValue)){
          return acc.concat(currentValue.reduce(reducer,[]));
        }else{
          return acc;
        }
      }
      return exp.reduce(reducer, ids);;
  };

  /**
   * Add states from a container to instance variable events.
   * Every states contains one or more rules.
   * @param container The element of the model
   */
  addEvents(container) {
    if (!container.states) {
      return;
    }
    const path = container.id;
    const states = container.states;
    for (const key in states) {
      if (states.hasOwnProperty(key)) {
        const exp:[] = states[key];
        if(Array.isArray(exp)){
          let ids = this.getStateIds(exp);
          ids.forEach(id =>{
            if(!!this.events[id] && this.events[id].length ){
              this.events[id] = this.events[id].includes(path) ? this.events[id] : this.events[id].concat(path);
            }else{
              this.events[id] = [path];
            }
          })
        }
      }
    }
  }

  updateDataEntryMap(field) {
    if (typeof this.dataEntryMap === 'undefined') {
      this.dataEntryMap = {};
    }
    const path = field.id.split('-');
    const fieldID = path[path.length - 1];
    if (typeof field.parents !== 'undefined' && Array.isArray(field.parents) && field.parents.length > 0) {
      let ref = this.dataEntryMap;
      for (let i = 0; i < field.parents.length - 1; i++) {
        const element = field.parents[i];
        if (typeof ref[element] === 'undefined') {
          ref[element] = {};
        } else if (typeof ref[element] === 'object' && Array.isArray(ref[element])) {
          console.warn('Mapping for property ' + fieldID + ' already exists');
          ref[element] = {};
        }
        ref = ref[element];
      }
      let valueKey = field.parents[field.parents.length - 1];
      if (typeof ref[valueKey] === 'undefined' || (typeof ref[valueKey] !== 'undefined' && !Array.isArray(ref[valueKey]))) {
        ref[valueKey] = [];
      }
      ref[valueKey].push(field.id);
    } else {
      if (typeof this.dataEntryMap[fieldID] !== 'undefined') {
        if (!Array.isArray(this.dataEntryMap[fieldID])) {
          this.dataEntryMap[fieldID] = [];
        }
        console.warn('Mapping for property ' + fieldID + ' already exists');
      } else {
        this.dataEntryMap[fieldID] = [];
      }
      this.dataEntryMap[fieldID].push(field.id);
    }
  }

  /**
   * Set values of the fields following the controller dataEntryMap.
   * @param data
   * @param dataEntryMap
   */
  patchModel(data, dataEntryMap) {
    try {
      for (const key in data) {
        if (data[key] === null) {
          continue;
        }
        if (data.hasOwnProperty(key) && dataEntryMap.hasOwnProperty(key)) {
          if (Array.isArray(dataEntryMap[key])) {
            for (let i = 0; i < dataEntryMap[key].length; i++) {
              const id = dataEntryMap[key][i];
              const container = this.containerMap[id];
              let handler = this.controlHandlerFactory.getHandler(container.type);
              if(!handler.getValue(container) || !container.default_value || (container.default_value === handler.getValue(container))){
                handler.patchValue(container, data[key]);
              }
            }
          } else if (typeof data[key] === 'object' && typeof dataEntryMap[key] === 'object') {
            this.patchModel(data[key], dataEntryMap[key]);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * It handles model changes following rules described on states.
   * @param element The element that trigger changes.
   */
  onModelChange(element) {
    if ( typeof this.events[element.id] === 'undefined') {
      return;
    }
    //recover all elements that can be affected by changes from events table
    this.events[element.id].forEach((e, key) => {
      // const path = e.join('@children@').split('@');
      const path = e.split('-').join('@children@').split('@');      ;
      let currentElement = this.model;
      for (let i = 0; i < path.length; i++) {
        currentElement = currentElement[path[i]];
      }
      for (const state in currentElement.states) {
        if (!currentElement.states.hasOwnProperty(state)) {
          continue;
        }
        let currentHandler = this.controlHandlerFactory.getHandler(currentElement.type);
        const exp = currentElement.states[state];
        switch (state) {
          case 'validate': {
            let visible = this.isVisible(currentElement,currentElement.visible.value);
            if(!visible){
              break;
            }
            let handler = this.controlHandlerFactory.getHandler(currentElement.type);
            let constraints = this.evalExp(exp);
            if (!!constraints) {
              if (!currentElement.default_validators && !!currentElement.validators) {
                currentElement.default_validators = JSON.parse(JSON.stringify(currentElement.validators));
                currentElement.default_validators['required'] = currentElement.required;
              }
              currentElement.validators = {};
              if (typeof constraints.max === 'number') {
                currentElement.validators.max = constraints.max;
              }
              if (typeof constraints.min === 'number') {
                currentElement.validators.min = constraints.min;
              }
              if (!!constraints.required) {
                currentElement.required = constraints.required;
              }
              if (!!constraints.pattern) {
                let isValidRegExp = true;
                try {
                  let tmp = new RegExp(constraints.pattern);
                } catch (e) {
                  isValidRegExp = false;
                }
                if (isValidRegExp) {
                  currentElement.validators.pattern = constraints.pattern;
                }
              }
              if (typeof constraints.minLength === 'number' && constraints.minLength >= 0) {
                currentElement.validators.minLength = constraints.minLength;
              }
              if (typeof constraints.maxLength === 'number' && constraints.maxLength >= 0) {
                currentElement.validators.maxLength = constraints.maxLength;
              }
              let validators = handler.getValidators(currentElement);
              handler.setValidators(currentElement,validators);
            } else {
              if (!!currentElement.default_validators) {
                currentElement.validators = JSON.parse(JSON.stringify(currentElement.default_validators));
                currentElement.required = !!currentElement.validators.required;
                delete currentElement.validators.required;
                currentElement.default_validators = undefined;
              }
              let validators = handler.getValidators(currentElement);
              handler.setValidators(currentElement,validators);
            }
            if (currentElement.visible.value) {
              currentElement.control.updateValueAndValidity();
              currentElement.control.markAsTouched();
            }
            break;
          }
          case 'visible': {
            this.setVisible(currentElement,  this.evalExp(exp));
            break;
          }
          case 'disabled': {
            currentHandler.setState(currentElement, !this.evalExp(exp));
            break;
          }
          case 'options': {
            const par = this.evalExp(exp);
            if (!helpers.isEquivalentObj(par, currentElement.lastParams)) {
              currentElement.lastParams = par;
              this.controlHandlerFactory.getHandler(currentElement.type).updateFromDS(currentElement, par);
            }
            break;
          }
          case 'update': {
            const par = this.evalExp(exp);
            if (!helpers.isEquivalentObj(par, currentElement.lastParams)) {
              currentElement.lastParams = par;
              this.controlHandlerFactory.getHandler(currentElement.type).updateFromDS(currentElement, par);
            }
            break;
          }
          case 'value': { 
            let value = this.evalExp(exp);
            if(typeof value !== 'undefined' &&  currentHandler.getValue(currentElement) !== value){
              currentHandler.patchValue(currentElement, value );
              currentElement.control.markAsTouched();
            };
            break;
          }
          case 'label': {
            currentElement.label = this.evalExp(exp);
            break;
          }
          case 'required': {
            let isVisible = this.isVisible(currentElement, currentElement.visible.value);
            if (isVisible) {
              currentElement.control.clearValidators();
              let validators = this.controlHandlerFactory.getHandler(currentElement.type).getValidators(currentElement);
              if (this.evalExp(exp)) {
                currentElement.required = true;
                if (!validators.includes(Validators.required)) {
                  validators.push(Validators.required);
                }
              } else {
                if (validators.includes(Validators.required)) {
                  validators = validators.filter(el => el !== Validators.required);
                }
                currentElement.required = false;
              }
              this.controlHandlerFactory.getHandler(currentElement.type).setValidators(currentElement,validators);
              if (currentElement.visible.value) {
                currentElement.control.updateValueAndValidity();
              }
            }
            break;
          }
          case 'reset': {
            if (this.evalExp(exp)) {
              this.reset(currentElement);
            }
            break;
          }
          default:
            return;
        }
      }
    });
  }

  evalExp(exp){
    if(typeof exp === 'string'){
      if(exp.startsWith('{{') && exp.endsWith('}}')){
        let id = exp.slice(2,-2);
        const container = this.containerMap[id];
        if(!container){
          throw new Error(`Id ${id} non trovato. Impossibile risolvere l'espressione`);
        }
        return this.controlHandlerFactory.getHandler(container.type).getValue(container);
      }else{
        return exp;
      }
    } else if (Array.isArray(exp) && exp.length>=2){
      let unaryOp = ['isDefined', 'isNotDefined', '!'];
      let binaryOp = ['&&', "||", "sum", "mul", "?", "==", "!=", "get","includes"];
      let ternaryOp = ["??"];
      let [op] = exp.slice(-1);
      if(exp.length === 2 && unaryOp.includes(op)){
        let val = this.evalExp(exp[0]);
        if(op != "!"){
          return op === 'isDefined' ? !!val: !val ; 
        }
        return !val;  
      }else if(exp.length === 3 && binaryOp.includes(op)) {
        let first = this.evalExp(exp[0]);
        let second = this.evalExp(exp[1]);
        return this.evalOp(first,second,op);
      }else if(exp.length === 4 && ternaryOp.includes(op)) {
        let first = this.evalExp(exp[0]);
        let second = this.evalExp(exp[1]);
        let third = this.evalExp(exp[2]);
        return !!first ? second : third;
      }else{
        let array = [];
        for (let i = 0; i < exp.length; i++) {
          array.push(this.evalExp(exp[i]));
        }
        return array;
      }
    } else {
      return exp;
    }
  }

  private evalOp(first,second,op){
    switch (op) {
      case '&&':
          return !!first && !!second;
      case '||':
          return !!first || !!second;
      case 'sum':{
          let firstAdd = typeof first === 'number' ? first : 0;
          let secondAdd = typeof second === 'number' ? second : 0;
          return firstAdd + secondAdd;
      }
      case 'mul':{
        let firstAdd = typeof first === 'number' ? first : 0;
        let secondAdd = typeof second === 'number' ? second : 0;
        return firstAdd + secondAdd;
      }
      case '?': {
        return first ? second : undefined;
      }
      case '==': {
        if(typeof first === 'object' && typeof second === 'object'){
          return helpers.isEquivalentObj(first,second);
        }else{
          return first === second;
        }
      }
      case '!=': {
        if(typeof first === 'object' && typeof second === 'object'){
          return !helpers.isEquivalentObj(first,second);
        }else{
          return first !== second;
        }
      }
      case 'get': {
        if(!!first && typeof first === 'object'){
          let path = second.split('.');
          let temp = first;
          for (let i = 0; i < path.length; i++) {
            if(!temp){
              break;
            }
            const prop = path[i];
            temp = temp[prop];
            
          }
          return temp;
        }
      }
      case 'includes': {
        if(!Array.isArray(second)){
          return false;
        }
        return second.includes(first);
      }
      default:
        break;
    }
    return;
  }

  /**
   * Set the visibility of a container.
   * @param container a container of model in FormGeneratorController
   * @param value a boolean that indicates the new visibility state
   */
  setVisible(container, value): void {
    if (typeof this.containerMap[container.id] === 'undefined') {
      return;
    }
    let isVisible = this.isVisible(this.containerMap[container.id], true);
    container.visible.next(value);
    if (isVisible) {
      this.setValidation(container, value)
    }else if(!isVisible && !value){
      this.setValidation(container, value);
    }
  }

  /**
   * A private recursive method that return the current visibility of the container.
   * A container is visible if all ancestors are visible.
   */
  private isVisible(container, acc) {
    if (container === null || (container !== null && !acc)) {
      return acc;
    } else {
      let parent = this.getParent(container);
      if (parent === container) {
        return acc;
      } else {
        let val = acc && !!parent && !!parent.visible.value;
        return this.isVisible(parent, val);
      }

    }
  }

  /**
   * Return the container parent. Model must be initialized
   */
  private getParent(container) {
    let path = container.id.split('-');
    if (path.length <= 1) {
      return container;
    } else {
      let ancestorPath = path.slice(0, path.length - 1);
      let ancestorId = ancestorPath.join('-');
      return this.containerMap[ancestorId];
    }
  }

  /**
   * Set validation on container and all children.
   * If container is not visible, validation is disabled for all children.
   */
  private setValidation(container, value) {
    let c: AbstractControl = container.control;
    if (LEAF_TYPES.includes(container.type)) {
      if (value) {
        let handler = this.controlHandlerFactory.getHandler(container.type);
        let validators = handler.getValidators(container);
        handler.setValidators(container,validators);
        // c.markAsUntouched();
      } else {
        c.clearValidators();
      }
    } else if (CONTAINER_TYPES.includes(container.type)) {
      for (const childkey in container.children) {
        if (container.children.hasOwnProperty(childkey)) {
          const child = container.children[childkey];
          let childVisible = this.isVisible(child, child.visible.value);
          this.setValidation(child, value && childVisible);
        }
      }
    } else {
      throw new Error('Missing container type');
    }
    c.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  recoverDataEntry(model, dataEntry) {
    for (const propId in model) {
      if (model.hasOwnProperty(propId)) {
        const element = model[propId];
        if (LEAF_TYPES.includes(element.type)) {
          const id = element.id.split('-');
          const fieldId = id[id.length - 1];
          if (this.isVisible(element, element.visible.value)) {
            let handler = this.controlHandlerFactory.getHandler(element.type);
            let value = handler.getValue(element);
            if (value !== null) {
              this.addElementToDataEntry(element.parents, dataEntry, value, fieldId);
            }
          }
        } else {
          if (typeof element.children !== 'undefined') {
            if(element.optional){
              const id = element.id.split('-');
              const containerId = id[id.length - 1];
              let handler = this.controlHandlerFactory.getHandler(element.type);
              let value = handler.getValue(element);
              if(value !== null){
                this.addElementToDataEntry(element.parents, dataEntry, value, containerId);
              }
            }
            this.recoverDataEntry(element.children, dataEntry);
          }
        }
      }
    }
  }

  addElementToDataEntry(path, dataEntry, value, fieldId) {
    if (typeof path !== 'undefined' && Array.isArray(path) && path.length > 0) {
      let ref = dataEntry;
      for (let i = 0; i < path.length - 1; i++) {
        const element = path[i];
        if (typeof ref[element] === 'undefined') {
          ref[element] = {};
        }
        ref = ref[element];
      }
      ref[path[path.length - 1]] = value;
    } else {
      dataEntry[fieldId] = value;
    }
  }

  orderComparator = (a: KeyValue<string, object>, b: KeyValue<string, object>): number => {
    return a.value['weight'] > b.value['weight'] ? 1 : (b.value['weight'] > a.value['weight'] ? -1 : 0);
  }

  private reset(element) {
    if (LEAF_TYPES.includes(element.type)) {
      let handler = this.controlHandlerFactory.getHandler(element.type);
      handler.reset(element);
    } else if (CONTAINER_TYPES.includes(element.type)) {
      let handler = this.controlHandlerFactory.getHandler(element.type);
      handler.reset(element);
      if (!!element.children) {
        for (const key in element.children) {
          if (element.children.hasOwnProperty(key)) {
            const children = element.children[key];
            this.reset(children);
          }
        }
      }
    }
  }


}

