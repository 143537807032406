import { FormGeneratorController } from "../form-generator";
import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ValidatorFn } from "@angular/forms";

export interface ControlHandlerI {
    initialize(element);
    getValidators(element):ValidatorFn [];
    reset(element, options?);
    onChange(element, controller: FormGeneratorController);
    setValue(element, value, options?);
    getValue(element);
    updateFromDS(element, params?);
    patchValue(element, value, options?);
    setState(element, value: boolean, options?);
    setValidators(element, validatiors);
}

@Injectable()
export class HttpService  {
    private cache = {};
    constructor(private http: HttpClient) {}

    get(path: string, parameters?): Observable<any> {
        let p = this.encodeQueryData(parameters);
        let url = environment.baseURL + (p.length > 0 ?  path + '?' + p : path);

        if (typeof this.cache[url] !== 'undefined' ) {
            let difference = Date.now() - this.cache[url].timestamp;
            return difference > 60000 ? this.makeHttpRequest(url) : of(this.cache[url].data);
        } else {
            return this.makeHttpRequest(url);
        }
    }

    private makeHttpRequest(url: string): Observable<any> {
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            withCredentials: true
        };
        let request: Observable<Object> =  this.http.get(url, httpOptions);
        return request.pipe(
            tap(response => {
                if ( response['status'] === 'OK' ) {
                     this.cache[url] = {data: response, timestamp: Date.now()};
                 }
             })
         );
    }

    private encodeQueryData(data) {
        const ret = [];
        for (const d in data) {
            if (data.hasOwnProperty(d)) {
                ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
            }
        }
        return ret.join('&');
     }
}
