import { Injectable } from "@angular/core";
import { ActionWithPayload } from "src/app/app.interfaces";

@Injectable()
export class UserActions {


    static USER_LOGIN = "[User] USER_LOGIN";
    userLogin ( payload ): ActionWithPayload {
        return {
            type: UserActions.USER_LOGIN,
            payload: payload
        };
    }

    static SET_USER = "[User] SET_USER";
    setUser ( payload ): ActionWithPayload {
        return {
            type: UserActions.SET_USER,
            payload: payload
        };
    }

    static USER_LOGIN_SUCCESS = "[User] USER_LOGIN_SUCCESS";
    userLoginSuccess ( payload ): ActionWithPayload {
        return {
            type: UserActions.USER_LOGIN_SUCCESS,
            payload: payload
        };
    }

    static USER_LOGIN_FAIL = "[User] USER_LOGIN_FAIL";
    userLoginFail ( payload ): ActionWithPayload {
        return {
            type: UserActions.USER_LOGIN_FAIL,
            payload: payload
        };
    }

    static USER_LOGOUT = "[User] USER_LOGOUT";
    userLogout (): ActionWithPayload {
        return {
            type: UserActions.USER_LOGOUT,
        };
    }

    static USER_LOGOUT_SUCCESS = "[User] USER_LOGOUT_SUCCESS";
    userLogoutSuccess (): ActionWithPayload {
        return {
            type: UserActions.USER_LOGOUT_SUCCESS
        };
    }

    static USER_LOGOUT_FAIL = "[User] USER_LOGOUT_FAIL";
    userLogoutFail ( payload ): ActionWithPayload {
        return {
            type: UserActions.USER_LOGOUT_FAIL
        };
    }

    static USER_INSERT_STRUTTURA = "[User] USER_INSERT_STRUTTURA";
    insertStruttura ( payload ): ActionWithPayload {
        return {
            type: UserActions.USER_INSERT_STRUTTURA,
            payload: payload
        };
    }

    static USER_INSERT_STRUTTURA_SUCCESS = "[User] USER_INSERT_STRUTTURA_SUCCESS";
    insertStrutturaSuccess ( payload ): ActionWithPayload {
        return {
            type: UserActions.USER_INSERT_STRUTTURA_SUCCESS,
            payload: payload
        };
    }

    static USER_INSERT_STRUTTURA_FAIL = "[User] USER_INSERT_STRUTTURA_FAIL";
    insertStrutturaFail ( err ): ActionWithPayload {
        return {
            type: UserActions.USER_INSERT_STRUTTURA_FAIL,
            payload: err
        };
    }

    static USER_EDIT_STRUTTURA = "[User] USER_EDIT_STRUTTURA";
    editStruttura ( payload ): ActionWithPayload {
        return {
            type: UserActions.USER_EDIT_STRUTTURA,
            payload: payload
        };
    }

    static USER_EDIT_STRUTTURA_SUCCESS = "[User] USER_EDIT_STRUTTURA_SUCCESS";
    editStrutturaSuccess ( payload ): ActionWithPayload {
        return {
            type: UserActions.USER_EDIT_STRUTTURA_SUCCESS,
            payload: payload
        };
    }

    static USER_EDIT_STRUTTURA_FAIL = "[User] USER_EDIT_STRUTTURA_FAIL";
    editStrutturaFail ( err ): ActionWithPayload {
        return {
            type: UserActions.USER_EDIT_STRUTTURA_FAIL,
            payload: err
        };
    }

    static USER_DELETE_STRUTTURA = "[User] USER_DELETE_STRUTTURA";
    deleteStruttura (dataEntry): ActionWithPayload {
        return {
            type: UserActions.USER_DELETE_STRUTTURA,
            payload: dataEntry
        };
    }

    static USER_DELETE_STRUTTURA_SUCCESS = "[User] USER_DELETE_STRUTTURA_SUCCESS";
    deleteStrutturaSuccess(): ActionWithPayload {
        return {
            type: UserActions.USER_DELETE_STRUTTURA_SUCCESS
        };
    }

    static USER_DELETE_STRUTTURA_FAIL = "[User] USER_DELETE_STRUTTURA_FAIL";
    deleteStrutturaFail(err): ActionWithPayload {
        return {
            type: UserActions.USER_DELETE_STRUTTURA_FAIL,
            payload: err
        };
    }

    static USER_EDIT_IMPRESA = "[User] USER_EDIT_IMPRESA";
    editImpresa ( payload ): ActionWithPayload {
        return {
            type: UserActions.USER_EDIT_IMPRESA,
            payload: payload
        };
    }

    static USER_EDIT_IMPRESA_SUCCESS = "[User] USER_EDIT_IMPRESA_SUCCESS";
    editImpresaSuccess ( payload ): ActionWithPayload {
        return {
            type: UserActions.USER_EDIT_IMPRESA_SUCCESS,
            payload: payload
        };
    }

    static USER_EDIT_IMPRESA_FAIL = "[User] USER_EDIT_IMPRESA_FAIL";
    editImpresaFail ( err ): ActionWithPayload {
        return {
            type: UserActions.USER_EDIT_IMPRESA_FAIL,
            payload: err
        };
    }

    static EDIT_IMPRESA_FROM_MODAL = '[User] EDIT_IMPRESA_FROM_MODAL';
    editImpresaFromModal ( payload ): ActionWithPayload {
        return {
            type: UserActions.EDIT_IMPRESA_FROM_MODAL,
            payload: payload
        };
    }

    static EDIT_IMPRESA_FROM_MODAL_SUCCESS = "[User] EDIT_IMPRESA_FROM_MODAL_SUCCESS";
    editImpresaFromModalSuccess ( payload ): ActionWithPayload {
        return {
            type: UserActions.EDIT_IMPRESA_FROM_MODAL_SUCCESS,
            payload: payload
        };
    }

    static EDIT_IMPRESA_FROM_MODAL_FAIL = "[User] EDIT_IMPRESA_FROM_MODAL_FAIL";
    editImpresaFromModalFail ( err ): ActionWithPayload {
        return {
            type: UserActions.EDIT_IMPRESA_FROM_MODAL_FAIL,
            payload: err
        };
    }

    static USER_UPLOAD_FILE = "[User] USER_UPLOAD_FILE";
    userUploadFile ( payload ): ActionWithPayload {
        return {
            type: UserActions.USER_UPLOAD_FILE,
            payload: payload
        };
    }

    static USER_UPLOAD_FILE_SUCCESS = "[User] USER_UPLOAD_FILE_SUCCESS";
    userUploadFileSuccess ( payload ): ActionWithPayload {
        return {
            type: UserActions.USER_UPLOAD_FILE_SUCCESS,
            payload: payload
        };
    }

    static USER_UPLOAD_FILE_FAIL = "[User] USER_UPLOAD_FILE_FAIL";
    userUploadFileFail ( err ): ActionWithPayload {
        return {
            type: UserActions.USER_UPLOAD_FILE_FAIL,
            payload: err
        };
    }

    static USER_DOWNLOAD_FILE = "[User] USER_DOWNLOAD_FILE";
    userDownloadFile ( payload ): ActionWithPayload {
        return {
            type: UserActions.USER_DOWNLOAD_FILE,
            payload: payload
        };
    }

    static USER_DOWNLOAD_FILE_SUCCESS = "[User] USER_DOWNLOAD_FILE_SUCCESS";
    userDownloadFileSuccess ( payload ): ActionWithPayload {
        return {
            type: UserActions.USER_DOWNLOAD_FILE_SUCCESS,
            payload: payload
        };
    }

    static USER_DOWNLOAD_FILE_FAIL = "[User] USER_DOWNLOAD_FILE_FAIL";
    userDownloadFileFail ( err ): ActionWithPayload {
        return {
            type: UserActions.USER_DOWNLOAD_FILE_FAIL,
            payload: err
        };
    }

    static USER_SET_OPERATION = "[User] USER_SET_OPERATION";
    userSetOperation ( payload ): ActionWithPayload {
        return {
            type: UserActions.USER_SET_OPERATION,
            payload: payload
        };
    }

    static USER_SET_SECTION = "[User] USER_SET_SECTION";
    userSetSection ( payload ): ActionWithPayload {
        return {
            type: UserActions.USER_SET_SECTION,
            payload: payload
        };
    }

    static USER_SET_ITEM = "[User] USER_SET_ITEM";
    userSetItem ( payload ): ActionWithPayload {
        return {
            type: UserActions.USER_SET_ITEM,
            payload: payload
        };
    }

    static USER_CHECK_DUPLICATI = "[User] USER_CHECK_DUPLICATI";
    userCheckDuplicati ( payload ): ActionWithPayload {
        return {
            type: UserActions.USER_CHECK_DUPLICATI,
            payload: payload
        };
    }

    static USER_CHECK_DUPLICATI_SUCCCESS = "[User] USER_CHECK_DUPLICATI_SUCCESS";
    userCheckDuplicatiSuccess ( payload ): ActionWithPayload {
        return {
            type: UserActions.USER_CHECK_DUPLICATI_SUCCCESS,
            payload: payload
        };
    }

    static USER_GET_BOZZE = '[User] USER_GET_BOZZE';
    userGetBozze () {
        return {
            type: UserActions.USER_GET_BOZZE
        };
    }

    static USER_GET_BOZZE_SUCCESS = '[User] USER_GET_BOZZE_SUCCESS';
    userGetBozzeSucess ( payload ) {
        return {
            type: UserActions.USER_GET_BOZZE_SUCCESS,
            payload: payload
        };
    }

    static USER_GET_BOZZE_FAIL = '[User] USER_GET_BOZZE_FAIL';
    userGetBozzeFail ( payload ) {
        return {
            type: UserActions.USER_GET_BOZZE_FAIL,
            payload: payload
        };
    }

    static USER_CHECK_DUPLICATI_FAIL = "[User] USER_CHECK_DUPLICATI_FAIL";
    userCheckDuplicatiFail ( err ): ActionWithPayload {
        return {
            type: UserActions.USER_CHECK_DUPLICATI_FAIL,
            payload: err
        };
    }

    static USER_CHECK_LOGIN = "[User] USER CHECK LOGIN";
    userCheckLogin(): ActionWithPayload {
        return {
            type: UserActions.USER_CHECK_LOGIN
        };
    }

    static USER_CHECK_LOGIN_SUCCESS = "[User] USER CHECK LOGIN_SUCCESS";
    userCheckLoginSucess(payload): ActionWithPayload {
        return {
            type: UserActions.USER_CHECK_LOGIN_SUCCESS,
            payload: payload
        };
    }

    static USER_CHECK_LOGIN_FAIL = "[User] USER CHECK LOGIN_FAIL";
    userCheckLoginFail(payload): ActionWithPayload {
        return {
            type: UserActions.USER_CHECK_LOGIN_FAIL,
            payload: payload
        };
    }

    static GET_STRUT_BY_IMPRESA = "[User] GET_STRUT_BY_IMPRESA";
    getStrutByImpresa(id): ActionWithPayload {
        return {
            type: UserActions.GET_STRUT_BY_IMPRESA,
            payload: id
        };
    }

    static GET_STRUT_BY_IMPRESA_SUCCESS = "[User] GET_STRUT_BY_IMPRESA_SUCCESS";
    getStrutByImpresaSuccess(id): ActionWithPayload {
        return {
            type: UserActions.GET_STRUT_BY_IMPRESA_SUCCESS,
            payload: id
        };
    }

    static GET_STRUT_BY_IMPRESA_FAIL = "[User] GET_STRUT_BY_IMPRESA_FAIL";
    getStrutByImpresaFail(id): ActionWithPayload {
        return {
            type: UserActions.GET_STRUT_BY_IMPRESA_FAIL,
            payload: id
        };
    }

}