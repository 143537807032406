import { ActionWithPayload } from "src/app/app.interfaces";
import { Injectable } from "@angular/core";

@Injectable()
export class AppFlowActions {

    static SET_CURRENT_ITEM = '[APP FLOW] SET_CURRENT_ITEM';
    setCurrentItem(item): ActionWithPayload {
        return {
            type: AppFlowActions.SET_CURRENT_ITEM,
            payload: item
        };
    }

    static SET_CURRENT_SECTION = '[APP FLOW] SET_CURRENT_SECTION';
    setCurrentSection(section): ActionWithPayload {
        return {
            type: AppFlowActions.SET_CURRENT_SECTION,
            payload: section
        };
    }

    static SET_CURRENT_STEP = '[APP FLOW] SET_CURRENT_STEP';
    setCurrentStep(step): ActionWithPayload {
        return {
            type: AppFlowActions.SET_CURRENT_STEP,
            payload: step
        };
    }

    static SET_STEPS = '[APP FLOW] SET_STEPS';
    setSteps(steps): ActionWithPayload {
        return {
            type: AppFlowActions.SET_STEPS,
            payload: steps
        };
    }

    static GO_TO_NEXT_STEP = '[APP FLOW] GO_TO_NEXT_STEP';
    goToNextStep(): ActionWithPayload {
        return {
            type: AppFlowActions.GO_TO_NEXT_STEP
        };
    }

    static GO_TO_PREVIOUS_STEP = '[APP FLOW] GO_TO_PREVIOUS_STEP';
    goToPreviousStep(): ActionWithPayload {
        return {
            type: AppFlowActions.GO_TO_NEXT_STEP
        };
    }

    static LOAD_STRUT_AND_MOVE = "[User] LOAD_STRUT_AND_MOVE";
    loadStrutAndMove ( payload ): ActionWithPayload {
        return {
            type: AppFlowActions.LOAD_STRUT_AND_MOVE,
            payload: payload
        };
    }

    static LOAD_STRUT_AND_MOVE_SUCCESS = "[User] LOAD_STRUT_AND_MOVE_SUCCESS";
    loadStrutAndMoveSuccess ( payload ): ActionWithPayload {
        return {
            type: AppFlowActions.LOAD_STRUT_AND_MOVE_SUCCESS,
            payload: payload
        };
    }

    static LOAD_STRUT_AND_MOVE_FAIL = "[User] LOAD_STRUT_AND_MOVE_FAIL";
    loadStrutAndMoveFail ( payload ): ActionWithPayload {
        return {
            type: AppFlowActions.LOAD_STRUT_AND_MOVE_FAIL,
            payload: payload
        };
    }

    static LOAD_IMPRESA_AND_MOVE = "[User] LOAD_IMPRESA_AND_MOVE";
    loadImpresaAndMove ( payload ): ActionWithPayload {
        return {
            type: AppFlowActions.LOAD_IMPRESA_AND_MOVE,
            payload: payload
        };
    }

    static LOAD_IMPRESA_AND_MOVE_SUCCESS = "[User] LOAD_IMPRESA_AND_MOVE_SUCCESS";
    loadImpresaAndMoveSuccess ( payload ): ActionWithPayload {
        return {
            type: AppFlowActions.LOAD_IMPRESA_AND_MOVE_SUCCESS,
            payload: payload
        };
    }

    static LOAD_IMPRESA_AND_MOVE_FAIL = "[User] LOAD_IMPRESA_AND_MOVE_FAIL";
    loadImpresaAndMoveFail ( payload ): ActionWithPayload {
        return {
            type: AppFlowActions.LOAD_IMPRESA_AND_MOVE_FAIL,
            payload: payload
        };
    }
}