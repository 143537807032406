import { Injectable } from "@angular/core";
import { UserSelectors } from "./user.selectors";

@Injectable({providedIn: 'root'})
export class UserService {
    private ROLE_OPERATORE_ASR = "Operatore ASR"
    private ROLE_SUPER_OPERATORE_ASR = "Super Operatore ASR";
    private ROLE_AUTHENTICATED_USER = "authenticated user";
    private ROLE_VALIDATED_USER = "validated user";
    private authenticated = false;
    private operatoreASR = false;
    private superOperatoreAsr = false;
    private userId = null;

    constructor(private userSelector$: UserSelectors) {
        this.userSelector$.getUser$().subscribe((user)=>this.updateUser(user));
        let user = localStorage.getItem('user');
        let roles = localStorage.getItem('roles');
        if (user && roles) {
            this.userId = user;
            this.updateRoles(JSON.parse(roles));
        }
    }

    public isAuthenticated() {
        return this.authenticated;
    }

    public isOperatoreAsr() {
        return this.operatoreASR;
    }

    public isSuperOperatoreAsr() {
        return this.superOperatoreAsr;
    }

    public getUserId() {
        return this.userId;
    }

    private updateUser = (user) => {
        if (!!user) {
            this.userId = user.id;
            this.updateRoles(user.roles);
        } else {
            this.resetUser();
        }
    }

    private updateRoles(roles) {
        if(!roles){
            this.resetUser();
        }
        let isAuthenticated = false;
        let isValidated = false;
        for (const roleKey in roles) {
            if (Object.prototype.hasOwnProperty.call(roles, roleKey)) {
                const role = roles[roleKey];
                if (role === this.ROLE_AUTHENTICATED_USER) {
                    isAuthenticated = true;
                    this.authenticated = isValidated && isAuthenticated;
                }
                if (role === this.ROLE_VALIDATED_USER) {
                    isValidated = true;
                    this.authenticated = isValidated && isAuthenticated;
                }
                if (role === this.ROLE_OPERATORE_ASR) {
                    this.operatoreASR = true;
                }
                if (role === this.ROLE_SUPER_OPERATORE_ASR) {
                    this.superOperatoreAsr = true;
                }
            }
        }
    }

    private resetUser = function () {
        this.userId = null;
        this.superOperatoreAsr = false;
        this.operatoreASR = false;
    }
}