export const environment = {
  production: true,
  baseURL: 'https://backend.asr.sardegnaturismo.it',
  iunBaseURL: 'https://www.iun-ras.eu',
  accessManager: 'https://login.regione.sardegna.it',
  authorizePath: '/oauth/authorize',
  response_type: 'code',
  client_id: 'asr.sardegnaturismo.it',
  redirect_uri: 'https://asr.sardegnaturismo.it/sso_return',
  scope: 'read',
  requests: {
    search: {
      method: 'POST',
      path: '/anagrafeAPI/search',
      mock: false
    },
    search_impresa: {
      method: 'POST',
      path: '/anagrafeAPI/search_impresa',
      mock: false
    },
    generic : {
      method: 'GET',
      path: '/anagrafeAPI/genericStats',
      mock: false
    },
    autocomplete : {
      method: 'GET',
      mock: false,
      path: '/anagrafeAPI/autocomplete',
      params: ['index', 'lookupKey']
    }, autocompleteImpresa : {
      method: 'GET',
      mock: false,
      path: '/anagrafeAPI/autocompleteImpresa?index=impresa',
      params: ['index', 'lookupKey']
    },
    fetchProvince : {
      method: 'GET',
      path: '/anagrafeAPI/fetchProvince',
      mock: false
    },
    fetchComuni : {
      method: 'GET',
      path: '/anagrafeAPI/fetchComuni',
      mock: false,
      params: ['provincia']
    },
    insertStruttura : {
      method: 'POST',
      path: '/anagrafeAPI/insertStruttura',
      mock: false
    },
    modificaStruttura: {
      method: 'GET',
      path: '/anagrafeAPI/modificaStruttura',
      params: ['idStruct'],
      mock: false
    },
    cancellaStruttura: {
      method: 'POST',
      path: '/anagrafeAPI/cancellaStruttura',
      mock: false
    },
    modificaImpresa: {
      method: 'POST',
      path: '/anagrafeAPI/modificaImpresa',
      mock: false
    },
    fetchDataEntryStruttura: {
      method: 'GET',
      path:  '/anagrafeAPI/fetchDataEntryStruttura',
      mock: false,
      params: ['idStruct']
    },
    fetchStruttura: {
      method: 'GET',
      path:  '/anagrafeAPI/fetchStruttura',
      mock: false,
      params: ['idStrut']
    },
    fetchStruttureDuplicate: {
      method: "POST",
      path: '/anagrafeAPI/fetchStruttureDuplicate',
      mock: false,
      params: ['comune', 'descrizioneidras']
    },
    uploadFile: {
      method: "POST",
      path: '/anagrafeAPI/uploadFile',
      mock: 'false'
    },
    downloadFile : {
      method: "GET",
      path: '/anagrafeAPI/downloadFile',
      mock: 'false'
    },
    login: {
      method: 'GET',
      path: '/rasAuth/login',
      mock: false,
      params: ['name']
    },
    isLogged: {
      method: 'GET',
      path: '/rasAuth/islogged',
      mock: false
    },
    logout: {
      method: 'GET',
      path: '/rasAuth/logout',
      mock: false
    },
    fetchBozze: {
      method: 'GET',
      path: '/anagrafeAPI/fetchBozze',
      mock: false,
      params: ['idUtente']
    },
    geocode: {
      method: 'GET',
      path: '/anagrafeAPI/geocode',
      mock: false,
      params: ['q']
    },
    fetchImpresa: {
      method: 'GET',
      path:  '/anagrafeAPI/fetchImpresa',
      mock: false,
      params: ['id']
    },
    fetchStrutByImpresa: {
      method: 'GET',
      path: '/anagrafeAPI/fetchStruttureByImpresa',
      mock: false,
      params: ['id']
    }
  },
  auth: true,
};
