import { Component, OnInit, AfterViewInit, Input, OnDestroy, } from '@angular/core';
import { Observable, from, Subject } from 'rxjs';
import L from 'leaflet';
import { Icon, icon, Marker, marker } from 'leaflet';
import { Store } from '@ngrx/store';
import { SearchActions, SearchSelectors } from 'src/app/logic/search';
import { takeUntil, distinctUntilChanged, skipLast, skip, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GenericActions } from 'src/app/logic/generic';

@Component({
    selector: 'asr-map-container',
    templateUrl: 'asr-map-container.component.html'
})
export class AsrMapContainer implements OnInit, OnDestroy {

    @Input() markers: Observable<Object>;
    @Input() boundingBox: Observable<Object>;
    map;
    mapMarkers;
    geoSearch$;
    destroyed$: Subject<any> = new Subject<any>();

    constructor(private store: Store<any>,
        private searchActions: SearchActions,
        private genericActions: GenericActions,
        private router: Router,
        private searchSelectors: SearchSelectors) {
    }

    // Override default Icons
    private iconOptions = {
        iconSize: [ 25, 41 ],
        iconAnchor: [ 13, 41 ],
        iconUrl: 'leaflet/images/marker-icon.png',
        shadowUrl: 'leaflet/images/marker-shadow.png'
    };
    private defaultIcon: Icon = icon(this.iconOptions);
    ngOnInit() {
        Marker.prototype.options.icon = this.defaultIcon;
        this.map = L.map('leafletmap').setView([40.097262, 9.140745], 7);
        this.map.setView(new L.LatLng(40.097262, 9.140745), 7);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);
        this.markers.pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe({
            next: (x) => {
                this.updateMap(x);
            },
            error: (err) => console.log(err),
            complete: () => {},
        });

        this.boundingBox.pipe(take(1)).subscribe({
            next: (x) => {
                this.setBoundingBox(x);
            },
            error: (err) => console.log(err),
            complete: () => {},
        });

        this.searchSelectors.getToogleGeoSearch$().pipe(
            takeUntil(this.destroyed$),
            distinctUntilChanged(),
            skip(1),
        ).subscribe({
            next: (x) => {
                this.geoSearch$ = x;
                if (x === true) {
                    try {
                        let coords = this.map.getBounds();
                        this.store.dispatch(this.searchActions.searchSetBoundingBox(coords));
                    } catch (error) {
                        console.error(error);
                    }
                } else {
                    this.store.dispatch(this.searchActions.searchStart());
                }
            },
            error: (err) => console.log(err),
            complete: () => {},
        });
        this.map.on('moveend', function (e) {
            try {
                if (this.geoSearch$ === true) {
                    let coords = e.target.getBounds();
                    this.store.dispatch(this.searchActions.searchSetBoundingBox(coords));
                }
            } catch (error) {
                console.error(error);
            }
        }, this);
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    private updateMap(markers) {
        if (typeof this.map !== 'undefined') {
            if (this.mapMarkers) {
                this.map.removeLayer(this.mapMarkers);
            }
            let markersArray = []
            for (let i = 0; i < markers.length; i++) {
                const marker = markers[i];
                if (marker.lat > -90 && marker.lat < 90 && marker.lng > -180 && marker.lng < 180) {
                    let feature = L.marker([marker.lat, marker.lng]);
          
                    feature.bindPopup(this.createPopup(marker));
                    markersArray.push(feature);
                } else {
                    // console.warn('Bad coordinates for '+marker.nome, marker.lat, marker.lmg);
                }
            }
            this.mapMarkers = L.featureGroup(markersArray);
            let router = this.router;
            let store = this.store;
            let generic = this.genericActions;
            this.mapMarkers.addTo(this.map).on('popupopen', function () {
                let element = self.document.querySelector('.popup-button-le');
                if (element) {
                    element.addEventListener('click', (e) => {
                        let link = element.getAttribute('data-link');
                        if (link) {
                            let path = link.split('-');
                            if (path[1]) {
                                router.navigate(['/edit'], { queryParams: { type: 'strut', step: 'dati_prelim', id: path[1] } });
                            } else {
                                store.dispatch(generic.showErrorModal({
                                    title: 'Errore',
                                    message: 'Impossibile identificare l\'id della struttura',
                                    buttons: [{ id: 'close', label: 'Chiudi', class: '', action: '', autoclose: true }]
                                }));
                            }
                        } else {
                            store.dispatch(generic.showErrorModal({
                                title: 'Errore',
                                message: 'Questa struttura è un agriturismo. Non è ancora possibile modificarla.',
                                buttons: [{ id: 'close', label: 'Chiudi', class: '', action: '', autoclose: true }]
                            }));
                        }
                    });
                }
            });
            if (this.geoSearch$ !== true) {
                try {
                    this.map.fitBounds(this.mapMarkers.getBounds(), { padding: L.point(20, 20) });
                } catch (error) {
                    console.error(error)
                    this.map.setView(new L.LatLng(40.097262, 9.140745), 7);
                }
            }
        }
    }

    private setBoundingBox(coords) {
        if (typeof this.map !== 'undefined' && typeof coords !== 'undefined') {
            try {
                var p1 = [coords['_southWest'].lat, coords['_southWest'].lng];
                var p2 = [coords['_northEast'].lat, coords['_northEast'].lng];
                this.map.fitBounds([
                    p1,
                    p2
                ]);
            } catch (error) {
                console.log('Error ', error);
            }
        }
    }

    private createPopup(markerInfo) {
        var customPopup = `<div class="p-2 d-flex align-items-baseline justify-content-between">
        <div class="text">
          <h4 class="mb-0">`+ markerInfo.nome + `</h4>
          <p class="m-0"><strong>`+ markerInfo.nome + ` ` + markerInfo.indirizzo + `</strong>
          </p>
        </div>
        <div class="actions float-right">
        </div>
      </div>
      <div class="row justify-content-center">`;
        if (typeof markerInfo.idStrut !== 'undefined') {
            let link = "strut-" + markerInfo.idStrut;
            customPopup += `<a class="popup-button-le" data-link="` + link + `"> <button class="btn btn-link" type="button"><i class="fa fa-edit"></i> modifica</button></a>`;
        } else if (typeof markerInfo.idAgriturismo !== 'undefined') {
            customPopup += `<a class="popup-button-le" > <button class="btn btn-link" type="button"><i class="fa fa-edit"></i> modifica</button></a>`;
        }
        customPopup += `</div>`;
        return customPopup;
    }
}

