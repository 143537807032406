import { Injectable } from "@angular/core";
import { ActionWithPayload } from "src/app/app.interfaces";

@Injectable()
export class GenericActions {

    static GENERIC_GET_INFO = "[Generic] GENERIC_GET_INFO";
    genericGetInfo(): ActionWithPayload {
        return {
            type: GenericActions.GENERIC_GET_INFO
        };
    }

    static GENERIC_GET_INFO_SUCCESS = "[Generic] GENERIC_GET_INFO_SUCCESS";
    genericGetInfoSuccess(info): ActionWithPayload {
        return {
            type: GenericActions.GENERIC_GET_INFO_SUCCESS,
            payload: info
        };
    }

    static GENERIC_GET_INFO_FAIL = "[GENERIC] GENERIC_GET_INFO FAIL";
    genericGetInfoFail(err): ActionWithPayload {
        return {
            type: GenericActions.GENERIC_GET_INFO_FAIL,
            payload: err
        };
    }

    static ERROR_MODAL_SHOW = '[Generic] ERROR_MODAL_SHOW'
    showErrorModal(payload): ActionWithPayload {
        return {
            type: GenericActions.ERROR_MODAL_SHOW,
            payload: payload
        };
    }

    static ERROR_MODAL_HIDE = '[Generic] ERROR_MODAL_HIDE'
    hideErrorModal(payload): ActionWithPayload {
        return {
            type: GenericActions.ERROR_MODAL_HIDE,
            payload: payload
        };
    }

    static DUPLICATES_MODAL_SHOW = '[Generic] DUPLICATES_MODAL_SHOW'
    showDuplicatesModal(payload): ActionWithPayload {
        return {
            type: GenericActions.DUPLICATES_MODAL_SHOW,
            payload: payload
        };
    }

    static DUPLICATES_MODAL_HIDE = '[Generic] DUPLICATES_MODAL_HIDE'
    hideDuplicatesModal(payload): ActionWithPayload {
        return {
            type: GenericActions.DUPLICATES_MODAL_HIDE,
            payload: payload
        };
    }

    static STRUTTURE_MODAL_SHOW = '[Generic] STRUTTURE_MODAL_SHOW'
    showStruttureModal(payload): ActionWithPayload {
        return {
            type: GenericActions.STRUTTURE_MODAL_SHOW,
            payload: payload
        };
    }

    static STRUTTURE_MODAL_HIDE = '[Generic] STRUTTURE_MODAL_HIDE'
    hideStruttureModal(payload): ActionWithPayload {
        return {
            type: GenericActions.STRUTTURE_MODAL_HIDE,
            payload: payload
        };
    }

    static DELETE_MODAL_SHOW = '[Generic] DELETE_MODAL_SHOW'
    showDeleteModal(payload): ActionWithPayload {
        return {
            type: GenericActions.DELETE_MODAL_SHOW,
            payload: payload
        };
    }

    static DELETE_MODAL_HIDE = '[Generic] DELETE_MODAL_HIDE'
    hideDeleteModal(payload): ActionWithPayload {
        return {
            type: GenericActions.DELETE_MODAL_HIDE,
            payload: payload
        };
    }

    static RENAME_MODAL_SHOW = '[Generic] RENAME_MODAL_SHOW'
    showRenameModal(payload): ActionWithPayload {
        return {
            type: GenericActions.RENAME_MODAL_SHOW,
            payload: payload
        };
    }

    static RENAME_MODAL_HIDE = '[Generic] RENAME_MODAL_HIDE'
    hideRenameModal(payload): ActionWithPayload {
        return {
            type: GenericActions.RENAME_MODAL_HIDE,
            payload: payload
        };
    }

    static EDIT_MODAL_SHOW = '[Generic] EDIT_MODAL_SHOW'
    showEditModal(payload): ActionWithPayload {
        return {
            type: GenericActions.EDIT_MODAL_SHOW,
            payload: payload
        };
    }

    static EDIT_MODAL_HIDE = '[Generic] EDIT_MODAL_HIDE'
    hideEditModal(payload): ActionWithPayload {
        return {
            type: GenericActions.EDIT_MODAL_HIDE,
            payload: payload
        };
    }

    static PREVIEW_MODAL_SHOW = '[Generic] PREVIEW_MODAL_SHOW'
    showPreviewModal(payload): ActionWithPayload {
        return {
            type: GenericActions.PREVIEW_MODAL_SHOW,
            payload: payload
        };
    }

    static PREVIEW_MODAL_SHOW_SUCCESS = '[Generic] PREVIEW_MODAL_SHOW_SUCCESS'
    showPreviewModalSuccess(payload): ActionWithPayload {
        return {
            type: GenericActions.PREVIEW_MODAL_SHOW_SUCCESS,
            payload: payload
        };
    }

    static PREVIEW_MODAL_SHOW_FAIL = '[Generic] PREVIEW_MODAL_SHOW_FAIL'
    showPreviewModalFail(payload): ActionWithPayload {
        return {
            type: GenericActions.PREVIEW_MODAL_SHOW_SUCCESS,
            payload: payload
        };
    }

    static PREVIEW_MODAL_HIDE = '[Generic] PREVIEW_MODAL_HIDE'
    hidePreviewModal(payload): ActionWithPayload {
        return {
            type: GenericActions.PREVIEW_MODAL_HIDE,
            payload: payload
        };
    }

    static MODAL_BUTTON_CLICK = '[Generic] MODAL_BUTTON_CLICK';
    modalButtonClick(button): ActionWithPayload {
        return {
            type: GenericActions.MODAL_BUTTON_CLICK,
            payload: button
        };
    }

    static START_LOADING = '[Generic] START_LOADING';
    startLoading(message): ActionWithPayload {
        return {
            type : GenericActions.START_LOADING,
            payload: message
        };
    }

    static STOP_LOADING = '[Generic] STOP_LOADING';
    stopLoading(): ActionWithPayload {
        return {
            type: GenericActions.STOP_LOADING,
        };
    }

    static NAVIGATE_EXTERNAL_URL = "[Generic] NAVIGATE EXTERNAL URL"
    goExternalUrl(url): ActionWithPayload {
        return {
            type: GenericActions.NAVIGATE_EXTERNAL_URL,
            payload: url
        };
    }

    static RESET_MODAL = "[Generic] RESET_MODAL"
    resetModal(): ActionWithPayload {
        return {
            type: GenericActions.RESET_MODAL
        };
    }

}
