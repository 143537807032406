import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class SearchImpresaRequests {

    httpOptions: {};
    constructor( private http: HttpClient ) {
        this.httpOptions = {
            headers: new HttpHeaders( {
                'Content-Type': 'application/json'
            } ),
            withCredentials: true
        };
    }

    requestSearch$( req ) {
        return this.http.post( environment.baseURL + environment.requests.search_impresa.path, req , this.httpOptions);
    }

}
