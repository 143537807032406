import { SearchImpresaActions } from './search-impresa.actions';
import { SearchImpresaState } from './search-impresa.interfaces';
import { ActionWithPayload } from '../../app.interfaces';
import { helpers } from '../../helpers';

export const initialState: SearchImpresaState = {
    queryString: '',
    facets: [],
    searchResultsList: [],
    searchNumResults: undefined,
    filters: {},
    sort: {},
    collapsed: {},
    shownMore: {},
    page: 0,
    resultsPerPage: 20,
    pager: [],
    currentSearchState: {status: 'empty', message: 'Nessun risultato', title: '' },
    previousState: {queryString: '', facets: [], filters: {}, page: 0, pager: []},
};

export function searchImpresaReducer(state = initialState, { type, payload, loadingMessage }: ActionWithPayload) {

    let nextState: SearchImpresaState;

    switch (type) {

        case SearchImpresaActions.SEARCH_START: {
            nextState = helpers.immutAssign({}, state, {
                '>previousState': {
                    facets: JSON.parse(JSON.stringify(state.facets)),
                    filters: helpers.immutAssign({}, state.filters, {}),
                    sort: JSON.parse(JSON.stringify(state.sort)),
                    page: state.page,
                    queryString: state.queryString}
             });
            return nextState;
        }

        case SearchImpresaActions.SEARCH_SUCCESS: {
            nextState = helpers.immutAssign({}, state, {
                '>searchResultsList': payload.results,
                facets: setActiveFacets(payload.facets, state.filters),
                '>sort': JSON.parse(JSON.stringify(payload.sort)),
                searchNumResults: payload.numResults,
                '>pager': getPager(state.page, Math.ceil(payload.numResults / state.resultsPerPage)),
                '>currentSearchState': (payload.numResults > 0) ?
                    {status: 'ok', message: '', title: '' } :
                    {status: 'empty', message: 'Nessun risultato trovato!', title: '' }
            });
            return nextState;
        }

        case SearchImpresaActions.SEARCH_FAIL: {
            nextState = helpers.immutAssign({}, state, {
            '>facets': state.previousState['facets'],
            '>filters': state.previousState['filters'],
            '>pager': state.previousState['pager'],
            '>sort': state.previousState['sort'],
              page: state.previousState['page'],
              queryString: state.previousState['queryString'],
              '>currentSearchState':  (state.searchNumResults > 0) ?
                {status: 'error', message: 'C\'è stato un errore! Riprova più tardi.', title: ''} :
                {status: 'empty', message: 'Nessun risultato trovato!', title: ''}
             });
            return nextState;
        }

        case SearchImpresaActions.SEARCH_SET_FILTER: {
            nextState = helpers.immutAssign({}, state, {
                '>previousState': {
                    facets: JSON.parse(JSON.stringify(state.facets)),
                    filters: helpers.immutAssign({}, state.filters, {}),
                    sort: JSON.parse(JSON.stringify(state.sort)),
                    page: state.page,
                    queryString: state.queryString},
                facets: setFacets(state, payload),
                filters: setFilters(state, payload),
                page: 0});
            return nextState;
        }

        case SearchImpresaActions.SEARCH_SORT: {
            nextState = helpers.immutAssign({}, state, {
                '>previousState': {
                    facets: JSON.parse(JSON.stringify(state.facets)),
                    filters: helpers.immutAssign({}, state.filters, {}),
                    sort: JSON.parse(JSON.stringify(state.sort)),
                    page: state.page,
                    queryString: state.queryString},
                '>sort': JSON.parse(JSON.stringify(payload))
                });
            return nextState;
        }

        case SearchImpresaActions.SEARCH_CLEAR_FILTERS: {
            nextState =  nextState = helpers.immutAssign({}, state, {
                '>previousState': {
                    facets: JSON.parse(JSON.stringify(state.facets)),
                    filters: helpers.immutAssign({}, state.filters, {}),
                    '>sort': JSON.parse(JSON.stringify(state.sort)),
                    page: state.page,
                    queryString: state.queryString},
                facets: [],
                filters: {},
                page: 0});
            return nextState;
        }

        case SearchImpresaActions.SEARCH_TOGGLE_SHOWN_MORE: {
            nextState = helpers.immutAssign({}, state, { shownMore: setShowMore(state, payload) });
            return nextState;
        }

        case SearchImpresaActions.SEARCH_TOGGLE_COLLAPSE: {
            nextState = helpers.immutAssign({}, state, { collapsed: setCollapsed(state, payload) });
            return nextState;
        }

        case SearchImpresaActions.SEARCH_FULL_TEXT: {
            nextState =  nextState = helpers.immutAssign({}, state, {
                queryString: payload,
                '>previousState': {
                    facets: JSON.parse(JSON.stringify(state.facets)),
                    sort: JSON.parse(JSON.stringify(state.sort)),
                    filters: helpers.immutAssign({}, state.filters, {}),
                    page: state.page,
                    '>sort': JSON.parse(JSON.stringify(state.sort)),
                    queryString: state.queryString},
                facets: [],
                filters: {},
                sort: {},
                page: 0});
            return nextState;
        }

        case SearchImpresaActions.SEARCH_FULL_TEXT_SUCCESS: {
            nextState = helpers.immutAssign({}, state, {
                '>searchResultsList': payload.results,
                '>facets': setActiveFacets(payload.facets, {}),
                '>filters': {},
                '>sort': JSON.parse(JSON.stringify(payload.sort)),
                '>collapsed': {},
                '>shownMore': {},
                 '>lastBoundingBoxCoords': undefined,
                page: 0,
                searchNumResults: payload.numResults,
                '>pager': getPager(0, Math.ceil(payload.numResults / state.resultsPerPage)),
                '>currentSearchState':
                    (payload.numResults > 0) ? {status: 'ok', message: '', title: '' } :
                    {status: 'empty', message: 'Nessun risultato trovato!', title: '' }
            });
            return nextState;
        }

        case SearchImpresaActions.SEARCH_FULL_TEXT_FAIL: {
            nextState = helpers.immutAssign({}, state, {
            facets: state.previousState['facets'],
            '>filters': state.previousState['filters'],
            '>pager': state.previousState['pager'],
            '>sort': state.previousState['sort'],
              page: state.previousState['page'],
              queryString: state.previousState['queryString'],
              '>currentSearchState': (payload.numResults > 0 ) ?
               {status: 'error', message: 'C\'è stato un errore! Riprova più tardi.', title: ''} :
               {status: 'empty', message: 'Nessun risultato trovato!', title: '' }
             });
            return nextState;
        }

        case SearchImpresaActions.SEARCH_CHANGE_RESULTS_PAGE: {
            nextState = helpers.immutAssign({}, state, {
                page: payload,
                pager: [],
                '>previousState': {
                    facets: JSON.parse(JSON.stringify(state.facets)),
                    filters: helpers.immutAssign({}, state.filters, {}),
                    page: state.page,
                    queryString: state.queryString},
            });
            return nextState;
        }

        case SearchImpresaActions.SEARCH_CHANGE_RESULTS_PAGE_BY: {
            nextState = helpers.immutAssign({}, state, {
                loading: true,
                loadingMessage: loadingMessage,
                page: (Math.ceil((state.searchNumResults / state.resultsPerPage)) > (state.page + payload)) ?
                    ((state.page + payload) > 0 ? state.page + payload : 0)
                    : Math.ceil(state.searchNumResults / state.resultsPerPage),
                pager: [],
                '>previousState': {
                    facets: JSON.parse(JSON.stringify(state.facets)),
                    filters: helpers.immutAssign({}, state.filters, {}),
                    page: state.page,
                    queryString: state.queryString}
            });
            return nextState;
        }

        default:
         return state;
    }

}

function setFacets(state, filter) {
    for (let i = 0; i < state.facets.length; i++) {
        if (state.facets[i].facetName !== filter.facetName) {
            continue;
        }
        let found = false;
        for (let j = 0; j < state.facets[i].facetResults.length; j++) {
            if (state.facets[i].facetResults[j].value === filter.value) {
                state.facets[i].facetResults[j].selected = filter.selected;
                found = true;
                break;
            }
        }
        if (found) {
            break;
        }
    }
    return state.facets;
}

function setFilters(state, filter) {
    if (typeof state.filters[filter.facetName] === 'undefined') {
        state.filters[filter.facetName] = {};
    }
    state.filters[filter.facetName][filter.value] = filter.selected;

    return state.filters;
}

function setShowMore(state, showMoreData) {
    if (typeof state.shownMore[showMoreData.facetName] === 'undefined') {
        state.shownMore[showMoreData.facetName] = true;
    } else {
        state.shownMore[showMoreData.facetName] = !state.shownMore[showMoreData.facetName];
    }
    return state.shownMore;
}

function setCollapsed(state, collapsedData) {
    if (typeof state.collapsed[collapsedData.facetName] === 'undefined') {
        state.collapsed[collapsedData.facetName] = true;
    } else {
        state.collapsed[collapsedData.facetName] = !state.collapsed[collapsedData.facetName];
    }

    return state.collapsed;
}

function getPager(currentPage, lastPage) {
    let pager = [];
    if (currentPage <= 6 && lastPage <= 6) {
        for (let i = 0; i < lastPage; i++) {
            pager.push({ value: i + 1, clickable: currentPage !== i });
        }
    } else if (currentPage < 5 && lastPage > 5) {
        for (let i = 0; i <= 5; i++) {
            pager.push({ value: i + 1, clickable: currentPage !== i });
        }
        pager.push({ value: '..', clickable: false });
        pager.push({ value: lastPage, clickable: true });
    } else if ((lastPage - currentPage) <= 3) {
        pager.push({ value: 1, clickable: true });
        pager.push({ value: '..', clickable: false });
        for (let i = (lastPage - 4); i < lastPage; i++) {
            pager.push({ value: i + 1, clickable: currentPage !== i });
        }
    } else {
        pager.push({ value: 1, clickable: true });
        pager.push({ value: '..', clickable: false });
        pager.push({ value: currentPage, clickable: true });
        pager.push({ value: currentPage + 1, clickable: false });
        pager.push({ value: currentPage + 2, clickable: true });
        pager.push({ value: '..', clickable: false });
        pager.push({ value: lastPage  , clickable: true });
    }

    return pager;
}

function setActiveFacets(facets, filters) {
    const newFilters = {};
    for (let i = 0; i < facets.length; i++) {
        let append = [];
        for (let j = facets[i].facetResults.length - 1; j >= 0; j--) {
            if (typeof filters[facets[i].facetName] !== 'undefined' && typeof filters[facets[i].facetName][facets[i].facetResults[j].value] !== 'undefined') {
                if (filters[facets[i].facetName][facets[i].facetResults[j].value]) {
                    facets[i].facetResults[j].selected = true;
                    if ( typeof newFilters[facets[i].facetName] === 'undefined') {
                        newFilters[facets[i].facetName] = {};
                    }
                    newFilters[facets[i].facetName][facets[i].facetResults[j].value] = true;
                    Array.prototype.unshift.apply(append, facets[i].facetResults.splice(j, 1));
                } else {
                    facets[i].facetResults[j].selected = filters[facets[i].facetName][facets[i].facetResults[j].value];
                    if (facets[i].facetResults[j].selected) {
                        if ( typeof newFilters[facets[i].facetName] === 'undefined') {
                            newFilters[facets[i].facetName] = {};
                        }
                        newFilters[facets[i].facetName][facets[i].facetResults[j].value] = true;
                    }
                }
            }
        }
        facets[i].facetResults = append.concat(facets[i].facetResults);
    }

    filters = newFilters;
    return facets;
}
