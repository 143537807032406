/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./asr-error-modal.component";
import * as i3 from "@ngrx/store";
import * as i4 from "../../../logic/generic/generic.actions";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
var styles_AsrErrorModalComponent = [];
var RenderType_AsrErrorModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AsrErrorModalComponent, data: {} });
export { RenderType_AsrErrorModalComponent as RenderType_AsrErrorModalComponent };
function View_AsrErrorModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickedButton(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(2, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "btn btn-secondary"; var currVal_1 = (_v.context.$implicit.class ? _v.context.$implicit.class : ""); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.label; _ck(_v, 2, 0, currVal_2); }); }
export function View_AsrErrorModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h3", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AsrErrorModalComponent_1)), i0.ɵdid(9, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.data == null) ? null : _co.data.buttons); _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.data == null) ? null : _co.data.title); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.data == null) ? null : _co.data.message); _ck(_v, 6, 0, currVal_1); }); }
export function View_AsrErrorModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "asr-error-modal", [], null, null, null, View_AsrErrorModalComponent_0, RenderType_AsrErrorModalComponent)), i0.ɵdid(1, 49152, null, 0, i2.AsrErrorModalComponent, [i3.Store, i4.GenericActions, i5.NgbActiveModal], null, null)], null, null); }
var AsrErrorModalComponentNgFactory = i0.ɵccf("asr-error-modal", i2.AsrErrorModalComponent, View_AsrErrorModalComponent_Host_0, { data: "data" }, {}, []);
export { AsrErrorModalComponentNgFactory as AsrErrorModalComponentNgFactory };
