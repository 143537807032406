import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpParamsOptions } from '@angular/common/http/src/params';

@Injectable()
export class SearchRequests {

    httpOptions: {};
    constructor( private http: HttpClient ) {
        this.httpOptions = {
            headers: new HttpHeaders( {
                'Content-Type': 'application/json'
            } ),
            withCredentials: true
        };
    }

    requestSearch$( req ) {
        return this.http.post( environment.baseURL + environment.requests.search.path, req ,this.httpOptions);
    }

    requestAutocomplete$( path, term, staticParams ) {
        let paramString = '';
        if ( typeof staticParams === 'object' ) {
            for ( const paramKey in staticParams ) {
                if ( staticParams.hasOwnProperty( paramKey ) ) {
                    paramString = paramString + '&';
                    const paramKeyword = staticParams[paramKey];
                    paramString = paramString + paramKey + '=' + paramKeyword;
                }
            }
        }
        const url = path + '?'
            + 'lookupKey' + '=' + term + paramString;
        return this.http.get( url , this.httpOptions);
    }



}
