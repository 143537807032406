/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../node_modules/ngx-loading/ngx-loading.ngfactory";
import * as i3 from "ngx-loading";
import * as i4 from "@angular/router";
import * as i5 from "../../components/asr-section/asr-section.component.ngfactory";
import * as i6 from "../../components/asr-section/asr-section.component";
import * as i7 from "./asr-insert.component";
import * as i8 from "../../logic/generic/generic.actions";
import * as i9 from "../../logic/generic/generic.selectors";
import * as i10 from "@ngrx/store";
import * as i11 from "../../logic/app-flow/app-flow.selectors";
import * as i12 from "../../logic/app-flow/app-flow.actions";
import * as i13 from "../../logic/user/user.actions";
import * as i14 from "../../logic/user/user.service";
var styles_AsrInsertComponent = [];
var RenderType_AsrInsertComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AsrInsertComponent, data: {} });
export { RenderType_AsrInsertComponent as RenderType_AsrInsertComponent };
function View_AsrInsertComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "step flex-fill bd-highlight"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeSection(_v.context.$implicit.key) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "active": 0, "done": 1, "error": 2 }), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "number"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [["class", "label mt-2 mb-0"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "step flex-fill bd-highlight"; var currVal_1 = _ck(_v, 2, 0, (_co.current_step === _v.context.$implicit.key), (((_v.context.$implicit == null) ? null : ((_v.context.$implicit.value == null) ? null : ((_v.context.$implicit.value.control == null) ? null : _v.context.$implicit.value.control.valid))) && (_v.context.$implicit.value.control.touched || _v.context.$implicit.value.control.dirty)), (((_v.context.$implicit == null) ? null : ((_v.context.$implicit.value == null) ? null : ((_v.context.$implicit.value.control == null) ? null : _v.context.$implicit.value.control.invalid))) && (_v.context.$implicit.value.control.touched || _v.context.$implicit.value.control.dirty))); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = (_v.context.index + 1); _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.value.label; _ck(_v, 6, 0, currVal_3); }); }
function View_AsrInsertComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_AsrInsertComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "row mt-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "ngx-loading", [], null, null, null, i2.View_NgxLoadingComponent_0, i2.RenderType_NgxLoadingComponent)), i0.ɵdid(2, 114688, null, 0, i3.NgxLoadingComponent, [i3.NgxLoadingService, i0.ChangeDetectorRef], { show: [0, "show"], config: [1, "config"], template: [2, "template"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpod(4, { backdropBorderRadius: 0, fullScreenBackdrop: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.isLoading$)) === true); var currVal_1 = _ck(_v, 4, 0, "3px", true); var currVal_2 = _co.customLoadingTemplate; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_AsrInsertComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [["class", "border-0"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "active": 0, "done": 1, "error": 2 }), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "text pt-2 pb-2 pl-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = "border-0"; var currVal_1 = _ck(_v, 2, 0, _v.parent.context.$implicit.value.inViewport, ((!_v.parent.context.$implicit.value.inViewport && _v.parent.context.$implicit.value.control.valid) && (_v.parent.context.$implicit.value.control.touched || _v.parent.context.$implicit.value.control.dirty)), ((!_v.parent.context.$implicit.value.inViewport && _v.parent.context.$implicit.value.control.invalid) && (_v.parent.context.$implicit.value.control.touched || _v.parent.context.$implicit.value.control.dirty))); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.value.label; _ck(_v, 4, 0, currVal_2); }); }
function View_AsrInsertComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "a", [["class", "text-dark"], ["routerLink", "./"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i1.LocationStrategy], { queryParams: [0, "queryParams"], fragment: [1, "fragment"], routerLink: [2, "routerLink"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AsrInsertComponent_6)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getQueryParams(); var currVal_3 = _v.context.$implicit.value.id; var currVal_4 = "./"; _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_v.context.$implicit.value.visible)); _ck(_v, 4, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_AsrInsertComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["tabindex", "0"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPreviousSection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-angle-left"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Indietro"]))], null, null); }
function View_AsrInsertComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["tabindex", "0"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToNextSection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Avanti "])), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-angle-right "]], null, null, null, null, null))], null, null); }
function View_AsrInsertComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 43, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 27, "aside", [["class", "col-12 col-sm-12 col-lg-3 mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 26, "div", [["class", "sticky-sidebar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 15, "div", [["class", "card bg-light shadow mb-3"], ["id", "info-stato"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 14, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "p", [["class", "mt-0 mb-0"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Stato "])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["-"])), (_l()(), i0.ɵeld(9, 0, null, null, 3, "p", [["class", "mt-0 mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Ultima modifica "])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["-"])), (_l()(), i0.ɵeld(13, 0, null, null, 2, "button", [["class", "btn btn-primary btn-sm mr-2 mb-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitData(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "i", [["class", "fas fa-save"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Salva bozza"])), (_l()(), i0.ɵeld(16, 0, null, null, 2, "button", [["class", "btn btn-primary btn-sm"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitData(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 0, "i", [["class", "fas fa-check-circle"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Inserisci Struttura"])), (_l()(), i0.ɵeld(19, 0, null, null, 9, "div", [["id", "form-modules"]], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 8, "div", [["class", "card bg-light shadow mb-3 active"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 2, "div", [["class", "card-header border-0 pb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 1, "h5", [["class", "mb-0 h4"]], null, null, null, null, null)), (_l()(), i0.ɵted(23, null, [" ", " "])), (_l()(), i0.ɵeld(24, 0, null, null, 4, "div", [["class", "card-body pt-0 pb-2 pl-3 pr-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(25, 0, null, null, 3, "ul", [["class", "m-0 p-0 lista_custom lista--form"], ["id", "scrollBar"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AsrInsertComponent_5)), i0.ɵdid(27, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(0, i1.KeyValuePipe, [i0.KeyValueDiffers]), (_l()(), i0.ɵeld(29, 0, null, null, 14, "section", [["class", "col-12 col-sm-12 col-lg-9"], ["id", "form"]], null, null, null, null, null)), (_l()(), i0.ɵeld(30, 0, null, null, 13, "div", [["class", "button_area col-12 mb-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(31, 0, null, null, 1, "div", [["asr-section", ""]], [[8, "className", 0]], [[null, "modelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_co.model[_co.current_step] = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_AsrSectionComponent_0, i5.RenderType_AsrSectionComponent)), i0.ɵdid(32, 49152, null, 0, i6.AsrSectionComponent, [], { model: [0, "model"], parentController: [1, "parentController"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AsrInsertComponent_7)), i0.ɵdid(34, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(35, 0, null, null, 8, "span", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(36, 0, null, null, 2, "button", [["class", "btn btn-primary mr-2"], ["tabindex", "0"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitData(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(37, 0, null, null, 0, "i", [["class", "fas fa-save"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Salva bozza"])), (_l()(), i0.ɵeld(39, 0, null, null, 2, "button", [["class", "btn btn-primary mr-2"], ["tabindex", "0"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitData(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(40, 0, null, null, 0, "i", [["class", "fas fa-check-circle"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Invia"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AsrInsertComponent_8)), i0.ɵdid(43, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 27, 0, i0.ɵnov(_v, 28).transform(((_co.model[_co.current_step] == null) ? null : _co.model[_co.current_step].children), _co.orderComparator)); _ck(_v, 27, 0, currVal_2); var currVal_4 = _co.model[_co.current_step]; var currVal_5 = _co; _ck(_v, 32, 0, currVal_4, currVal_5); var currVal_6 = (_co.current_step !== "dati_prelim"); _ck(_v, 34, 0, currVal_6); var currVal_8 = (_co.current_step !== "servizi"); _ck(_v, 43, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formGroup.invalid; _ck(_v, 16, 0, currVal_0); var currVal_1 = ((_co.model[_co.current_step] == null) ? null : _co.model[_co.current_step].label); _ck(_v, 23, 0, currVal_1); var currVal_3 = i0.ɵinlineInterpolate(1, "", (((_co.model[_co.current_step] == null) ? null : _co.model[_co.current_step].attributes) ? (_co.model[_co.current_step].attributes.class ? _co.model[_co.current_step].attributes.class : "") : ""), ""); _ck(_v, 31, 0, currVal_3); var currVal_7 = _co.formGroup.invalid; _ck(_v, 39, 0, currVal_7); }); }
export function View_AsrInsertComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "main", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "section", [["class", "d-flex mt-4 mb-4 text-center"], ["id", "step"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AsrInsertComponent_1)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(0, i1.KeyValuePipe, [i0.KeyValueDiffers]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AsrInsertComponent_2)), i0.ɵdid(6, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AsrInsertComponent_3)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["steps", 2]], null, 0, null, View_AsrInsertComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.model, _co.orderComparator)); _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 10); _ck(_v, 6, 0, currVal_1); var currVal_2 = (i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform(_co.isLoading$)) === true); _ck(_v, 8, 0, currVal_2); }, null); }
export function View_AsrInsertComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "asr-insert", [], null, null, null, View_AsrInsertComponent_0, RenderType_AsrInsertComponent)), i0.ɵdid(1, 245760, null, 0, i7.AsrInsertComponent, [i0.Injector, i8.GenericActions, i9.GenericSelectors, i10.Store, i11.AppFlowSelectors, i12.AppFlowActions, i4.Router, i4.ActivatedRoute, i13.UserActions, i14.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AsrInsertComponentNgFactory = i0.ɵccf("asr-insert", i7.AsrInsertComponent, View_AsrInsertComponent_Host_0, {}, {}, []);
export { AsrInsertComponentNgFactory as AsrInsertComponentNgFactory };
