var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, Injector } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGeneratorController, LEAF_TYPES, CONTAINER_TYPES } from 'src/app/form-generator';
import { FormGroup } from '@angular/forms';
import { UserActions } from 'src/app/logic/user';
import { Store } from '@ngrx/store';
import { GenericActions } from 'src/app/logic/generic';
import { getEditImpresaModel } from 'src/app/form-model';
import { UserService } from 'src/app/logic/user/user.service';
var AsrEditModalComponent = /** @class */ (function (_super) {
    __extends(AsrEditModalComponent, _super);
    function AsrEditModalComponent(injector, userActions, genericActions, store, activeModal, userService) {
        var _this = _super.call(this, injector) || this;
        _this.userActions = userActions;
        _this.genericActions = genericActions;
        _this.store = store;
        _this.activeModal = activeModal;
        _this.userService = userService;
        _this.current_step = 'dati_impresa';
        _this.current_item = null;
        _this.queueSubsectionInView = [];
        _this.subsectioNoMoreInView = false;
        _this.inViewportOptions = {
            rootMargin: '0px',
            threshold: 1.0
        };
        return _this;
    }
    AsrEditModalComponent.prototype.ngOnInit = function () {
        this.dataEntryMap = {};
        this.events = {};
        this.model = getEditImpresaModel(this.disableValidation);
        this.formGroup = new FormGroup({});
        this.initializeModel(this.model, this.formGroup);
        for (var fieldId in this.events) {
            if (this.events.hasOwnProperty(fieldId)) {
                this.onModelChange(this.containerMap[fieldId]);
            }
        }
        if (!!this.dataEntry && !!this.dataEntry.dataEntry) {
            this.current_item = this.dataEntry.dataEntry;
            this.resetModel();
            this.patchModel(this.current_item, this.dataEntryMap);
            this.markModelAsTouched(this.model);
        }
    };
    AsrEditModalComponent.prototype.close = function () {
        this.store.dispatch(this.genericActions.hideEditModal({ ref: this.activeModal, result: null, field: this.field }));
    };
    AsrEditModalComponent.prototype.getQueryParams = function () {
        if (this.current_item) {
            return {
                id: this.current_item && this.current_item.idImpresa,
                step: this.current_step
            };
        }
    };
    AsrEditModalComponent.prototype.markSectionAsTouched = function (element) {
        if (element.visible.getValue() === false) {
            return;
        }
        if (LEAF_TYPES.includes(element.type)) {
            var c = element.control;
            c.markAsTouched({ onlySelf: true });
        }
        else if (CONTAINER_TYPES.includes(element.type)) {
            var c = element.control;
            c.markAsTouched({ onlySelf: true });
            for (var child in element.children) {
                if (element.children.hasOwnProperty(child)) {
                    this.markSectionAsTouched(element.children[child]);
                }
            }
        }
    };
    AsrEditModalComponent.prototype.markModelAsTouched = function (model) {
        for (var key in model) {
            if (model.hasOwnProperty(key)) {
                var element = model[key];
                this.markSectionAsTouched(element);
            }
        }
    };
    AsrEditModalComponent.prototype.ngOnDestroy = function () {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    };
    AsrEditModalComponent.prototype.submitData = function () {
        var dataEntry = {};
        this.recoverDataEntry(this.model, dataEntry);
        dataEntry['idImpresa'] = this.current_item.idImpresa;
        dataEntry['idUtente'] = this.userService.getUserId();
        this.store.dispatch(this.userActions.editImpresaFromModal({ ref: this.activeModal, dataEntry: dataEntry, field: this.field }));
    };
    AsrEditModalComponent.prototype.onInViewportChange = function ($event, element) {
        var currentSectionWeight = 0;
        var elementSectionWeight = 0;
        if (this.currentSectionInView) {
            var elementParentId = element.id.split('-');
            elementParentId.pop();
            elementParentId = elementParentId.join('-');
            if (this.currentSectionInView !== elementParentId) {
                currentSectionWeight = this.containerMap[this.currentSectionInView]['weight'];
                elementSectionWeight = this.containerMap[elementParentId]['weight'];
            }
        }
        if ($event) {
            if (!this.currentSubsectionInView) {
                this.currentSubsectionInView = element;
                element.inViewport = true;
                this.subsectioNoMoreInView = false;
            }
            else {
                if (this.subsectioNoMoreInView) {
                    this.currentSubsectionInView.inViewport = false;
                    this.markSectionAsTouched(this.currentSubsectionInView);
                    this.currentSubsectionInView = undefined;
                    this.currentSubsectionInView = element;
                    this.currentSubsectionInView.inViewport = true;
                    this.subsectioNoMoreInView = false;
                }
                else if (elementSectionWeight < currentSectionWeight) {
                    this.currentSubsectionInView.inViewport = false;
                    this.markSectionAsTouched(this.currentSubsectionInView);
                    this.queueSubsectionInView.unshift(this.currentSubsectionInView);
                    this.currentSubsectionInView = undefined;
                    this.currentSubsectionInView = element;
                    this.currentSubsectionInView.inViewport = true;
                    this.subsectioNoMoreInView = false;
                }
                else if (element.weight < this.currentSubsectionInView.weight && elementSectionWeight === currentSectionWeight) {
                    this.currentSubsectionInView.inViewport = false;
                    this.markSectionAsTouched(this.currentSubsectionInView);
                    this.queueSubsectionInView.unshift(this.currentSubsectionInView);
                    this.currentSubsectionInView = undefined;
                    this.currentSubsectionInView = element;
                    this.currentSubsectionInView.inViewport = true;
                    this.subsectioNoMoreInView = false;
                }
                else {
                    this.queueSubsectionInView.push(element);
                }
            }
        }
        else {
            if (this.currentSubsectionInView && this.currentSubsectionInView === element) {
                if (this.queueSubsectionInView.length > 0) {
                    element.inViewport = false;
                    this.currentSubsectionInView = this.queueSubsectionInView.shift();
                    this.currentSubsectionInView.inViewport = true;
                    this.markSectionAsTouched(element);
                }
                else {
                    this.subsectioNoMoreInView = true;
                }
            }
            else {
                var index = this.queueSubsectionInView.indexOf(element);
                if (index > -1) {
                    this.queueSubsectionInView.splice(index, 1);
                }
            }
        }
        if (this.currentSubsectionInView) {
            var path = this.currentSubsectionInView.id.split('-');
            path.pop();
            path = path.join('-');
            this.currentSectionInView = path;
        }
    };
    AsrEditModalComponent.prototype.resetAffix = function () {
        if (this.currentSubsectionInView) {
            this.currentSubsectionInView.inViewport = false;
            this.currentSubsectionInView = undefined;
        }
        this.queueSubsectionInView.forEach(function (element) {
            element.inViewport = false;
        });
        this.queueSubsectionInView = [];
        this.subsectioNoMoreInView = false;
    };
    return AsrEditModalComponent;
}(FormGeneratorController));
export { AsrEditModalComponent };
