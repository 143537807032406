import { Component, OnInit, Input } from '@angular/core';
import { FormGeneratorInterface } from 'src/app/form-generator/form-generator.interface';
import { FormGeneratorController } from 'src/app/form-generator/form-generator';

@Component({
  selector: '[asr-container], asr-container',
  templateUrl: './asr-container.component.html',
})
export class AsrContainerComponent implements OnInit, FormGeneratorInterface {

  @Input() model;
  @Input() parentController: FormGeneratorController;
  constructor() {
   }

  ngOnInit() {}

}