import { Injectable } from "@angular/core";
import { GenericActions, GenericRequests, GenericSelectors, getGenericStatsResponse } from "./index";
import { Effect, Actions } from "@ngrx/effects";
import { environment } from "src/environments/environment";
import { switchMap, catchError, tap, map, withLatestFrom, filter, debounceTime, delay } from "rxjs/operators";
import { of, merge } from "rxjs";
import { SearchActions } from "../search";
import { Router } from "@angular/router";
import { UserActions } from "../user";
import { SearchImpresaActions } from '../search-impresa';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AsrErrorModalComponent, AsrEditModalComponent } from 'src/app/components/asr-modals';
import { AsrDuplicatesModalComponent } from 'src/app/components/asr-modals/asr-duplicates-modal';
import { AsrDeleteModalComponent } from 'src/app/components/asr-modals/asr-delete-modal';
import { AsrStruttureModalComponent } from 'src/app/components/asr-modals/asr-srutture-modal';
import { AsrRenameModalComponent } from 'src/app/components/asr-modals/asr-rename-modal';
import { AsrPreviewModalComponent } from 'src/app/components/asr-modals/asr-preview-modal/asr-preview-modal.component';

@Injectable()
export class GenericEffects {

    constructor( private actions$: Actions ,
        private genericActions: GenericActions,
        private genericSelectors: GenericSelectors,
        private router: Router,
        private modalService: NgbModal,
        private genericRequests: GenericRequests ) {
        }

    @Effect() doGet$ =
    this.actions$.ofType(GenericActions.GENERIC_GET_INFO).pipe(
        switchMap( () => environment.requests.generic.mock ?
            of(this.genericActions.genericGetInfoSuccess(getGenericStatsResponse()) ) :
            this.genericRequests.requestStats$()
            .pipe(
              switchMap(response =>
                of(this.genericActions.genericGetInfoSuccess(response))
              )
            ),
        ),
        catchError( (err) => of(this.genericActions.genericGetInfoFail(err)))
    );

    @Effect() startSpinner$ = merge(
        this.actions$.ofType(SearchActions.SEARCH_START),
        this.actions$.ofType(SearchActions.SEARCH_SET_FILTER),
        this.actions$.ofType(SearchActions.SEARCH_SORT),
        this.actions$.ofType(GenericActions.GENERIC_GET_INFO),
        this.actions$.ofType(SearchActions.SEARCH_CLEAR_FILTERS),
        this.actions$.ofType(SearchActions.SEARCH_FULL_TEXT),
        this.actions$.ofType(SearchActions.SEARCH_CHANGE_RESULTS_PAGE),
        this.actions$.ofType(SearchActions.SEARCH_CHANGE_RESULTS_PAGE_BY),
        this.actions$.ofType(SearchImpresaActions.SEARCH_START),
        this.actions$.ofType(SearchImpresaActions.SEARCH_SET_FILTER),
        this.actions$.ofType(SearchImpresaActions.SEARCH_CLEAR_FILTERS),
        this.actions$.ofType(SearchImpresaActions.SEARCH_FULL_TEXT),
        this.actions$.ofType(SearchImpresaActions.SEARCH_CHANGE_RESULTS_PAGE),
        this.actions$.ofType(SearchImpresaActions.SEARCH_CHANGE_RESULTS_PAGE_BY),
        this.actions$.ofType(UserActions.USER_LOGIN),
        this.actions$.ofType(UserActions.USER_LOGOUT),
        this.actions$.ofType(UserActions.USER_CHECK_LOGIN),
        this.actions$.ofType(UserActions.USER_UPLOAD_FILE),
        this.actions$.ofType(UserActions.USER_DOWNLOAD_FILE),
        this.actions$.ofType(UserActions.USER_INSERT_STRUTTURA),
        this.actions$.ofType(UserActions.USER_EDIT_IMPRESA),
        this.actions$.ofType(UserActions.EDIT_IMPRESA_FROM_MODAL),
        this.actions$.ofType(UserActions.USER_EDIT_STRUTTURA),
        this.actions$.ofType(UserActions.USER_DELETE_STRUTTURA),
        this.actions$.ofType(UserActions.USER_GET_BOZZE),
    ).pipe(
        // map(action => action['type']),
        // tap(type=> console.log(type)),
        withLatestFrom(this.genericSelectors.isLoading$()),
        map(() => this.genericActions.startLoading('') )
    );

    @Effect() stopSpinner$ = merge(
        this.actions$.ofType(SearchActions.SEARCH_SUCCESS),
        this.actions$.ofType(SearchActions.SEARCH_FAIL),
        this.actions$.ofType(SearchActions.SEARCH_FULL_TEXT_FAIL),
        this.actions$.ofType(SearchActions.SEARCH_FULL_TEXT_SUCCESS),
        this.actions$.ofType(SearchImpresaActions.SEARCH_SUCCESS),
        this.actions$.ofType(SearchImpresaActions.SEARCH_FAIL),
        this.actions$.ofType(SearchImpresaActions.SEARCH_FULL_TEXT_FAIL),
        this.actions$.ofType(SearchImpresaActions.SEARCH_FULL_TEXT_SUCCESS),
        this.actions$.ofType(GenericActions.GENERIC_GET_INFO_SUCCESS),
        this.actions$.ofType(GenericActions.GENERIC_GET_INFO_FAIL),
        this.actions$.ofType(UserActions.USER_LOGIN_FAIL),
        this.actions$.ofType(UserActions.USER_LOGIN_SUCCESS),
        this.actions$.ofType(UserActions.USER_LOGOUT_SUCCESS),
        this.actions$.ofType(UserActions.USER_LOGOUT_FAIL),
        this.actions$.ofType(UserActions.USER_CHECK_LOGIN_SUCCESS),
        this.actions$.ofType(UserActions.USER_CHECK_LOGIN_FAIL),
        this.actions$.ofType(UserActions.USER_UPLOAD_FILE_SUCCESS),
        this.actions$.ofType(UserActions.USER_UPLOAD_FILE_FAIL),
        this.actions$.ofType(UserActions.USER_DOWNLOAD_FILE_FAIL),
        this.actions$.ofType(UserActions.USER_DOWNLOAD_FILE_SUCCESS),
        this.actions$.ofType(UserActions.USER_INSERT_STRUTTURA_FAIL),
        this.actions$.ofType(UserActions.USER_INSERT_STRUTTURA_SUCCESS),
        this.actions$.ofType(UserActions.USER_EDIT_IMPRESA_SUCCESS),
        this.actions$.ofType(UserActions.USER_EDIT_IMPRESA_FAIL),
        this.actions$.ofType(UserActions.EDIT_IMPRESA_FROM_MODAL_SUCCESS),
        this.actions$.ofType(UserActions.EDIT_IMPRESA_FROM_MODAL_FAIL),
        this.actions$.ofType(UserActions.USER_EDIT_STRUTTURA_SUCCESS),
        this.actions$.ofType(UserActions.USER_EDIT_STRUTTURA_FAIL),
        this.actions$.ofType(UserActions.USER_DELETE_STRUTTURA_SUCCESS),
        this.actions$.ofType(UserActions.USER_DELETE_STRUTTURA_FAIL),
        this.actions$.ofType(UserActions.USER_GET_BOZZE_FAIL),
        this.actions$.ofType(UserActions.USER_GET_BOZZE_SUCCESS),
    ).pipe(
        // map(action => action['type']),
        // tap(type=> console.log(type)),
        delay(200),
        withLatestFrom(this.genericSelectors.isLoading$()),
        map(() => this.genericActions.stopLoading() )
    );

    @Effect({dispatch: false}) redirectPath$ = this.actions$.ofType(GenericActions.MODAL_BUTTON_CLICK).pipe(
        map(res => res['payload']),
        tap( (path) => this.router.navigate([path]))
      );

    @Effect({dispatch: false}) redirectExternalUrl$ = this.actions$.ofType(GenericActions.NAVIGATE_EXTERNAL_URL).pipe(
        map(res => res['payload']),
        tap( (path) => window.location.href = path)
    );

    @Effect({dispatch: false}) showErrorModal$ = this.actions$.ofType(GenericActions.ERROR_MODAL_SHOW).pipe(
        map(res => res['payload']),
        tap(data => {
            let modalRef = this.modalService.open(AsrErrorModalComponent, {centered: true, backdrop: 'static'});
            modalRef.componentInstance.data = data;
        })
    );

    @Effect({dispatch: false}) hideErrorModal$ = this.actions$.ofType(GenericActions.ERROR_MODAL_HIDE).pipe(
        map(res => res['payload']),
        tap(data => {
            let modal: NgbActiveModal = data.ref;
            modal.close(data.result);
        })
    );

    @Effect({dispatch: false}) showDuplicatesModal$ = this.actions$.ofType(GenericActions.DUPLICATES_MODAL_SHOW).pipe(
        map(res => res['payload']),
        tap(data => {
            let modalRef = this.modalService.open(AsrDuplicatesModalComponent, { size: 'lg', centered: true, keyboard: false, backdrop: 'static'});
            modalRef.componentInstance.data = data;
        })
    );

    @Effect({dispatch: false}) hideDuplicatesModal$ = this.actions$.ofType(GenericActions.DUPLICATES_MODAL_HIDE).pipe(
        map(res => res['payload']),
        tap(data => {
            let modal: NgbActiveModal = data.ref;
            modal.close(data.result);
        })
    );

    @Effect({dispatch: false}) showDeleteModal$ = this.actions$.ofType(GenericActions.DELETE_MODAL_SHOW).pipe(
        map(res => res['payload']),
        tap(data => {
            let modalRef = this.modalService.open(AsrDeleteModalComponent, {centered: true, keyboard: false, backdrop: 'static'});
            modalRef.componentInstance.data = data;
        })
    );

    @Effect({dispatch: false}) hideDeleteModal$ = this.actions$.ofType(GenericActions.DELETE_MODAL_HIDE).pipe(
        map(res => res['payload']),
        tap(data => {
            let modal: NgbActiveModal = data.ref;
            modal.close(data.result);
        })
    );

    @Effect({dispatch: false}) showStruttureModal$ = this.actions$.ofType(GenericActions.STRUTTURE_MODAL_SHOW).pipe(
        map(res => res['payload']),
        tap(data => {
            let modalRef = this.modalService.open(AsrStruttureModalComponent, { centered: true, keyboard: false, backdrop: 'static'});
            modalRef.componentInstance.data = data;
        })
    );

    @Effect({dispatch: false}) hideStruttureModal$ = this.actions$.ofType(GenericActions.STRUTTURE_MODAL_HIDE).pipe(
        map(res => res['payload']),
        tap(data => {
            let modal: NgbActiveModal = data.ref;
            modal.close(data.result);
        })
    );

    @Effect({dispatch: false}) showRenameModal$ = this.actions$.ofType(GenericActions.RENAME_MODAL_SHOW).pipe(
        map(res => res['payload']),
        tap(data => {
            let modalRef = this.modalService.open(AsrRenameModalComponent, { centered: true, backdrop: 'static'});
            modalRef.componentInstance.data = data;
        })
    );

    @Effect({dispatch: false}) hideRenameModal$ = this.actions$.ofType(GenericActions.RENAME_MODAL_HIDE).pipe(
        map(res => res['payload']),
        tap(data => {
            let modal: NgbActiveModal = data.ref;
            modal.close(data.result);
        })
    );

    @Effect({dispatch: false}) showEditModal$ = this.actions$.ofType(GenericActions.EDIT_MODAL_SHOW).pipe(
        map(res => res['payload']),
        tap(data => {
            let modalRef = this.modalService.open(AsrEditModalComponent, {size: 'lg', keyboard: false, backdrop: 'static', centered: true});
            modalRef.componentInstance.dataEntry = data.data;
            modalRef.componentInstance.field = data.field;
            modalRef.componentInstance.disableValidation = !!data.disableValidation;
        })
    );

    @Effect({dispatch: false}) hideEditModal$ = this.actions$.ofType(GenericActions.EDIT_MODAL_HIDE).pipe(
        map(res => res['payload']),
        tap(data => {
            let modal: NgbActiveModal = data.ref;
            modal.close({result: data.result, field: data.field});
        })
    );

    @Effect() fetchDataPreviewModal$ = this.actions$.ofType(GenericActions.PREVIEW_MODAL_SHOW).pipe(
        map(res => res['payload']),
        switchMap(payload => this.genericRequests.fetchStruttura$(payload , null).pipe(
            switchMap(response => response['status'] === 'OK' ?
                of(this.genericActions.showPreviewModalSuccess(response['payload'])) :
                of(this.genericActions.showErrorModal({
                    title: 'Errore',
                    message: 'Impossibile recuperare i dati della struttura.',
                    buttons: [{ id: 'close', label: 'Chiudi', class: '', action: '', autoclose: true }]
                }))
            )

        )),
        catchError( err => [this.genericActions.showErrorModal({
            title: 'Errore',
            message: 'Impossibile recuperare i dati della struttura.',
            buttons: [{ id: 'close', label: 'Chiudi', class: '', action: '', autoclose: true }]
        })] )
    );


    @Effect({dispatch: false}) showPreviewModal$ = this.actions$.ofType(GenericActions.PREVIEW_MODAL_SHOW_SUCCESS).pipe(
        map(res => res['payload']),
        tap(data => {
            let modalRef = this.modalService.open(AsrPreviewModalComponent, {size: 'lg', keyboard: false, backdrop: 'static', centered: true});
            modalRef.componentInstance.data = data;
        })
    );

    @Effect({dispatch: false}) hidePreviewModal$ = this.actions$.ofType(GenericActions.PREVIEW_MODAL_HIDE).pipe(
        map(res => res['payload']),
        tap(data => {
            let modal: NgbActiveModal = data.ref;
            modal.close({result: data.result, field: data.field});
        })
    );
}
