import { Component } from '@angular/core';

@Component({
  selector: 'asr-header',
  templateUrl: './asr-header.component.html',
})

export class AsrHeaderComponent {
  title = 'asr-header';
}
