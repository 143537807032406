<main class="container">
  <section class="d-flex mt-4 mb-4 text-center" id="step">
    <div class="step flex-fill bd-highlight" (click)="changeSection(section.key)" [ngClass]="{'active': current_step === section.key,
      'done': section?.value?.control?.valid && (section.value.control.touched || section.value.control.dirty ),
      'error': section?.value?.control?.invalid && (section.value.control.touched || section.value.control.dirty )
     }" *ngFor="let section of model | keyvalue:orderComparator; index as i">
      <span class="number">{{i + 1}}</span>
      <p class="label mt-2 mb-0">{{section.value.label}}</p>
    </div>
  </section>
  <ng-container
    *ngTemplateOutlet="current_step === 'ricerca' ? ricerca : current_step === 'riepilogo' ? riepilogo : steps">
  </ng-container>
</main>
<div class="row mt-4" *ngIf="(this.isLoading$ | async) === true">
  <ngx-loading [show]="(this.isLoading$ | async) === true"
    [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop: true }" [template]="customLoadingTemplate">
  </ngx-loading>
</div>

<ng-template #steps>
  <div class="row">
    <h2 class="col-12 mb-3">{{curret_item?.nome}}</h2>
    <aside class="col-12 col-sm-12 col-lg-3 mb-3">
      <div class="sticky-sidebar">
        <div class="card bg-light shadow mb-3" id="info-stato">
          <div class="card-body">

            <p class="mt-0 mb-0">IdRas: <strong>{{current_item?.id}}</strong></p>
            <p class="mt-0 mb-0" *ngIf="current_item?.iun">IUN: <strong><a
                  href="{{ iunUrl + current_item?.iun}}">{{current_item?.iun}}</a></strong></p>
            <p class="mt-0 mb-0">Stato
              <strong>{{current_item?.dataEntry?.bozza === '1' ? 'Bozza' : 'Inserita'}}</strong></p>
            <p class="mt-0 mb-2">Ultima modifica
              <strong>{{(current_item?.dataEntry?.aggiornamento?.ultimoAgg + 'Z' | date: "yyyy-MM-dd HH:mm:ss") || '-' }}</strong></p>
            <button type="button" *ngIf="current_item?.dataEntry?.bozza === '1'"
              class="btn btn-primary btn-sm mr-2 mb-2" (click)="submitData(true)"><i class="fas fa-save"></i>
              Salva bozza</button>
            <button type="button" class="btn btn-primary btn-sm" (click)="submitData(false)"
              [disabled]="formGroup.invalid"><i class="fas fa-check-circle"></i>
              {{ current_item?.dataEntry?.bozza === '1' ?'Inserisci Struttura' : 'Salva modifiche'}}</button>
          </div>
        </div>
        <div id="form-modules">
          <div class="card bg-light shadow mb-3 active">
            <div class="card-header border-0 pb-2">
              <h5 class="mb-0 h4">
                {{this.model[current_step]?.label}}
              </h5>
            </div>
            <div class="card-body pt-0 pb-2 pl-3 pr-2">
              <ul class="m-0 p-0 lista_custom lista--form" id="scrollBar">
                <ng-container *ngFor="let subsection of this.model[current_step]?.children | keyvalue: orderComparator">
                  <a class="text-dark" routerLink="./" [queryParams]="getQueryParams()"
                    [fragment]="subsection.value.id">
                    <li class="border-0" tabindex="0" *ngIf="subsection.value.visible | async" [ngClass]="{
                        'active': subsection.value.inViewport,
                        'done': !subsection.value.inViewport && (subsection.value.control.valid || subsection.value.control.disabled ) && (subsection.value.control.touched || subsection.value.control.dirty ),
                        'error': !subsection.value.inViewport && subsection.value.control.invalid  && (subsection.value.control.touched || subsection.value.control.dirty )
                      }">
                      <div class="text pt-2 pb-2 pl-3">
                        {{subsection.value.label}}
                      </div>
                    </li>
                  </a>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </aside>

    <section class="col-12 col-sm-12 col-lg-9" id="form">
      <div class="button_area col-12 mb-5">
        <div asr-section
          class="{{this.model[current_step]?.attributes ? this.model[current_step].attributes.class ? this.model[current_step].attributes.class  : '' : '' }}"
          [(model)]="this.model[current_step]" [parentController]="this"></div>
        <button type="button" class="btn btn-primary" (click)="goToPreviousSection()"><i class="fas fa-angle-left"></i>
          Indietro</button>
        <span class="float-right">
          <ng-container *ngIf="current_item?.dataEntry?.bozza === '1'">
            <button type="button" (click)="submitData(true)" class="btn btn-primary mr-2"><i class="fas fa-save"></i>
              Salva bozza</button>
            <button type="button" class="btn btn-primary" (click)="submitData(false)" *ngIf="current_step === 'servizi'"
              [disabled]="this.formGroup.invalid"><i class="fas fa-check-circle"></i>
              Invia</button>
          </ng-container>
          <ng-container *ngIf="current_item?.dataEntry?.bozza !== '1'">
            <button type="button" class="btn btn-primary mr-2" (click)="submitData(false)"
              *ngIf="current_step === 'servizi'" [disabled]="this.formGroup.invalid"><i class="fas fa-check-circle"></i>
              Salva modifiche</button>
          </ng-container>
          <button type="button" (click)="goToNextSection()" *ngIf="current_step !== 'servizi' " class="btn btn-primary">
            Avanti <i class="fas fa-angle-right "></i></button>
        </span>
      </div>
    </section>
  </div>
</ng-template>
<ng-template #ricerca>
  <div asr-container
    class="{{this.model[current_step]?.attributes ? this.model[current_step].attributes.class ? this.model[current_step].attributes.class  : '' : '' }}"
    [(model)]="this.model[current_step]" [parentController]="this"></div>
</ng-template>
<ng-template #riepilogo>
  <div class="row">
    <h2 class="col-12 mb-3">{{curret_item?.nome}}</h2>
    <aside class="col-12 col-sm-12 col-lg-3 mb-3">
      <div class="sticky-sidebar">
        <div class="card bg-light shadow mb-3" id="info-stato">
          <div class="card-body">
            <p class="mt-0 mb-0">IdRas: <strong>{{current_item?.id}}</strong></p>
            <p class="mt-0 mb-0" *ngIf="current_item?.iun">IUN: <strong><a
                  href="{{ iunUrl + current_item?.iun}}">{{current_item?.iun}}</a></strong></p>
            <p class="mt-0 mb-0">Stato
              <strong>{{current_item?.dataEntry?.bozza === '1' ? 'Bozza' : 'Inserita'}}</strong></p>
            <p class="mt-0 mb-2">Ultima modifica
              <strong>{{(current_item?.dataEntry?.aggiornamento?.ultimoAgg + 'Z' | date: "yyyy-MM-dd HH:mm:ss") || '-' }}</strong></p>
            <button type="button" *ngIf="current_item?.dataEntry?.bozza === '1'"
              class="btn btn-primary btn-sm mr-2 mb-2" (click)="submitData(true)"><i class="fas fa-save"></i>
              Salva bozza</button>
            <button type="button" class="btn btn-primary btn-sm" (click)="submitData(false)"
              [disabled]="formGroup.invalid"><i class="fas fa-check-circle"></i>
              {{ current_item?.dataEntry?.bozza === '1' ?'Inserisci Struttura' : 'Salva modifiche'}}</button>
          </div>
        </div>
        <ng-container *ngFor="let section of model | keyvalue:orderComparator">
          <ng-container *ngIf="section.key !== 'ricerca' && section.key !=='riepilogo'">
            <div id="form-modules">
              <div class="card bg-light shadow mb-3" [ngClass]="{'active': section.key === currentSectionInView }">
                <div class="card-header border-0 pb-2">
                  <h5 class="mb-0 h4">
                    {{section.value?.label}}
                  </h5>
                </div>
                <div class="card-body pt-0 pb-2 pl-3 pr-2">
                  <ul class="m-0 p-0 lista_custom lista--form" id="scrollBar">
                    <ng-container *ngFor="let subsection of section.value?.children | keyvalue: orderComparator">
                      <a class="text-dark" routerLink="./" [queryParams]="getQueryParams()" [fragment]="subsection.value.id">
                        <li class="border-0"
                          *ngIf="(subsection.value.visible | async) && (!subsection.value.optional || subsection.value.toggleState.value)"
                          [ngClass]="{
                                                  'active': subsection.value.inViewport,
                                                  'done': !subsection.value.inViewport && subsection.value.control.valid && (subsection.value.control.touched || subsection.value.control.dirty ),
                                                  'error': !subsection.value.inViewport && subsection.value.control.invalid  && (subsection.value.control.touched || subsection.value.control.dirty )
                                                }">
                          <div class="text pt-2 pb-2 pl-3">
                            {{subsection.value.label}}
                          </div>
                        </li>
                      </a>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </aside>

    <section class="col-12 col-sm-12 col-lg-9" id="form">
      <div class="button_area col-12 mb-5">
        <ng-container *ngFor="let section of model | keyvalue:orderComparator">
          <ng-container *ngIf="section.key !== 'ricerca' && section.key !=='riepilogo'">
            <div
              class="{{section.value?.attributes ? section.value.attributes.class ? section.value.attributes.class  : '' : '' }}">
              <div
                class="{{section.value?.attributes ? section.value.attributes.class ? section.value.attributes.class  : '' : '' }}">
                <div class="form_section mb-3 p-1 pl-3 pr-3 d-inline">
                  <span>{{ section.value?.label }}</span>
                </div>
                <ng-container *ngFor="let subsection of section.value?.children | keyvalue:orderComparator;">
                  <ng-container *ngIf="(subsection.value.visible | async) === true">
                    <div class='{{subsection.value.attributes.class ?  subsection.value.attributes.class : "" }}'>
                      <ng-container
                        *ngIf="( subsection.value.visible | async ) === true && (!subsection.value.optional || subsection.value.toggleState.value)">
                        <div class="row" tabindex="0">
                          <h3 class="col-12 mt-3 mb-3" snInViewport [inViewportOptions]="inViewportOptions"
                            (inViewportChange)="onInViewportChange($event,subsection.value)" id={{subsection.value.id}}>
                            <span class="underlined">{{subsection.value.label}}</span></h3>
                          <ng-container *ngFor="let forms of subsection.value.children | keyvalue:orderComparator">
                            <ng-container *ngIf="(forms.value.visible | async) === true">
                              <div
                                class="{{forms.value.attributes ? forms.value.attributes.class ? forms.value.attributes.class : 'col-12 col-md-6': 'col-12 col-md-6' }}"
                                asr-summary="" [(model)]="forms.value" [parentController]="this"></div>
                            </ng-container>
                          </ng-container>
                        </div>
                      </ng-container>

                    </div>
                  </ng-container>
                </ng-container>

              </div>
            </div>
          </ng-container>
        </ng-container>

        <button type="button" class="btn btn-primary" (click)="goToPreviousSection()"><i class="fas fa-angle-left"></i>
          Indietro</button>
        <span class="float-right">
          <ng-container *ngIf="current_item?.dataEntry?.bozza === '1'">
            <button type="button" (click)="submitData(true)" class="btn btn-primary mr-2 "><i class="fas fa-save"></i>
              Salva bozza</button>
            <button type="button" class="btn btn-primary" (click)="submitData(false)"
              [disabled]="this.formGroup.invalid"><i class="fas fa-check-circle"></i>
              Invia</button>
          </ng-container>
          <ng-container *ngIf="current_item?.dataEntry?.bozza !== '1'">
            <button type="button" class="btn btn-primary mr-2" (click)="submitData(false)"
              [disabled]="this.formGroup.invalid">
              <i class="fas fa-check-circle"></i>
              Salva modifiche</button>
          </ng-container>
        </span>
      </div>
    </section>
  </div>
</ng-template>