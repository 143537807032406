var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect } from "@ngrx/effects";
import { Router, ActivatedRoute } from "@angular/router";
import { UserRequests, UserActions } from "../user";
import { AppFlowActions } from "./app-flow.actions";
import { map, switchMap, catchError, tap } from "rxjs/operators";
import { of, merge } from "rxjs";
var AppFlowEffects = /** @class */ (function () {
    function AppFlowEffects(actions$, userRequests$, userActions$, flowActions, router, activatedRoute) {
        var _this = this;
        this.actions$ = actions$;
        this.userRequests$ = userRequests$;
        this.userActions$ = userActions$;
        this.flowActions = flowActions;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.loadData$ = this.actions$.ofType(AppFlowActions.LOAD_STRUT_AND_MOVE)
            .pipe(map(function (action) { return action['payload']; }), switchMap(function (payload) { return _this.userRequests$.fetchDataEntryStruttura$(payload.id, payload.type)
            .pipe(switchMap(function (response) { return (response['status'] === 'OK' && typeof response['payload']['dataEntry'] !== 'undefined') ?
            of(_this.flowActions.loadStrutAndMoveSuccess({ current_item: response['payload'], current_step: payload.current_step })) :
            of(_this.flowActions.loadStrutAndMoveFail('Impossibile trovare la struttura selezionata.')); }), catchError(function (err) { return [_this.flowActions.loadStrutAndMoveFail('Attenzione! Non è stato possibile caricare i dati della struttura.')]; })); }), catchError(function (err) { return [_this.flowActions.loadStrutAndMoveFail('Impossibile trovare la struttura selezionata.')]; }));
        this.loadImpresa$ = this.actions$.ofType(AppFlowActions.LOAD_IMPRESA_AND_MOVE)
            .pipe(map(function (action) { return action['payload']; }), switchMap(function (payload) { return _this.userRequests$.fetchImpresa$(payload.id)
            .pipe(switchMap(function (response) { return (response['status'] === 'OK' && typeof response['payload'] !== 'undefined') ?
            of(_this.flowActions.loadImpresaAndMoveSuccess({ current_item: response['payload'], current_step: payload.current_step })) :
            of(_this.flowActions.loadImpresaAndMoveFail('Impossibile trovare la struttura selezionata.')); }), catchError(function (err) { return [_this.flowActions.loadImpresaAndMoveFail('Attenzione! Non è stato possibile caricare i dati della struttura.')]; })); }), catchError(function (err) { return [_this.flowActions.loadImpresaAndMoveFail('Impossibile trovare la struttura selezionata.')]; }));
        this.updateStrutParams$ = this.actions$.ofType(AppFlowActions.LOAD_STRUT_AND_MOVE_SUCCESS).pipe(map(function (action) { return action['payload']; }), tap(function (action) {
            if (action) {
                var type = 'strut';
                if (action.current_item.dataEntry.idAgriturismo) {
                    type = 'agr';
                }
                _this.router.navigate([], {
                    relativeTo: _this.activatedRoute,
                    queryParams: { id: action.current_item.id, type: type, 'step': action.current_step },
                    queryParamsHandling: "merge"
                });
            }
        }));
        this.updateImpresaPrams$ = this.actions$.ofType(AppFlowActions.LOAD_IMPRESA_AND_MOVE_SUCCESS).pipe(map(function (action) { return action['payload']; }), tap(function (action) {
            if (action) {
                _this.router.navigate([], {
                    relativeTo: _this.activatedRoute,
                    queryParams: { id: action.current_item.idImpresa, 'step': action.current_step },
                    queryParamsHandling: "merge"
                });
            }
        }));
        this.clearParams$ = merge(this.actions$.ofType(AppFlowActions.LOAD_STRUT_AND_MOVE_FAIL), this.actions$.ofType(AppFlowActions.LOAD_IMPRESA_AND_MOVE_FAIL), this.actions$.ofType(AppFlowActions.SET_CURRENT_ITEM)).pipe(map(function (action) { return action['payload']; }), tap(function (action) {
            _this.router.navigate([], {
                relativeTo: _this.activatedRoute,
                queryParams: {},
                queryParamsHandling: ''
            });
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AppFlowEffects.prototype, "loadData$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AppFlowEffects.prototype, "loadImpresa$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], AppFlowEffects.prototype, "updateStrutParams$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], AppFlowEffects.prototype, "updateImpresaPrams$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], AppFlowEffects.prototype, "clearParams$", void 0);
    return AppFlowEffects;
}());
export { AppFlowEffects };
