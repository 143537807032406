import { Component, Input, OnInit, OnDestroy, Injector } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { GenericActions } from 'src/app/logic/generic';

@Component({
  selector: 'asr-strutture-modal',
  templateUrl: './asr-strutture-modal.component.html'
})
export class AsrStruttureModalComponent {
  @Input() data;

  constructor(
    private store: Store<any>,
    private genericActions: GenericActions,
    private activeModal: NgbActiveModal) {
  }

  clickedButton(button) {
    if (button.redirect) {
      this.store.dispatch(this.genericActions.modalButtonClick(button.redirect));
    }
    if (typeof button.action !== 'undefined' && button.action.type && button.action.type !== '') {
      this.store.dispatch(button.action);
    }
    if (button.autoclose) {
      this.store.dispatch(this.genericActions.hideStruttureModal({ ref: this.activeModal, result: null }));
    }
  }
}
