import { Component, Input, OnInit, OnDestroy, Injector } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGeneratorController, FormGeneratorInterface, LEAF_TYPES, CONTAINER_TYPES } from 'src/app/form-generator';
import { AbstractControl, FormGroup } from '@angular/forms';
import { UserActions } from 'src/app/logic/user';
import { Store } from '@ngrx/store';
import { GenericActions } from 'src/app/logic/generic';
import { getEditImpresaModel } from 'src/app/form-model';
import { UserService } from 'src/app/logic/user/user.service';

@Component({
    selector: 'asr-edit-modal-content',
    templateUrl: './asr-edit-modal.component.html'
})
export class AsrEditModalComponent extends FormGeneratorController implements FormGeneratorInterface, OnInit, OnDestroy {
    @Input() dataEntry;
    @Input() field;
    @Input() disableValidation;

    constructor(
        injector: Injector,
        private userActions: UserActions,
        private genericActions: GenericActions,
        private store: Store<any>,
        private activeModal: NgbActiveModal,
        private userService: UserService) {
        super(injector);
    }

    isLoading$;
    current_step = 'dati_impresa';
    current_item = null;
    private currentSubsectionInView;
    private currentSectionInView;
    private queueSubsectionInView = [];
    private subsectioNoMoreInView = false;
    inViewportOptions = {
        rootMargin: '0px',
        threshold: 1.0
    };

    ngOnInit() {
        this.dataEntryMap = {};
        this.events = {};
        this.model = getEditImpresaModel(this.disableValidation);
        this.formGroup = new FormGroup({});
        this.initializeModel(this.model, this.formGroup);
        for (const fieldId in this.events) {
            if (this.events.hasOwnProperty(fieldId)) {
                this.onModelChange(this.containerMap[fieldId]);
            }
        }
        if (!!this.dataEntry &&  !!this.dataEntry.dataEntry) {
            this.current_item = this.dataEntry.dataEntry;
            this.resetModel();
            this.patchModel(this.current_item, this.dataEntryMap);
            this.markModelAsTouched(this.model);
        }
    }

    close() {
        this.store.dispatch(this.genericActions.hideEditModal({ref: this.activeModal, result: null, field: this.field}));
    }

    getQueryParams() {
        if (this.current_item) {
            return {
            id: this.current_item  && this.current_item.idImpresa,
            step: this.current_step
            };
        }
    }

    markSectionAsTouched(element) {
        if (element.visible.getValue() === false) {
            return;
        }
        if (LEAF_TYPES.includes(element.type)) {
            let c: AbstractControl = element.control;
            c.markAsTouched({ onlySelf: true });
        } else if (CONTAINER_TYPES.includes(element.type)) {
            let c: AbstractControl = element.control;
            c.markAsTouched({ onlySelf: true });
            for (const child in element.children) {
                if (element.children.hasOwnProperty(child)) {
                    this.markSectionAsTouched(element.children[child]);
                }
            }
        }
    }

    markModelAsTouched(model) {
        for (const key in model) {
            if (model.hasOwnProperty(key)) {
                const element = model[key];
                this.markSectionAsTouched(element);
            }
        }
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    submitData() {
        const dataEntry = {};
        this.recoverDataEntry(this.model, dataEntry);
        dataEntry['idImpresa'] = this.current_item.idImpresa;
    
        dataEntry['idUtente'] = this.userService.getUserId();
        this.store.dispatch(this.userActions.editImpresaFromModal({ref: this.activeModal, dataEntry: dataEntry,  field: this.field}));
    }

    onInViewportChange($event, element) {
        let currentSectionWeight = 0;
        let elementSectionWeight = 0;
        if (this.currentSectionInView) {
            let elementParentId = element.id.split('-');
            elementParentId.pop();
            elementParentId = elementParentId.join('-');
            if (this.currentSectionInView !== elementParentId) {
                currentSectionWeight = this.containerMap[this.currentSectionInView]['weight'];
                elementSectionWeight = this.containerMap[elementParentId]['weight'];
            }
        }
        if ($event) {
            if (!this.currentSubsectionInView) {
                this.currentSubsectionInView = element;
                element.inViewport = true;
                this.subsectioNoMoreInView = false;
            } else {
                if (this.subsectioNoMoreInView) {
                    this.currentSubsectionInView.inViewport = false;
                    this.markSectionAsTouched(this.currentSubsectionInView);
                    this.currentSubsectionInView = undefined;
                    this.currentSubsectionInView = element;
                    this.currentSubsectionInView.inViewport = true;
                    this.subsectioNoMoreInView = false;
                } else if (elementSectionWeight < currentSectionWeight) {
                    this.currentSubsectionInView.inViewport = false;
                    this.markSectionAsTouched(this.currentSubsectionInView);
                    this.queueSubsectionInView.unshift(this.currentSubsectionInView);
                    this.currentSubsectionInView = undefined;
                    this.currentSubsectionInView = element;
                    this.currentSubsectionInView.inViewport = true;
                    this.subsectioNoMoreInView = false;
                } else if (element.weight < this.currentSubsectionInView.weight && elementSectionWeight === currentSectionWeight) {
                    this.currentSubsectionInView.inViewport = false;
                    this.markSectionAsTouched(this.currentSubsectionInView);
                    this.queueSubsectionInView.unshift(this.currentSubsectionInView);
                    this.currentSubsectionInView = undefined;
                    this.currentSubsectionInView = element;
                    this.currentSubsectionInView.inViewport = true;
                    this.subsectioNoMoreInView = false;
                } else {
                    this.queueSubsectionInView.push(element);
                }

            }
        } else {
            if (this.currentSubsectionInView && this.currentSubsectionInView === element) {
                if (this.queueSubsectionInView.length > 0) {
                    element.inViewport = false;
                    this.currentSubsectionInView = this.queueSubsectionInView.shift();
                    this.currentSubsectionInView.inViewport = true;
                    this.markSectionAsTouched(element);
                } else {
                    this.subsectioNoMoreInView = true;
                }
            } else {
                let index = this.queueSubsectionInView.indexOf(element);
                if (index > -1) {
                    this.queueSubsectionInView.splice(index, 1);
                }
            }
        }
        if (this.currentSubsectionInView) {
            let path = this.currentSubsectionInView.id.split('-');
            path.pop();
            path = path.join('-');
            this.currentSectionInView = path;
        }
    }

    private resetAffix() {
        if (this.currentSubsectionInView) {
            this.currentSubsectionInView.inViewport = false;
            this.currentSubsectionInView = undefined;
        }
        this.queueSubsectionInView.forEach(element => {
            element.inViewport = false;
        });
        this.queueSubsectionInView = [];
        this.subsectioNoMoreInView = false;
    }
}
