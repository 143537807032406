import { CONTAINER_TYPES, LEAF_TYPES } from "../form-generator";

export const getInsertModel = function (isSuperOperator) {
   let model = {
      "dati_prelim": getDatiPreliminari(0, false),
      "dati_struttura": getDatiStruttura(1, false),
      "capacita_ricettiva": getCapacitaRicettiva(2, false),
      "servizi": getServizi(3, false)
   };
   return model;
};

export const getEditModel = function (isSuperOperator) {
   let model = {
      "ricerca": getRicerca(0, false),
      "dati_prelim": getDatiPreliminari(1, true),
      "dati_struttura": getDatiStruttura(2, true),
      "capacita_ricettiva": getCapacitaRicettiva(3, true),
      "servizi": getServizi(4, true),
      "riepilogo": getRiepilogo(5, true)
   };
   if (isSuperOperator) {
      let stateExpression = [["{{dati_prelim-stati-stato}}", "C", "=="], "!"];
      model = addRequiredStateToModel(model, stateExpression);
   }
   return model;
}

const getDatiPreliminari = function (weight: number, edit) {
   return {
      "type": "container",
      "label": "Dati preliminari",
      "id": "dati_prelim",
      "weight": weight,
      "visible": true,
      "attributes": {
         "class": "row",
         "html": "asr-section"
      },
      "children": {
         "classificazione": {
            "label": "Classificazione",
            "type": "container",
            "weight": 0,
            "attributes": {
               "class": "card shadow mb-4 col-12",
            },
            "children": {
               "tipologia_struttura": {
                  "label": "Tipologia Struttura",
                  "type": "radio",
                  "required": true,
                  "options": {
                     "Aziende ricettive alberghiere": "Aziende ricettive alberghiere",
                     "Aziende ricettive all'aria aperta": "Aziende ricettive all'aria aperta",
                     "Aziende ricettive extra alberghiere": "Aziende ricettive extra alberghiere",
                     "Bed and breakfast": "Bed and Breakfast",
                     "Boat and Breakfast": "Boat and Breakfast"
                  },
                  "attributes": {
                     "class": "col-12 col-md-4"
                  },
                  "id": "dati_prelim-classificazione-tipologia_struttura"
               },
               "aziende_ricettive_alberghiere": {
                  "label": "Aziende ricettive alberghiere",
                  "type": "radio",
                  "required": true,
                  "options": {
                     "Alberghi": "Alberghi",
                     "Albergo residenziale": "Albergo residenziale"
                  },
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive alberghiere", "=="],
                     "reset": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive alberghiere", "!="]
                  },
                  "attributes": {
                     "class": "col-12 col-md-4",
                  },
                  "id": "dati_prelim-classificazione-aziende_ricettive_alberghiere"
               },
               "alberghi": {
                  "label": "Alberghi",
                  "type": "radio",
                  "required": true,
                  "options": {
                     "Albergo Ordinario": "Albergo Ordinario",
                     "Villaggio albergo": "Villaggio albergo",
                     "Albergo diffuso": "Albergo diffuso",
                     "Motel": "Motel",
                     "Albergo turismo rurale": "Albergo turismo rurale"
                  },
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-aziende_ricettive_alberghiere}}", "Alberghi", "=="],
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive alberghiere", "=="],
                        "&&"
                     ],
                     "reset": [
                        ["{{dati_prelim-classificazione-aziende_ricettive_alberghiere}}", "Alberghi", "!="],
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive alberghiere", "!="],
                        "&&"
                     ],
                  },
                  "attributes": {
                     "class": "col-12 col-md-4"
                  },
                  "id": "dati_prelim-classificazione-alberghi"
               },
               "aria_aperta": {
                  "label": "Aria aperta",
                  "type": "radio",
                  "required": true,
                  "sortOptions": () => 0,
                  "options": {
                     "Villaggi turistici": "Villaggi turistici",
                     "Campeggio": "Campeggio",
                     "Marina Resort": "Marina Resort"
                  },
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                     "reset": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "!="],
                  },
                  "attributes": {
                     "class": "col-12 col-md-4",
                  },
                  "id": "dati_prelim-classificazione-aria_aperta"
               },
               "extra_alberghiere": {
                  "label": "Extra alberghiere",
                  "type": "radio",
                  "required": true,
                  "options": {
                     "Case per ferie": "Case per ferie",
                     "Ostelli per la gioventù": "Ostelli per la gioventù",
                     "Affittacamere": "Affittacamere",
                     "Case e appartamenti per vacanze": "Case e appartamenti per vacanze",
                     "Residence": "Residence"
                  },
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="],
                     "reset": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "!="],
                  },
                  "attributes": {
                     "class": "col-12 col-md-4"
                  },
                  "id": "dati_prelim-classificazione-extra_alberghiere"
               },
               "affittacamere": {
                  "label": "Affittacamere",
                  "type": "radio",
                  "required": true,
                  "options": {
                     "Affittacamere": "Domo (ex Affittacamere)",
                     "Locanda": "Locanda"
                  },
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-extra_alberghiere}}", "Affittacamere", "=="],
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="],
                        "&&"
                     ],
                     "reset": [
                        ["{{dati_prelim-classificazione-extra_alberghiere}}", "Affittacamere", "!="],
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "!="],
                        "&&"
                     ]
                  },
                  "attributes": {
                     "class": "col-12 col-md-4"
                  },
                  "id": "dati_prelim-classificazione-affittacamere"
               },
               "residence": {
                  "label": "Residence",
                  "type": "radio",
                  "required": true,
                  "options": {
                     "Residence Ordinario": "Residence Ordinario",
                     "Residence Villaggio": "Villaggio"
                  },
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-extra_alberghiere}}", "Residence", "=="],
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="],
                        "&&"
                     ],
                     "reset": [
                        ["{{dati_prelim-classificazione-extra_alberghiere}}", "Residence", "!="],
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "!="],
                        "&&"
                     ]
                  },
                  "attributes": {
                     "class": "col-12 col-md-4"
                  },
                  "id": "dati_prelim-classificazione-residence"
               },
               'section_tipologia': {
                  "type": "section",
                  "visible": true,
                  "attributes": {
                     "class": "col-12"
                  },
                  "id": "dati_struttura-dati_impresa_proprietaria-section_tipologia"
               },
               "descrizioneidras": {
                  "label": "Denominazione struttura",
                  "type": "input",
                  "required": true,
                  "id": "dati_prelim-classificazione-descrizioneidras"
               },
               "quinquennio_classificazione": {
                  "label": "Quinquennio classificazione",
                  "type": "select",
                  "required": false,
                  "options": {
                     "1985-1989": "1985-1989",
                     "1990-1994": "1990-1994",
                     "1995-1999": "1995-1999",
                     "2000-2004": "2000-2004",
                     "2005-2009": "2005-2009",
                     "2010-2014": "2010-2014",
                     "2015-2019": "2015-2019"
                  },
                  "id": "dati_prelim-classificazione-quinquennio_classificazione"
               },
               "esercizio_a_carattere": {
                  "label": "Esercizio a carattere",
                  "type": "select",
                  "required": false,
                  "options": {
                     "Permanente": "Permanente",
                     "Stagionale": "Stagionale"
                  },
                  "id": "dati_prelim-classificazione-esercizio_a_carattere"
               },
               "stagionale": {
                  "label": "stagionale",
                  "type": "input",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-esercizio_a_carattere}}", "Stagionale", "=="]
                  },
                  "id": "dati_prelim-classificazione-stagionale"
               }
            },
            "id": "dati_prelim-classificazione"
         },
         "pratica": {
            "label": "Pratica",
            "type": "container",
            "weight": 1,
            "attributes": {
               "class": "card shadow mb-4 col-12",
            },
            "children": {
               "numero_pratica": {
                  "label": "Numero Pratica",
                  "type": "input",
                  "required": false,
                  "id": "dati_prelim-pratica-numero_pratica",
                  "attributes": {
                     "step": "1"
                  },
                  // "validators": {
                  //    "pattern": "[a-zA-Z0-9]+"
                  // }
               },
               "comune_pratica": {
                  "label": "Comune Pratica",
                  "type": "textfield",
                  "required": true,
                  "autocomplete": {
                     'path': '/anagrafeAPI/autocompleteComuni',
                     'staticParams': {
                        'regione': '20'
                     },
                     'display': ['comune'],
                     'startDelay': 300
                  },
                  "id": "dati_prelim-pratica-comune_pratica"
               },
               "mittente_documento": {
                  "label": "Mittente documento",
                  "type": "select",
                  "required": true,
                  "options": {
                     "Altro": "Altro",
                     "Comune": "Comune",
                     "Provincia": "Provincia",
                     "Struttura": "Struttura"
                  },
                  "id": "dati_prelim-pratica-mittente_documento"
               },
               "comunicazione_descrizione": {
                  "label": "Comunicazione descrizione",
                  "type": "select",
                  "required": true,
                  "options": {
                     "Autorizzazione": "Autorizzazione",
                     "Avvio attività": "Avvio attività",
                     "Cessazione": "Cessazione",
                     "Classificazione": "Classificazione",
                     "Classificazione nuova struttura": "Classificazione nuova struttura",
                     "Classificazione Provvisoria": "Classificazione Provvisoria",
                     "Comunicazione Prezzi/Servizi": "Comunicazione Prezzi/Servizi",
                     "Declassificazione": "Declassificazione",
                     "Dichiarazione Inizio Attività": "Dichiarazione Inizio Attività",
                     "Diffida": "Diffida",
                     "DUAP": "DUAP",
                     "Parere della Provincia": "Parere della Provincia",
                     "Revisione": "Revisione",
                     "Revoca": "Revoca",
                     "Richiesta di adesione": "Richiesta di adesione",
                     "Rinnovo": "Rinnovo",
                     "Sanzione": "Sanzione",
                     "Scia": "Scia",
                     "Sospensione": "Sospensione",
                     "Subentro": "Subentro",
                     "Varie": "Varie"
                  },
                  "id": "dati_prelim-pratica-comunicazione_descrizione"
               },
               "tipologia_procedimento": {
                  "label": "Tipologia procedimento",
                  "type": "radio",
                  "required": false,
                  "options": {
                     "immediatoavvio": "immediato avvio [0 giorni]",
                     "immediatoavvio2": "immediato avvio [20 giorni]",
                     "conferenzaservizi": "Conferenza di Servizi"
                  },
                  "id": "dati_prelim-pratica-tipologia_procedimento"
               },
               "data_immediato_avvio": {
                  "label": "data immediato avvio",
                  "type": "date",
                  "required": false,
                  "id": "dati_prelim-pratica-data_immediato_avvio"
               },
               "numero_protocollo_regionale": {
                  "label": "N° Prot. Regionale",
                  "type": "input",
                  "required": false,
                  "id": "dati_prelim-pratica-numero_protocollo_regionale",
                  "validators": {
                     "pattern": "[0-9]+"
                  }
               },
               "data_protocollo_regionale": {
                  "label": "Data Protocollo Regionale",
                  "type": "date",
                  "required": false,
                  "id": "dati_prelim-pratica-data_protocollo_regionale"
               },
               "numero_protocollo_mittente": {
                  "label": "N° Prot. Mittente",
                  "type": "input",
                  "required": false,
                  "validators": {
                     "pattern": "[0-9]+"
                  },
                  "id": "dati_prelim-pratica-numero_protocollo_mittente"
               },
               "data_protocollo_mittente": {
                  "label": "Data Protocollo Mittente",
                  "type": "date",
                  "required": false,
                  "id": "dati_prelim-pratica-data_protocollo_mittente"
               },

            },
            "id": "dati_prelim-pratica"
         },
         "localizzazione": {
            "label": "Localizzazione e Recapiti",
            "type": "container",
            "weight": 2,
            "attributes": {
               "class": "card shadow mb-4 col-12",
            },
            "children": {
               "comune": {
                  'parents': ['indirizzo', 'comune'],
                  "label": "Comune",
                  "type": "textfield",
                  "required": true,
                  "autocomplete": {
                     'path': '/anagrafeAPI/autocompleteComuni',
                     'staticParams': {
                        'regione': '20'
                     },
                     'display': ['comune'],
                     'startDelay': 300
                  },
                  "id": "dati_prelim-localizzazione-comune"
               },
               "provincia": {
                  'parents': ['indirizzo', 'provincia'],
                  "label": "Provincia",
                  "type": "select",
                  "optionsUpdate": '/anagrafeAPI/fetchProvince',
                  "display": 'provincia',
                  "options": {
                  },
                  "key": "sigla",
                  "value": true,
                  "states": {
                     "value": ['{{dati_prelim-localizzazione-comune}}', "provincia", "get"],
                     "disabled": true,
                     "reset": ["{{dati_prelim-localizzazione-comune}}", "isNotDefined"]
                  },
                  "id": "dati_prelim-localizzazione-provincia"
               },
               "indirizzo": {
                  "parents": ['indirizzo', 'indirizzo'],
                  "label": "Indirizzo",
                  "type": "input",
                  "required": true,
                  "id": "dati_prelim-localizzazione-indirizzo"
               },
               "map": {
                  "parents": ["location"],
                  "label": "Localizzazione",
                  "type": "map",
                  "id": "dati_prelim-localizzazione-map",
                  "visible": true,
                  "disabled": false,
                  "geocoding": !edit,
                  "attributes": {
                     "class": "col-12",
                  },
                  "states": {
                     "update": [
                        "{{dati_prelim-localizzazione-comune}}",
                        "{{dati_prelim-localizzazione-indirizzo}}"
                     ]
                  }
               },
               "telefono": {
                  "label": "Telefono",
                  "type": "input",
                  "required": true,
                  "id": "dati_prelim-localizzazione-telefono",
                  "validators": {
                     "pattern": '^[+]*[0-9]{1,4}[ \/0-9]*$'
                  },
               },
               "fax": {
                  "label": "Fax",
                  "type": "input",
                  "required": false,
                  "id": "dati_prelim-localizzazione-fax",
                  "validators": {
                     "pattern": '^[+]*[0-9]{1,4}[ \/0-9]*$'
                  },
               },
               "altri_recapiti_telefonici": {
                  "label": "Altri recapiti telefonici",
                  "type": "input",
                  "required": false,
                  "id": "dati_prelim-localizzazione-altri_recapiti_telefonici",
                  "validators": {
                     "pattern": '^[+]*[0-9]{1,4}[ \/0-9]*$'
                  },
               },
               "email": {
                  "label": "Email",
                  "type": "input",
                  "required": true,
                  "id": "dati_prelim-localizzazione-email",
                  "validators": {
                     "pattern": '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
                  },
               },
               "email2": {
                  "label": "Email2",
                  "type": "input",
                  "required": false,
                  "id": "dati_prelim-localizzazione-email2",
                  "validators": {
                     "pattern": '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
                  },
               },
               "sito_internet": {
                  "label": "Sito Internet",
                  "type": "input",
                  "required": false,
                  "id": "dati_prelim-localizzazione-sito_internet"
               }
            },
            "id": "dati_prelim-localizzazione"
         },
         "stati": {
            "label": "Stati",
            "type": "container",
            "weight": 3,
            "attributes": {
               "class": "card shadow mb-4 col-12",
            },
            "children": {
               "stato": {
                  "label": "Stato",
                  "type": "select",
                  "required": false,
                  "default_value": "A",
                  "options": {
                     "A": "Aperta",
                     "C": "Cessata"
                  },
                  "id": "dati_prelim-stati-stato"
               },
               "istruttoria_ras_completata": {
                  "label": "Istruttoria RAS completata",
                  "type": "select",
                  "required": false,
                  "options": {
                     "1": "Si",
                     "0": "No"
                  },
                  "states": {
                     "disabled": ["{{dati_prelim-stati-stato}}", "C", "=="]
                  },
                  "id": "dati_prelim-stati-istruttoria_ras_completata"
               },
               "sospesa": {
                  "label": "Sospesa",
                  "type": "select",
                  "default_value": "0",
                  "required": false,
                  "options": {
                     "1": "Si",
                     "0": "No"
                  },
                  "states": {
                     "disabled": ["{{dati_prelim-stati-stato}}", "C", "=="]
                  },
                  "id": "dati_prelim-stati-sospesa"
               },
               "online": {
                  "label": "Online",
                  "type": "select",
                  "default_value": "1",
                  "required": false,
                  "options": {
                     "1": "Si",
                     "0": "No"
                  },
                  "states": {
                     "disabled": ["{{dati_prelim-stati-stato}}", "C", "=="],
                     "value": [["{{dati_prelim-stati-stato}}", "isDefined"], [["{{dati_prelim-stati-stato}}", "A", "=="], "1", "0", "??"], "?"]
                  },
                  "id": "dati_prelim-stati-online"
               }
            },
            "id": "dati_prelim-stati"
         },
         "provvedimenti": {
            "label": "Provvedimenti e note",
            "type": "container",
            "weight": 4,
            "collapsable": true,
            "collapsed": true,
            "attributes": {
               "class": "card shadow mb-4 col-12",
            },
            "children": {
               "parere_provincia": {
                  "label": "Provvedimento Provincia",
                  "type": "select",
                  "required": false,
                  "options": {
                     "Si": "Si",
                     "No": "No"
                  },
                  "id": "dati_prelim-provvedimenti-parere_provincia"
               },
               "data_parere_provincia": {
                  "label": "data",
                  "type": "date",
                  "required": false,
                  "id": "dati_prelim-provvedimenti-data_parere_provincia"
               },
               "n_parere_provincia": {
                  "label": "n° provvedimento provincia",
                  "type": "number",
                  "required": false,
                  "id": "dati_prelim-provvedimenti-n_parere_provincia",
                  "validators": {
                     "min": 1
                  }
               },
               "provedimento_comune": {
                  "label": "Provvedimento di classifica del Comune",
                  "type": "select",
                  "required": false,
                  "options": {
                     "Si": "Si",
                     "No": "No"
                  },
                  "id": "dati_prelim-provvedimenti-provedimento_comune"
               },
               "data_provedimento_comune": {
                  "label": "data",
                  "type": "date",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-provvedimenti-provedimento_comune}}", "Si", "=="]
                  },
                  "id": "dati_prelim-provvedimenti-data_provedimento_comune"
               },
               "n_provedimento_comune": {
                  "label": "n° provvedimento comune",
                  "type": "number",
                  "required": false,
                  "id": "dati_prelim-provvedimenti-n_provedimento_comune",
                  "validators": {
                     "min": 1
                  }
               },
               "noteinterne": {
                  "label": "Note Interne",
                  "type": "textarea",
                  "required": false,
                  "id": "dati_prelim-provvedimenti-noteinterne"
               },
               "noteesterne": {
                  "label": "Note Esterne",
                  "type": "textarea",
                  "required": false,
                  "id": "dati_prelim-provvedimenti-noteesterne"
               }
            },
            "id": "dati_prelim-provvedimenti"
         }
      }

   }
}

const getDatiStruttura = function (weight: number, edit) {
   return {
      "type": "container",
      "label": "Dati struttura",
      "visible": true,
      "weight": weight,
      "attributes": {
         "class": "row",
         "html": ""
      },
      "children": {
         "stelle": {
            "label": "Stelle e cronologia",
            "type": "container",
            "weight": 0,
            "attributes": {
               "class": "card shadow mb-4 col-12",
            },
            "children": {
               "tipo_classificazione": {
                  "label": "Tipo Classificazione",
                  "type": "select",
                  "required": true,
                  "default_value": "stelle",
                  "options": {
                     "stelle": "Stelle",
                     "categoria": "Categoria",
                  },
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="],
                        ["{{dati_prelim-classificazione-extra_alberghiere}}",
                           ["Residence", "Case e appartamenti per vacanze", "Affittacamere", "Ostelli per la gioventù", "Case per ferie"],
                           "includes"],
                        "&&"
                     ]
                  },
                  "id": "dati_struttura-stelle-tipo_classificazione"
               },
               "categoria": {
                  "label": "categorie",
                  "type": "select",
                  "required": true,
                  "options": {
                     "1cat": "categoria 1°",
                     "2cat": "categoria 2°",
                     "3cat": "categoria 3°"
                  },
                  "states": {
                     "visible": [
                        ["{{dati_struttura-stelle-tipo_classificazione}}", "categoria", "=="],
                        [["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="],
                        ["{{dati_prelim-classificazione-extra_alberghiere}}",
                           ["Residence", "Case e appartamenti per vacanze", "Affittacamere", "Ostelli per la gioventù", "Case per ferie"],
                           "includes"], "&&"],
                        "&&"
                     ]
                  },
                  "id": "dati_struttura-stelle-categoria"
               },
               "stelle_extra_alberghiere": {
                  "label": "stelle",
                  "type": "select",
                  "required": true,
                  "options": {
                     "1stelle": "1 stella ( * )",
                     "2stelle": "2 stelle ( ** )",
                     "3stelle": "3 stelle ( *** )"
                  },
                  'parents': ['stelle'],
                  "states": {
                     "visible": [
                        ["{{dati_struttura-stelle-tipo_classificazione}}", "stelle", "=="],
                        [["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="],
                        ["{{dati_prelim-classificazione-extra_alberghiere}}",
                           ["Residence", "Case e appartamenti per vacanze", "Affittacamere", "Ostelli per la gioventù", "Case per ferie"],
                           "includes"], "&&"],
                        "&&"
                     ]
                  },
                  "id": "dati_struttura-stelle-stelle_extra_alberghiere"
               },
               "stelle_aria_aperta": {
                  'parents': ['stelle'],
                  "label": "stelle",
                  "type": "select",
                  "required": true,
                  "options": {
                     "1stelle": "1 stella ( * )",
                     "2stelle": "2 stelle ( ** )",
                     "3stelle": "3 stelle ( *** )",
                     "4stelle": "4 stelle ( **** )"
                  },
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="]
                  },
                  "id": "dati_struttura-stelle-stelle_aria_aperta"
               },
               "stelle": {
                  "label": "stelle",
                  "type": "select",
                  "required": true,
                  "sortOptions": () => 0,
                  "options": {
                     "1stelle": "1 stella ( * )",
                     "2stelle": "2 stelle ( ** )",
                     "3stelle": "3 stelle ( *** )",
                     "4stelle": "4 stelle ( **** )",
                     "45stelle": "4 stelle superior ( **** S)",
                     "5stelle": "5 stelle ( ***** )",
                     "6stelle": "5 stelle lusso ( ***** L )"
                  },
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive alberghiere", "=="]
                  },
                  "id": "dati_struttura-stelle-stelle"
               },
               "stelle_bb": {
                  'parents': ['stelle'],
                  "label": "stelle",
                  "type": "select",
                  "required": true,
                  "options": {
                     "1stelle": "1 stella ( * )",
                     "2stelle": "2 stelle ( ** )",
                     "3stelle": "3 stelle ( *** )",
                     "non_classificato": 'Non classificato'
                  },
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "=="],
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Boat and Breakfast", "=="],
                        "||"
                     ]
                  },
                  "id": "dati_struttura-stelle-stelle_bb"
               },
               "anno_di_costruzione": {
                  "label": "Anno di costruzione",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": [
                        "{{dati_prelim-classificazione-tipologia_struttura}}",
                        ["Aziende ricettive alberghiere",
                           "Aziende ricettive all'aria aperta",
                           "Aziende ricettive extra alberghiere"
                        ],
                        "includes"
                     ]
                  },
                  "validators": {
                     "min": 1
                  },
                  "id": "dati_struttura-stelle-anno_di_costruzione"
               },
               "data_apertura_struttura": {
                  "label": "Anno Apertura struttura",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": [
                        "{{dati_prelim-classificazione-tipologia_struttura}}",
                        ["Aziende ricettive alberghiere",
                           "Aziende ricettive all'aria aperta",
                           "Aziende ricettive extra alberghiere"
                        ],
                        "includes"
                     ]
                  },
                  "validators": {
                     "min": 1
                  },
                  "id": "dati_struttura-stelle-data_apertura_struttura"
               },
               "anno_di_ristrutturazione1": {
                  "label": "Anno di  ultima ristrutturazione",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": [
                        "{{dati_prelim-classificazione-tipologia_struttura}}",
                        ["Aziende ricettive alberghiere",
                           "Aziende ricettive all'aria aperta",
                           "Aziende ricettive extra alberghiere"
                        ],
                        "includes"
                     ]
                  },
                  "validators": {
                     "min": 1
                  },
                  "id": "dati_struttura-stelle-anno_di_ristrutturazione1"
               },
               "data_chiusura_struttura": {
                  "label": "Anno Chiusura struttura",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": [
                        "{{dati_prelim-classificazione-tipologia_struttura}}",
                        ["Aziende ricettive alberghiere",
                           "Aziende ricettive all'aria aperta",
                           "Aziende ricettive extra alberghiere"
                        ],
                        "includes"
                     ]
                  },
                  "validators": {
                     "min": 1
                  },
                  "id": "dati_struttura-stelle-data_chiusura_struttura"
               }
            },
            "id": "dati_struttura-stelle"
         },
         "unita_da_diporto": {
            "label": "Dati unità da diporto",
            "type": "container",
            "weight": 1,
            "attributes": {
               "class": "card shadow mb-4 col-12"
            },
            "children": {
               "denominazione": {
                  "id": "dati_struttura-unita_da_diporto-denominazione",
                  "label": "Denominazione unità",
                  "parents": ['unita_da_diporto', 'denominazione'],
                  "type": "input",
                  "required": true,
               },
               "rid": {
                  "id": "dati_struttura-unita_da_diporto-rid",
                  "label": "Num. RID",
                  "parents": ['unita_da_diporto', 'rid'],
                  "type": "input",
                  "required": true,
               },
               "lunghezza": {
                  "id": "dati_struttura-unita_da_diporto-lunghezza",
                  "label": "Lunghezza scafo f.t.",
                  "parents": ['unita_da_diporto', 'lunghezza'],
                  "type": "number",
                  "validators": {
                     "min": 0
                  },
                  "required": true,
               },
               "propulsione": {
                  "id": "dati_struttura-unita_da_diporto-propulsione",
                  "label": "Propulsione",
                  "parents": ['unita_da_diporto', 'propulsione'],
                  "type": "select",
                  "required": true,
                  "options": {
                     "remi": "Remi",
                     "vela": "Vela",
                     "motore": "Motore"
                  }
               },
               "produttore": {
                  "id": "dati_struttura-unita_da_diporto-produttore",
                  "label": "Produttore",
                  "parents": ['unita_da_diporto', 'produttore'],
                  "type": "input",
                  "required": true
               },
               "modello": {
                  "id": "dati_struttura-unita_da_diporto-modello",
                  "label": "Modello",
                  "parents": ['unita_da_diporto', 'modello'],
                  "type": "input",
                  "required": true
               },
               "ormeggio": {
                  "id": "dati_struttura-unita_da_diporto-ormeggio",
                  "label": "Porto ormeggio",
                  "parents": ['unita_da_diporto', 'ormeggio'],
                  "type": "input",
                  "required": true
               },
               "provincia": {
                  "id": "dati_struttura-unita_da_diporto-provincia",
                  "label": "Provincia",
                  "parents": ['unita_da_diporto', 'provincia'],
                  "type": "select",
                  "optionsUpdate": '/anagrafeAPI/fetchProvinceItalia',
                  "display": 'provincia',
                  "value": true,
                  "options": {
                  },
                  "key": "sigla",
                  "states": {
                     "disabled": true,
                     "reset": ["{{dati_struttura-unita_da_diporto-comune}}", "isNotDefined"],
                     "value": ["{{dati_struttura-unita_da_diporto-comune}}", "provincia", "get"]
                  }
               },
               "comune": {
                  "id": "dati_struttura-unita_da_diporto-comune",
                  "label": "Comune",
                  "parents": ['unita_da_diporto', 'comune'],
                  "type": "textfield",
                  "required": true,
                  "autocomplete": {
                     'path': '/anagrafeAPI/autocompleteComuni',
                     'display': ['comune'],
                     'staticParams': {
                        'regione': '20'
                     },
                     'startDelay': 300
                  }
               },
               "cap": {
                  "id": "dati_struttura-unita_da_diporto-cap",
                  "label": "Cap",
                  "parents": ['unita_da_diporto', 'cap'],
                  "type": "input",
                  "required": true,
                  "validators": {
                     "pattern": '[0-9]{5}'
                  }
               }
            },
            "states": {
               "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Boat and Breakfast", "=="],
               "reset": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Boat and Breakfast", "!="]
            },
            "id": "dati_struttura-unita_da_diporto",
         },
         "dati_gestore": {
            "label": "Dati gestore",
            "type": "container",
            "weight": 3,
            "visible": true,
            "attributes": {
               "class": "card shadow mb-4 col-12",
            },
            "children": {
               "id_gestore": {
                  "label": "Id gestore",
                  "parents": ['gestore', 'idImpresa'],
                  "type": "input",
                  "id": "dati_struttura-dati_gestore-id_gestore",
                  "visible": false,
                  "disabled": true,
                  "states": {
                     "visible": false,
                     "value": ["{{dati_struttura-dati_gestore-search_gestore}}", "id", "get"],
                     "reset": ["{{dati_struttura-dati_gestore-search_gestore}}", "isNotDefined"]
                  }
               },
               "tipologia_gestore": {
                  "label": "Tipologia gestore",
                  "type": "radio",
                  "default_value": edit ? "impresa" : undefined,
                  "required": true,
                  "options": {
                     "persona": "Persona",
                     "impresa": "Impresa",
                  },
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "!="],
                     "value": [["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "=="], "persona", "?"]
                  },
                  "id": "dati_struttura-dati_gestore-tipologia_gestore"
               },
               "inserisci_info_gestore": {
                  "label": "Cosa vuoi fare?",
                  "id": "dati_struttura-dati_gestore-inserisci_info_gestore",
                  "required": true,
                  "type": "radio",
                  "options": {
                     "inserisci": "Inserisci",
                     "cerca": "Cerca"
                  },
                  "states": {
                     "visible": ["{{dati_struttura-dati_gestore-tipologia_gestore}}", "impresa", "=="],
                     "disabled": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "=="],
                     "reset": ["{{dati_struttura-dati_gestore-tipologia_gestore}}", " persona", "=="],
                     "value": [["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "=="], "inserisci", "?"]
                  }
               },
               "search_gestore": {
                  "label": "Trova un gestore",
                  "type": "textfield",
                  "id": "dati_struttura-dati_gestore-search_gestore",
                  "required": true,
                  "autocomplete": {
                     'path': '/anagrafeAPI/autocompleteImpresa',
                     'staticParams': {
                        'index': 'impresa'
                     },
                     'display': ['dataEntry.ragioneSociale'],
                     'startDelay': 300
                  },
                  "states": {
                     "visible": [
                        ["{{dati_struttura-dati_gestore-tipologia_gestore}}", "impresa", "=="],
                        ["{{dati_struttura-dati_gestore-inserisci_info_gestore}}", "cerca", "=="],
                        "&&"
                     ],
                     "reset": [
                        ["{{dati_struttura-dati_gestore-tipologia_gestore}}", "persona", "=="],
                        ["{{dati_struttura-dati_gestore-inserisci_info_gestore}}", "inserisci", "=="],
                        "||"
                     ]
                  }
               },
               "edit_gestore": {
                  "label": "Modifica",
                  "type": "button",
                  "target": "dati_struttura-dati_gestore-search_gestore",
                  "required": false,
                  "attributes": {
                     "class": "col-12 col-md-12 text-right",
                  },
                  "states": {
                     "visible": [
                        ["{{dati_struttura-dati_gestore-search_gestore}}", 'isDefined'],
                        ["{{dati_struttura-dati_gestore-inserisci_info_gestore}}", "cerca", "=="],
                        "&&"
                     ]
                  },
                  "id": "dati_struttura-dati_gestore-edit_gestore",
               },
               "section_titolare": {
                  "label": "Titolare",
                  "type": "section",
                  "visible": true,
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "!="],
                        [
                           ["{{dati_struttura-dati_gestore-search_gestore}}", 'isDefined'],
                           [
                              ["{{dati_struttura-dati_gestore-inserisci_info_gestore}}", "inserisci", "=="],
                              ["{{dati_struttura-dati_gestore-tipologia_gestore}}", "persona", "=="],
                              "||"
                           ],
                           "||"
                        ],
                        "&&"
                     ],
                     "disabled": [
                        ["{{dati_struttura-dati_gestore-search_gestore}}", 'isDefined'],
                        ["{{dati_struttura-dati_gestore-inserisci_info_gestore}}", "cerca", "=="],
                        "&&"
                     ],
                     "reset": ["{{dati_struttura-dati_gestore-search_gestore}}", 'isNotDefined']
                  },
                  "attributes": {
                     "class": "col-12"
                  },
                  "children": {
                     "cognome_titolare": {
                        "label": "Cognome titolare",
                        "parents": ['gestore', 'rappresentante', 'cognomeRappr'],
                        "type": "input",
                        "required": true,
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", "isDefined"], ["{{dati_struttura-dati_gestore-search_gestore}}", "dataEntry.rappresentante.cognomeRappr", "get"], "?"]
                        },
                        "validators": {
                           "pattern": '^[A-Za-zÀ-ÖØ-öø-ÿ]+[\']*[A-Za-zÀ-ÖØ-öø-ÿ][A-Za-zÀ-ÖØ-öø-ÿ ]*$'
                        },
                        "id": "dati_struttura-dati_gestore-section_titolare-cognome_titolare"
                     },
                     "nome_titolare": {
                        "label": "Nome titolare",
                        "parents": ['gestore', 'rappresentante', 'nomeRappr'],
                        "type": "input",
                        "required": true,
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", "isDefined"], ["{{dati_struttura-dati_gestore-search_gestore}}", "dataEntry.rappresentante.nomeRappr", "get"], "?"]
                        },
                        "validators": {
                           "pattern": '^[A-Za-zÀ-ÖØ-öø-ÿ]+[\']*[A-Za-zÀ-ÖØ-öø-ÿ][A-Za-zÀ-ÖØ-öø-ÿ ]*$'
                        },
                        "id": "dati_struttura-dati_gestore-section_titolare-nome_titolare"
                     },
                     "data_nascita_titolare": {
                        "label": "Data di nascita",
                        "parents": ['gestore', 'rappresentante', 'dataNascita'],
                        "type": "date",
                        "required": true,
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", "isDefined"], ["{{dati_struttura-dati_gestore-search_gestore}}", "dataEntry.rappresentante.dataNascita", "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_titolare-data_nascita_titolare"
                     },
                     "stato_nascita": {
                        "label": "Stato nascita",
                        "type": "textfield",
                        "default_value": "Italia",
                        "parents": ['gestore', 'rappresentante', 'statoNascita'],
                        "required": true,
                        "visible": true,
                        "autocomplete": {
                           'local': true,
                           'startDelay': 300,
                           "data": getNomiStati(),
                        },
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", "isDefined"], ["{{dati_struttura-dati_gestore-search_gestore}}", "dataEntry.rappresentante.statoNascita", "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_titolare-stato_nascita"
                     },
                     "provincia_nascita": {
                        "label": "Provincia di nascita",
                        "type": "select",
                        "parents": ['gestore', 'rappresentante', 'provinciaNascita'],
                        "required": true,
                        "optionsUpdate": '/anagrafeAPI/fetchProvinceItalia',
                        "display": 'provincia',
                        "value": true,
                        "visible": true,
                        "options": {
                        },
                        "key": "sigla",
                        "states": {
                           "visible": [
                              ["{{dati_struttura-dati_gestore-section_titolare-stato_nascita}}", "Italia", "=="],
                              ["{{dati_struttura-dati_gestore-section_titolare-comune_nascita}}", { "custom": true, "comune": "ALTRO" }, "!="],
                              "&&"],
                           "disabled": true,
                           "reset": ["{{dati_struttura-dati_gestore-section_titolare-comune_nascita}}", "isNotDefined"],
                           "value": ["{{dati_struttura-dati_gestore-section_titolare-comune_nascita}}", "provincia", "get"]
                        },
                        "id": "dati_struttura-dati_gestore-section_titolare-provincia_nascita"
                     },
                     "comune_nascita": {
                        "label": "Comune di nascita",
                        "type": "textfield",
                        "parents": ['gestore', 'rappresentante', 'comuneNascita'],
                        "required": true,
                        "visible": true,
                        "autocomplete": {
                           'path': '/anagrafeAPI/autocompleteComuni',
                           'display': ['comune'],
                           'startDelay': 300,
                        },
                        "fixedOptions": [{
                           "custom": true,
                           "comune": "ALTRO"
                        }],
                        "states": {
                           "visible": ["{{dati_struttura-dati_gestore-section_titolare-stato_nascita}}", "Italia", "=="],
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", "isDefined"], ["{{dati_struttura-dati_gestore-search_gestore}}", "dataEntry.rappresentante.comuneNascita", "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_titolare-comune_nascita"
                     },
                     "comune_estero_nascita": {
                        "label": "Comune estero di nascita",
                        "type": "input",
                        "parents": ['gestore', 'rappresentante', 'comuneEstero'],
                        "required": true,
                        "states": {
                           "visible": [
                              ["{{dati_struttura-dati_gestore-section_titolare-stato_nascita}}", "isDefined"],
                              ["{{dati_struttura-dati_gestore-section_titolare-stato_nascita}}", "Italia", "!="],
                              "&&"
                           ],
                           "reset": ["{{dati_struttura-dati_gestore-section_titolare-stato_nascita}}", "Italia", "=="],
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", "isDefined"], ["{{dati_struttura-dati_gestore-search_gestore}}", "dataEntry.rappresentante.comuneEstero", "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_titolare-comune_estero_nascita"
                     },
                     "altro_comune_nascita": {
                        "label": "Altro comune di nascita",
                        "type": "input",
                        "parents": ['gestore', 'rappresentante', 'altroComune'],
                        "required": true,
                        "states": {
                           "visible": [
                              ["{{dati_struttura-dati_gestore-section_titolare-stato_nascita}}", "Italia", "=="],
                              ["{{dati_struttura-dati_gestore-section_titolare-comune_nascita}}", { "custom": true, "comune": "ALTRO" }, "=="],
                              "&&"],
                           "reset": ["{{dati_struttura-dati_gestore-section_titolare-comune_nascita}}", "isNotDefined"],
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", "isDefined"], ["{{dati_struttura-dati_gestore-search_gestore}}", "dataEntry.rappresentante.altroComune", "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_titolare-altro_comune_nascita"
                     },
                     "codice_fiscale_titolare": {
                        "label": "Codice fiscale",
                        "parents": ['gestore', 'rappresentante', 'codiceFiscale'],
                        "type": "input",
                        "required": true,
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", "isDefined"], ["{{dati_struttura-dati_gestore-search_gestore}}", "dataEntry.rappresentante.codiceFiscale", "get"], "?"]
                        },
                        "validators": {
                           "pattern": '^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$'
                        },
                        "id": "dati_struttura-dati_gestore-section_titolare-codice_fiscale_titolare"
                     },
                     "cittadinanza_titolare": {
                        "label": "Cittadinanza",
                        "parents": ['gestore', 'rappresentante', 'cittadinanza'],
                        "type": "input",
                        "required": true,
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", "isDefined"], ["{{dati_struttura-dati_gestore-search_gestore}}", "dataEntry.rappresentante.cittadinanza", "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_titolare-cittadinanza_titolare"
                     },
                     "documento_sogg_titolare": {
                        "label": "Documento di soggiorno (se cittadino non UE)",
                        "type": "input",
                        "parents": ['gestore', 'rappresentante', 'documentoPermessoSoggiorno'],
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", "isDefined"], ["{{dati_struttura-dati_gestore-search_gestore}}", "dataEntry.rappresentante.documentoPermessoSoggiorno", "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_titolare-documento_sogg_titolare"
                     },
                     "stato_residenza": {
                        "label": "Stato residenza",
                        "type": "textfield",
                        "default_value": "Italia",
                        "parents": ['gestore', 'rappresentante', 'statoResidenza'],
                        "required": true,
                        "visible": true,
                        "autocomplete": {
                           'local': true,
                           'startDelay': 300,
                           "data": getNomiStati(),
                        },
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", "isDefined"], ["{{dati_struttura-dati_gestore-search_gestore}}", "dataEntry.rappresentante.statoResidenza", "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_titolare-stato_residenza"
                     },
                     "provincia_residenza_titolare": {
                        "label": "Provincia di residenza",
                        "type": "select",
                        "parents": ['gestore', 'rappresentante', 'provinciaResidenza'],
                        "required": true,
                        "optionsUpdate": '/anagrafeAPI/fetchProvinceItalia',
                        "display": 'provincia',
                        "value": true,
                        "options": {
                        },
                        "key": "sigla",
                        "states": {
                           "visible": [
                              ["{{dati_struttura-dati_gestore-section_titolare-stato_residenza}}", "Italia", "=="],
                              ["{{dati_struttura-dati_gestore-section_titolare-comune_residenza_titolare}}", { "custom": true, "comune": "ALTRO" }, "!="],
                              "&&"],
                           "disabled": true,
                           "reset": ["{{dati_struttura-dati_gestore-section_titolare-comune_residenza_titolare}}", "isNotDefined"],
                           "value": ["{{dati_struttura-dati_gestore-section_titolare-comune_residenza_titolare}}", ["{{dati_struttura-dati_gestore-section_titolare-comune_residenza_titolare}}", "provincia", "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_titolare-provincia_residenza_titolare"
                     },
                     "comune_residenza_titolare": {
                        "label": "Comune di residenza",
                        "type": "textfield",
                        "parents": ['gestore', 'rappresentante', 'comuneResidenza'],
                        "required": true,
                        "autocomplete": {
                           'path': '/anagrafeAPI/autocompleteComuni',
                           'display': ['comune'],
                           'startDelay': 300
                        },
                        "fixedOptions": [{
                           "custom": true,
                           "comune": "ALTRO"
                        }],
                        "states": {
                           "visible": ["{{dati_struttura-dati_gestore-section_titolare-stato_residenza}}", "Italia", "=="],
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", "isDefined"], ["{{dati_struttura-dati_gestore-search_gestore}}", "dataEntry.rappresentante.comuneResidenza", "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_titolare-comune_residenza_titolare"
                     },
                     "comune_estero_residenza": {
                        "label": "Comune estero di residenza",
                        "type": "input",
                        "parents": ['gestore', 'rappresentante', 'comuneEsteroResidenza'],
                        "required": true,
                        "states": {
                           "visible": [
                              ["{{dati_struttura-dati_gestore-section_titolare-stato_residenza}}", "isDefined"],
                              ["{{dati_struttura-dati_gestore-section_titolare-stato_residenza}}", "Italia", "!="],
                              "&&"
                           ],
                           "reset": ["{{dati_struttura-dati_gestore-section_titolare-stato_residenza}}", "Italia", "=="],
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", "isDefined"], ["{{dati_struttura-dati_gestore-search_gestore}}", "dataEntry.rappresentante.comuneEsteroResidenza", "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_titolare-comune_estero_residenza"
                     },
                     "altro_comune_residenza": {
                        "label": "Altro comune di residenza",
                        "type": "input",
                        "parents": ['gestore', 'rappresentante', 'altroComuneResidenza'],
                        "required": true,
                        "states": {
                           "visible": [
                              ["{{dati_struttura-dati_gestore-section_titolare-stato_residenza}}", "Italia", "=="],
                              ["{{dati_struttura-dati_gestore-section_titolare-comune_residenza_titolare}}", { "custom": true, "comune": "ALTRO" }, "=="],
                              "&&"],
                           "reset": ["{{dati_struttura-dati_gestore-section_titolare-comune_residenza_titolare}}", "isNotDefined"],
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", "isDefined"], ["{{dati_struttura-dati_gestore-search_gestore}}", "dataEntry.rappresentante.altroComuneResidenza", "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_titolare-altro_comune_residenza"
                     },
                     "cap_residenza_titolare": {
                        "label": "CAP residenza titolare",
                        "parents": ['gestore', 'rappresentante', 'capResidenza'],
                        "type": "input",
                        "required": true,
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", "isDefined"], ["{{dati_struttura-dati_gestore-search_gestore}}", "dataEntry.rappresentante.capResidenza", "get"], "?"]
                        },
                        "validators": {
                           "pattern": '[0-9]{5}'
                        },
                        "id": "dati_struttura-dati_gestore-section_titolare-cap_residenza_titolare"
                     },
                     "indirizzo_residenza_titolare": {
                        "label": "Indirizzo di residenza del titolare",
                        "parents": ['gestore', 'rappresentante', 'indirizzoResidenza'],
                        "type": "input",
                        "required": true,
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", "isDefined"], ["{{dati_struttura-dati_gestore-search_gestore}}", "dataEntry.rappresentante.indirizzoResidenza", "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_titolare-indirizzo_residenza_titolare"
                     },
                     "telefono_titolare": {
                        "label": "Telefono",
                        "parents": ['gestore', 'rappresentante', 'telefono'],
                        "type": "input",
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", "isDefined"], ["{{dati_struttura-dati_gestore-search_gestore}}", "dataEntry.rappresentante.telefono", "get"], "?"]
                        },
                        "validators": {
                           "pattern": '^[+]*[0-9]{1,4}[ \/0-9]*$'
                        },
                        "id": "dati_struttura-dati_gestore-section_titolare-telefono_titolare"
                     },
                     "email_titolare": {
                        "label": "Email",
                        "parents": ['gestore', 'rappresentante', 'email'],
                        "type": "input",
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", "isDefined"], ["{{dati_struttura-dati_gestore-search_gestore}}", "dataEntry.rappresentante.email", "get"], "?"]
                        },
                        "validators": {
                           "pattern": '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
                        },
                        "id": "dati_struttura-dati_gestore-section_titolare-email_titolare"
                     }
                  },
                  "id": "dati_struttura-dati_gestore-section_titolare"
               },
               "section_persona": {
                  "label": "",
                  "type": "section",
                  "visible": true,
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "=="],
                        ["{{dati_struttura-dati_gestore-inserisci_info_gestore}}", "inserisci", "=="],
                        "&&"
                     ],
                     "reset": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "!="],
                  },
                  "attributes": {
                     "class": "col-12"
                  },
                  "children": {
                     "cognome": {
                        "label": "Cognome",
                        "parents": ['cognome_bb'],
                        "type": "input",
                        "required": true,
                        "id": "dati_struttura-dati_gestore-section_persona-cognome"
                     },
                     "nome": {
                        "label": "Nome",
                        "parents": ['nome_bb'],
                        "type": "input",
                        "required": true,
                        "id": "dati_struttura-dati_gestore-section_persona-nome"
                     },
                     "data_nascita": {
                        "label": "Data di nascita",
                        "parents": ['data_di_nascita_prop_bb'],
                        "type": "date",
                        "required": true,
                        "id": "dati_struttura-dati_gestore-section_persona-data_nascita"
                     },
                     "stato_nascita": {
                        "label": "Stato nascita",
                        "parents": ["stato_nascita_prop_bb"],
                        "type": "textfield",
                        "default_value": "Italia",
                        "required": true,
                        "visible": true,
                        "autocomplete": {
                           'local': true,
                           'startDelay': 300,
                           "data": getNomiStati(),
                        },
                        "states": {
                           "reset": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "!="]
                        },
                        "id": "dati_struttura-dati_gestore-section_persona-stato_nascita"
                     },
                     "provincia_nascita": {
                        "label": "Provincia di nascita",
                        "type": "select",
                        "parents": ['provincia_nascita_prop_bb'],
                        "required": true,
                        "optionsUpdate": '/anagrafeAPI/fetchProvinceItalia',
                        "display": 'provincia',
                        "value": true,
                        "visible": true,
                        "options": {
                        },
                        "key": "sigla",
                        "states": {
                           "visible": [
                              ["{{dati_struttura-dati_gestore-section_persona-comune_nascita}}", { "custom": true, "comune": "ALTRO" }, "!="],
                              ["{{dati_struttura-dati_gestore-section_persona-stato_nascita}}", "Italia", "=="],
                              "&&"
                           ],
                           "disabled": true,
                           "reset": ["{{dati_struttura-dati_gestore-section_persona-comune_nascita}}", "isNotDefined"],
                           "value": [["{{dati_struttura-dati_gestore-section_persona-comune_nascita}}", "isDefined"], ["{{dati_struttura-dati_gestore-section_persona-comune_nascita}}", "provincia", "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_persona-provincia_nascita"
                     },
                     "comune_nascita": {
                        "label": "Comune di nascita",
                        "type": "textfield",
                        "parents": ['comune_nascita_prop_bb'],
                        "required": true,
                        "visible": true,
                        "autocomplete": {
                           'path': '/anagrafeAPI/autocompleteComuni',
                           'display': ['comune'],
                           'startDelay': 300,
                        },
                        "fixedOptions": [{
                           "custom": true,
                           "comune": "ALTRO"
                        }
                        ],
                        "states": {
                           "visible": ["{{dati_struttura-dati_gestore-section_persona-stato_nascita}}", "Italia", "=="],
                           "reset": ["{{dati_struttura-dati_gestore-section_persona-stato_nascita}}", "Italia", "!="]
                        },
                        "id": "dati_struttura-dati_gestore-section_persona-comune_nascita"
                     },
                     "comune_estero_nascita": {
                        "label": "Comune estero di nascita",
                        "type": "input",
                        "parents": ['altro_comune_nascita_prop_bb'],
                        "required": true,
                        "states": {
                           "visible": [
                              ["{{dati_struttura-dati_gestore-section_persona-stato_nascita}}", "isDefined"],
                              ["{{dati_struttura-dati_gestore-section_persona-stato_nascita}}", "Italia", "!="],
                              "&&"
                           ],
                           "reset": ["{{dati_struttura-dati_gestore-section_persona-stato_nascita}}", "Italia", "=="],
                        },
                        "id": "dati_struttura-dati_gestore-section_persona-comune_estero_nascita"
                     },
                     "altro_comune_nascita": {
                        "label": "Altro comune di nascita",
                        "type": "input",
                        "parents": ['altro_comune_nascita_prop_bb'],
                        "required": true,
                        "states": {
                           "visible": [
                              ["{{dati_struttura-dati_gestore-section_persona-stato_nascita}}", "Italia", "=="],
                              ["{{dati_struttura-dati_gestore-section_persona-comune_nascita", { "custom": true, "comune": "ALTRO" }, "=="],
                              "&&"
                           ],
                           "reset": ["{{dati_struttura-dati_gestore-section_persona-comune_nascita}}", "isNotDefined"]
                        },
                        "id": "dati_struttura-dati_gestore-section_persona-altro_comune_nascita"
                     },
                     "codice_fiscale": {
                        "label": "Codice fiscale",
                        "parents": ['codice_fiscale_bb'],
                        "type": "input",
                        "required": true,
                        "id": "dati_struttura-dati_gestore-section_persona-codice_fiscale"
                     },
                     "cittadinanza": {
                        "label": "Cittadinanza",
                        "parents": ['cittadinanza_prop_bb'],
                        "type": "input",
                        "required": true,
                        "id": "dati_struttura-dati_gestore-section_persona-cittadinanza"
                     },
                     "documento_sogg": {
                        "label": "Documento di soggiorno (se cittadino non UE)",
                        "type": "input",
                        "parents": ['documento_sogg_bb'],
                        "required": false,
                        "id": "dati_struttura-dati_gestore-section_persona-documento_sogg"
                     },
                     "provincia_residenza": {
                        "label": "Provincia di residenza",
                        "type": "select",
                        "parents": ['provincia_residenza_prop_bb'],
                        "required": true,
                        "optionsUpdate": '/anagrafeAPI/fetchProvinceItalia',
                        "display": 'provincia',
                        "value": true,
                        "options": {
                        },
                        "key": "sigla",
                        "states": {
                           "disabled": true,
                           "reset": ["{{dati_struttura-dati_gestore-section_persona-comune_residenza}}", "isNotDefined"],
                           "value": [["{{dati_struttura-dati_gestore-section_persona-comune_residenza}}", "isDefined"], ["{{dati_struttura-dati_gestore-section_persona-comune_residenza}}", "provincia", "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_persona-provincia_residenza"
                     },
                     "comune_residenza": {
                        "label": "Comune di residenza",
                        "type": "textfield",
                        "parents": ['comune_residenza_prop_bb'],
                        "required": true,
                        "autocomplete": {
                           'path': '/anagrafeAPI/autocompleteComuni',
                           'display': ['comune'],
                           'startDelay': 300
                        },
                        "id": "dati_struttura-dati_gestore-section_persona-comune_residenza"
                     },
                     "cap_residenza": {
                        "label": "CAP residenza",
                        "parents": ['cap_residenza_prop_bb'],
                        "type": "input",
                        "required": true,
                        "id": "dati_struttura-dati_gestore-section_persona-cap_residenza"
                     },
                     "indirizzo_residenza": {
                        "label": "Indirizzo di residenza",
                        "parents": ['indirizzo_residenza_bb'],
                        "type": "input",
                        "required": true,
                        "id": "dati_struttura-dati_gestore-section_persona-indirizzo_residenza"
                     },
                     "telefono": {
                        "label": "Telefono",
                        "parents": ['telefono_bb'],
                        "type": "input",
                        "required": false,
                        "id": "dati_struttura-dati_gestore-section_persona-telefono",
                        "validators": {
                           "pattern": '^[+]*[0-9]{1,4}[ \/0-9]*$'
                        },
                     },
                     "email": {
                        "label": "Email",
                        "parents": ['email_bb'],
                        "type": "input",
                        "required": false,
                        "id": "dati_struttura-dati_gestore-section_persona-email"
                     },
                     "pec": {
                        "label": "Pec",
                        "parents": ['pec_bb'],
                        "type": "input",
                        "required": false,
                        "id": "dati_struttura-dati_gestore-section_persona-pec"
                     }

                  },
                  "id": "dati_struttura-dati_gestore-section_persona"
               },
               "section_impresa": {
                  "label": "Impresa",
                  "type": "section",
                  "visible": true,
                  "required": false,
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "!="],
                        [
                           ["{{dati_struttura-dati_gestore-search_gestore}}", 'isDefined'],
                           ["{{dati_struttura-dati_gestore-inserisci_info_gestore}}", "inserisci", "=="],
                           "||"
                        ],
                        "&&"
                     ],
                     "disabled": [
                        ["{{dati_struttura-dati_gestore-search_gestore}}", 'isDefined'],
                        ["{{dati_struttura-dati_gestore-inserisci_info_gestore}}", "cerca", "=="],
                        "&&"
                     ],
                     "reset": ["{{dati_struttura-dati_gestore-search_gestore}}", 'isNotDefined'],
                  },
                  "attributes": {
                     "class": "col-12 mt-3"
                  },
                  "children": {
                     "denominazione_impresa": {
                        "label": "Denominazione impresa",
                        "parents": ['gestore', 'ragioneSociale'],
                        "type": "input",
                        "required": true,
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", 'isDefined'], ["{{dati_struttura-dati_gestore-search_gestore}}", 'dataEntry.ragioneSociale', "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_impresa-denominazione_impresa"
                     },
                     "forma_giuridica": {
                        "label": "Forma giuridica",
                        "parents": ['gestore', 'formaGiuridica', 'sigla'],
                        "type": "select",
                        "required": false,
                        "options": {
                           "A.r.l.": "A.r.l.",
                           "D.I.": "D.I.",
                           "S.a.s.": "S.a.s.",
                           "S.d.f.": "S.d.f.",
                           "S.n.c.": "S.n.c.",
                           "S.p.a.": "S.p.a.",
                           "S.r.l.": "S.r.l.",
                           "Soc. Coop.": "Soc. Coop.",
                           "p.c.a.": "p.c.a."
                        },
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", 'isDefined'], ["{{dati_struttura-dati_gestore-search_gestore}}", 'dataEntry.formaGiuridica.sigla', "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_impresa-forma_giuridica"
                     },
                     "codice_fiscale_impresa": {
                        "label": "Codice fiscale",
                        "parents": ['gestore', 'codiceFiscale'],
                        "type": "input",
                        "required": false,
                        "states": {
                           "required": ["{{dati_struttura-dati_gestore-section_impresa-partita_iva}}", 'isNotDefined'],
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", 'isDefined'], ["{{dati_struttura-dati_gestore-search_gestore}}", 'dataEntry.codiceFiscale', "get"], "?"]

                        },
                        "validators": {
                           "pattern": '^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$'
                        },
                        "id": "dati_struttura-dati_gestore-section_impresa-codice_fiscale_impresa"
                     },
                     "partita_iva": {
                        "label": "Partita Iva",
                        "type": "input",
                        "parents": ['gestore', 'partitaIva'],
                        "required": false,
                        "states": {
                           "required": ["{{dati_struttura-dati_gestore-section_impresa-codice_fiscale_impresa}}", 'isNotDefined'],
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", 'isDefined'], ["{{dati_struttura-dati_gestore-search_gestore}}", 'dataEntry.partitaIva', "get"], "?"]
                        },
                        "validators": {
                           "pattern": '^[0-9]{11}$'
                        },
                        "id": "dati_struttura-dati_gestore-section_impresa-partita_iva"

                     },
                     "provincia_impresa": {
                        "label": "Provincia (Sede legale)",
                        "parents": ['gestore', 'indirizzo', 'provincia'],
                        "type": "select",
                        "required": false,
                        "optionsUpdate": '/anagrafeAPI/fetchProvinceItalia',
                        "display": 'provincia',
                        "value": true,
                        "options": {
                        },
                        "key": "sigla",
                        "states": {
                           "disabled": true,
                           "value": [["{{dati_struttura-dati_gestore-section_impresa-comune_impresa}}", "isDefined"], ["{{dati_struttura-dati_gestore-section_impresa-comune_impresa}}", "provincia", "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_impresa-provincia_impresa"
                     },
                     "comune_impresa": {
                        "label": "Comune (Sede legale)",
                        "parents": ['gestore', 'indirizzo', 'comune'],
                        "type": "textfield",
                        "required": true,
                        "autocomplete": {
                           'path': '/anagrafeAPI/autocompleteComuni',
                           'display': ['comune'],
                           'startDelay': 300
                        },
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", 'isDefined'], ["{{dati_struttura-dati_gestore-search_gestore}}", 'dataEntry.indirizzo.comune', "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_impresa-comune_impresa"
                     },
                     "indirizzo_impresa": {
                        "label": "Indirizzo",
                        "parents": ['gestore', 'indirizzo', 'indirizzo'],
                        "type": "input",
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", 'isDefined'], ["{{dati_struttura-dati_gestore-search_gestore}}", 'dataEntry.indirizzo.indirizzo', "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_impresa-indirizzo_impresa"
                     },
                     "cap_impresa": {
                        "label": "CAP",
                        "type": "input",
                        "parents": ['gestore', 'indirizzo', 'cap'],
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", 'isDefined'], ["{{dati_struttura-dati_gestore-search_gestore}}", 'dataEntry.indirizzo.cap', "get"], "?"]
                        },
                        "validators": {
                           "pattern": '[0-9]{5}'
                        },
                        "id": "dati_struttura-dati_gestore-section_impresa-cap_impresa"
                     },
                     "telefono_impresa": {
                        "label": "Telefono",
                        "parents": ['gestore', 'contatti', 'telefono'],
                        "type": "input",
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", 'isDefined'], ["{{dati_struttura-dati_gestore-search_gestore}}", 'dataEntry.contatti.telefono', "get"], "?"]
                        },
                        "validators": {
                           "pattern": '^[+]*[0-9]{1,4}[ \/0-9]*$'
                        },
                        "id": "dati_struttura-dati_gestore-section_impresa-telefono_impresa"
                     },
                     "fax_impresa": {
                        "label": "Fax",
                        "type": "input",
                        "parents": ['gestore', 'contatti', 'fax'],
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", 'isDefined'], ["{{dati_struttura-dati_gestore-search_gestore}}", 'dataEntry.contatti.fax', "get"], "?"]
                        },
                        "validators": {
                           "pattern": '^[+]*[0-9]{1,4}[ \/0-9]*$'
                        },
                        "id": "dati_struttura-dati_gestore-section_impresa-fax_impresa"
                     },
                     "cellulare_impresa": {
                        "label": "Cellulare",
                        "parents": ['gestore', 'contatti', 'cellulare'],
                        "type": "input",
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", 'isDefined'], ["{{dati_struttura-dati_gestore-search_gestore}}", 'dataEntry.contatti.cellulare', "get"], "?"]
                        },
                        "validators": {
                           "pattern": '^[+]*[0-9]{1,4}[ \/0-9]*$'
                        },
                        "id": "dati_struttura-dati_gestore-section_impresa-cellulare_impresa"
                     },
                     "email_impresa": {
                        "label": "Email",
                        "type": "input",
                        "parents": ['gestore', 'contatti', 'email'],
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", 'isDefined'], ["{{dati_struttura-dati_gestore-search_gestore}}", 'dataEntry.contatti.email', "get"], "?"]
                        },
                        "validators": {
                           "pattern": '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
                        },
                        "id": "dati_struttura-dati_gestore-section_impresa-email_impresa"
                     },
                     "note_impresa": {
                        "label": "Note",
                        "parents": ['gestore', 'note'],
                        "type": "textarea",
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", 'isDefined'], ["{{dati_struttura-dati_gestore-search_gestore}}", 'dataEntry.note', "get"], "?"]
                        },
                        "id": "dati_struttura-dati_gestore-section_impresa-note_impresa"
                     },
                     "pec": {
                        "label": "Pec",
                        "type": "input",
                        "parents": ['gestore', 'contatti', 'pec'],
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_gestore-search_gestore}}", 'isDefined'], ["{{dati_struttura-dati_gestore-search_gestore}}", 'dataEntry.contatti.pec', "get"], "?"]

                        },
                        "id": "dati_struttura-dati_gestore-section_impresa-pec"
                     }
                  },
                  "id": "dati_struttura-dati_gestore-section_impresa"
               },
               "stesse_info_gestore": {
                  "label": "Il gestore è anche proprietario dell'immobile?",
                  "id": "dati_struttura-dati_gestore-stesse_info_gestore",
                  "type": "radio",
                  "required": false,
                  "default_value": "Si",
                  "options": {
                     "Si": "Si",
                     "No": "No"
                  },
                  "states": {
                     "visible": [
                        ["{{dati_struttura-dati_gestore-tipologia_gestore}}", "persona", "=="],
                        ["{{dati_struttura-dati_gestore-inserisci_info_gestore}}", "isDefined"],
                        "||"
                     ],
                     "reset": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "=="],
                     "label": [["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "=="], "Il titolare è anche proprietario dell'immobile?", "Il gestore è anche proprietario dell'immobile?", "??"]
                  }
               }
            },
            "states": {
               "label": [["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "=="], "Dati titolare", "Dati gestore", "??"]
            },
            "id": "dati_struttura-dati_gestore"
         },
         "dati_proprietario": {
            "label": "Dati proprietario",
            "type": "container",
            "weight": 4,
            "attributes": {
               "class": "card shadow mb-4 col-12",
            },
            "states": {
               "visible": ["{{dati_struttura-dati_gestore-stesse_info_gestore}}", "No", "=="],
               "reset": ["{{dati_struttura-dati_gestore-stesse_info_gestore}}", "Si", "=="],
            },
            "children": {
               "id_proprietario": {
                  "label": "Id proprietario",
                  "parents": ['proprietario', 'idImpresa'],
                  "type": "input",
                  "id": "dati_struttura-dati_proprietario-id_proprietario",
                  "visible": false,
                  "disabled": true,
                  "states": {
                     "visible": false,
                     "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", "isDefined"], ["{{dati_struttura-dati_proprietario-search_impresa}}", "id", "get"], "?"],
                     "reset": ["{{dati_struttura-dati_proprietario-search_impresa}}", 'isNotDefined']
                  }
               },
               "tipologia_proprietario": {
                  "label": "Tipologia proprietario",
                  "required": true,
                  "type": "radio",
                  "default_value": "impresa",
                  "options": {
                     "impresa": "Impresa",
                     "persona": "Persona",
                     "sconosciuta": "I dati non sono disponibili",
                     "non_inserita": "I dati non sono stati inseriti"
                  },
                  "id": "dati_struttura-dati_proprietario-tipologia_proprietario"
               },
               "inserisci_info_proprietario": {
                  "label": "Cosa vuoi fare?",
                  "id": "dati_struttura-dati_proprietario-inserisci_info_proprietario",
                  "required": true,
                  "type": "radio",
                  "default_value": 'cerca',
                  "options": {
                     "inserisci": "Inserisci",
                     "cerca": "Cerca",
                  },
                  "states": {
                     "visible": ["{{dati_struttura-dati_proprietario-tipologia_proprietario}}", "impresa", "=="],
                     "reset": [
                        ["{{dati_struttura-dati_proprietario-tipologia_proprietario}}", ["non_inserita", "sconosciuta", "persona"], "includes"],
                        ["{{dati_struttura-dati_gestore-stesse_info_gestore}}", "No", "=="],
                        "||"]
                  }
               },
               "search_impresa": {
                  "label": "Trova un impresa",
                  "type": "textfield",
                  "id": "dati_struttura-dati_proprietario-search_impresa",
                  "required": true,
                  "autocomplete": {
                     'path': '/anagrafeAPI/autocompleteImpresa',
                     'staticParams': {
                        'index': 'impresa'
                     },
                     'display': ['dataEntry.ragioneSociale'],
                     'startDelay': 300
                  },
                  'states': {
                     'visible': [
                        ["{{dati_struttura-dati_proprietario-tipologia_proprietario}}", "impresa", "=="],
                        ["{{dati_struttura-dati_proprietario-inserisci_info_proprietario}}", "cerca", "=="],
                        "&&"
                     ],
                     "reset": [
                        ["{{dati_struttura-dati_proprietario-tipologia_proprietario}}", ["non_inserita", "sconosciuta", "persona"], "includes"],
                        ["{{dati_struttura-dati_proprietario-inserisci_info_proprietario}}", "inserisci", "=="],
                        "||"
                     ]
                  }
               },
               "edit_proprietario": {
                  "label": "Modifica",
                  "type": "button",
                  "target": "dati_struttura-dati_proprietario-search_impresa",
                  "attributes": {
                     "class": "col-12 col-md-12 text-right",
                  },
                  "states": {
                     "visible": [
                        ["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'],
                        ["{{dati_struttura-dati_proprietario-inserisci_info_proprietario}}", "cerca", "=="],
                        "&&"
                     ]
                  },
                  "id": "dati_struttura-dati_proprietario-edit_proprietario",
               },
               "section_titolare": {
                  "label": "Titolare",
                  "type": "section",
                  "visible": true,
                  "states": {
                     "visible": [
                        [
                           [
                              [
                                 ["{{dati_struttura-dati_proprietario-inserisci_info_proprietario}}", "cerca", "=="],
                                 ["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'],
                                 "&&"
                              ],
                              ["{{dati_struttura-dati_proprietario-inserisci_info_proprietario}}", "inserisci", "=="],
                              "||"
                           ],
                           ["{{dati_struttura-dati_proprietario-tipologia_proprietario}}", "impresa", "=="],
                           "&&"
                        ],
                        ["{{dati_struttura-dati_proprietario-tipologia_proprietario}}", "persona", "=="],
                        "||"
                     ],
                     "disabled": [
                        [
                           ["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'],
                           ["{{dati_struttura-dati_proprietario-inserisci_info_proprietario}}", "cerca", "=="],
                           "&&"
                        ],
                        ["{{dati_struttura-dati_proprietario-tipologia_proprietario}}", 'impresa', "=="],
                        "&&"
                     ],
                     "reset": [
                        ["{{dati_struttura-dati_proprietario-search_impresa}}", 'isNotDefined'],
                        ["{{dati_struttura-dati_proprietario-inserisci_info_proprietario}}", "inserisci", "=="], ,
                        "||"
                     ]
                  },
                  "attributes": {
                     "class": "col-12"
                  },
                  "children": {
                     "cognome_titolare": {
                        "label": "Cognome",
                        "parents": ['proprietario', 'rappresentante', 'cognomeRappr'],
                        "type": "input",
                        "required": true,
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.rappresentante.cognomeRappr', 'get'], "?"]
                        },
                        "validators": {
                           "pattern": '^[A-Za-zÀ-ÖØ-öø-ÿ]+[\']*[A-Za-zÀ-ÖØ-öø-ÿ][A-Za-zÀ-ÖØ-öø-ÿ ]*$'
                        },
                        "id": "dati_struttura-dati_proprietario-section_titolare-cognome_titolare"
                     },
                     "nome_titolare": {
                        "label": "Nome",
                        "parents": ['proprietario', 'rappresentante', 'nomeRappr'],
                        "type": "input",
                        "required": true,
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.rappresentante.nomeRappr', 'get'], "?"]
                        },
                        "validators": {
                           "pattern": '^[A-Za-zÀ-ÖØ-öø-ÿ]+[\']*[A-Za-zÀ-ÖØ-öø-ÿ][A-Za-zÀ-ÖØ-öø-ÿ ]*$'
                        },
                        "id": "dati_struttura-dati_proprietario-section_titolare-nome_titolare"
                     },
                     "data_nascita_titolare": {
                        "label": "Data di nascita",
                        "parents": ['proprietario', 'rappresentante', 'dataNascita'],
                        "type": "date",
                        "required": true,
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.rappresentante.dataNascita', 'get'], "?"]
                        },
                        "id": "dati_struttura-dati_proprietario-section_titolare-data_nascita_titolare"
                     },
                     "stato_nascita": {
                        "label": "Stato nascita",
                        "type": "textfield",
                        "default_value": "Italia",
                        "parents": ['proprietario', 'rappresentante', 'statoNascita'],
                        "required": true,
                        "visible": true,
                        "autocomplete": {
                           'local': true,
                           'startDelay': 300,
                           "data": getNomiStati(),
                        },
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.rappresentante.statoNascita', 'get'], "?"]
                        },
                        "id": "dati_struttura-dati_proprietario-section_titolare-stato_nascita"
                     },
                     "provincia_nascita": {
                        "label": "Provincia di nascita",
                        "type": "select",
                        "parents": ['proprietario', 'rappresentante', 'provinciaNascita'],
                        "required": true,
                        "optionsUpdate": '/anagrafeAPI/fetchProvinceItalia',
                        "display": 'provincia',
                        "value": true,
                        "visible": true,
                        "options": {
                        },
                        "key": "sigla",
                        "states": {
                           "visible": [
                              ["{{dati_struttura-dati_proprietario-section_titolare-stato_nascita}}", "Italia", "=="],
                              ["{{dati_struttura-dati_proprietario-section_titolare-comune_nascita}}", { "custom": true, "comune": "ALTRO" }, "!="],
                              "&&"
                           ],
                           "disabled": true,
                           "reset": ["{{dati_struttura-dati_proprietario-section_titolare-comune_nascita}}", "isNotDefined"],
                           "value": [["{{dati_struttura-dati_proprietario-section_titolare-comune_nascita}}", "isDefined"], ["{{dati_struttura-dati_proprietario-section_titolare-comune_nascita}}", "provincia", "get"], "?"]
                        },
                        "id": "dati_struttura-dati_proprietario-section_titolare-provincia_nascita"
                     },
                     "comune_nascita": {
                        "label": "Comune di nascita",
                        "type": "textfield",
                        "parents": ['proprietario', 'rappresentante', 'comuneNascita'],
                        "required": true,
                        "visible": true,
                        "autocomplete": {
                           'path': '/anagrafeAPI/autocompleteComuni',
                           'display': ['comune'],
                           'startDelay': 300,
                        },
                        "fixedOptions": [{
                           "custom": true,
                           "comune": "ALTRO"
                        }],
                        "states": {
                           "visible": ["{{dati_struttura-dati_proprietario-section_titolare-stato_nascita}}", "Italia", "=="],
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.rappresentante.comuneNascita', 'get'], "?"]
                        },
                        "id": "dati_struttura-dati_proprietario-section_titolare-comune_nascita"
                     },
                     "comune_estero_nascita": {
                        "label": "Comune estero di nascita",
                        "type": "input",
                        "parents": ['proprietario', 'rappresentante', 'comuneEstero'],
                        "required": true,
                        "states": {
                           "visible": [
                              ["{{dati_struttura-dati_proprietario-section_titolare-stato_nascita}}", "Italia", "!="],
                              ["{{dati_struttura-dati_proprietario-section_titolare-stato_nascita}}", "isDefined"],
                              "&&"
                           ],
                           "reset": ["{{dati_struttura-dati_proprietario-section_titolare-stato_nascita}}", "Italia", "=="],
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.rappresentante.comuneEstero', 'get'], "?"]
                        },
                        "id": "dati_struttura-dati_proprietario-section_titolare-comune_estero_nascita"
                     },
                     "altro_comune_nascita": {
                        "label": "Altro comune di nascita",
                        "type": "input",
                        "parents": ['proprietario', 'rappresentante', 'altroComune'],
                        "required": true,
                        "states": {
                           "visible": [
                              ["{{dati_struttura-dati_proprietario-section_titolare-stato_nascita}}", "Italia", "=="],
                              ["{{dati_struttura-dati_proprietario-section_titolare-comune_nascita}}", { "custom": true, "comune": "ALTRO" }, "=="],
                              "&&"
                           ],
                           "reset": ["{{dati_struttura-dati_proprietario-section_titolare-comune_nascita}}", "isNotDefined"],
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.rappresentante.altroComune', 'get'], "?"]
                        },
                        "id": "dati_struttura-dati_proprietario-section_titolare-altro_comune_nascita"
                     },
                     "codice_fiscale_titolare": {
                        "label": "Codice Fiscale",
                        "parents": ['proprietario', 'rappresentante', 'codiceFiscale'],
                        "type": "input",
                        "required": true,
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.rappresentante.codiceFiscale', 'get'], "?"]
                        },
                        "validators": {
                           "pattern": '^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$'
                        },
                        "id": "dati_struttura-dati_proprietario-section_titolare-codice_fiscale_titolare"
                     },
                     "cittadinanza_titolare": {
                        "label": "Cittadinanza",
                        "parents": ['proprietario', 'rappresentante', 'cittadinanza'],
                        "type": "input",
                        "required": true,
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.rappresentante.cittadinanza', 'get'], "?"]
                        },
                        "id": "dati_struttura-dati_proprietario-section_titolare-cittadinanza_titolare"
                     },
                     "documento_sogg_titolare": {
                        "label": "Documento di soggiorno (se cittadino non UE)",
                        "type": "input",
                        "parents": ['proprietario', 'rappresentante', 'documentoPermessoSoggiorno'],
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.rappresentante.documentoPermessoSoggiorno', 'get'], "?"]
                        },
                        "id": "dati_struttura-dati_proprietario-section_titolare-documento_sogg_titolare"
                     },
                     "stato_residenza": {
                        "label": "Stato residenza",
                        "type": "textfield",
                        "default_value": "Italia",
                        "parents": ['proprietario', 'rappresentante', 'statoResidenza'],
                        "required": true,
                        "visible": true,
                        "autocomplete": {
                           'local': true,
                           'startDelay': 300,
                           "data": getNomiStati(),
                        },
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.rappresentante.statoResidenza', 'get'], "?"]
                        },
                        "id": "dati_struttura-dati_proprietario-section_titolare-stato_residenza"
                     },
                     "provincia_residenza_titolare": {
                        "label": "Provincia di residenza",
                        "type": "select",
                        "parents": ['proprietario', 'rappresentante', 'provinciaResidenza'],
                        "optionsUpdate": '/anagrafeAPI/fetchProvinceItalia',
                        "display": 'provincia',
                        "value": true,
                        "options": {
                        },
                        "key": "sigla",
                        "states": {
                           "visible": [
                              ["{{dati_struttura-dati_proprietario-section_titolare-stato_residenza}}", "Italia", "=="],
                              ["{{dati_struttura-dati_proprietario-section_titolare-comune_residenza_titolare}}", { "custom": true, "comune": "ALTRO" }, "!="],
                              "&&"
                           ],
                           "value": [
                              ["{{dati_struttura-dati_proprietario-section_titolare-comune_residenza_titolare}}", 'isDefined'],
                              ["{{dati_struttura-dati_proprietario-section_titolare-comune_residenza_titolare}}", 'provincia', "get"], "?"],
                           "reset": ["{{dati_struttura-dati_proprietario-section_titolare-comune_residenza_titolare}}", 'isNotDefined'],
                           "disabled": true
                        },
                        "id": "dati_struttura-dati_proprietario-section_titolare-provincia_residenza_titolare"
                     },
                     "comune_residenza_titolare": {
                        "label": "Comune di residenza",
                        "type": "textfield",
                        "parents": ['proprietario', 'rappresentante', 'comuneResidenza'],
                        "required": true,
                        "autocomplete": {
                           'path': '/anagrafeAPI/autocompleteComuni',
                           'display': ['comune'],
                           'startDelay': 300
                        },
                        "fixedOptions": [{
                           "custom": true,
                           "comune": "ALTRO"
                        }],
                        "states": {
                           "visible": ["{{dati_struttura-dati_proprietario-section_titolare-stato_nascita}}", "Italia", "=="],
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.rappresentante.comuneResidenza', 'get'], "?"]
                        },
                        "id": "dati_struttura-dati_proprietario-section_titolare-comune_residenza_titolare"
                     },
                     "comune_estero_residenza": {
                        "label": "Comune estero di residenza",
                        "type": "input",
                        "parents": ['proprietario', 'rappresentante', 'comuneEsteroResidenza'],
                        "required": true,
                        "states": {
                           "visible": [
                              ["{{dati_struttura-dati_proprietario-section_titolare-stato_residenza}}", "Italia", "!="],
                              ["{{dati_struttura-dati_proprietario-section_titolare-stato_residenza}}", "isDefined"],
                              "&&"
                           ],
                           "reset": ["{{dati_struttura-dati_proprietario-section_titolare-stato_residenza}}", "Italia", "=="],
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.rappresentante.comuneEsteroResidenza', 'get'], "?"]
                        },
                        "id": "dati_struttura-dati_proprietario-section_titolare-comune_estero_residenza"
                     },
                     "altro_comune_residenza": {
                        "label": "Altro comune di residenza",
                        "type": "input",
                        "parents": ['proprietario', 'rappresentante', 'altroComuneResidenza'],
                        "required": true,
                        "states": {
                           "visible": [
                              ["{{dati_struttura-dati_proprietario-section_titolare-stato_residenza}}", "Italia", "=="],
                              ["{{dati_struttura-dati_proprietario-section_titolare-comune_residenza_titolare}}", { "custom": true, "comune": "ALTRO" }, "=="],
                              "&&"
                           ],
                           "reset": ["{{dati_struttura-dati_proprietario-section_titolare-comune_residenza_titolare}}", "isNotDefined"],
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.rappresentante.altroComuneResidenza', 'get'], "?"]
                        },
                        "id": "dati_struttura-dati_proprietario-section_titolare-altro_comune_residenza"
                     },
                     "cap_residenza_titolare": {
                        "label": "CAP residenza",
                        "parents": ['proprietario', 'rappresentante', 'capResidenza'],
                        "type": "input",
                        "required": true,
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.rappresentante.capResidenza', 'get'], "?"]
                        },
                        "validators": {
                           "pattern": '[0-9]{5}'
                        },
                        "id": "dati_struttura-dati_proprietario-section_titolare-cap_residenza_titolare"
                     },
                     "indirizzo_residenza_titolare": {
                        "label": "Indirizzo di residenza",
                        "parents": ['proprietario', 'rappresentante', 'indirizzoResidenza'],
                        "type": "input",
                        "required": true,
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.rappresentante.indirizzoResidenza', 'get'], "?"]
                        },
                        "id": "dati_struttura-dati_proprietario-section_titolare-indirizzo_residenza_titolare"
                     },
                     "telefono_titolare": {
                        "label": "Telefono",
                        "parents": ['proprietario', 'rappresentante', 'telefono'],
                        "type": "input",
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.rappresentante.telefono', 'get'], "?"]
                        },
                        "validators": {
                           "pattern": '^[+]*[0-9]{1,4}[ \/0-9]*$'
                        },
                        "id": "dati_struttura-dati_proprietario-section_titolare-telefono_titolare"
                     },
                     "email_titolare": {
                        "label": "Email",
                        "parents": ['proprietario', 'rappresentante', 'email'],
                        "type": "input",
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.rappresentante.email', 'get'], "?"]
                        },
                        "validators": {
                           "pattern": '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
                        },
                        "id": "dati_struttura-dati_proprietario-section_titolare-email_titolare"
                     },
                  },
                  "id": "dati_struttura-dati_proprietario-section_titolare"
               },
               "section_impresa": {
                  "label": "Impresa",
                  "type": "section",
                  "visible": true,
                  "required": false,
                  "states": {
                     "visible": [
                        [
                           [
                              ["{{dati_struttura-dati_proprietario-inserisci_info_proprietario}}", "cerca", "=="],
                              ["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'],
                              "&&"
                           ],
                           ["{{dati_struttura-dati_proprietario-inserisci_info_proprietario}}", "inserisci", "=="],
                           "||"
                        ],
                        ["{{dati_struttura-dati_proprietario-tipologia_proprietario}}", "impresa", "=="],
                        "&&"
                     ],
                     "disabled": [
                        ["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'],
                        ["{{dati_struttura-dati_proprietario-inserisci_info_proprietario}}", "cerca", "=="],
                        "&&"
                     ],
                     "reset": [
                        ["{{dati_struttura-dati_proprietario-search_impresa}}", 'isNotDefined'],
                        ["{{dati_struttura-dati_proprietario-inserisci_info_proprietario}}", "inserisci", "=="], ,
                        "||"
                     ],
                  },
                  "attributes": {
                     "class": "col-12 mt-3"
                  },
                  "children": {
                     "denominazione_impresa": {
                        "label": "Denominazione",
                        "parents": ['proprietario', 'ragioneSociale'],
                        "type": "input",
                        "required": true,
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.ragioneSociale', 'get'], "?"]
                        },
                        "id": "dati_struttura-dati_proprietario-section_impresa-denominazione_impresa"
                     },
                     "forma_giuridica": {
                        "label": "Forma giuridica",
                        "parents": ['proprietario', 'formaGiuridica', 'sigla'],
                        "type": "select",
                        "required": false,
                        "options": {
                           "A.r.l.": "A.r.l.",
                           "D.I.": "D.I.",
                           "S.a.s.": "S.a.s.",
                           "S.d.f.": "S.d.f.",
                           "S.n.c.": "S.n.c.",
                           "S.p.a.": "S.p.a.",
                           "S.r.l.": "S.r.l.",
                           "Soc. Coop.": "Soc. Coop.",
                           "p.c.a.": "p.c.a."
                        },
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.formaGiuridica.sigla', 'get'], "?"]
                        },
                        "id": "dati_struttura-dati_proprietario-section_impresa-forma_giuridica"
                     },
                     "codice_fiscale_impresa": {
                        "label": "Codice Fiscale",
                        "parents": ['proprietario', 'codiceFiscale'],
                        "type": "input",
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.codiceFiscale', 'get'], "?"],
                           "required": ["{{dati_struttura-dati_proprietario-section_impresa-partita_iva}}", 'isNotDefined']
                        },
                        "validators": {
                           "pattern": '^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$'
                        },
                        "id": "dati_struttura-dati_proprietario-section_impresa-codice_fiscale_impresa"
                     },
                     "partita_iva": {
                        "label": "Partita Iva",
                        "type": "input",
                        "parents": ['proprietario', 'partitaIva'],
                        "required": false,
                        "states": {
                           "required": ['{{dati_struttura-dati_proprietario-section_impresa-codice_fiscale_impresa}}', 'isNotDefined'],
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.partitaIva', 'get'], "?"]
                        },
                        "validators": {
                           "pattern": '^[0-9]{11}$'
                        },
                        "id": "dati_struttura-dati_proprietario-section_impresa-partita_iva"
                     },
                     "provincia_impresa": {
                        "label": "Provincia (Sede legale)",
                        "parents": ['proprietario', 'indirizzo', 'provincia'],
                        "type": "select",
                        "required": false,
                        "optionsUpdate": '/anagrafeAPI/fetchProvinceItalia',
                        "display": 'provincia',
                        "value": true,
                        "options": {
                        },
                        "key": "sigla",
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-section_impresa-comune_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-section_impresa-comune_impresa}}", 'provincia', 'get'], "?"],
                           "disabled": true,
                           "reset": ["{{dati_struttura-dati_proprietario-section_impresa-comune_impresa}}", 'isNotDefined'],
                        },
                        "id": "dati_struttura-dati_proprietario-section_impresa-provincia_impresa"
                     },
                     "comune_impresa": {
                        "label": "Comune (Sede legale)",
                        "parents": ['proprietario', 'indirizzo', 'comune'],
                        "type": "textfield",
                        "required": true,
                        "autocomplete": {
                           'path': '/anagrafeAPI/autocompleteComuni',
                           'display': ['comune'],
                           'startDelay': 300
                        },
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.indirizzo.comune', 'get'], "?"]
                        },
                        "id": "dati_struttura-dati_proprietario-section_impresa-comune_impresa"
                     },
                     "indirizzo_impresa": {
                        "label": "Indirizzo",
                        "parents": ['proprietario', 'indirizzo', 'indirizzo'],
                        "type": "input",
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.indirizzo.indirizzo', 'get'], "?"]
                        },
                        "id": "dati_struttura-dati_proprietario-section_impresa-indirizzo_impresa"
                     },
                     "cap_impresa": {
                        "label": "CAP",
                        "type": "input",
                        "parents": ['proprietario', 'indirizzo', 'cap'],
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.indirizzo.cap', 'get'], "?"]
                        },
                        "validators": {
                           "pattern": '[0-9]{5}'
                        },
                        "id": "dati_struttura-dati_proprietario-section_impresa-cap_impresa"
                     },
                     "telefono_impresa": {
                        "label": "Telefono",
                        "parents": ['proprietario', 'contatti', 'telefono'],
                        "type": "input",
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.contatti.telefono', 'get'], "?"]
                        },
                        "validators": {
                           "pattern": '^[+]*[0-9]{1,4}[ \/0-9]*$'
                        },
                        "id": "dati_struttura-dati_proprietario-section_impresa-telefono_impresa"
                     },
                     "fax_impresa": {
                        "label": "Fax",
                        "type": "input",
                        "parents": ['proprietario', 'contatti', 'fax'],
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.contatti.fax', 'get'], "?"]
                        },
                        "validators": {
                           "pattern": '^[+]*[0-9]{1,4}[ \/0-9]*$'
                        },
                        "id": "dati_struttura-dati_proprietario-section_impresa-fax_impresa"
                     },
                     "cellulare_impresa": {
                        "label": "Cellulare",
                        "parents": ['proprietario', 'contatti', 'cellulare'],
                        "type": "input",
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.contatti.cellulare', 'get'], "?"]
                        },
                        "validators": {
                           "pattern": '^[+]*[0-9]{1,4}[ \/0-9]*$'
                        },
                        "id": "dati_struttura-dati_proprietario-section_impresa-cellulare_impresa"
                     },
                     "email_impresa": {
                        "label": "Email",
                        "type": "input",
                        "parents": ['proprietario', 'contatti', 'email'],
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.contatti.email', 'get'], "?"]
                        },
                        "validators": {
                           "pattern": '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
                        },
                        "id": "dati_struttura-dati_proprietario-section_impresa-email_impresa"
                     },
                     "note_impresa": {
                        "label": "Note",
                        "parents": ['proprietario', 'note'],
                        "type": "textarea",
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.note', 'get'], "?"]
                        },
                        "id": "dati_struttura-dati_proprietario-section_impresa-note_impresa"
                     },
                     "pec": {
                        "label": "Pec",
                        "type": "input",
                        "parents": ['proprietario', 'contatti', 'pec'],
                        "required": false,
                        "states": {
                           "value": [["{{dati_struttura-dati_proprietario-search_impresa}}", 'isDefined'], ["{{dati_struttura-dati_proprietario-search_impresa}}", 'dataEntry.contatti.pec', 'get'], "?"]
                        },
                        "id": "dati_struttura-dati_proprietario-section_impresa-pec"
                     }
                  },
                  "id": "dati_struttura-dati_proprietario-section_impresa"
               }
            },
            "id": "dati_struttura-dati_proprietario"
         },
         "dati_bb": {
            "label": "Informazioni struttura",
            "type": "container",
            "weight": 2,
            "attributes": {
               "class": "card shadow mb-4 col-12",
            },
            "states": {
               "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "=="],
               "reset": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "!="],
            },
            "children": {
               "classe_prop_bb": {
                  "label": "Categoria-Classe",
                  "type": "input",
                  "required": false,
                  "validators": {
                     "pattern": "((^[Aa][1-9])|(^[Aa][1][0])|(^[Aa][1][1]))"
                  },
                  "id": "dati_struttura-dati_bb-classe_prop_bb"
               },
               "foglio_prop_bb": {
                  "label": "foglio",
                  "type": "input",
                  "required": false,
                  "validators": {
                     "pattern": "(^0$)|(^[1-9][0-9]*)"
                  },
                  "id": "dati_struttura-dati_bb-foglio_prop_bb"
               },
               "mappale_prop_bb": {
                  "label": "mappale",
                  "type": "input",
                  "required": false,
                  "validators": {
                     "pattern": "(^0$)|(^[1-9][0-9]*)"
                  },
                  "id": "dati_struttura-dati_bb-mappale_prop_bb"
               },
               "categoria_catastale": {
                  "label": "subalterno",
                  "type": "input",
                  "required": false,
                  "validators": {
                     "pattern": "(^0$)|(^[1-9][0-9]*)"
                  },
                  "id": "dati_struttura-dati_bb-categoria_catastale"
               },
               "concessione_edilizia_bb": {
                  "label": "concessione edilizia n°",
                  "type": "input",
                  "required": false,
                  "validators": {
                     "pattern": "(^0$)|(^[1-9][0-9]*)"
                  },
                  "id": "dati_struttura-dati_bb-concessione_edilizia_bb"
               },
               "concessionedel_bb": {
                  "label": "del",
                  "type": "date",
                  "required": false,
                  "id": "dati_struttura-dati_bb-concessionedel_bb"
               },
               "certificato_agibilita_bb": {
                  "label": "certificato agibilità",
                  "type": "input",
                  "required": false,
                  "id": "dati_struttura-dati_bb-certificato_agibilita_bb"
               },
               "certificatoagibilita_del_bb": {
                  "label": "del",
                  "type": "date",
                  "required": false,
                  "id": "dati_struttura-dati_bb-certificatoagibilita_del_bb"
               },
               "insegna_desercizio_bb": {
                  "label": "insegna d'esercizio",
                  "type": "input",
                  "required": false,
                  "id": "dati_struttura-dati_bb-insegna_desercizio_bb"
               }
            },
            "id": "dati_struttura-dati_bb"
         }
      },
      "id": "dati_struttura"
   };
}

const getCapacitaRicettiva = function (weight: number, edit) {
   return {
      "type": "container",
      "label": "Capacità ricettiva",
      "visible": true,
      "weight": weight,
      "attributes": {
         "class": "row",
      },
      "children": {
         "camere": {
            "label": "Camere",
            "type": "container",
            "parents": ['capacita_ricettiva'],
            "optional": true,
            "weight": 0,
            "attributes": {
               "class": "card shadow mb-4 col-12",
            },
            "children": {
               "numero_complessivo_delle_camere_f29": {
                  "label": "N° complessivo camere",
                  "type": "number",
                  "required": true,
                  "notEdit": true,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive alberghiere", "=="],
                     "value": [
                        "{{capacita_ricettiva-camere-camere_singole_f29}}",
                        "{{capacita_ricettiva-camere-camere_doppie_f29}}",
                        "sum"
                     ]
                  },
                  "validators": {
                     "min": 7
                  },
                  "id": "capacita_ricettiva-camere-numero_complessivo_delle_camere_f29"
               },
               "numero_complessivo_servizi_igienici_f29": {
                  "label": "N° complessivo servizi igienici",
                  "type": "number",
                  "required": true,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive alberghiere", "=="]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-numero_complessivo_servizi_igienici_f29"
               },
               "camere_singole_f29": {
                  "label": "Camere singole",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive alberghiere", "=="]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-camere_singole_f29"
               },
               "camere_doppie_f29": {
                  "label": "Camere doppie",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive alberghiere", "=="]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-camere_doppie_f29"
               },
               "vani_soggiorno_f29": {
                  "label": "Vani soggiorno",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive alberghiere", "=="]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-vani_soggiorno_f29"
               },
               "posti_letto_standard_f29": {
                  "label": "posti letto standard (A)",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive alberghiere", "=="]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-posti_letto_standard_f29"
               },
               "posti_letto_aggiuntivi_f29": {
                  "label": "posti letto aggiuntivi (B)",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive alberghiere", "=="]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-posti_letto_aggiuntivi_f29"
               },
               "totale_complessivo_dei_posti_letto_f29": {
                  "label": "Totale complessivo dei posti letto (A+B)",
                  "type": "number",
                  "required": true,
                  "notEdit": true,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive alberghiere", "=="],
                     "value": [
                        "{{capacita_ricettiva-camere-posti_letto_aggiuntivi_f29}}",
                        "{{capacita_ricettiva-camere-posti_letto_standard_f29}}",
                        "sum"
                     ]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-totale_complessivo_dei_posti_letto_f29"
               },

               "numero_complessivo_delle_camere_f31": {
                  "label": "N° complessivo camere",
                  "type": "number",
                  "required": true,
                  "notEdit": true,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="],
                     "validate": [["{{dati_prelim-classificazione-extra_alberghiere}}", "Affittacamere", "=="], { 'max': 6, 'min': 1, 'required': true }, "?"],
                     "value": [
                        [
                           ["{{capacita_ricettiva-camere-camere_singole_f31}}", "{{capacita_ricettiva-camere-camere_doppie_f31}}", "sum"],
                           ["{{capacita_ricettiva-camere-camere_triple_f31}}", "{{capacita_ricettiva-camere-camere_quadruple_f31}}", "sum"],
                           "sum"
                        ],
                        "{{capacita_ricettiva-camere-camere_piu_di_4_f31}}",
                        "sum"
                     ]
                  },
                  "validators": {
                     "min": 1
                  },
                  "id": "capacita_ricettiva-camere-numero_complessivo_delle_camere_f31"
               },
               "numero_complessivo_servizi_igienici_f31": {
                  "label": "N° complessivo servizi igienici",
                  "type": "number",
                  "required": true,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="],
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-numero_complessivo_servizi_igienici_f31"
               },
               "camere_singole_f31": {
                  "label": "Camere singole",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="],
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-camere_singole_f31"
               },
               "camere_doppie_f31": {
                  "label": "Camere doppie",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="],
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-camere_doppie_f31"
               },
               "camere_triple_f31": {
                  "label": "Camere triple",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="],
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-camere_triple_f31"
               },
               "camere_quadruple_f31": {
                  "label": "Camere quadruple",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="],
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-camere_quadruple_f31"
               },
               "camere_piu_di_4_f31": {
                  "label": "Camere con più di 4 posti",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="],
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-camere_piu_di_4_f31"
               },
               "vani_soggiorno_f31": {
                  "label": "Vani soggiorno",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="],
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-vani_soggiorno_f31"
               },
               "posti_letto_standard_f31": {
                  "label": "posti letto standard (A)",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="],
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-posti_letto_standard_f31"
               },
               "posti_letto_aggiuntivi_f31": {
                  "label": "posti letto aggiuntivi (B)",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="],
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-posti_letto_aggiuntivi_f31"
               },
               "totale_complessivo_dei_posti_letto_f31": {
                  "label": "Totale complessivo dei posti letto (A+B)",
                  "type": "number",
                  "required": true,
                  "notEdit": true,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="],
                     "value": [
                        "{{capacita_ricettiva-camere-posti_letto_standard_f31}}",
                        "{{capacita_ricettiva-camere-posti_letto_aggiuntivi_f31}}",
                        "sum"
                     ]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-totale_complessivo_dei_posti_letto_f31"
               },
               "numero_complessivo_delle_camere_f46": {
                  "label": "N° complessivo camere",
                  "type": "number",
                  "required": true,
                  "notEdit": true,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", ["Bed and breakfast", "Boat and Breakfast"], "includes"],
                     "value": [
                        [
                           ["{{capacita_ricettiva-camere-singole_con_bagno_f46}}", "{{capacita_ricettiva-camere-singole_senza_bagno_f46}}", "sum"],
                           ["{{capacita_ricettiva-camere-doppie_con_bagno_f46}}", "{{capacita_ricettiva-camere-doppie_senza_bagno_f46}}", "sum"],
                           "sum"
                        ],
                        [
                           ["{{capacita_ricettiva-camere-triple_con_bagno_f46}}", "{{capacita_ricettiva-camere-triple_senza_bagno_f46}}", "sum"],
                           ["{{capacita_ricettiva-camere-quadruple_con_bagno_f46}}", "{{capacita_ricettiva-camere-quadruple_senza_bagno_f46}}", "sum"],
                           "sum"
                        ],
                        "sum"]
                  },
                  "validators": {
                     "max": 3
                  },
                  "id": "capacita_ricettiva-camere-numero_complessivo_delle_camere_f46"
               },
               "numero_complessivo_servizi_igienici_f46": {
                  "label": "N° totale bagni a disposizione ospiti",
                  "type": "number",
                  "required": true,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", ["Bed and breakfast", "Boat and Breakfast"], "includes"],
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-numero_complessivo_servizi_igienici_f46"
               },
               "singole_con_bagno_f46": {
                  "label": "singole con bagno",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", ["Bed and breakfast", "Boat and Breakfast"], "includes"],
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-singole_con_bagno_f46"
               },
               "doppie_con_bagno_f46": {
                  "label": "doppie con bagno",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", ["Bed and breakfast", "Boat and Breakfast"], "includes"],
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-doppie_con_bagno_f46"
               },
               "singole_senza_bagno_f46": {
                  "label": "singole senza bagno",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", ["Bed and breakfast", "Boat and Breakfast"], "includes"],
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-singole_senza_bagno_f46"
               },
               "doppie_senza_bagno_f46": {
                  "label": "doppie senza bagno",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", ["Bed and breakfast", "Boat and Breakfast"], "includes"],
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-doppie_senza_bagno_f46"
               },
               "triple_senza_bagno_f46": {
                  "label": "triple senza bagno",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", ["Bed and breakfast", "Boat and Breakfast"], "includes"]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-triple_senza_bagno_f46"
               },
               "triple_con_bagno_f46": {
                  "label": "triple con bagno",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", ["Bed and breakfast", "Boat and Breakfast"], "includes"]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-triple_con_bagno_f46"
               },
               "quadruple_senza_bagno_f46": {
                  "label": "quadruple senza bagno",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", ["Bed and breakfast", "Boat and Breakfast"], "includes"]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-quadruple_senza_bagno_f46"
               },
               "quadruple_con_bagno_f46": {
                  "label": "quadruple con bagno",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", ["Bed and breakfast", "Boat and Breakfast"], "includes"]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-camere-quadruple_con_bagno_f46"
               },
               "totale_posti_letto_f46": {
                  "label": "posti letto totali",
                  "type": "number",
                  "required": true,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", ["Bed and breakfast", "Boat and Breakfast"], "includes"]
                  },
                  "validators": {
                     "min": 0,
                     "max": 10
                  },
                  "id": "capacita_ricettiva-camere-totale_posti_letto_f46"
               },
               "dotazione": {
                  "label": "Dotazione",
                  "type": "checkbox",
                  "collapsable": true,
                  "collapsed": false,
                  "required": false,
                  "options": {
                     "Riscaldamento": "Riscaldamento",
                     "Aria Condizionata": "Aria Condizionata",
                     "Insonorizzazione": "Insonorizzazione",
                     "Cassetta di sicurezza a muro": "Cassetta di sicurezza a muro",
                     "Telefono abilitato a chiamata diretta esterna": "Telefono abilitato a chiamata diretta esterna",
                     "Telefono non abilitato a chiamata diretta": "Telefono non abilitato a chiamata diretta",
                     "Apparecchio TV": "Apparecchio TV",
                     "Frigo Bar": "Frigo Bar",
                     "Radio": "Radio",
                     "Satellitare": "Satellitare"
                  },
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", ["Bed and breakfast", "Boat and Breakfast"], "includes"],
                  },
                  "attributes": {
                     "class": "col-12"
                  },
                  "id": "capacita_ricettiva-camere-dotazione"
               },
            },
            "states": {
               "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", ["Aziende ricettive extra alberghiere", "Aziende ricettive alberghiere", "Bed and breakfast", "Boat and Breakfast"], "includes"],
            },
            "id": "capacita_ricettiva-camere"
         },
         "piazzole": {
            "label": "Piazzole",
            "type": "container",
            "weight": 0,
            "attributes": {
               "class": "card shadow mb-4 col-12"
            },
            "states": {
               "visible": [
                  ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                  ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "!="],
                  "&&"
               ]
            },
            "id": "capacita_ricettiva-piazzole",
            "children": {
               "num_piazzole_da_campeggio": {
                  "label": "N° piazzole da campeggio",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                        ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "!="],
                        "&&"
                     ]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-piazzole-num_piazzole_da_campeggio"
               },
               "num_piazzole_con_unita_abitative": {
                  "label": "N° piazzole con Unita Abitative",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                        ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "!="],
                        "&&"
                     ]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-piazzole-num_piazzole_con_unita_abitative"
               },
               "totale_piazzole": {
                  "label": "Totale piazzole",
                  "type": "number",
                  "required": true,
                  "notEdit": true,
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                        ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "!="],
                        "&&"
                     ],
                     "value": [
                        "{{capacita_ricettiva-piazzole-num_piazzole_con_unita_abitative}}",
                        "{{capacita_ricettiva-piazzole-num_piazzole_da_campeggio}}",
                        "sum"
                     ]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-piazzole-totale_piazzole"
               },
               "posti_letto_per_piazzole_campeggio": {
                  "label": "Posti letto per piazzole campeggio",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                        ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "!="],
                        "&&"
                     ]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-piazzole-posti_letto_per_piazzole_campeggio"
               },
               "posti_letto_per_piazzole_con_unita_abitative": {
                  "label": "Posti letto per piazzole con Unita Abitative",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                        ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "!="],
                        "&&"
                     ]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-piazzole-posti_letto_per_piazzole_con_unita_abitative"
               },
               "totale_posti_letto_piazzole": {
                  "label": "Totale posti letto",
                  "type": "number",
                  "required": true,
                  "notEdit": true,
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                        ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "!="],
                        "&&"
                     ],
                     "value": [
                        "{{capacita_ricettiva-piazzole-posti_letto_per_piazzole_con_unita_abitative}}",
                        "{{capacita_ricettiva-piazzole-posti_letto_per_piazzole_campeggio}}",
                        "sum"
                     ]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-piazzole-totale_posti_letto_piazzole"
               },
            }
         },
         "posti_barca": {
            "label": "Posti barca",
            "type": "container",
            "weight": 0,
            "attributes": {
               "class": "card shadow mb-4 col-12"
            },
            "states": {
               "visible": [
                  ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                  ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "=="],
                  "&&"
               ]
            },
            "id": "capacita_ricettiva-posti_barca",
            "children": {
               "num_posti_barca_tipo_ordinario": {
                  "label": "N° posti barca di tipo Ordinario",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                        ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "=="],
                        "&&"
                     ]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-posti_barca-num_posti_barca_tipo_ordinario"
               },
               "num_posti_barca_tipo_temporaneo": {
                  "label": "N° posti barca di tipo Temporaneo",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                        ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "=="],
                        "&&"
                     ]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-posti_barca-num_posti_barca_tipo_temporaneo"
               },
               "totale_posti_barca": {
                  "label": "Totale posti barca",
                  "type": "number",
                  "required": true,
                  "notEdit": true,
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                        ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "=="],
                        "&&"
                     ],
                     "value": [
                        "{{capacita_ricettiva-posti_barca-num_posti_barca_tipo_temporaneo}}",
                        "{{capacita_ricettiva-posti_barca-num_posti_barca_tipo_ordinario}}",
                        "sum"
                     ]
                  },
                  "validators": {
                     "min": 7
                  },
                  "id": "capacita_ricettiva-posti_barca-totale_posti_barca"
               },
               "num_posti_letto_in_posti_barca_tipo_ordinario": {
                  "label": "N° posti letto in posti barca di tipo Ordinario",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                        ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "=="],
                        "&&"
                     ]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-posti_barca-num_posti_letto_in_posti_barca_tipo_ordinario"
               },
               "num_posti_letto_in_posti_barca_tipo_temporaneo": {
                  "label": "N° posti letto in posti barca di tipo Temporaneo",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                        ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "=="],
                        "&&"
                     ]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-posti_barca-num_posti_letto_in_posti_barca_tipo_temporaneo"
               },
               "totale_posti_letto_posti_barca": {
                  "label": "Totale posti letto",
                  "type": "number",
                  "required": true,
                  "notEdit": true,
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                        ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "=="],
                        "&&"
                     ],
                     "value": [
                        "{{capacita_ricettiva-posti_barca-num_posti_letto_in_posti_barca_tipo_temporaneo}}",
                        "{{capacita_ricettiva-posti_barca-num_posti_letto_in_posti_barca_tipo_ordinario}}",
                        "sum"
                     ]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-posti_barca-totale_posti_letto_posti_barca"
               },
            }
         },
         "unita_abitative": {
            "label": "Unità abitative",
            "type": "container",
            "weight": 1,
            "parents": ['unita_abitative'],
            "optional": true,
            "attributes": {
               "class": "card shadow mb-4 col-12"
            },
            "children": {
               "monolocali_f29": {
                  "label": "Monolocali",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive alberghiere", "=="]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-unita_abitative-monolocali_f29"
               },
               "bilocali_attrezzati_per_cucina_f29": {
                  "label": "Bilocali attrezzati per cucina, soggiorno e pernottamento",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive alberghiere", "=="]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-unita_abitative-bilocali_attrezzati_per_cucina_f29"
               },
               "pluri_locali_per_cucina_f29": {
                  "label": "Pluri locali attrezzati per cucina e pernottamento",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive alberghiere", "=="]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-unita_abitative-pluri_locali_per_cucina_f29"
               },
               "totale_ua_f29": {
                  "label": "Totale unità abitative",
                  "type": "number",
                  "required": true,
                  "notEdit": true,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive alberghiere", "=="],
                     "value": [
                        ["{{capacita_ricettiva-unita_abitative-monolocali_f29}}", "{{capacita_ricettiva-unita_abitative-bilocali_attrezzati_per_cucina_f29}}", "sum"],
                        "{{capacita_ricettiva-unita_abitative-pluri_locali_per_cucina_f29}}",
                        "sum"
                     ]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-unita_abitative-totale_ua_f29"
               },
               "posti_letto_standard_UA_f29": {
                  "label": "posti letto standard in camera singola e doppia (A)",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive alberghiere", "=="],
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-unita_abitative-posti_letto_standard_UA_f29"
               },
               "posti_letto_aggiuntivi_UA_f29": {
                  "label": "posti letto aggiuntivi in locali autorizzati (B)",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive alberghiere", "=="],
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-unita_abitative-posti_letto_aggiuntivi_UA_f29"
               },
               "posti_letto_totali_UA_f29": {
                  "label": "Posti letto totali (A+B)",
                  "type": "number",
                  "required": true,
                  "notEdit": true,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive alberghiere", "=="],
                     "value": [
                        "{{capacita_ricettiva-unita_abitative-posti_letto_standard_UA_f29}}",
                        "{{capacita_ricettiva-unita_abitative-posti_letto_aggiuntivi_UA_f29}}",
                        "sum"
                     ]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-unita_abitative-posti_letto_totali_UA_f29"
               },
               "monolocali_f31": {
                  "label": "Monolocali",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-unita_abitative-monolocali_f31"
               },
               "bilocali_attrezzati_per_cucina_f31": {
                  "label": "Bilocali attrezzati per cucina, soggiorno e pernottamento",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-unita_abitative-bilocali_attrezzati_per_cucina_f31"
               },
               "pluri_locali_per_cucina_f31": {
                  "label": "Pluri locali attrezzati per cucina e pernottamento",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-unita_abitative-pluri_locali_per_cucina_f31"
               },
               "totale_ua_f31": {
                  "label": "Totale unità abitative",
                  "type": "number",
                  "required": true,
                  "notEdit": true,
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="],
                        ["{{dati_prelim-classificazione-extra_alberghiere}}", "Affittacamere", "!="],
                        "&&"
                     ],
                     "validate": [["{{dati_prelim-classificazione-extra_alberghiere}}", "Case e appartamenti per vacanze", "=="], { 'min': 3, 'required': true }, "?"],
                     "value": [
                        ["{{capacita_ricettiva-unita_abitative-monolocali_f31}}", "{{capacita_ricettiva-unita_abitative-bilocali_attrezzati_per_cucina_f31}}", "sum"],
                        "{{capacita_ricettiva-unita_abitative-pluri_locali_per_cucina_f31}}",
                        "sum"
                     ]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-unita_abitative-totale_ua_f31"
               },
               "posti_letto_standard_UA_f31": {
                  "label": "posti letto standard in camera singola e doppia (A)",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-unita_abitative-posti_letto_standard_UA_f31"
               },
               "posti_letto_aggiuntivi_UA_f31": {
                  "label": "posti letto aggiuntivi in locali autorizzati (B)",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-unita_abitative-posti_letto_aggiuntivi_UA_f31"
               },
               "posti_letto_totali_UA_f31": {
                  "label": "Posti letto totali (A+B)",
                  "type": "number",
                  "required": true,
                  "notEdit": true,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive extra alberghiere", "=="],
                     "value": ["{{capacita_ricettiva-unita_abitative-posti_letto_standard_UA_f31}}", "{{capacita_ricettiva-unita_abitative-posti_letto_aggiuntivi_UA_f31}}", "sum"]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-unita_abitative-posti_letto_totali_UA_f31"
               },
               "totale_ua_f30": {
                  "label": "Totale unità abitative",
                  "type": "number",
                  "required": true,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-unita_abitative-totale_ua_f30"
               },
               "posti_letto_totali_UA_f30": {
                  "label": "Posti letto totali",
                  "type": "number",
                  "required": true,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-unita_abitative-posti_letto_totali_UA_f30"
               },
               "dotazione_UA": {
                  "label": "Dotazione",
                  "type": "checkbox",
                  "collapsable": true,
                  "collapsed": false,
                  "required": false,
                  "options": {
                     "Riscaldamento": "Riscaldamento",
                     "Aria Condizionata": "Aria Condizionata",
                     "Insonorizzazione": "Insonorizzazione",
                     "Cassetta di sicurezza a muro": "Cassetta di sicurezza a muro",
                     "Telefono abilitato a chiamata diretta esterna": "Telefono abilitato a chiamata diretta esterna",
                     "Telefono non abilitato a chiamata diretta": "Telefono non abilitato a chiamata diretta",
                     "Apparecchio TV": "Apparecchio TV",
                     "Frigo Bar": "Frigo Bar",
                     "Radio": "Radio",
                     "Satellitare": "Satellitare"
                  },
                  "attributes": {
                     "class": "col-12"
                  },
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", ["Aziende ricettive alberghiere", "Aziende ricettive extra alberghiere"], "includes"]
                  },
                  "id": "capacita_ricettiva-unita_abitative-dotazione_UA"
               },
               "altre_dotazioni_UA": {
                  "label": "Altre dotazioni",
                  "type": "checkbox",
                  "collapsable": true,
                  "collapsed": true,
                  "attributes": {
                     "class": "col-12"
                  },
                  "required": false,
                  "options": {
                     "Bagni": "Bagni",
                     "Bagni - Accessori bagno": "Bagni - Accessori bagno",
                     "Bagni - Asciugamani / accappatoi": "Bagni - Asciugamani / accappatoi",
                     "Bagni - Chiamata emergenza dai servizi igienici": "Bagni - Chiamata emergenza dai servizi igienici",
                     "Bagni - Materiale igiene persona": "Bagni - Materiale igiene persona",
                     "Bagni – Asciugacapelli": "Bagni – Asciugacapelli",
                     "Bagni delle UA": "Bagni delle UA",
                     "Bidet": "Bidet",
                     "Camere - Posti letto aggiuntivi": "Camere - Posti letto aggiuntivi",
                     "Camere - Posti letto standard": "Camere - Posti letto standard",
                     "Camere - Vani soggiorno": "Camere - Vani soggiorno",
                     "Camere – Comodino": "Camere – Comodino",
                     "Camere – Lampade": "Camere – Lampade",
                     "Camere – Poltrona": "Camere – Poltrona",
                     "Camere – Punto Luce": "Camere – Punto Luce",
                     "Camere – Scrittoio": "Camere – Scrittoio",
                     "Camere – Sgabello": "Camere – Sgabello",
                     "Camere – Specchio con presa": "Camere – Specchio con presa",
                     "Camere doppie": "Camere doppie",
                     "Camere quadruple": "Camere quadruple",
                     "Camere singole": "Camere singole",
                     "Camere triple": "Camere triple",
                     "Casi di promiscuità": "Casi di promiscuità",
                     "Docce": "Docce",
                     "Documentazione albergo": "Documentazione albergo",
                     "Guardaroba e deposito bagagli": "Guardaroba e deposito bagagli",
                     "Impianto di illuminazione": "Impianto di illuminazione",
                     "Impianto elettrico": "Impianto elettrico",
                     "Impianto idrico": "Impianto idrico",
                     "Impianto prevenzione incendi": "Impianto prevenzione incendi",
                     "Impianto raccolta rifiuti solidi": "Impianto raccolta rifiuti solidi",
                     "Impianto rete fognaria": "Impianto rete fognaria",
                     "Ingresso protetto portico o pensilina": "Ingresso protetto portico o pensilina",
                     "Ingresso separato bagagli": "Ingresso separato bagagli",
                     "Installazioni igenico-sanitarie": "Installazioni igenico-sanitarie",
                     "Lavabi": "Lavabi",
                     "Lavapiedi": "Lavapiedi",
                     "Lavatoi per panni": "Lavatoi per panni",
                     "Lavelli per stoviglie": "Lavelli per stoviglie",
                     "Montacarichi": "Montacarichi",
                     "Montavivande": "Montavivande",
                     "Necessario per cucito": "Necessario per cucito",
                     "Necessario per scrivere": "Necessario per scrivere",
                     "Passaggio pedonale": "Passaggio pedonale",
                     "Persone ospitabili": "Persone ospitabili",
                     "Piazzole": "Piazzole",
                     "Posti letto": "Posti letto",
                     "Posti letto delle UA": "Posti letto delle UA",
                     "Pronto soccorso": "Pronto soccorso",
                     "Suite": "Suite",
                     "Telefono non abilitato a chiamata diretta": "Telefono non abilitato a chiamata diretta",
                     "Telescrivente": "Telescrivente",
                     "UA - Bilocali Attrezzati": "UA - Bilocali Attrezzati",
                     "UA - Monolocali": "UA - Monolocali",
                     "UA - Plurilocali Attrezzati": "UA - Plurilocali Attrezzati",
                     "UA - Posti letto aggiuntivi": "UA - Posti letto aggiuntivi",
                     "UA - Posti letto standard": "UA - Posti letto standard",
                     "UA – Acqua corrente calda e fredda": "UA – Acqua corrente calda e fredda",
                     "Unità abitative": "Unità abitative",
                     "Vuotatoi per WC chimici": "Vuotatoi per WC chimici",
                     "WC": "WC"
                  },
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", ["Aziende ricettive alberghiere", "Aziende ricettive extra alberghiere"], "includes"]
                  },
                  "id": "capacita_ricettiva-unita_abitative-altre_dotazioni_UA"
               }
            },
            "states": {
               "visible": [
                  [[
                     ["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "!="],
                     ["{{dati_prelim-classificazione-tipologia_struttura}}", "Boat and Breakfast", "!="],
                     "&&"
                  ],
                  ["{{dati_prelim-classificazione-extra_alberghiere}}", "Affittacamere", "!="],
                     "&&"
                  ],
                  ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "!="],
                  "&&"
               ]
            },
            "id": "capacita_ricettiva-unita_abitative"
         },
         "dotazioni_marina_resort": {
            "label": "Tipologia posti barca e servizi",
            "type": "container",
            "weight": 1,
            "attributes": {
               "class": "card shadow mb-4 col-12"
            },
            "children": {
               "section_posti_barca": {
                  "label": "Dotazione posti barca",
                  "type": "section",
                  "visible": true,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "=="]
                  },
                  "attributes": {
                     "title": {
                        'class': 'h4 mt-3'
                     },
                     "class": "col-12",
                     "hideSeparator": true
                  },
                  "children": {
                     "num_posti_barca_senza_dotazione": {
                        "label": "N° Posti barca senza alcuna dotazione",
                        "type": "number",
                        "required": true,
                        "states": {
                           "visible": [
                              ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                              ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "=="],
                              "&&"
                           ]
                        },
                        "validators": {
                           "min": 0
                        },
                        "id": "capacita_ricettiva-dotazioni_marina_resort-section_posti_barca-num_posti_barca_senza_dotazione"
                     },
                     "num_posti_barca_acqua_potabile": {
                        "label": "N° Posti barca dotati acqua potabile",
                        "type": "number",
                        "required": true,
                        "states": {
                           "visible": [
                              ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                              ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "=="],
                              "&&"
                           ]
                        },
                        "validators": {
                           "min": 0
                        },
                        "id": "capacita_ricettiva-dotazioni_marina_resort-section_posti_barca-num_posti_barca_acqua_potabile"
                     },
                     "num_posti_barca_energia_elettrica": {
                        "label": "N° Posti barca dotati di impianto di erogazione energia elettrica",
                        "type": "number",
                        "required": true,
                        "states": {
                           "visible": [
                              ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                              ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "=="],
                              "&&"
                           ]
                        },
                        "validators": {
                           "min": 0
                        },
                        "id": "capacita_ricettiva-dotazioni_marina_resort-section_posti_barca-num_posti_barca_energia_elettrica"
                     }

                  },
                  "id": "capacita_ricettiva-dotazioni_marina_resort-section_posti_barca"
               },
               "section_sevizi_generali": {
                  "label": "Dotazione servizi generali",
                  "type": "section",
                  "visible": true,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "=="]
                  },
                  "attributes": {
                     "title": {
                        'class': 'h4 mt-3'
                     },
                     "class": "col-12",
                     "hideSeparator": true
                  },
                  "children": {
                     "num_wc": {
                        "label": "Numero WC",
                        "type": "number",
                        "required": true,
                        "states": {
                           "visible": [
                              ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                              ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "=="],
                              "&&"
                           ]
                        },
                        "validators": {
                           "min": 0
                        },
                        "id": "capacita_ricettiva-dotazioni_marina_resort-section_sevizi_generali-num_wc"
                     },

                     "num_docce_chiuse": {
                        "label": "Numero Docce chiuse",
                        "type": "number",
                        "required": true,
                        "states": {
                           "visible": [
                              ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                              ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "=="],
                              "&&"
                           ]
                        },
                        "validators": {
                           "min": 0
                        },
                        "id": "capacita_ricettiva-dotazioni_marina_resort-section_sevizi_generali-num_docce_chiuse"
                     },

                     "num_lavabi": {
                        "label": "Numero Lavabi",
                        "type": "number",
                        "required": true,
                        "states": {
                           "visible": [
                              ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                              ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "=="],
                              "&&"
                           ]
                        },
                        "validators": {
                           "min": 0
                        },
                        "id": "capacita_ricettiva-dotazioni_marina_resort-section_sevizi_generali-num_lavabi"
                     },

                     "num_lavatrici": {
                        "label": "Numero Lavatrici",
                        "type": "number",
                        "required": true,
                        "states": {
                           "visible": [
                              ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                              ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "=="],
                              "&&"
                           ]
                        },
                        "validators": {
                           "min": 0
                        },
                        "id": "capacita_ricettiva-dotazioni_marina_resort-section_sevizi_generali-num_lavatrici"
                     },

                     "num_asciugatrici": {
                        "label": "Numero Asciugatrici",
                        "type": "number",
                        "required": true,
                        "states": {
                           "visible": [
                              ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                              ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "=="],
                              "&&"
                           ]
                        },
                        "validators": {
                           "min": 0
                        },
                        "id": "capacita_ricettiva-dotazioni_marina_resort-section_sevizi_generali-num_asciugatrici"
                     },
                     "num_vuotato_wc_chimici": {
                        "label": "Numero vuotato wc chimici",
                        "type": "number",
                        "required": true,
                        "states": {
                           "visible": [
                              ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                              ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "=="],
                              "&&"
                           ]
                        },
                        "validators": {
                           "min": 0
                        },
                        "id": "capacita_ricettiva-dotazioni_marina_resort-section_sevizi_generali-num_vuotato_wc_chimici"
                     },
                     "num_posti_auto": {
                        "label": "Numero posti auto",
                        "type": "number",
                        "required": true,
                        "states": {
                           "visible": [
                              ["{{dati_prelim-classificazione-tipologia_struttura}}", "Aziende ricettive all'aria aperta", "=="],
                              ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "=="],
                              "&&"
                           ]
                        },
                        "validators": {
                           "min": 0
                        },
                        "id": "capacita_ricettiva-dotazioni_marina_resort-section_sevizi_generali-num_posti_auto"
                     }
                  },
                  "id": "capacita_ricettiva-dotazioni_marina_resort-section_sevizi_generali"
               },
            },
            "states": {
               "visible": ["{{dati_prelim-classificazione-aria_aperta}}", "Marina Resort", "=="]
            },
            "id": "capacita_ricettiva-dotazioni_marina_resort"
         },
         "ricettivita_bb": {
            "label": "Ricettività",
            "type": "container",
            "weight": 1,
            "attributes": {
               "class": "card shadow mb-4 col-12"
            },
            "children": {
               "tariffa_di_pernottamento_minimo_bb": {
                  "label": "tariffa di pernottamento minimo",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", ["Bed and breakfast", "Boat and Breakfast"], "includes"]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-ricettivita_bb-tariffa_di_pernottamento_minimo_bb"
               },
               "tariffa_di_pernottamento_massimo_bb": {
                  "label": "tariffa di pernottamento massimo",
                  "type": "number",
                  "required": false,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", ["Bed and breakfast", "Boat and Breakfast"], "includes"]
                  },
                  "validators": {
                     "min": 0
                  },
                  "id": "capacita_ricettiva-ricettivita_bb-tariffa_di_pernottamento_massimo_bb"
               },
               "periodo_disponibilita": {
                  "label": "Periodo disponibilità",
                  "type": "date-range",
                  "required": false,
                  "attributes": {
                     "class": "col-12"
                  },
                  // "maxRepeat":1,
                  "states": {
                     "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", ["Bed and breakfast", "Boat and Breakfast"], "includes"]
                  },
                  "parents": ['periodo_disponibilita'],
                  "id": "capacita_ricettiva-ricettivita_bb-periodo_disponibilita"
               },
            },
            "states": {
               "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", ["Bed and breakfast", "Boat and Breakfast"], "includes"]
            },
            "id": "capacita_ricettiva-ricettivita_bb"
         }
      },
      "id": "capacita_ricettiva"
   };
}

const getServizi = function (weight: number, edit) {
   return {
      "type": "container",
      "label": "Servizi",
      "visible": true,
      "weight": weight,
      "attributes": {
         "class": "row"
      },
      "children": {
         "carte_pagamento": {
            "label": "Carte pagamento",
            "type": "container",
            "weight": 0,
            "attributes": {
               "class": "card shadow mb-4 col-12"
            },
            "children": {
               "cartedicredito": {
                  "label": "carte",
                  "type": "checkbox",
                  "collapsable": true,
                  "collapsed": false,
                  "weight": 1,
                  "required": false,
                  "options": {
                     "Americanexpress": "American Express",
                     "Bancomat": "Bancomat",
                     "Cartasi": "CartaSi",
                     "Circuitomaestro": "Circuito Maestro",
                     "Diners": "Diners",
                     "Eurocard": "Eurocard",
                     "Mastercard": "Mastercard",
                     "Visa": "Visa"
                  },
                  "attributes": {
                     "class": "col-12"
                  },
                  "id": "servizi-carte_pagamento-cartedicredito"
               }
            },
            "states": {
               "visible": ["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "!="]
            },
            "id": "servizi-carte_pagamento"
         },
         "servizi": {
            "label": "Servizi",
            "type": "container",
            "attributes": {
               "class": "card shadow mb-4 col-12"
            },
            "children": {
               "descrizione": {
                  "label": "descrizione",
                  "type": "checkbox",
                  "collapsable": true,
                  "collapsed": true,
                  "required": false,
                  "options": {
                     "Accessibilita' disabili": "Accessibilita' disabili",
                     "Accesso ai mezzi pubblici": "Accesso ai mezzi pubblici",
                     "Accesso vetture private": "Accesso vetture private",
                     "Accettazione gruppi": "Accettazione gruppi",
                     "Acconciatore": "Acconciatore",
                     "Addetto disponibile a chiamata": "Addetto disponibile a chiamata",
                     "Altre sale": "Altre sale",
                     "Altro": "Altro",
                     "Ammessi animali domestici": "Ammessi animali domestici",
                     "Angolo cottura": "Angolo cottura",
                     "Animazione": "Animazione",
                     "Aria Condizionata": "Aria Condizionata",
                     "Ascensore": "Ascensore",
                     "Asciugacapelli": "Asciugacapelli",
                     "Atrio": "Atrio",
                     "Autorimessa": "Autorimessa",
                     "Baby Parking": "Baby Parking",
                     "Bar": "Bar",
                     "Biliardo": "Biliardo",
                     "Boutique": "Boutique",
                     "Cambio biancheria": "Cambio biancheria",
                     "Cambio biancheria bagni": "Cambio biancheria bagni",
                     "Cambio biancheria camere / UA": "Cambio biancheria camere / UA",
                     "Camera con balcone": "Camera con balcone",
                     "Camera con veranda": "Camera con veranda",
                     "Camere con giardino": "Camere con giardino",
                     "Camere con terrazza": "Camere con terrazza",
                     "Camere disabili": "Camere disabili",
                     "Camere non fumatori": "Camere non fumatori",
                     "Camere vista mare": "Camere vista mare",
                     "Camere vista panoramica": "Camere vista panoramica",
                     "Camper service": "Camper service",
                     "Campo bocce": "Campo bocce",
                     "Campo calcetto": "Campo calcetto",
                     "Campo da Calcio": "Campo da Calcio",
                     "Campo da Tennis": "Campo da Tennis",
                     "Canoa": "Canoa",
                     "Carte di credito": "Carte di credito",
                     "Cassaforte": "Cassaforte",
                     "Cassetta di sicurezza": "Cassetta di sicurezza",
                     "Centralino telefonico": "Centralino telefonico",
                     "Centro Benessere": "Centro Benessere",
                     "Centro città": "Centro città",
                     "Centro diving": "Centro diving",
                     "Centro Termale": "Centro Termale",
                     "Cinema": "Cinema",
                     "Collegamenti Marittimi": "Collegamenti Marittimi",
                     "Cucina": "Cucina",
                     "Cucina dietetica": "Cucina dietetica",
                     "Cucina internazionale": "Cucina internazionale",
                     "Cucina mediterranea": "Cucina mediterranea",
                     "Cucina tipica": "Cucina tipica",
                     "Cucina vegetariana": "Cucina vegetariana",
                     "Discoteca": "Discoteca",
                     "Distribuzione benzina": "Distribuzione benzina",
                     "Equitazione": "Equitazione",
                     "Forno": "Forno",
                     "Frigorifero": "Frigorifero",
                     "Garage": "Garage",
                     "Giardino": "Giardino",
                     "Giochi per bambini": "Giochi per bambini",
                     "Golf": "Golf",
                     "In campagna": "In campagna",
                     "In collina": "In collina",
                     "In periferia": "In periferia",
                     "Ingresso indipendente": "Ingresso indipendente",
                     "Internet": "Internet",
                     "Insonorizzazione": "Insonorizzazione",
                     "Lavabiancheria": "Lavabiancheria",
                     "Lavanderia": "Lavanderia",
                     "Lavastoviglie": "Lavastoviglie",
                     "Lingua francese": "Lingua francese",
                     "Lingua inglese": "Lingua inglese",
                     "Lingua russa": "Lingua russa",
                     "Lingua spagnola": "Lingua spagnola",
                     "Lingua tedesca": "Lingua tedesca",
                     "Localita' balneari": "Localita' balneari",
                     "Maneggio": "Maneggio",
                     "Market": "Market",
                     "Massaggi": "Massaggi",
                     "MiniBar": "MiniBar",
                     "Miniclub": "Miniclub",
                     "Minigolf": "Minigolf",
                     "Naturismo": "Naturismo",
                     "Nel verde": "Nel verde",
                     "Noleggio articoli sportivi": "Noleggio articoli sportivi",
                     "Noleggio auto": "Noleggio auto",
                     "Noleggio Bici": "Noleggio Bici",
                     "Noleggio bombole e attrezzatura subacquea": "Noleggio bombole e attrezzatura subacquea",
                     "Noleggio ciclomotori": "Noleggio ciclomotori",
                     "Noleggio gommoni/barche": "Noleggio gommoni/barche",
                     "Noleggio natanti": "Noleggio natanti",
                     "Noleggio pattini pedalo": "Noleggio pattini pedalo",
                     "Noleggio quad": "Noleggio quad",
                     "Office piani": "Office piani",
                     "Officina riparazioni": "Officina riparazioni",
                     "Ormeggio natanti": "Ormeggio natanti",
                     "Palestra": "Palestra",
                     "Parcheggio": "Parcheggio",
                     "Parco": "Parco",
                     "Parco Giochi per Bambini": "Parco Giochi per Bambini",
                     "Parrucchiere donna": "Parrucchiere donna",
                     "Parrucchiere uomo": "Parrucchiere uomo",
                     "Piano Bar": "Piano Bar",
                     "Piscina": "Piscina",
                     "Piscina coperta": "Piscina coperta",
                     "Portiere Notturno": "Portiere Notturno",
                     "Prima colazione": "Prima colazione",
                     "Pulizia scarpe": "Pulizia scarpe",
                     "Radio": "Radio",
                     "Ricarica bombole sub": "Ricarica bombole sub",
                     "Rifornimento gas liquido": "Rifornimento gas liquido",
                     "Rimessa auto": "Rimessa auto",
                     "Rimessaggio caravan": "Rimessaggio caravan",
                     "Riscaldamento": "Riscaldamento",
                     "Ristorante": "Ristorante",
                     "Rivendita giornali profumeria": "Rivendita giornali profumeria",
                     "Rivendita souvenir": "Rivendita souvenir",
                     "Rivendita tabacchi": "Rivendita tabacchi",
                     "Roof garden": "Roof garden",
                     "Sala ballo": "Sala ballo",
                     "Sala comune": "Sala comune",
                     "Sala congressi": "Sala congressi",
                     "Sala giochi": "Sala giochi",
                     "Sala lettura": "Sala lettura",
                     "Sala meeting": "Sala meeting",
                     "Sala TV": "Sala TV",
                     "Salotti piani": "Salotti piani",
                     "Sauna": "Sauna",
                     "Sci Montano": "Sci Montano",
                     "Self service": "Self service",
                     "Servizio baby sitter": "Servizio baby sitter",
                     "Servizio bancario": "Servizio bancario",
                     "Servizio cambio": "Servizio cambio",
                     "Servizio deposito valori": "Servizio deposito valori",
                     "Servizio fax": "Servizio fax",
                     "Servizio in camera": "Servizio in camera",
                     "Servizio interpreti": "Servizio interpreti",
                     "Servizio navetta": "Servizio navetta",
                     "Servizio ospitalità": "Servizio ospitalità",
                     "Servizio pulizie": "Servizio pulizie",
                     "Servizio ricevimento / portineria": "Servizio ricevimento / portineria",
                     "Servizio trasferimenti": "Servizio trasferimenti",
                     "Solarium": "Solarium",
                     "Spaccio alimentari": "Spaccio alimentari",
                     "Spiaggia libera": "Spiaggia libera",
                     "Spiaggia Privata": "Spiaggia Privata",
                     "Sport invernali": "Sport invernali",
                     "Sport nautici": "Sport nautici",
                     "Sul mare": "Sul mare",
                     "Tavernetta": "Tavernetta",
                     "Tavola calda": "Tavola calda",
                     "Telefono diretto": "Telefono diretto",
                     "Telefono uso comune": "Telefono uso comune",
                     "Televisione": "Televisione",
                     "Televisore satellitare": "Televisore satellitare",
                     "Tennis tavolo/ping pong": "Tennis tavolo/ping pong",
                     "Terrazze elioterapiche": "Terrazze elioterapiche",
                     "Trasporto bagagli a cura del personale": "Trasporto bagagli a cura del personale",
                     "Trasposto bagagli con carrello": "Trasposto bagagli con carrello",
                     "Ufficio postale": "Ufficio postale",
                     "Vasca Idromassaggio": "Vasca Idromassaggio",
                     "Vela": "Vela",
                     "Vicino Aeroporto": "Vicino Aeroporto",
                     "Vicino al fiume": "Vicino al fiume",
                     "Vicino al lago": "Vicino al lago",
                     "Vicino al mare": "Vicino al mare",
                     "Vicino Ferrovia": "Vicino Ferrovia",
                     "Vicino Mare": "Vicino Mare",
                     "Vicino Porto": "Vicino Porto",
                     "Vicino Stazione": "Vicino Stazione",
                     "Vicino Stazione Bus": "Vicino Stazione Bus",
                     "Wi-fi": "Wi-fi",
                     "Windsurf": "Windsurf",
                     "Zona impianti risalita": "Zona impianti risalita",
                     "Zona ombreggiata": "Zona ombreggiata",
                     "Zona soggiorno": "Zona soggiorno"
                  },
                  "attributes": {
                     "class": "col-12"
                  },
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "!="],
                        ["{{dati_struttura-stelle-stelle_bb}}", "non_classificato", "=="],
                        "||"
                     ]
                  },
                  "id": "servizi-servizi-descrizione"
               },
               "servizi_bb_1": {
                  "label": "descrizione",
                  "type": "checkbox",
                  "collapsable": true,
                  "collapsed": true,
                  "default_value": [
                     "Bagni - Accessori bagno",
                     "Bagni - Materiale igiene persona",
                     "Camere – Specchio con presa",
                     "Bagni - Asciugamani / accappatoi"],
                  "required": false,
                  "notEdit": true,
                  "options": {
                     "Bagni - Accessori bagno": "Bagni - Accessori bagno",
                     "Bagni - Asciugamani / accappatoi": "Bagni - Asciugamani / accappatoi",
                     "Bagni - Materiale igiene persona": "Bagni - Materiale igiene persona",
                     "Camere – Specchio con presa": "Camere – Specchio con presa",
                  },
                  "attributes": {
                     "class": "col-12",
                  },
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "=="],
                        ["{{dati_struttura-stelle-stelle_bb}}", "1stelle", "=="],
                        "&&"
                     ]
                  },
                  "parents": ['dotazione_UA'],
                  "id": "servizi-servizi-servizi_bb_1"
               },
               "servizi_bb_2": {
                  "label": "descrizione",
                  "type": "checkbox",
                  "collapsable": true,
                  "collapsed": true,
                  "default_value": [
                     "Bagni - Accessori bagno",
                     "Bagni - Asciugamani / accappatoi",
                     "Bagni - Materiale igiene persona",
                     "Riscaldamento",
                     "Camere – Specchio con presa",
                     "Camere – Sgabello",
                     "Guardaroba e deposito bagagli",
                  ],
                  "required": false,
                  "notEdit": true,
                  "options": {
                     "Bagni - Accessori bagno": "Bagni - Accessori bagno",
                     "Bagni - Asciugamani / accappatoi": "Bagni - Asciugamani / accappatoi",
                     "Bagni - Materiale igiene persona": "Bagni - Materiale igiene persona",
                     "Riscaldamento": "Riscaldamento",
                     "Camere – Specchio con presa": "Camere – Specchio con presa",
                     "Camere – Sgabello": "Camere – Sgabello",
                     "Guardaroba e deposito bagagli": "Guardaroba e deposito bagagli",
                  },
                  "attributes": {
                     "class": "col-12",
                  },
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "=="],
                        ["{{dati_struttura-stelle-stelle_bb}}", "2stelle", "=="],
                        "&&"
                     ]
                  },
                  "parents": ['dotazione_UA'],
                  "id": "servizi-servizi-servizi_bb_2"
               },
               "servizi_bb_3": {
                  "label": "descrizione",
                  "type": "checkbox",
                  "collapsable": true,
                  "collapsed": true,
                  "default_value": [
                     "Bagni - Accessori bagno",
                     "Bagni - Asciugamani / accappatoi",
                     "Bagni - Materiale igiene persona",
                     "Riscaldamento",
                     "Camere – Specchio con presa",
                     "Camere – Sgabello",
                     "Guardaroba e deposito bagagli",
                     "Aria condizionata"
                  ],
                  "required": false,
                  "notEdit": true,
                  "options": {
                     "Bagni - Accessori bagno": "Bagni - Accessori bagno",
                     "Bagni - Asciugamani / accappatoi": "Bagni - Asciugamani / accappatoi",
                     "Bagni - Materiale igiene persona": "Bagni - Materiale igiene persona",
                     "Riscaldamento": "Riscaldamento",
                     "Camere – Specchio con presa": "Camere – Specchio con presa",
                     "Camere – Sgabello": "Camere – Sgabello",
                     "Guardaroba e deposito bagagli": "Guardaroba e deposito bagagli",
                     "Aria condizionata": "Aria condizionata",
                  },
                  "attributes": {
                     "class": "col-12",
                  },
                  "states": {
                     "visible": [
                        ["{{dati_prelim-classificazione-tipologia_struttura}}", "Bed and breakfast", "=="],
                        ["{{dati_struttura-stelle-stelle_bb}}", "3stelle", "=="],
                        "&&"
                     ]
                  },
                  "parents": ['dotazione_UA'],
                  "id": "servizi-servizi-servizi_bb_3"
               }
            },
            "id": "servizi-servizi"
         }
      },
      "id": "servizi"
   }
}

const getRicerca = function (weight: number, edit) {
   return {
      "label": "Ricerca una struttura",
      "type": "search_step",
      "id": "ricerca",
      "placeholder": "Inserisci il nome della struttura",
      "required": false,
      "autocomplete": {
         'path': '/anagrafeAPI/autocomplete',
         'staticParams': {
            'index': 'struttura'
         },
         'display': ['nome'],
         'startDelay': 300
      },
      "weight": weight,
      "visible": true,
      "attributes": {
         "html": "search_step"
      }
   };
}

const getRiepilogo = function (weight: number, edit) {
   return {
      "label": "Riepilogo",
      "id": "riepilogo",
      "type": "summary",
      "weight": weight,
      "visible": true,
      "attributes": {
         "class": "row"
      }
   };
}

const getNomiStati = function (): Array<String> {
   return ["Afghanistan",
      "Albania",
      "Algeria",
      "Andorra",
      "Angola",
      "Anguilla",
      "Antigua e Barbuda",
      "Arabia Saudita",
      "Argentina",
      "Armenia",
      "Aruba",
      "Australia",
      "Austria",
      "Azerbaigian",
      "Bahamas",
      "Bahrein",
      "Bangladesh",
      "Barbados",
      "Belgio",
      "Belize",
      "Benin",
      "Bermuda",
      "Bhutan",
      "Bielorussia",
      "Bolivia",
      "Bosnia-Erzegovina",
      "Botswana",
      "Brasile",
      "Brunei Darussalam",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cambogia",
      "Camerun",
      "Canada",
      "Capo Verde",
      "Ciad",
      "Cile",
      "Cina",
      "Cipro",
      "Colombia",
      "Comore",
      "Congo",
      "Corea del Nord",
      "Corea del Sud",
      "Costa d'Avorio",
      "Costa Rica",
      "Croazia",
      "Cuba",
      "Curaçao",
      "Danimarca",
      "Dominica",
      "Ecuador",
      "Egitto",
      "El Salvador",
      "Emirati Arabi Uniti",
      "Eritrea",
      "Estonia",
      "Eswatini",
      "Etiopia",
      "Ex Repubblica Jugoslava di Macedonia",
      "Federazione russa",
      "Figi",
      "Filippine",
      "Finlandia",
      "Francia",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germania",
      "Ghana",
      "Giamaica",
      "Giappone",
      "Gibilterra",
      "Gibuti",
      "Giordania",
      "Grecia",
      "Grenada",
      "Groenlandia",
      "Guatemala",
      "Guernsey",
      "Guinea",
      "Guinea equatoriale",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Honduras",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Irlanda",
      "Islanda",
      "Isola di Man",
      "Isole Cayman",
      "Isole Cook (NZ)",
      "Isole Fær Øer",
      "Isole Falkland (Malvine)",
      "Isole Marshall",
      "Isole Pitcairn",
      "Isole Salomone",
      "Isole Turks e Caicos",
      "Isole Vergini britanniche",
      "Israele",
      "Italia",
      "Jersey",
      "Kazakhstan",
      "Kenya",
      "Kirghizistan",
      "Kiribati",
      "Kosovo",
      "Kuwait",
      "Laos",
      "Lesotho",
      "Lettonia",
      "Libano",
      "Liberia",
      "Libia",
      "Liechtenstein",
      "Lituania",
      "Lussemburgo",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldive",
      "Mali",
      "Malta",
      "Marocco",
      "Mauritania",
      "Maurizio",
      "Messico",
      "Moldova",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Montserrat",
      "Mozambico",
      "Myanmar/Birmania",
      "Namibia",
      "Nauru",
      "Nepal",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "Norvegia",
      "Nuova Caledonia",
      "Nuova Zelanda",
      "Oman",
      "Paesi Bassi",
      "Pakistan",
      "Palau",
      "Palestina",
      "Panama",
      "Papua Nuova Guinea",
      "Paraguay",
      "Perù",
      "Polinesia francese",
      "Polonia",
      "Portogallo",
      "Qatar",
      "Regno Unito",
      "Repubblica ceca",
      "Repubblica Centrafricana",
      "Repubblica Democratica del Congo",
      "Repubblica Dominicana",
      "Romania",
      "Ruanda",
      "Sahara occidentale",
      "Saint Kitts e Nevis",
      "Saint Pierre e Miquelon",
      "Saint Vincent e Grenadine",
      "Saint-Barthélemy",
      "Saint-Martin (FR)",
      "Samoa",
      "San Marino",
      "Sant'Elena",
      "Santa Lucia",
      "Sao Tomé e Principe",
      "Sark",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Sint Maarten (NL)",
      "Siria",
      "Slovacchia",
      "Slovenia",
      "Somalia",
      "Spagna",
      "Sri Lanka",
      "Stati Federati di Micronesia",
      "Stati Uniti d'America",
      "Stato della Città del Vaticano",
      "Sud Sudan",
      "Sudafrica",
      "Sudan",
      "Suriname",
      "Svezia",
      "Svizzera",
      "Tagikistan",
      "Taiwan",
      "Tanzania",
      "Terre australi e antartiche francesi",
      "Thailandia",
      "Timor Leste",
      "Togo",
      "Tonga",
      "Trinidad e Tobago",
      "Tunisia",
      "Turchia",
      "Turkmenistan",
      "Tuvalu",
      "Ucraina",
      "Uganda",
      "Ungheria",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Venezuela",
      "Vietnam",
      "Wallis e Futuna",
      "Yemen",
      "Zambia",
      "Zimbabwe"
   ]
}

export const getEditImpresaModel = function (disableValidation) {
   let model = {
      "ricerca": getRicercaImpresa(1),
      "dati_impresa": getDatiImpresa(2, false),
      "riepilogo": getRiepilogo(3, true)
   };
   if (disableValidation) {
      model = removeRequiredValidator(model);
   }
   return model;
}

const getRicercaImpresa = function (weight) {
   return {
      "label": "Ricerca un' impresa",
      "type": "search_step",
      "id": "ricerca",
      "placeholder": "Inserisci il nome dell'impresa",
      "required": false,
      "autocomplete": {
         'path': '/anagrafeAPI/autocompleteImpresa',
         'staticParams': {
            'index': 'impresa'
         },
         'display': ['dataEntry.ragioneSociale'],
         'startDelay': 300
      },
      "weight": weight,
      "visible": true,
      "attributes": {
         "html": "search_step"
      }
   };
}

const getDatiImpresa = function (weight, modal) {
   return {
      "type": "container",
      "label": "Dati impresa",
      "visible": true,
      "weight": weight,
      "attributes": {
         "class": modal ? "row" : "row ml-1"
      },
      "children": {
         "info_titolare": {
            "label": "Dati titolare",
            "type": "container",
            "weight": 1,
            "attributes": {
               "class": "card shadow mb-4 col-12"
            },
            "children": {
               "id": {
                  "parents": ['idImpresa'],
                  "label": "idimpresa",
                  "type": "input",
                  "id": "dati_impresa-info_titolare-id",
                  "visible": false,
                  "disabled": false
               },
               "cognome_titolare": {
                  "parents": ['rappresentante', 'cognomeRappr'],
                  "label": "Cognome",
                  "type": "input",
                  "required": true,
                  "id": "dati_impresa-info_titolare-cognome_titolare",
                  "validators": {
                     "pattern": '^[A-Za-zÀ-ÖØ-öø-ÿ]+[\']*[A-Za-zÀ-ÖØ-öø-ÿ][A-Za-zÀ-ÖØ-öø-ÿ ]*$'
                  },
               },
               "nome_titolare": {
                  "parents": ['rappresentante', 'nomeRappr'],
                  "label": "Nome",
                  "type": "input",
                  "required": true,
                  "id": "dati_impresa-info_titolare-nome_titolare",
                  "validators": {
                     "pattern": '^[A-Za-zÀ-ÖØ-öø-ÿ]+[\']*[A-Za-zÀ-ÖØ-öø-ÿ][A-Za-zÀ-ÖØ-öø-ÿ ]*$'
                  },
               },
               "data_nascita_titolare": {
                  "parents": ['rappresentante', 'dataNascita'],
                  "label": "Data di nascita",
                  "type": "date",
                  "required": true,
                  "id": "dati_impresa-info_titolare-data_nascita_titolare"
               },
               "stato_nascita": {
                  "label": "Stato nascita",
                  "type": "textfield",
                  "parents": ['rappresentante', 'statoNascita'],
                  "default_value": "Italia",
                  "required": true,
                  "visible": true,
                  "autocomplete": {
                     'local': true,
                     'startDelay': 300,
                     "data": getNomiStati(),
                  },
                  "id": "dati_impresa-info_titolare-stato_nascita"
               },
               "provincia_nascita": {
                  "label": "Provincia di nascita",
                  "type": "select",
                  "parents": ['rappresentante', 'provinciaNascita'],
                  "required": true,
                  "optionsUpdate": '/anagrafeAPI/fetchProvinceItalia',
                  "display": 'provincia',
                  "value": true,
                  "visible": true,
                  "options": {
                  },
                  "key": "sigla",
                  "states": {
                     "visible": [
                        ["{{dati_impresa-info_titolare-comune_nascita}}", { "custom": true, "comune": "ALTRO" }, "!="],
                        ["{{dati_impresa-info_titolare-stato_nascita}}", "Italia", "=="],
                        "&&"
                     ],
                     "disabled": true,
                     "reset": ["{{dati_impresa-info_titolare-comune_nascita}}", "isNotDefined"],
                     "value": [["{{dati_impresa-info_titolare-comune_nascita}}", "isDefined"], ["{{dati_impresa-info_titolare-comune_nascita}}", "provincia", "get"], "?"]
                  },
                  "id": "dati_impresa-info_titolare-provincia_nascita"
               },
               "comune_nascita": {
                  "label": "Comune di nascita",
                  "type": "textfield",
                  "parents": ['rappresentante', 'comuneNascita'],
                  "required": true,
                  "visible": true,
                  "autocomplete": {
                     'path': '/anagrafeAPI/autocompleteComuni',
                     'display': ['comune'],
                     'startDelay': 300,
                  },
                  "fixedOptions": [{
                     "custom": true,
                     "comune": "ALTRO"
                  }
                  ],
                  "states": {
                     "visible": ["{{dati_impresa-info_titolare-stato_nascita}}", "Italia", "=="],
                     "reset": ["{{dati_impresa-info_titolare-stato_nascita}}", "Italia", "!="],
                  },
                  "id": "dati_impresa-info_titolare-comune_nascita"
               },
               "comune_estero_nascita": {
                  "label": "Comune estero di nascita",
                  "type": "input",
                  "parents": ['rappresentante', 'comuneEstero'],
                  "required": true,
                  "states": {
                     "visible": [
                        ["{{dati_impresa-info_titolare-stato_nascita}}", "isDefined"],
                        ["{{dati_impresa-info_titolare-stato_nascita}}", "Italia", "!="],
                        "&&"
                     ],
                     "reset": ["{{dati_impresa-info_titolare-stato_nascita}}", "Italia", "=="],
                  },
                  "id": "dati_impresa-info_titolare-comune_estero_nascita"
               },
               "altro_comune_nascita": {
                  "label": "Altro comune di nascita",
                  "type": "input",
                  "parents": ['rappresentante', "altroComune"],
                  "required": true,
                  "states": {
                     "visible": [
                        ["{{dati_impresa-info_titolare-comune_nascita}}", { "custom": true, "comune": "ALTRO" }, "=="],
                        ["{{dati_impresa-info_titolare-stato_nascita}}", "Italia", "=="],
                        "&&"
                     ],
                     "reset": [
                        { "dati_impresa-info_titolare-comune_nascita": { "operator": "isNotDefined" } }
                     ]
                  },
                  "id": "dati_impresa-info_titolare-altro_comune_nascita"
               },
               "codice_fiscale_titolare": {
                  "parents": ['rappresentante', 'codiceFiscale'],
                  "label": "Codice Fiscale",
                  "type": "input",
                  "required": true,
                  "validators": {
                     "pattern": '^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$'
                  },
                  "id": "dati_impresa-info_titolare-codice_fiscale_titolare"
               },
               "cittadinanza_titolare": {
                  "parents": ['rappresentante', 'cittadinanza'],
                  "label": "Cittadinanza ",
                  "type": "input",
                  "required": true,
                  "id": "dati_impresa-info_titolare-cittadinanza_titolare"
               },
               "documento_sogg_titolare": {
                  "parents": ['rappresentante', 'documentoPermessoSoggiorno'],
                  "label": "Documento di soggiorno (se cittadino non UE)",
                  "type": "input",
                  "required": false,
                  "id": "dati_impresa-info_titolare-documento_sogg_titolare"
               },
               "stato_residenza": {
                  "label": "Stato residenza",
                  "type": "textfield",
                  "parents": ['rappresentante', 'statoResidenza'],
                  "default_value": "Italia",
                  "required": true,
                  "visible": true,
                  "autocomplete": {
                     'local': true,
                     'startDelay': 300,
                     "data": getNomiStati(),
                  },
                  "id": "dati_impresa-info_titolare-stato_residenza"
               },
               "provincia_residenza_titolare": {
                  "parents": ['rappresentante', 'provinciaResidenza'],
                  "label": "Provincia di residenza",
                  "type": "select",
                  "required": true,
                  "disabled": true,
                  "optionsUpdate": '/anagrafeAPI/fetchProvinceItalia',
                  "key": "sigla",
                  "display": 'provincia',
                  "value": true,
                  "options": {
                  },
                  "states": {
                     "visible": [
                        ["{{dati_impresa-info_titolare-comune_residenza_titolare}}", { "custom": true, "comune": "ALTRO" }, "!="],
                        ["{{dati_impresa-info_titolare-stato_residenza}}", "Italia", "=="],
                        "&&"
                     ],
                     "value": [
                        ["{{dati_impresa-info_titolare-comune_residenza_titolare}}", "isDefined"],
                        ["{{dati_impresa-info_titolare-comune_residenza_titolare}}", "provincia", "get"],
                        "?"
                     ],
                     "reset": ["{{dati_impresa-info_titolare-comune_residenza_titolare}}", "isNotDefined"],
                     "disabled": true
                  },
                  "id": "dati_impresa-info_titolare-provincia_residenza_titolare"
               },
               "comune_residenza_titolare": {
                  "parents": ['rappresentante', 'comuneResidenza'],
                  "label": "Comune di residenza",
                  "type": "textfield",
                  "required": true,
                  "autocomplete": {
                     'path': '/anagrafeAPI/autocompleteComuni',
                     'display': ['comune'],
                     'startDelay': 300
                  },
                  "fixedOptions": [{
                     "custom": true,
                     "comune": "ALTRO"
                  }
                  ],
                  "states": {
                     "visible": ["{{dati_impresa-info_titolare-stato_residenza}}", "Italia", "=="],
                     "reset": ["{{dati_impresa-info_titolare-stato_residenza}}", "Italia", "!="],
                  },
                  "id": "dati_impresa-info_titolare-comune_residenza_titolare"
               },
               "comune_estero_residenza": {
                  "label": "Comune estero di residenza",
                  "type": "input",
                  "parents": ['rappresentante', 'comuneEsteroResidenza'],
                  "required": true,
                  "states": {
                     "visible": [
                        ["{{dati_impresa-info_titolare-stato_residenza}}", "isDefined"],
                        ["{{dati_impresa-info_titolare-stato_residenza}}", "Italia", "!="],
                        "&&"
                     ],
                     "reset": ["{{dati_impresa-info_titolare-stato_residenza}}", "Italia", "=="],
                  },
                  "id": "dati_impresa-info_titolare-comune_estero_residenza"
               },
               "altro_comune_residenza": {
                  "label": "Altro comune di residenza",
                  "type": "input",
                  "parents": ['rappresentante', "altroComuneResidenza"],
                  "required": true,
                  "states": {
                     "visible": [
                        ["{{dati_impresa-info_titolare-comune_residenza_titolare}}", { "custom": true, "comune": "ALTRO" }, "=="],
                        ["{{dati_impresa-info_titolare-stato_residenza}}", "Italia", "=="],
                        "&&"
                     ],
                     "reset": [
                        { "dati_impresa-info_titolare-comune_residenza_titolare": { "operator": "isNotDefined" } }
                     ]
                  },
                  "id": "dati_impresa-info_titolare-altro_comune_residenza"
               },
               "cap_residenza_titolare": {
                  "parents": ['rappresentante', 'capResidenza'],
                  "label": "CAP residenza",
                  "type": "input",
                  "required": true,
                  "validators": {
                     "pattern": '[0-9]{5}'
                  },
                  "id": "dati_impresa-info_titolare-cap_residenza_titolare"
               },
               "indirizzo_residenza_titolare": {
                  "parents": ['rappresentante', 'indirizzoResidenza'],
                  "label": "Indirizzo residenza",
                  "type": "input",
                  "required": true,
                  "id": "dati_impresa-info_titolare-indirizzo_residenza_titolare"
               },
               "telefono_titolare": {
                  "parents": ['rappresentante', 'telefono'],
                  "label": "Telefono",
                  "type": "input",
                  "required": false,
                  "id": "dati_impresa-info_titolare-telefono_titolare",
                  "validators": {
                     "pattern": '^[+]*[0-9]{1,4}[ \/0-9]*$'
                  },
               },
               "email_titolare": {
                  "parents": ['rappresentante', 'email'],
                  "label": "Email",
                  "type": "input",
                  "required": false,
                  "validators": {
                     "pattern": '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
                  },
                  "id": "dati_impresa-info_titolare-email_titolare"
               },
            },
            "id": "dati_impresa-info_titolare"
         },
         "info_impresa": {
            "label": "Dati azienda",
            "type": "container",
            "weight": 1,
            "attributes": {
               "styles": "",
               "class": "card shadow mb-4 col-12",
               "html": ""
            },
            "children": {
               "denominazione_impresa": {
                  "parents": ['ragioneSociale'],
                  "label": "Denominazione",
                  "type": "input",
                  "required": true,
                  "id": "dati_impresa-info_impresa-denominazione_impresa"
               },
               "forma_giuridica": {
                  "label": "Forma giuridica",
                  "parents": ['formaGiuridica', 'sigla'],
                  "type": "select",
                  "required": false,
                  "options": {
                     "A.r.l.": "A.r.l.",
                     "D.I.": "D.I.",
                     "S.a.s.": "S.a.s.",
                     "S.d.f.": "S.d.f.",
                     "S.n.c.": "S.n.c.",
                     "S.p.a.": "S.p.a.",
                     "S.r.l.": "S.r.l.",
                     "Soc. Coop.": "Soc. Coop.",
                     "p.c.a.": "p.c.a."
                  },
                  "id": "dati_impresa-info_impresa-forma_giuridica"
               },
               "codice_fiscale_impresa": {
                  "parents": ['codiceFiscale'],
                  "label": "Codice Fiscale",
                  "type": "input",
                  "required": true,
                  "id": "dati_impresa-info_impresa-codice_fiscale_impresa",
                  "states": {
                     "required": ["{{dati_impresa-info_impresa-partita_iva}}", "isNotDefined"]
                  },
                  "validators": {
                     "pattern": '^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$'
                  },
               },
               "partita_iva": {
                  "label": "Partita Iva",
                  "parents": ['partitaIva'],
                  "type": "input",
                  "required": true,
                  "id": "dati_impresa-info_impresa-partita_iva",
                  "states": {
                     "required": ["{{dati_impresa-info_impresa-codice_fiscale_impresa}}", "isNotDefined"]
                  },
                  "validators": {
                     "pattern": '^[0-9]{11}$'
                  }
               },
               "provincia_impresa": {
                  "parents": ['indirizzo', 'provincia'],
                  "label": "Provincia (Sede legale)",
                  "type": "select",
                  "required": false,
                  "disabled": true,
                  "optionsUpdate": '/anagrafeAPI/fetchProvinceItalia',
                  "key": "sigla",
                  "display": 'provincia',
                  "value": true,
                  "options": {
                  },
                  "states": {
                     "value": [
                        ["{{dati_impresa-info_impresa-comune_impresa}}", "isDefined"],
                        ["{{dati_impresa-info_impresa-comune_impresa}}", "provincia", "get"],
                        "?"
                     ],
                     "reset": ["{{dati_impresa-info_impresa-comune_impresa}}", "isNotDefined"],
                     "disabled": true,
                  },
                  "id": "dati_impresa-info_impresa-provincia_impresa"
               },
               "comune_impresa": {
                  "label": "Comune (Sede legale)",
                  "parents": ['indirizzo', 'comune'],
                  "type": "textfield",
                  "required": true,
                  "autocomplete": {
                     'path': '/anagrafeAPI/autocompleteComuni',
                     'display': ['comune'],
                     'startDelay': 300
                  },
                  "id": "dati_impresa-info_impresa-comune_impresa"
               },
               "indirizzo_impresa": {
                  "label": "Indirizzo",
                  "parents": ['indirizzo', 'indirizzo'],
                  "type": "input",
                  "required": false,
                  "id": "dati_impresa-info_impresa-indirizzo_impresa"
               },
               "cap_impresa": {
                  "parents": ['indirizzo', 'cap'],
                  "label": "CAP",
                  "type": "input",
                  "required": false,
                  "validators": {
                     "pattern": '[0-9]{5}'
                  },
                  "id": "dati_impresa-info_impresa-cap_impresa"
               },
               "telefono_impresa": {
                  "parents": ['contatti', 'telefono'],
                  "label": "Telefono",
                  "type": "input",
                  "required": false,
                  "id": "dati_impresa-info_impresa-telefono_impresa",
                  "validators": {
                     "pattern": '^[+]*[0-9]{1,4}[ \/0-9]*$'
                  },
               },
               "fax_impresa": {
                  "label": "Fax",
                  "parents": ['contatti', 'fax'],
                  "type": "input",
                  "required": false,
                  "validators": {
                     "pattern": '^[+]*[0-9]{1,4}[ \/0-9]*$'
                  },
                  "id": "dati_impresa-info_impresa-fax_impresa"
               },
               "cellulare_impresa": {
                  "parents": ['contatti', 'cellulare'],
                  "label": "Cellulare",
                  "type": "input",
                  "required": false,
                  "validators": {
                     "pattern": '^[+]*[0-9]{1,4}[ \/0-9]*$'
                  },
                  "id": "dati_impresa-info_impresa-cellulare_impresa"
               },
               "email_impresa": {
                  "parents": ['contatti', 'email'],
                  "label": "Email",
                  "type": "input",
                  "required": false,
                  "id": "dati_impresa-info_impresa-email_impresa",
                  "validators": {
                     "pattern": '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
                  },
               },
               "note_impresa": {
                  "label": "Note",
                  "parents": ['note'],
                  "type": "textarea",
                  "required": false,
                  "id": "dati_impresa-info_impresa-note_impresa"
               },
               "pec": {
                  "parents": ['contatti', 'pec'],
                  "label": "Pec",
                  "type": "input",
                  "required": false,
                  "validators": {
                     "pattern": '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
                  },
                  "id": "dati_impresa-info_impresa-pec"
               },
            },
            "id": "dati_impresa-info_impresa"
         }
      },
      "id": "dati_impresa"
   }
}

const addRequiredStateToModel = function (model, stateExpression) {
   const elementKey = Object.keys(model);
   elementKey.forEach(key => {
      let element = model[key];
      if (LEAF_TYPES.includes(element.type)) {
         if (!!element.required) {
            if (!!element.states && !!element.states.required) {
               element.states.required = [JSON.parse(JSON.stringify(stateExpression)), element.states.required, "&&"];
            } else if (element.states && !element.states.required) {
               element.states.required = JSON.parse(JSON.stringify(stateExpression));
            } else {
               element.states = {
                  required: JSON.parse(JSON.stringify(stateExpression))
               }
            }
         }
      } else if (CONTAINER_TYPES.includes(element.type) && element.children) {
         addRequiredStateToModel(element.children, stateExpression);
      }
   });
   return model;
}

const removeRequiredValidator = (model) => {
   const elementKey = Object.keys(model);
   elementKey.forEach(key => {
      let element = model[key];
      if (LEAF_TYPES.includes(element.type)) {
         if (element.required) {
            element.required = false;
         }
         if (element.states && !element.states.required) {
            delete element.states.required;
         }
      } else if (CONTAINER_TYPES.includes(element.type) && element.children) {
         removeRequiredValidator(element.children);
      }
   });
   return model;
}

