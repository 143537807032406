import { Injectable } from "@angular/core";
import { Effect, Actions } from "@ngrx/effects";
import { UserActions } from "./user.actions";
import { map, switchMap, catchError, tap, withLatestFrom, filter, delay } from "rxjs/operators";
import { UserRequests } from "./user.request";
import { of, merge } from "rxjs";
import { UserSelectors } from "./user.selectors";
import { Router } from "@angular/router";
import { GenericActions } from "../generic";
import { saveAs } from 'file-saver';
import { Location } from "@angular/common";
import { SearchActions } from "../search";
import { AppFlowActions } from '../app-flow/app-flow.actions';
@Injectable()
export class UserEffects {
    constructor(
        private actions$: Actions,
        private userActions$: UserActions,
        private searchActions$: SearchActions,
        private userRequests$: UserRequests,
        private userSelectors$: UserSelectors,
        private genericActions$: GenericActions,
        private appFlowActions$: AppFlowActions,
        private router: Router,
        private location: Location
    ) { }

    @Effect() login$ = this.actions$.ofType(UserActions.USER_LOGIN)
        .pipe(
            map(action => action['payload']),
            switchMap((code) => this.userRequests$.requestLogin$(code).
                pipe(
                    map(response => response['status'] === 'OK' ?
                        this.userActions$.userLoginSuccess(response['payload']) :
                        this.userActions$.userLoginFail('Utente non trovato'))
                )
            ),
            catchError(err => [this.userActions$.userLoginFail('Errore durante la procedura di login.')])
        );

    @Effect() checkLogin$ = this.actions$.ofType(UserActions.USER_CHECK_LOGIN)
        .pipe(
            map(action => action['payload']),
            switchMap(() => this.userRequests$.requestIsLogged$()
                .pipe(
                    map(response => {
                        if (response['status'] === 'OK') {
                            if (typeof response['payload'] !== 'undefined' && typeof response['payload'].message === 'undefined') {
                                return this.userActions$.userCheckLoginSucess(response['payload']);
                            } else if (typeof response['payload'] !== 'undefined' && typeof response['payload'].message !== 'undefined') {
                                return this.userActions$.userCheckLoginFail('Utente non loggato.');
                            } else {
                                return this.genericActions$.showErrorModal({
                                    title: 'Errore',
                                    message: 'Impossibile ripristinare le sessione...',
                                    buttons: [{ id: 'close', label: 'Chiudi', class: '', action: '', autoclose: true }]
                                });
                            }
                        } else {
                            return this.userActions$.userCheckLoginFail('Impossibile ripristinare la sessione. Ripeti la procedura di autenticazione');
                        }
                    }
                    )
                )
            ),
            catchError(err => [this.userActions$.userCheckLoginFail('Errore durante la verifica della sessione.')])
        );

    @Effect() logout$ = this.actions$.ofType(UserActions.USER_LOGOUT)
        .pipe(
            withLatestFrom(this.userSelectors$.getUser$()),
            switchMap(([, user]) => this.userRequests$.requestLogout$()
                .pipe(
                    switchMap(response => response['status'] === 'OK' ?
                        of(this.userActions$.userLogoutSuccess()) :
                        of(this.userActions$.userLogoutFail(response))
                    )
                )
            ),
            catchError(err => [this.userActions$.userLogoutFail('Errore durante la procedura di logout')])
        );

    @Effect({ dispatch: false }) loginSucces$ = this.actions$.ofType(UserActions.USER_LOGIN_SUCCESS)
        .pipe(
            map(action => action['payload']),
            tap((payload) => { localStorage.setItem('user', payload['user']['name']); localStorage.setItem('roles', JSON.stringify(payload['user']['roles'])); }),
            tap(() => this.router.navigate(['/home'], { queryParams: {} }))
        );

    @Effect({ dispatch: false }) checkloginSucces$ = this.actions$.ofType(UserActions.USER_CHECK_LOGIN_SUCCESS)
        .pipe(
            map(action => action['payload']),
            tap((payload) => { localStorage.setItem('user', payload['user']['name']); localStorage.setItem('roles', JSON.stringify(payload['user']['roles'])); }),
        );

    @Effect({ dispatch: false }) loginFail$ = merge(
        this.actions$.ofType(UserActions.USER_LOGIN_FAIL),
        this.actions$.ofType((UserActions.USER_CHECK_LOGIN_FAIL))
    ).pipe(
        map(action => action['payload']),
        tap(() => { localStorage.removeItem('user'); localStorage.removeItem('roles'); }),
        // tap( () => this.router.navigate( ['/home'], { queryParams: {} } ) )
    );

    @Effect({ dispatch: false }) logoutSuccess$ = this.actions$.ofType(UserActions.USER_LOGOUT_SUCCESS)
        .pipe(
            tap((token) => { localStorage.removeItem('user'); localStorage.removeItem('roles') }),
            tap(() => this.router.navigate(['/home'], { queryParams: {} }))
        );

    @Effect() userActionFails$ = merge(
        this.actions$.ofType(UserActions.USER_LOGOUT_FAIL),
        this.actions$.ofType(UserActions.USER_LOGIN_FAIL),
        this.actions$.ofType(UserActions.USER_DOWNLOAD_FILE_FAIL),
        this.actions$.ofType(UserActions.USER_UPLOAD_FILE_FAIL),
        this.actions$.ofType(UserActions.USER_EDIT_IMPRESA_FAIL),
        this.actions$.ofType(UserActions.EDIT_IMPRESA_FROM_MODAL_FAIL),
        this.actions$.ofType(UserActions.USER_INSERT_STRUTTURA_FAIL),
        this.actions$.ofType(UserActions.USER_EDIT_STRUTTURA_FAIL),
        this.actions$.ofType(UserActions.USER_CHECK_DUPLICATI_FAIL)
    ).pipe(
        map(action => action['payload']),
        filter(payload => payload !== null && typeof payload !== 'undefined'),
        map(err => this.genericActions$.showErrorModal({
            title: 'Errore',
            message: err,
            buttons: [{ id: 'close', label: 'Chiudi', class: '', action: '', autoclose: true }]
        })
        )
    );


    @Effect() userSessionExpired$ = merge(
        this.actions$.ofType(UserActions.USER_DOWNLOAD_FILE_FAIL),
        this.actions$.ofType(UserActions.USER_UPLOAD_FILE_FAIL),
        this.actions$.ofType(UserActions.USER_EDIT_IMPRESA_FAIL),
        this.actions$.ofType(UserActions.EDIT_IMPRESA_FROM_MODAL_FAIL),
        this.actions$.ofType(UserActions.USER_INSERT_STRUTTURA_FAIL),
        this.actions$.ofType(UserActions.USER_EDIT_STRUTTURA_FAIL),
        this.actions$.ofType(SearchActions.SEARCH_FAIL),
        this.actions$.ofType(SearchActions.SEARCH_FULL_TEXT_FAIL)
    ).pipe(
        map(action => action['payload']),
        filter(payload => payload !== null && typeof payload === 'string' && payload.includes('autenticato')),
        map(err => this.userActions$.userLogoutSuccess())
    );

    @Effect() uploadEffect$ = this.actions$.ofType(UserActions.USER_UPLOAD_FILE)
        .pipe(
            map(action => action['payload']),
            switchMap(payload => this.userRequests$.requestPostFile$(payload.data).
                pipe(
                    switchMap(
                        (res) => {
                            if (res['status'] === 'OK') {
                                return of(this.userActions$.userUploadFileSuccess({ payload: res['payload'], ref: payload.ref }));
                            } else if (res['status'] === 'NOK' && res['payload']['message'] === 'Sessione scaduta') {
                                return of(this.userActions$.userUploadFileFail('Sessione scaduta. Effettua nuovamente il login.'));
                            } else {
                                return of(this.userActions$.userUploadFileFail('Impossibile eseguire l\'upload del file.'));
                            }
                        }
                    ),
                    catchError(err => [this.userActions$.userUploadFileFail('Impossibile eseguire l\'upload del file.')])
                )
            ),
        );

    @Effect({ 'dispatch': false }) uploadSuccessEffect$ = this.actions$.ofType(UserActions.USER_UPLOAD_FILE_SUCCESS)
        .pipe(
            map(action => action['payload']),
            tap(payload => {
                if (payload.ref && payload.ref.type === 'file') {
                    if (typeof payload.ref.value === 'undefined') {
                        payload.ref.value = {}
                    }
                    payload.ref.value[payload.payload.id] = payload.payload;
                }
            })
        );

    @Effect() downloadEffect$ = this.actions$.ofType(UserActions.USER_DOWNLOAD_FILE)
        .pipe(
            map(action => action['payload']),
            switchMap(payload => this.userRequests$.requestDownloadFile$(payload.id)
                .pipe(
                    switchMap(
                        (res) => {
                            if (res['status'] === 'OK') {
                                return of(this.userActions$.userDownloadFileSuccess({ data: res, filename: payload.fileName }));
                            } else if (res['status'] === 'NOK' && res['payload']['message'] === 'Sessione scaduta') {
                                return of(this.userActions$.userDownloadFileFail('Sessione scaduta. Effettua nuovamente il login.'));
                            } else {
                                return of(this.userActions$.userDownloadFileFail('Errore durante il downoload del file.'));
                            }
                        }
                    ),
                    catchError(err => [this.userActions$.userDownloadFileFail('Errore durante il downoload del file.')])
                )
            ),
        );

    @Effect({ dispatch: false }) downloadSuccess$ = this.actions$.ofType(UserActions.USER_DOWNLOAD_FILE_SUCCESS)
        .pipe(
            map(action => action['payload']),
            tap(payload => this.downLoadFile(payload.data, 'text/plain', payload.filename))
        );

    @Effect() doNewStruttura$ = this.actions$.ofType(UserActions.USER_INSERT_STRUTTURA)
        .pipe(
            map(action => action['payload']),
            switchMap(payload => this.userRequests$.requestInsertStruttura$(payload)
                .pipe(
                    switchMap(
                        (res) => {
                            if (res['status'] === 'OK') {
                                return of(this.userActions$.insertStrutturaSuccess(res));
                            } else if (res['status'] === 'NOK' && res['payload']['message'] === 'Utene non autenticato') {
                                return of(this.userActions$.insertStrutturaFail('Sessione scaduta. Effettua nuovamente il login.'));
                            } else {
                                return of(this.userActions$.insertStrutturaFail('Errore nell\' inserimento della struttura'));
                            }
                        }
                    ),
                    catchError(err => [this.userActions$.insertStrutturaFail('Errore nell\' inserimento della struttura')])
                )
            )
        );

    @Effect({ dispatch: false }) doNewStrutturaSuccess$ =
        this.actions$.ofType(UserActions.USER_INSERT_STRUTTURA_SUCCESS)
            .pipe(
                map(action => action['payload']),
                tap((payload) => { this.router.navigate(['/edit'], { queryParams: { id: payload.payload.id, type: 'strut', step: 'riepilogo' } }); }),
            );

    @Effect() doEditStruttura$ = this.actions$.ofType(UserActions.USER_EDIT_STRUTTURA)
        .pipe(
            map(action => action['payload']),
            switchMap(payload => this.userRequests$.requestEditStruttura$(payload)
                .pipe(
                    switchMap(
                        (res) => {
                            if (res['status'] === 'OK') {
                                return of(this.userActions$.editStrutturaSuccess(res));
                            } else if (res['status'] === 'NOK' && res['payload']['message'] === 'Utente non autenticato') {
                                return of(this.userActions$.editStrutturaFail('Sessione scaduta. Effettua nuovamente il login.'));
                            } else {
                                return of(this.userActions$.editStrutturaFail('Errore durante la modifica della struttura'));
                            }
                        }
                    ),
                    catchError(err => [this.userActions$.editStrutturaFail('Errore durante la modifica della struttura')])
                )
            )
        );

    @Effect() doEditImpresa$ = this.actions$.ofType(UserActions.USER_EDIT_IMPRESA)
        .pipe(
            map(action => action['payload']),
            switchMap(payload => this.userRequests$.requestEditImpresa$(payload)
                .pipe(
                    switchMap((res) => {
                        if (res['status'] === 'OK') {
                            return of(this.userActions$.editImpresaSuccess(res['payload']));
                        } else if (res['status'] === 'NOK' && res['payload']['message'] === 'Utente non autenticato') {
                            return of(this.userActions$.editImpresaFail('Sessione scaduta. Effettua nuovamente il login.'));
                        } else {
                            return of(this.userActions$.editImpresaFail('Errore nella modifica dell\' impresa'));
                        }
                    }
                    ),
                    catchError(err => [this.userActions$.editImpresaFail('Errore nella modifica dell\' impresa')])
                )
            )
        );

    @Effect() doEditFromModal$ = this.actions$.ofType(UserActions.EDIT_IMPRESA_FROM_MODAL)
        .pipe(
            map(action => action['payload']),
            switchMap(payload => this.userRequests$.requestEditImpresa$(payload.dataEntry)
                .pipe(
                    switchMap((res) => {
                        if (res['status'] === 'OK') {
                            return of(this.userActions$.editImpresaFromModalSuccess({ ref: payload.ref, result: { dataEntry: payload.dataEntry, id: payload.dataEntry.idImpresa }, field: payload.field }));
                        } else if (res['status'] === 'NOK' && res['payload']['message'] === 'Utente non autenticato') {
                            return of(this.userActions$.editImpresaFromModalFail('Sessione scaduta. Effettua nuovamente il login.'));
                        } else {
                            return of(this.userActions$.editImpresaFromModalFail('Errore nella modifica dell\' impresa'));
                        }
                    }
                    ),
                    catchError(err => { console.log(err); return [this.userActions$.editImpresaFromModalFail('Errore nella modifica dell\' impresa.')] })
                )
            )
        );

    @Effect() doEditFromModalSuccess$ = this.actions$.ofType(UserActions.EDIT_IMPRESA_FROM_MODAL_SUCCESS)
        .pipe(
            map(action => action['payload']),
            map(res => this.genericActions$.hideEditModal(res))
        );

    @Effect() doSoftDelete$ = this.actions$.ofType(UserActions.USER_DELETE_STRUTTURA)
        .pipe(
            map(action => action['payload']),
            switchMap(payload => this.userRequests$.requesDeleteStruttura$(payload).pipe(
                switchMap((res) => {
                    if (res['status'] === 'OK') {
                        return of(this.userActions$.deleteStrutturaSuccess());
                    } else if (res['status'] === 'NOK' && res['payload']['message'] === 'Utente non autenticato') {
                        return of(this.userActions$.deleteStrutturaFail('Sessione scaduta. Effettua nuovamente il login.'));
                    } else {
                        return of(this.userActions$.deleteStrutturaFail('Impossibile cancellare la struttura.'));
                    }
                }), delay(4000))
            )
        );

    @Effect() restartSearch$ = this.actions$.ofType(UserActions.USER_DELETE_STRUTTURA_SUCCESS)
        .pipe(
            map(action => this.searchActions$.searchStart())
        );

    @Effect({ dispatch: false }) doEditImpresaSuccess$ = this.actions$.ofType(UserActions.USER_EDIT_IMPRESA_SUCCESS)
        .pipe(
            map(action => action['payload']),
            // map(payload => this.appFlowActions$.setCurrentStep('riepilogo'))
            tap(payload => this.router.navigateByUrl('/').then(() =>
                this.router.navigate(['edit_impresa'], { queryParams: { step: 'dati_impresa', id: payload['id'] } })
            ))
        );

    @Effect({ dispatch: false }) doEditStrutturaSuccess$ = this.actions$.ofType(UserActions.USER_EDIT_STRUTTURA_SUCCESS)
        .pipe(
            map(action => action['payload']),
            // map(payload => this.appFlowActions$.setCurrentStep('riepilogo'))
            tap(payload => this.router.navigateByUrl('/').then(() =>
                this.router.navigate(['/edit'], { queryParams: { type: "strut", step: 'riepilogo', id: payload.payload.id } })
            )
            )
        );

    // @Effect({dispatch: false}) doReload$ =
    //     this.actions$.ofType(UserActions.USER_EDIT_STRUTTURA_SUCCESS).pipe(
    //         map(action => action['payload']),
    //         tap(payload => window.location.assign('/edit?type=strut&step=riepilogo&id=' + payload.payload.id))
    //     );


    @Effect() doCheckDuplicati$ = this.actions$.ofType(UserActions.USER_CHECK_DUPLICATI)
        .pipe(
            map(action => action['payload']),
            switchMap(payload => this.userRequests$.fetchDuplicati$(payload.comune, payload.nome)
                .pipe(
                    switchMap(res => (res['status'] === 'OK' && res['payload'] && res['payload']['resultsFound'] && res['payload']['resultsFound'].length) ?
                        of(this.userActions$.userCheckDuplicatiSuccess({ results: res['payload']['resultsFound'], action: payload.postAction })) :
                        of(this.userActions$.userCheckDuplicatiFail(null), payload.postAction)
                    ),
                    catchError(err => [this.userActions$.userCheckDuplicatiFail('Attenzione! Non è stato possibile controllare i possibili duplicati.')])
                )
            )
        );

    @Effect() doDuplicatiSuccess$ = this.actions$.ofType(UserActions.USER_CHECK_DUPLICATI_SUCCCESS)
        .pipe(
            map(action => action['payload']),
            map(payload =>
                this.genericActions$.showDuplicatesModal({
                    title: 'Attenzione possibile struttura duplicata',
                    results: payload.results,
                    buttons: [{ id: 'close', label: 'Annulla', class: '', autoclose: true },
                    { id: 'insert', label: 'Prosegui con l\'inserimento', class: '', action: payload.action, autoclose: true }],
                    size: 'large'
                })
            )
        );

    @Effect() dispatchBozze$ = merge(
        this.actions$.ofType(UserActions.USER_LOGIN_SUCCESS),
        this.actions$.ofType(UserActions.USER_CHECK_LOGIN_SUCCESS),
        this.actions$.ofType(UserActions.USER_EDIT_STRUTTURA_SUCCESS),
        this.actions$.ofType(UserActions.USER_INSERT_STRUTTURA_SUCCESS),
    ).pipe(
        withLatestFrom(this.userSelectors$.getUser$()),
        filter(([, user]) => user !== null),
        map(action => this.userActions$.userGetBozze())
    );

    @Effect() setBozzeEffect$ = this.actions$.ofType(UserActions.SET_USER)
        .pipe(
            map(action => this.userActions$.userGetBozze())
        );


    @Effect() doGetBozze$ = this.actions$.ofType(UserActions.USER_GET_BOZZE)
        .pipe(
            map(action => action['payload']),
            withLatestFrom(this.userSelectors$.getUser$()),
            filter(([, user]) => user !== null),
            switchMap(([, user]) => this.userRequests$.getBozze$(user['id'])
                .pipe(
                    switchMap(res => res[status] !== 'OK' ?
                        of(this.userActions$.userGetBozzeSucess(res['payload']['dataEntries'])) :
                        of(this.userActions$.userGetBozzeFail('Impossibile recuperare le bozze.'))
                    ),
                )
            ),
            catchError(err => [this.userActions$.userGetBozzeFail('Errore durante il recupero delle bozze.')])
        );

    @Effect() doGetStruttureByImpresa$ = this.actions$.ofType(UserActions.GET_STRUT_BY_IMPRESA)
        .pipe(
            map(action => action['payload']),
            switchMap(payload => this.userRequests$.getStruttureByImpresa$(payload.id)
                .pipe(
                    switchMap(res => res[status] !== 'OK' ?
                        of(this.userActions$.getStrutByImpresaSuccess({ results: res['payload']['results'], nome: payload.nome })) :
                        of(this.userActions$.getStrutByImpresaFail('Impossibile recuperare le strutture associate all\'impresa.'))
                    ),
                )
            ),
            catchError(err => [this.userActions$.getStrutByImpresaFail('Impossibile recuperare le strutture associate all\'impresa.')])
        );

    @Effect() displayStrutModal$ = this.actions$.ofType(UserActions.GET_STRUT_BY_IMPRESA_SUCCESS)
        .pipe(
            map(action => action['payload']),
            map(payload => this.genericActions$.showStruttureModal({
                title: payload.nome,
                results: payload.results,
                buttons: [{ id: 'close', label: 'Chiudi', class: '', autoclose: true }],
                size: 'large'
            })
            )
        );

    downLoadFile(data: any, type: string, fileName) {
        let blob = new Blob([data], { type: type });
        return saveAs(blob, fileName);
    }
}