<div class="container">
    <div class="modal-header">
        <h3 class="modal-title"> {{data?.title}}</h3>
    </div>
    <div class="modal-body">
        <p>{{data?.message}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary"
            [ngClass]=" modalButton.class ?  modalButton.class : ''" *ngFor="let modalButton of data?.buttons"
            (click)="clickedButton(modalButton)">{{modalButton.label}}</button>
    </div>
</div>