import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { SearchImpresaState } from './search-impresa.interfaces';
import { getSearchImpresaState } from '..';

@Injectable()
export class SearchImpresaSelectors {
    public search$: Observable<SearchImpresaState>;

    constructor( private store: Store<any>) {
        this.search$ = this.store.pipe(
            select(getSearchImpresaState),
            map(state => state)
        );
    }

    getSearch$() {
        return this.search$
    }

    getSearchResultsList$() {
        return this.store.pipe(
            select(getSearchImpresaState),
            map(state => state.searchResultsList )
        );
    }

    getCurrentSearchState$() {
        return this.store.pipe(
            select(getSearchImpresaState),
            map(state => state.currentSearchState));
    }

    getSearchFacets$() {
        return this.store.pipe(
            select(getSearchImpresaState),
            map( state => state.facets)
        );
    }

    getShownMore$() {
        return this.store.pipe(
            select(getSearchImpresaState),
            map( state => state.shownMore)
        );
    }

    getCollapsed$() {
        return this.store.pipe(
            select(getSearchImpresaState),
            map( state => state.collapsed)
        );
    }

    getSearchNumResults$() {
        return this.store.pipe(
            select(getSearchImpresaState),
            map( state => state.searchNumResults)
        );
    }

    getSearchRequest$() {
        return this.store.pipe(
            select(getSearchImpresaState),
            map((state) => ({
                facets: ["tipologia","provincia","comune"],
                facetFilters:  this.getFacetFilters(state.filters),
                index: 'impresa',
                queryString: state.queryString,
                numPerPage: state.resultsPerPage,
                page: state.page,
                sort: { "ultimo_aggiornamento":{"label":"Ultimo aggiornamento","order":"desc"}},
            })
        )
        );
    }

    searchIsFiltered$() {
        return this.store.pipe(
            select(getSearchImpresaState),
            map( state => state.filters),
            map (filters => Object.keys(filters))
        );
    }

    searchIsSorted$() {
        return this.store.pipe(
            select(getSearchImpresaState),
            map( state => state.sort),
            map (sort => {
                let check = false;
                for (const key in sort) {
                    if (sort.hasOwnProperty(key)) {
                        const element = sort[key];
                        if (element.order === 'asc' || element.order === 'desc') {
                            check = true;
                            break;
                        }
                    }
                }
                return check;
            })
        );
    }

    getNumOfPages$() {
        return this.store.pipe(
            select(getSearchImpresaState),
            map((state) =>  (Math.ceil( state.searchNumResults / state.resultsPerPage) )
            )
        );
    }

    getSort$() {
        return this.store.pipe(
            select(getSearchImpresaState),
            map((state) =>   state.sort )
        );
    }

    getFullTextSearchRequest$() {
    return this.store.pipe(
            select(getSearchImpresaState),
      map((state) => ({
            facets: ["tipologia","provincia","comune"],
          facetFilters:  [],
          index: 'impresa',
          queryString: state.queryString,
          numPerPage: state.resultsPerPage,
          sort: !!state.queryString? state.sort : { "ultimo_aggiornamento":{"label":"Ultimo aggiornamento","order":"desc"}},
          page: 0
        })
      )
    );
    }

    getPager$() {
        return this.store.pipe(
            select(getSearchImpresaState),
           map((state) => (state.pager))
        );
    }

    getCurrentPage$() {
        return this.store.pipe(
            select(getSearchImpresaState),
            map(state => (state.page))
        );
    }

    getQueryString$() {
        return this.store.pipe(
            select(getSearchImpresaState),
            map(state => state.queryString)
        );
    }

    getFacetFilters(filters) {
        let obj = [];
        for (const filter in filters) {
            if (!filters.hasOwnProperty(filter) ) {
            continue;
            }
            let tmp = {name: filter, values: []};
            for (const facet in filters[filter]) {
                if (!filters[filter].hasOwnProperty(facet)) {
                    continue;
                }
                if (filters[filter][facet]) {
                    tmp['values'].push(facet);
                }
            }
            if (tmp['values'].length > 0) {
                obj.push(tmp);
            }
        }
        return obj;
    }
}
