import { UserActions } from "./user.actions";
import { helpers } from "src/app/helpers";
var initialState = {
    isAuthenticated: false,
    user: null,
    currentItem: null,
    currentOperation: null,
    currentOperationSection: null,
    showedDuplicatesModal: null,
    bozzeList: []
};
export function userReducer(state, _a) {
    if (state === void 0) { state = initialState; }
    var type = _a.type, payload = _a.payload, loadingMessage = _a.loadingMessage;
    var nextState;
    switch (type) {
        case UserActions.USER_CHECK_LOGIN:
        case UserActions.USER_LOGIN: {
            nextState = helpers.immutAssign({}, state, {
                isAuthenticated: false,
                user: null,
                '>bozzeList': []
            });
            return nextState;
        }
        case UserActions.USER_CHECK_LOGIN_SUCCESS:
        case UserActions.SET_USER:
        case UserActions.USER_LOGIN_SUCCESS: {
            nextState = helpers.immutAssign({}, state, {
                isAuthenticated: true,
                user: {
                    id: payload.user.name,
                    token: payload.token,
                    email: payload.email,
                    roles: payload.user.roles
                }
            });
            return nextState;
        }
        case UserActions.USER_CHECK_LOGIN_FAIL:
        case UserActions.USER_LOGIN_FAIL: {
            nextState = helpers.immutAssign({}, state, {
                isAuthenticated: false,
                user: null,
                '>bozzeList': []
            });
            return nextState;
        }
        case UserActions.USER_LOGOUT: {
            nextState = helpers.immutAssign({}, state, {});
            return nextState;
        }
        case UserActions.USER_LOGOUT_SUCCESS: {
            nextState = helpers.immutAssign({}, state, {
                isAuthenticated: false,
                user: null,
                '>bozzeList': []
            });
            return nextState;
        }
        case UserActions.USER_LOGOUT_FAIL: {
            nextState = helpers.immutAssign({}, state, {});
            return nextState;
        }
        case UserActions.USER_UPLOAD_FILE: {
            nextState = helpers.immutAssign({}, state, {});
            return nextState;
        }
        case UserActions.USER_SET_OPERATION: {
            nextState = helpers.immutAssign({}, state, {
                currentOperation: payload.currentOperation,
                currentOperationSection: typeof payload.currentOperationSection === 'undefined' ? null : payload.currentOperationSection,
                currentItem: typeof payload.currentItem === 'undefined' ? null : payload.currentItem
            });
            return nextState;
        }
        case UserActions.USER_SET_SECTION: {
            nextState = helpers.immutAssign({}, state, {
                currentOperationSection: payload.currentOperationSection,
                currentItem: typeof payload.currentItem === 'undefined' ? null : payload.currentItem
            });
            return nextState;
        }
        case UserActions.USER_SET_ITEM: {
            nextState = helpers.immutAssign({}, state, {
                currentItem: payload
            });
            return nextState;
        }
        case UserActions.USER_CHECK_DUPLICATI: {
            nextState = helpers.immutAssign({}, state, {
                showedDuplicatesModal: false
            });
            return nextState;
        }
        case UserActions.USER_CHECK_DUPLICATI_SUCCCESS: {
            nextState = helpers.immutAssign({}, state, {
                showedDuplicatesModal: true
            });
            return nextState;
        }
        case UserActions.USER_CHECK_DUPLICATI_FAIL: {
            nextState = helpers.immutAssign({}, state, {
                showedDuplicatesModal: true
            });
            return nextState;
        }
        case UserActions.USER_GET_BOZZE: {
            nextState = helpers.immutAssign({}, state, {});
            return nextState;
        }
        case UserActions.USER_GET_BOZZE_SUCCESS: {
            nextState = helpers.immutAssign({}, state, {
                bozzeList: payload
            });
            return nextState;
        }
        case UserActions.USER_GET_BOZZE_FAIL: {
            nextState = helpers.immutAssign({}, state, {
                '>bozzeList': []
            });
            return nextState;
        }
        default:
            return state;
    }
}
