<main class="container">
  <section class="d-flex mt-4 mb-4 text-center" id="step">
    <div class="step flex-fill bd-highlight" (click)="changeSection(section.key)" [ngClass]="{'active': current_step === section.key,
      'done': section?.value?.control?.valid && (section.value.control.touched || section.value.control.dirty ),
      'error': section?.value?.control?.invalid && (section.value.control.touched || section.value.control.dirty )
     }" *ngFor="let section of model | keyvalue:orderComparator; index as i">
      <span class="number">{{i + 1}}</span>
      <p class="label mt-2 mb-0">{{section.value.label}}</p>
    </div>
  </section>
  <ng-container *ngTemplateOutlet="steps">
  </ng-container>
</main>
<div class="row mt-4" *ngIf="(this.isLoading$ | async) === true">
  <ngx-loading [show]="(this.isLoading$ | async) === true"
    [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop: true }" [template]="customLoadingTemplate">
  </ngx-loading>
</div>

<ng-template #steps>
  <div class="row">
    <aside class="col-12 col-sm-12 col-lg-3 mb-3">
      <div class="sticky-sidebar">
        <div class="card bg-light shadow mb-3" id="info-stato">
          <div class="card-body">
            <p class="mt-0 mb-0">Stato <strong>-</strong></p>
            <p class="mt-0 mb-2">Ultima modifica <strong>-</strong></p>
            <button type="button" class="btn btn-primary btn-sm mr-2 mb-2" (click)="submitData(true)"><i
                class="fas fa-save"></i>
              Salva bozza</button>
            <button type="button" class="btn btn-primary btn-sm" (click)="submitData(false)"
              [disabled]="formGroup.invalid"><i class="fas fa-check-circle"></i>Inserisci Struttura</button>
          </div>
        </div>
        <div id="form-modules">
          <div class="card bg-light shadow mb-3 active">
            <div class="card-header border-0 pb-2">
              <h5 class="mb-0 h4">
                {{this.model[current_step]?.label}}
              </h5>
            </div>
            <div class="card-body pt-0 pb-2 pl-3 pr-2">
              <ul class="m-0 p-0 lista_custom lista--form" id="scrollBar">
                <ng-container *ngFor="let subsection of this.model[current_step]?.children | keyvalue: orderComparator">
                  <a class="text-dark" routerLink="./" [queryParams]="getQueryParams()"
                    [fragment]="subsection.value.id">
                    <li class="border-0" *ngIf="subsection.value.visible | async" [ngClass]="{
                    'active': subsection.value.inViewport,
                    'done': !subsection.value.inViewport && subsection.value.control.valid && (subsection.value.control.touched || subsection.value.control.dirty ),
                    'error': !subsection.value.inViewport && subsection.value.control.invalid  && (subsection.value.control.touched || subsection.value.control.dirty )
                  }">
                      <div class="text pt-2 pb-2 pl-3">
                        {{subsection.value.label}}
                      </div>
                    </li>
                  </a>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </aside>

    <section class="col-12 col-sm-12 col-lg-9" id="form">


      <!-- <div *ngIf="this.state.invalid_feedback && this.state.currentSection !== 'ricerca' " class="alert alert-warning">
    Attenzione! Alcuni campi obbligatori non sono stati inseriti.
  </div> -->
      <div class="button_area col-12 mb-5">
        <div asr-section
          class="{{this.model[current_step]?.attributes ? this.model[current_step].attributes.class ? this.model[current_step].attributes.class  : '' : '' }}"
          [(model)]="this.model[current_step]" [parentController]="this"></div>
        <button type="button" class="btn btn-primary" *ngIf="current_step !== 'dati_prelim'"
          (click)="goToPreviousSection()" tabindex="0"><i class="fas fa-angle-left"></i>
          Indietro</button>
        <span class="float-right">
          <button type="button" (click)="submitData(true)" tabindex="0" class="btn btn-primary mr-2"><i
              class="fas fa-save"></i>
            Salva bozza</button>
          <button type="button" class="btn btn-primary mr-2" (click)="submitData(false)" tabindex="0"
            [disabled]="this.formGroup.invalid"><i class="fas fa-check-circle"></i>
            Invia</button>
          <button type="button" (click)="goToNextSection()" *ngIf="current_step !== 'servizi'" tabindex="0"
            class="btn btn-primary">
            Avanti <i class="fas fa-angle-right "></i></button>
        </span>
      </div>
    </section>
  </div>
</ng-template>