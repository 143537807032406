import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserState } from "./user.interface";
import { Store, select } from "@ngrx/store";
import { map, filter, tap, distinctUntilChanged } from "rxjs/operators";
import { getUserState } from "..";

@Injectable()
export class UserSelectors {

    private user$: Observable<UserState>;

    constructor( private store: Store<any>) {
        this.user$ = this.store.pipe(
            select(getUserState, state => state),
            filter(val => val !== undefined)
        )
    }

    getUser$() {
        return this.store.pipe(
            select(getUserState, state => state),
            filter(val => val !== undefined),
            map(state => state.user ),
            distinctUntilChanged((firstUser, secondUser)=> { 
                if(!!firstUser && !!secondUser){
                    return firstUser.id === secondUser.id
                }
                return firstUser === secondUser;
            })
        );
    }

    isAuthenticated$() {
        return this.store.pipe(
            select(getUserState, state => state),
            filter(val => val !== undefined),
            map(state => state.isAuthenticated )
        );
    }

    getCurrentOperation$(){
        return this.store.pipe(
            select(getUserState, state => state),
            filter(val => val !== undefined),
            map( state => state.currentOperation)
        )
    }

    getCurrentOperationSection$(){
        return this.store.pipe(
            select(getUserState, state => state),
            filter(val => val !== undefined),
            map( state => state.currentOperationSection)
        )
    }

    getCurrentItem$(){
        return this.store.pipe(
            select(getUserState, state => state),
            filter(val => val !== undefined),
            map( state => state.currentItem)
        )
    }

    isDuplicateModalShowed$(){
        return this.store.pipe(
            select(getUserState, state => state),
            filter(val => val !== undefined),
            map( state => state.showedDuplicatesModal)
        )
    }

    getBozzeList$(){
        return this.store.pipe(
            select(getUserState, state => state),
            map( state => state.bozzeList)
        );
    }
}