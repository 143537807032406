export function getSearchResponse()  {
  return {
    "payload": {
      "numResults": 20,
      "results": [
        {
          "idStrut": 2695,
          "nome": "SELEMA",
          "lat": 40.5786144589869,
          "lng": 9.7730579318187
        },
        {
          "idStrut": 2700,
          "indirizzo": "Via Nuoro",
          "nome": "Tegas"
        },
        {
          "idStrut": 2708,
          "indirizzo": "Via dei Portoghesi Porto Frailis",
          "nome": "Il Gabbiano",
          "lat": 39.9253142215872,
          "lng": 9.70733750620346
        },
        {
          "idStrut": 2728,
          "indirizzo": "Via F.lli Cervi, 6",
          "nome": "RIVIERA"
        },
        {
          "idStrut": 2749,
          "indirizzo": "S.P. per Cannigione km  3,800",
          "nome": "GOLFO DI ARZACHENA",
          "lat": 41.0963261243308,
          "lng": 9.43656617046473
        },
        {
          "idStrut": 2762,
          "indirizzo": "Via Londra, 1  - Località Montelongu",
          "nome": "CORALLO",
          "lat": 40.6192697636165,
          "lng": 9.73342856650261
        },
        {
          "idStrut": 2777,
          "indirizzo": "Via S' Isuledda",
          "nome": "LISCIA ELDI",
          "lat": 40.766171523605,
          "lng": 9.68236527126919
        },
        {
          "idStrut": 2782,
          "indirizzo": "Genn'e Sa Luna",
          "nome": "MIRAMONTI"
        },
        {
          "idStrut": 2784,
          "indirizzo": "Viale dei Pini ",
          "nome": "LA MANDRAGOLA"
        },
        {
          "idStrut": 2786,
          "indirizzo": "Località Tilzitta",
          "nome": "GRAZIA DELEDDA",
          "lat": 41.0873278984553,
          "lng": 9.45385363567134
        }
      ],
      "facets": [
        {
          "facetName": "stato",
          "facetResults": [
            {
              "value": "aperta",
              "docCount": 4163,
              "label": "Aperta"
            },
            {
              "value": "chiusa",
              "docCount": 782,
              "label": "Chiusa"
            },
            {
              "value": "archiviata",
              "docCount": 9,
              "label": "Archiviata"
            }
          ]
        },
        {
          "facetName": "comune",
          "facetResults": [
            {
              "value": "090003",
              "docCount": 443,
              "label": "090003"
            },
            {
              "value": "092009",
              "docCount": 323,
              "label": "092009"
            },
            {
              "value": "090064",
              "docCount": 192,
              "label": "090064"
            },
            {
              "value": "104017",
              "docCount": 175,
              "label": "104017"
            },
            {
              "value": "104004",
              "docCount": 130,
              "label": "104004"
            },
            {
              "value": "095018",
              "docCount": 115,
              "label": "095018"
            },
            {
              "value": "104023",
              "docCount": 98,
              "label": "104023"
            },
            {
              "value": "091063",
              "docCount": 81,
              "label": "091063"
            },
            {
              "value": "092050",
              "docCount": 80,
              "label": "092050"
            },
            {
              "value": "092100",
              "docCount": 80,
              "label": "092100"
            },
            {
              "value": "104022",
              "docCount": 80,
              "label": "104022"
            },
            {
              "value": "092051",
              "docCount": 77,
              "label": "092051"
            },
            {
              "value": "091017",
              "docCount": 73,
              "label": "091017"
            },
            {
              "value": "104009",
              "docCount": 70,
              "label": "104009"
            },
            {
              "value": "092039",
              "docCount": 63,
              "label": "092039"
            },
            {
              "value": "091085",
              "docCount": 62,
              "label": "091085"
            },
            {
              "value": "095079",
              "docCount": 62,
              "label": "095079"
            },
            {
              "value": "104020",
              "docCount": 62,
              "label": "104020"
            },
            {
              "value": "104012",
              "docCount": 60,
              "label": "104012"
            },
            {
              "value": "106001",
              "docCount": 60,
              "label": "106001"
            },
            {
              "value": "090023",
              "docCount": 47,
              "label": "090023"
            },
            {
              "value": "104013",
              "docCount": 47,
              "label": "104013"
            },
            {
              "value": "090069",
              "docCount": 46,
              "label": "090069"
            },
            {
              "value": "090079",
              "docCount": 46,
              "label": "090079"
            },
            {
              "value": "105018",
              "docCount": 46,
              "label": "105018"
            },
            {
              "value": "092011",
              "docCount": 44,
              "label": "092011"
            },
            {
              "value": "092106",
              "docCount": 44,
              "label": "092106"
            },
            {
              "value": "107009",
              "docCount": 44,
              "label": "107009"
            },
            {
              "value": "092084",
              "docCount": 41,
              "label": "092084"
            },
            {
              "value": "091051",
              "docCount": 38,
              "label": "091051"
            },
            {
              "value": "090058",
              "docCount": 37,
              "label": "090058"
            },
            {
              "value": "104002",
              "docCount": 37,
              "label": "104002"
            },
            {
              "value": "090089",
              "docCount": 36,
              "label": "090089"
            },
            {
              "value": "091073",
              "docCount": 36,
              "label": "091073"
            },
            {
              "value": "092080",
              "docCount": 36,
              "label": "092080"
            },
            {
              "value": "107003",
              "docCount": 36,
              "label": "107003"
            },
            {
              "value": "104025",
              "docCount": 35,
              "label": "104025"
            },
            {
              "value": "107004",
              "docCount": 35,
              "label": "107004"
            },
            {
              "value": "104011",
              "docCount": 34,
              "label": "104011"
            },
            {
              "value": "095019",
              "docCount": 32,
              "label": "095019"
            },
            {
              "value": "095050",
              "docCount": 31,
              "label": "095050"
            },
            {
              "value": "107020",
              "docCount": 31,
              "label": "107020"
            },
            {
              "value": "090048",
              "docCount": 28,
              "label": "090048"
            },
            {
              "value": "095043",
              "docCount": 27,
              "label": "095043"
            },
            {
              "value": "104005",
              "docCount": 27,
              "label": "104005"
            },
            {
              "value": "104026",
              "docCount": 26,
              "label": "104026"
            },
            {
              "value": "095038",
              "docCount": 25,
              "label": "095038"
            },
            {
              "value": "107006",
              "docCount": 25,
              "label": "107006"
            },
            {
              "value": "092003",
              "docCount": 23,
              "label": "092003"
            },
            {
              "value": "095006",
              "docCount": 23,
              "label": "095006"
            },
            {
              "value": "105011",
              "docCount": 23,
              "label": "105011"
            },
            {
              "value": "105012",
              "docCount": 23,
              "label": "105012"
            },
            {
              "value": "107002",
              "docCount": 23,
              "label": "107002"
            },
            {
              "value": "092066",
              "docCount": 22,
              "label": "092066"
            },
            {
              "value": "091055",
              "docCount": 21,
              "label": "091055"
            },
            {
              "value": "092018",
              "docCount": 21,
              "label": "092018"
            },
            {
              "value": "092037",
              "docCount": 21,
              "label": "092037"
            },
            {
              "value": "105002",
              "docCount": 21,
              "label": "105002"
            },
            {
              "value": "106008",
              "docCount": 17,
              "label": "106008"
            },
            {
              "value": "091028",
              "docCount": 16,
              "label": "091028"
            },
            {
              "value": "104014",
              "docCount": 16,
              "label": "104014"
            },
            {
              "value": "104019",
              "docCount": 16,
              "label": "104019"
            },
            {
              "value": "105004",
              "docCount": 16,
              "label": "105004"
            },
            {
              "value": "091027",
              "docCount": 15,
              "label": "091027"
            },
            {
              "value": "092097",
              "docCount": 15,
              "label": "092097"
            },
            {
              "value": "092099",
              "docCount": 15,
              "label": "092099"
            },
            {
              "value": "095035",
              "docCount": 15,
              "label": "095035"
            },
            {
              "value": "095049",
              "docCount": 15,
              "label": "095049"
            },
            {
              "value": "107008",
              "docCount": 15,
              "label": "107008"
            },
            {
              "value": "091024",
              "docCount": 14,
              "label": "091024"
            },
            {
              "value": "092105",
              "docCount": 14,
              "label": "092105"
            },
            {
              "value": "105003",
              "docCount": 14,
              "label": "105003"
            },
            {
              "value": "106002",
              "docCount": 14,
              "label": "106002"
            },
            {
              "value": "107012",
              "docCount": 14,
              "label": "107012"
            },
            {
              "value": "095031",
              "docCount": 13,
              "label": "095031"
            },
            {
              "value": "095083",
              "docCount": 13,
              "label": "095083"
            },
            {
              "value": "107018",
              "docCount": 13,
              "label": "107018"
            },
            {
              "value": "092017",
              "docCount": 12,
              "label": "092017"
            },
            {
              "value": "092068",
              "docCount": 12,
              "label": "092068"
            },
            {
              "value": "090052",
              "docCount": 11,
              "label": "090052"
            },
            {
              "value": "090071",
              "docCount": 11,
              "label": "090071"
            },
            {
              "value": "091001",
              "docCount": 11,
              "label": "091001"
            },
            {
              "value": "091086",
              "docCount": 11,
              "label": "091086"
            },
            {
              "value": "104001",
              "docCount": 11,
              "label": "104001"
            },
            {
              "value": "104016",
              "docCount": 11,
              "label": "104016"
            },
            {
              "value": "107017",
              "docCount": 11,
              "label": "107017"
            },
            {
              "value": "090039",
              "docCount": 10,
              "label": "090039"
            },
            {
              "value": "090078",
              "docCount": 10,
              "label": "090078"
            },
            {
              "value": "092064",
              "docCount": 10,
              "label": "092064"
            },
            {
              "value": "092090",
              "docCount": 10,
              "label": "092090"
            },
            {
              "value": "092118",
              "docCount": 10,
              "label": "092118"
            },
            {
              "value": "095020",
              "docCount": 10,
              "label": "095020"
            },
            {
              "value": "104024",
              "docCount": 10,
              "label": "104024"
            },
            {
              "value": "106016",
              "docCount": 10,
              "label": "106016"
            },
            {
              "value": "107019",
              "docCount": 10,
              "label": "107019"
            },
            {
              "value": "092059",
              "docCount": 9,
              "label": "092059"
            },
            {
              "value": "092074",
              "docCount": 9,
              "label": "092074"
            },
            {
              "value": "092109",
              "docCount": 9,
              "label": "092109"
            },
            {
              "value": "095025",
              "docCount": 9,
              "label": "095025"
            },
            {
              "value": "095045",
              "docCount": 9,
              "label": "095045"
            },
            {
              "value": "095065",
              "docCount": 9,
              "label": "095065"
            },
            {
              "value": "104006",
              "docCount": 9,
              "label": "104006"
            },
            {
              "value": "104015",
              "docCount": 9,
              "label": "104015"
            },
            {
              "value": "105005",
              "docCount": 9,
              "label": "105005"
            },
            {
              "value": "105015",
              "docCount": 9,
              "label": "105015"
            },
            {
              "value": "106007",
              "docCount": 9,
              "label": "106007"
            },
            {
              "value": "106025",
              "docCount": 9,
              "label": "106025"
            },
            {
              "value": "090046",
              "docCount": 8,
              "label": "090046"
            },
            {
              "value": "090075",
              "docCount": 8,
              "label": "090075"
            },
            {
              "value": "091003",
              "docCount": 8,
              "label": "091003"
            },
            {
              "value": "091033",
              "docCount": 8,
              "label": "091033"
            },
            {
              "value": "091044",
              "docCount": 8,
              "label": "091044"
            },
            {
              "value": "091093",
              "docCount": 8,
              "label": "091093"
            },
            {
              "value": "091094",
              "docCount": 8,
              "label": "091094"
            },
            {
              "value": "092117",
              "docCount": 8,
              "label": "092117"
            },
            {
              "value": "095067",
              "docCount": 8,
              "label": "095067"
            },
            {
              "value": "105013",
              "docCount": 8,
              "label": "105013"
            },
            {
              "value": "106015",
              "docCount": 8,
              "label": "106015"
            },
            {
              "value": "107016",
              "docCount": 8,
              "label": "107016"
            },
            {
              "value": "090033",
              "docCount": 7,
              "label": "090033"
            },
            {
              "value": "091016",
              "docCount": 7,
              "label": "091016"
            },
            {
              "value": "091046",
              "docCount": 7,
              "label": "091046"
            },
            {
              "value": "091062",
              "docCount": 7,
              "label": "091062"
            },
            {
              "value": "092078",
              "docCount": 7,
              "label": "092078"
            },
            {
              "value": "092108",
              "docCount": 7,
              "label": "092108"
            },
            {
              "value": "092119",
              "docCount": 7,
              "label": "092119"
            },
            {
              "value": "095004",
              "docCount": 7,
              "label": "095004"
            },
            {
              "value": "095021",
              "docCount": 7,
              "label": "095021"
            },
            {
              "value": "095041",
              "docCount": 7,
              "label": "095041"
            },
            {
              "value": "095047",
              "docCount": 7,
              "label": "095047"
            },
            {
              "value": "095052",
              "docCount": 7,
              "label": "095052"
            },
            {
              "value": "104008",
              "docCount": 7,
              "label": "104008"
            },
            {
              "value": "104021",
              "docCount": 7,
              "label": "104021"
            },
            {
              "value": "105010",
              "docCount": 7,
              "label": "105010"
            },
            {
              "value": "105017",
              "docCount": 7,
              "label": "105017"
            },
            {
              "value": "106006",
              "docCount": 7,
              "label": "106006"
            },
            {
              "value": "106010",
              "docCount": 7,
              "label": "106010"
            },
            {
              "value": "107013",
              "docCount": 7,
              "label": "107013"
            },
            {
              "value": "107022",
              "docCount": 7,
              "label": "107022"
            },
            {
              "value": "090008",
              "docCount": 6,
              "label": "090008"
            },
            {
              "value": "090011",
              "docCount": 6,
              "label": "090011"
            },
            {
              "value": "090013",
              "docCount": 6,
              "label": "090013"
            },
            {
              "value": "090040",
              "docCount": 6,
              "label": "090040"
            },
            {
              "value": "090059",
              "docCount": 6,
              "label": "090059"
            },
            {
              "value": "090086",
              "docCount": 6,
              "label": "090086"
            },
            {
              "value": "090087",
              "docCount": 6,
              "label": "090087"
            },
            {
              "value": "092120",
              "docCount": 6,
              "label": "092120"
            },
            {
              "value": "095082",
              "docCount": 6,
              "label": "095082"
            },
            {
              "value": "105006",
              "docCount": 6,
              "label": "105006"
            },
            {
              "value": "105016",
              "docCount": 6,
              "label": "105016"
            },
            {
              "value": "105019",
              "docCount": 6,
              "label": "105019"
            },
            {
              "value": "105022",
              "docCount": 6,
              "label": "105022"
            },
            {
              "value": "106003",
              "docCount": 6,
              "label": "106003"
            },
            {
              "value": "106022",
              "docCount": 6,
              "label": "106022"
            },
            {
              "value": "107021",
              "docCount": 6,
              "label": "107021"
            },
            {
              "value": "107023",
              "docCount": 6,
              "label": "107023"
            },
            {
              "value": "090077",
              "docCount": 5,
              "label": "090077"
            },
            {
              "value": "091009",
              "docCount": 5,
              "label": "091009"
            },
            {
              "value": "091056",
              "docCount": 5,
              "label": "091056"
            },
            {
              "value": "091071",
              "docCount": 5,
              "label": "091071"
            },
            {
              "value": "091090",
              "docCount": 5,
              "label": "091090"
            },
            {
              "value": "092036",
              "docCount": 5,
              "label": "092036"
            },
            {
              "value": "092101",
              "docCount": 5,
              "label": "092101"
            },
            {
              "value": "092113",
              "docCount": 5,
              "label": "092113"
            },
            {
              "value": "095015",
              "docCount": 5,
              "label": "095015"
            },
            {
              "value": "095033",
              "docCount": 5,
              "label": "095033"
            },
            {
              "value": "095053",
              "docCount": 5,
              "label": "095053"
            },
            {
              "value": "104018",
              "docCount": 5,
              "label": "104018"
            },
            {
              "value": "105023",
              "docCount": 5,
              "label": "105023"
            },
            {
              "value": "106028",
              "docCount": 5,
              "label": "106028"
            },
            {
              "value": "107001",
              "docCount": 5,
              "label": "107001"
            },
            {
              "value": "107010",
              "docCount": 5,
              "label": "107010"
            },
            {
              "value": "107011",
              "docCount": 5,
              "label": "107011"
            },
            {
              "value": "090030",
              "docCount": 4,
              "label": "090030"
            },
            {
              "value": "090050",
              "docCount": 4,
              "label": "090050"
            },
            {
              "value": "090056",
              "docCount": 4,
              "label": "090056"
            },
            {
              "value": "091012",
              "docCount": 4,
              "label": "091012"
            },
            {
              "value": "091041",
              "docCount": 4,
              "label": "091041"
            },
            {
              "value": "091043",
              "docCount": 4,
              "label": "091043"
            },
            {
              "value": "091077",
              "docCount": 4,
              "label": "091077"
            },
            {
              "value": "092038",
              "docCount": 4,
              "label": "092038"
            },
            {
              "value": "092071",
              "docCount": 4,
              "label": "092071"
            },
            {
              "value": "092114",
              "docCount": 4,
              "label": "092114"
            },
            {
              "value": "095026",
              "docCount": 4,
              "label": "095026"
            },
            {
              "value": "095032",
              "docCount": 4,
              "label": "095032"
            },
            {
              "value": "095039",
              "docCount": 4,
              "label": "095039"
            },
            {
              "value": "095066",
              "docCount": 4,
              "label": "095066"
            },
            {
              "value": "095075",
              "docCount": 4,
              "label": "095075"
            },
            {
              "value": "105007",
              "docCount": 4,
              "label": "105007"
            },
            {
              "value": "105008",
              "docCount": 4,
              "label": "105008"
            },
            {
              "value": "105009",
              "docCount": 4,
              "label": "105009"
            },
            {
              "value": "105014",
              "docCount": 4,
              "label": "105014"
            },
            {
              "value": "106018",
              "docCount": 4,
              "label": "106018"
            },
            {
              "value": "106027",
              "docCount": 4,
              "label": "106027"
            },
            {
              "value": "107005",
              "docCount": 4,
              "label": "107005"
            },
            {
              "value": "090007",
              "docCount": 3,
              "label": "090007"
            },
            {
              "value": "090025",
              "docCount": 3,
              "label": "090025"
            },
            {
              "value": "090042",
              "docCount": 3,
              "label": "090042"
            },
            {
              "value": "090051",
              "docCount": 3,
              "label": "090051"
            },
            {
              "value": "090055",
              "docCount": 3,
              "label": "090055"
            },
            {
              "value": "090067",
              "docCount": 3,
              "label": "090067"
            },
            {
              "value": "090076",
              "docCount": 3,
              "label": "090076"
            },
            {
              "value": "091007",
              "docCount": 3,
              "label": "091007"
            },
            {
              "value": "091010",
              "docCount": 3,
              "label": "091010"
            },
            {
              "value": "091040",
              "docCount": 3,
              "label": "091040"
            },
            {
              "value": "091047",
              "docCount": 3,
              "label": "091047"
            },
            {
              "value": "091057",
              "docCount": 3,
              "label": "091057"
            },
            {
              "value": "092016",
              "docCount": 3,
              "label": "092016"
            },
            {
              "value": "092070",
              "docCount": 3,
              "label": "092070"
            },
            {
              "value": "092082",
              "docCount": 3,
              "label": "092082"
            },
            {
              "value": "092121",
              "docCount": 3,
              "label": "092121"
            },
            {
              "value": "095001",
              "docCount": 3,
              "label": "095001"
            },
            {
              "value": "095011",
              "docCount": 3,
              "label": "095011"
            },
            {
              "value": "095013",
              "docCount": 3,
              "label": "095013"
            },
            {
              "value": "095055",
              "docCount": 3,
              "label": "095055"
            },
            {
              "value": "095056",
              "docCount": 3,
              "label": "095056"
            },
            {
              "value": "095068",
              "docCount": 3,
              "label": "095068"
            },
            {
              "value": "095088",
              "docCount": 3,
              "label": "095088"
            },
            {
              "value": "104007",
              "docCount": 3,
              "label": "104007"
            },
            {
              "value": "104010",
              "docCount": 3,
              "label": "104010"
            },
            {
              "value": "105020",
              "docCount": 3,
              "label": "105020"
            },
            {
              "value": "106014",
              "docCount": 3,
              "label": "106014"
            },
            {
              "value": "107015",
              "docCount": 3,
              "label": "107015"
            },
            {
              "value": "090004",
              "docCount": 2,
              "label": "090004"
            },
            {
              "value": "090010",
              "docCount": 2,
              "label": "090010"
            },
            {
              "value": "090012",
              "docCount": 2,
              "label": "090012"
            },
            {
              "value": "090016",
              "docCount": 2,
              "label": "090016"
            },
            {
              "value": "090018",
              "docCount": 2,
              "label": "090018"
            },
            {
              "value": "090038",
              "docCount": 2,
              "label": "090038"
            },
            {
              "value": "090057",
              "docCount": 2,
              "label": "090057"
            },
            {
              "value": "090065",
              "docCount": 2,
              "label": "090065"
            },
            {
              "value": "090068",
              "docCount": 2,
              "label": "090068"
            },
            {
              "value": "090082",
              "docCount": 2,
              "label": "090082"
            },
            {
              "value": "091004",
              "docCount": 2,
              "label": "091004"
            },
            {
              "value": "091008",
              "docCount": 2,
              "label": "091008"
            },
            {
              "value": "091025",
              "docCount": 2,
              "label": "091025"
            },
            {
              "value": "091060",
              "docCount": 2,
              "label": "091060"
            },
            {
              "value": "091061",
              "docCount": 2,
              "label": "091061"
            },
            {
              "value": "091064",
              "docCount": 2,
              "label": "091064"
            },
            {
              "value": "091066",
              "docCount": 2,
              "label": "091066"
            },
            {
              "value": "091068",
              "docCount": 2,
              "label": "091068"
            },
            {
              "value": "091070",
              "docCount": 2,
              "label": "091070"
            },
            {
              "value": "091084",
              "docCount": 2,
              "label": "091084"
            },
            {
              "value": "092002",
              "docCount": 2,
              "label": "092002"
            },
            {
              "value": "092027",
              "docCount": 2,
              "label": "092027"
            },
            {
              "value": "092058",
              "docCount": 2,
              "label": "092058"
            },
            {
              "value": "092075",
              "docCount": 2,
              "label": "092075"
            },
            {
              "value": "092079",
              "docCount": 2,
              "label": "092079"
            },
            {
              "value": "092081",
              "docCount": 2,
              "label": "092081"
            },
            {
              "value": "092083",
              "docCount": 2,
              "label": "092083"
            },
            {
              "value": "092088",
              "docCount": 2,
              "label": "092088"
            },
            {
              "value": "092091",
              "docCount": 2,
              "label": "092091"
            },
            {
              "value": "092110",
              "docCount": 2,
              "label": "092110"
            },
            {
              "value": "092116",
              "docCount": 2,
              "label": "092116"
            },
            {
              "value": "092122",
              "docCount": 2,
              "label": "092122"
            },
            {
              "value": "095008",
              "docCount": 2,
              "label": "095008"
            },
            {
              "value": "095016",
              "docCount": 2,
              "label": "095016"
            },
            {
              "value": "095023",
              "docCount": 2,
              "label": "095023"
            },
            {
              "value": "095030",
              "docCount": 2,
              "label": "095030"
            },
            {
              "value": "095036",
              "docCount": 2,
              "label": "095036"
            },
            {
              "value": "095057",
              "docCount": 2,
              "label": "095057"
            },
            {
              "value": "095059",
              "docCount": 2,
              "label": "095059"
            },
            {
              "value": "095062",
              "docCount": 2,
              "label": "095062"
            },
            {
              "value": "095069",
              "docCount": 2,
              "label": "095069"
            },
            {
              "value": "095081",
              "docCount": 2,
              "label": "095081"
            },
            {
              "value": "095086",
              "docCount": 2,
              "label": "095086"
            },
            {
              "value": "095087",
              "docCount": 2,
              "label": "095087"
            },
            {
              "value": "105021",
              "docCount": 2,
              "label": "105021"
            },
            {
              "value": "106009",
              "docCount": 2,
              "label": "106009"
            },
            {
              "value": "106020",
              "docCount": 2,
              "label": "106020"
            },
            {
              "value": "106023",
              "docCount": 2,
              "label": "106023"
            },
            {
              "value": "090005",
              "docCount": 1,
              "label": "090005"
            },
            {
              "value": "090020",
              "docCount": 1,
              "label": "090020"
            },
            {
              "value": "090024",
              "docCount": 1,
              "label": "090024"
            },
            {
              "value": "090026",
              "docCount": 1,
              "label": "090026"
            },
            {
              "value": "090027",
              "docCount": 1,
              "label": "090027"
            },
            {
              "value": "090029",
              "docCount": 1,
              "label": "090029"
            },
            {
              "value": "090031",
              "docCount": 1,
              "label": "090031"
            },
            {
              "value": "090032",
              "docCount": 1,
              "label": "090032"
            },
            {
              "value": "090043",
              "docCount": 1,
              "label": "090043"
            },
            {
              "value": "090044",
              "docCount": 1,
              "label": "090044"
            },
            {
              "value": "090045",
              "docCount": 1,
              "label": "090045"
            },
            {
              "value": "090053",
              "docCount": 1,
              "label": "090053"
            },
            {
              "value": "090060",
              "docCount": 1,
              "label": "090060"
            },
            {
              "value": "090061",
              "docCount": 1,
              "label": "090061"
            },
            {
              "value": "090073",
              "docCount": 1,
              "label": "090073"
            },
            {
              "value": "090088",
              "docCount": 1,
              "label": "090088"
            },
            {
              "value": "091011",
              "docCount": 1,
              "label": "091011"
            },
            {
              "value": "091038",
              "docCount": 1,
              "label": "091038"
            },
            {
              "value": "091058",
              "docCount": 1,
              "label": "091058"
            },
            {
              "value": "091059",
              "docCount": 1,
              "label": "091059"
            },
            {
              "value": "091083",
              "docCount": 1,
              "label": "091083"
            },
            {
              "value": "091091",
              "docCount": 1,
              "label": "091091"
            },
            {
              "value": "091104",
              "docCount": 1,
              "label": "091104"
            },
            {
              "value": "092008",
              "docCount": 1,
              "label": "092008"
            },
            {
              "value": "092020",
              "docCount": 1,
              "label": "092020"
            },
            {
              "value": "092031",
              "docCount": 1,
              "label": "092031"
            },
            {
              "value": "092042",
              "docCount": 1,
              "label": "092042"
            },
            {
              "value": "092044",
              "docCount": 1,
              "label": "092044"
            },
            {
              "value": "092061",
              "docCount": 1,
              "label": "092061"
            },
            {
              "value": "092069",
              "docCount": 1,
              "label": "092069"
            },
            {
              "value": "092111",
              "docCount": 1,
              "label": "092111"
            },
            {
              "value": "092112",
              "docCount": 1,
              "label": "092112"
            },
            {
              "value": "095005",
              "docCount": 1,
              "label": "095005"
            },
            {
              "value": "095010",
              "docCount": 1,
              "label": "095010"
            },
            {
              "value": "095012",
              "docCount": 1,
              "label": "095012"
            },
            {
              "value": "095014",
              "docCount": 1,
              "label": "095014"
            },
            {
              "value": "095017",
              "docCount": 1,
              "label": "095017"
            },
            {
              "value": "095024",
              "docCount": 1,
              "label": "095024"
            },
            {
              "value": "095027",
              "docCount": 1,
              "label": "095027"
            },
            {
              "value": "095029",
              "docCount": 1,
              "label": "095029"
            },
            {
              "value": "095040",
              "docCount": 1,
              "label": "095040"
            },
            {
              "value": "095046",
              "docCount": 1,
              "label": "095046"
            },
            {
              "value": "095048",
              "docCount": 1,
              "label": "095048"
            },
            {
              "value": "095051",
              "docCount": 1,
              "label": "095051"
            },
            {
              "value": "095060",
              "docCount": 1,
              "label": "095060"
            },
            {
              "value": "095063",
              "docCount": 1,
              "label": "095063"
            },
            {
              "value": "095072",
              "docCount": 1,
              "label": "095072"
            },
            {
              "value": "095073",
              "docCount": 1,
              "label": "095073"
            },
            {
              "value": "095074",
              "docCount": 1,
              "label": "095074"
            },
            {
              "value": "095077",
              "docCount": 1,
              "label": "095077"
            },
            {
              "value": "105001",
              "docCount": 1,
              "label": "105001"
            },
            {
              "value": "106005",
              "docCount": 1,
              "label": "106005"
            },
            {
              "value": "106012",
              "docCount": 1,
              "label": "106012"
            },
            {
              "value": "106013",
              "docCount": 1,
              "label": "106013"
            },
            {
              "value": "106017",
              "docCount": 1,
              "label": "106017"
            },
            {
              "value": "106019",
              "docCount": 1,
              "label": "106019"
            },
            {
              "value": "106021",
              "docCount": 1,
              "label": "106021"
            },
            {
              "value": "106026",
              "docCount": 1,
              "label": "106026"
            },
            {
              "value": "107007",
              "docCount": 1,
              "label": "107007"
            },
            {
              "value": "107014",
              "docCount": 1,
              "label": "107014"
            }
          ]
        },
        {
          "facetName": "tipoStruttura",
          "facetResults": [
            {
              "value": "1410",
              "docCount": 2214,
              "label": "Bed and breakfast"
            },
            {
              "value": "1119",
              "docCount": 862,
              "label": "Albergo Ordinario"
            },
            {
              "value": "1311",
              "docCount": 537,
              "label": "Affittacamere"
            },
            {
              "value": "1112",
              "docCount": 504,
              "label": "Agriturismo"
            },
            {
              "value": "2280",
              "docCount": 394,
              "label": "Agriturismo"
            },
            {
              "value": "1310",
              "docCount": 376,
              "label": "Case e appartamenti per vacanze"
            },
            {
              "value": "1211",
              "docCount": 136,
              "label": "Campeggio"
            },
            {
              "value": "1117",
              "docCount": 99,
              "label": "Albergo residenziale"
            },
            {
              "value": "1115",
              "docCount": 72,
              "label": "Villaggio albergo"
            },
            {
              "value": "2326",
              "docCount": 67,
              "label": "Agriturismo"
            },
            {
              "value": "1118",
              "docCount": 46,
              "label": "Albergo turismo rurale"
            },
            {
              "value": "1313",
              "docCount": 31,
              "label": "Case per ferie"
            },
            {
              "value": "1113",
              "docCount": 19,
              "label": "Locanda"
            },
            {
              "value": "1312",
              "docCount": 18,
              "label": "Ostelli per la gioventù"
            },
            {
              "value": "1116",
              "docCount": 17,
              "label": "Albergo diffuso"
            },
            {
              "value": "1210",
              "docCount": 16,
              "label": "Villaggi turistici"
            },
            {
              "value": "1111",
              "docCount": 8,
              "label": "Residence Ordinario"
            },
            {
              "value": "1110",
              "docCount": 2,
              "label": "Albergo"
            }
          ]
        }
      ]
    },
    "status": "OK"
  }
};

export function getSearchResponseWithEmptyResults()  {
  return {
    "payload": {
      "numResults": 0,
      "results": [],
       "facets": []
      },
    "status": "OK"
  }
};


export const SEARCH_REQUEST = {
	"facets": ["stato","comune","tipoStruttura"],
    "index": "struttura",
    "queryString": "a",
    "numPerPage": "10",
    "page": "0"
};

