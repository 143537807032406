<div class="container">
    <div class="modal-header">
        <h3 class="modal-title"> <strong>{{data?.title}}</strong></h3>
    </div>
    <div class="modal-body">
        <p *ngIf="data?.results?.length === 0"><strong>L'impresa non ha nessuna struttura associata.</strong><br></p>
        <p *ngIf="data?.results?.length === 1"><strong>L'impresa ha una struttura associata.</strong><br></p>
        <p *ngIf="data?.results?.length > 1"><strong>L'impresa ha {{data.results.length}} strutture associate.</strong><br></p>
        <div class="accordion mb-3" id="accordionExample">
            <div class="card bg-light shadow">
                <ng-container *ngFor="let element of data.results; let i = index">
                    <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne">
                        <h5 class="mb-0 h4">
                            <span>{{i + 1}}</span> - <strong>{{element?.denominazione}}</strong>
                            <!-- <i class="fas fa-angle-up float-right"></i> -->
                        </h5>
                        <p class="m-0">
                          {{(element?.tipologia | uppercase) + ','  }} {{element?.indirizzo?.comune?.comune}} {{element?.indirizzo?.provincia?.provincia}} {{element?.indirizzo?.indirizzo}}
                        </p>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary"
            [ngClass]=" modalButton.class ?  modalButton.class : ''" *ngFor="let modalButton of data?.buttons"
            (click)="clickedButton(modalButton)">{{modalButton.label}}</button>
    </div>
</div>