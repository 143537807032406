import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GenericActions } from './logic/generic/generic.actions';
import { GenericSelectors } from './logic/generic/generic.selectors';
import { UserActions } from './logic/user';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component( {
  selector: 'app-root',
  templateUrl: './app.component.html',
} )
export class AppComponent implements OnInit {
  title = 'Anagrafe Strutture Ricettive';

  constructor(
    private store: Store<any>,
    private genericActions: GenericActions,
    private userActions: UserActions,
    private activatedRoute: ActivatedRoute
    ) {
  }

  ngOnInit () {
    this.store.dispatch( this.userActions.userCheckLogin());
    this.store.dispatch( this.genericActions.genericGetInfo() );
  }
}
