<div  class="{{this.model?.attributes ? this.model.attributes.class ? this.model.attributes.class  : '' : '' }}" >
  <div class="form_section mb-3 p-1 pl-3 pr-3 d-inline">
    <span>{{ this.model?.label }}</span>
  </div>
  <ng-container *ngFor="let subsection of model?.children | keyvalue: parentController.orderComparator;">
    <ng-container *ngIf="(subsection.value.visible | async) === true">
      <div class='{{subsection.value.attributes.class ?  subsection.value.attributes.class : "" }}'
       asr-subsection [(model)]="subsection.value" [parentController]="this.parentController"></div>
    </ng-container>
  </ng-container>
</div>
