/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../asr-section/asr-section.component.ngfactory";
import * as i2 from "../../asr-section/asr-section.component";
import * as i3 from "@angular/common";
import * as i4 from "./asr-edit-modal.component";
import * as i5 from "../../../logic/user/user.actions";
import * as i6 from "../../../logic/generic/generic.actions";
import * as i7 from "@ngrx/store";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
import * as i9 from "../../../logic/user/user.service";
var styles_AsrEditModalComponent = [];
var RenderType_AsrEditModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AsrEditModalComponent, data: {} });
export { RenderType_AsrEditModalComponent as RenderType_AsrEditModalComponent };
function View_AsrEditModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitData(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-check-circle"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Salva modifiche"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formGroup.invalid; _ck(_v, 0, 0, currVal_0); }); }
export function View_AsrEditModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "main", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["class", "close mt-1"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(4, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h2", [["class", "col-12 mt-3 ml-4"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 9, "section", [["class", "col-12 col-sm-12 col-lg-12"], ["id", "form"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 8, "div", [["class", "button_area col-12 mb-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["asr-section", ""]], [[8, "className", 0]], [[null, "modelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_co.model[_co.current_step] = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AsrSectionComponent_0, i1.RenderType_AsrSectionComponent)), i0.ɵdid(10, 49152, null, 0, i2.AsrSectionComponent, [], { model: [0, "model"], parentController: [1, "parentController"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 5, "span", [["class", "float-right pb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "button", [["class", "btn btn-primary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 0, "i", [["class", "fas fa-times-circle"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Annulla"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AsrEditModalComponent_1)), i0.ɵdid(16, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.model[_co.current_step]; var currVal_3 = _co; _ck(_v, 10, 0, currVal_2, currVal_3); var currVal_4 = (_co.current_step === "dati_impresa"); _ck(_v, 16, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.current_item == null) ? null : _co.current_item.ragioneSociale); _ck(_v, 6, 0, currVal_0); var currVal_1 = i0.ɵinlineInterpolate(1, "", (((_co.model[_co.current_step] == null) ? null : _co.model[_co.current_step].attributes) ? (_co.model[_co.current_step].attributes.class ? _co.model[_co.current_step].attributes.class : "") : ""), ""); _ck(_v, 9, 0, currVal_1); }); }
export function View_AsrEditModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "asr-edit-modal-content", [], null, null, null, View_AsrEditModalComponent_0, RenderType_AsrEditModalComponent)), i0.ɵdid(1, 245760, null, 0, i4.AsrEditModalComponent, [i0.Injector, i5.UserActions, i6.GenericActions, i7.Store, i8.NgbActiveModal, i9.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AsrEditModalComponentNgFactory = i0.ɵccf("asr-edit-modal-content", i4.AsrEditModalComponent, View_AsrEditModalComponent_Host_0, { dataEntry: "dataEntry", field: "field", disableValidation: "disableValidation" }, {}, []);
export { AsrEditModalComponentNgFactory as AsrEditModalComponentNgFactory };
