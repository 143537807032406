import { Component, OnInit, OnDestroy } from "@angular/core";
import { UserRequests, UserSelectors } from "src/app/logic/user";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    selector: 'asr-bozze',
    templateUrl: './asr-bozze.component.html'
})
export class AsrBozzeComponent implements OnInit, OnDestroy {
    results;
    destroyed$: Subject<any> = new Subject<any>();
    constructor(private userSelectors: UserSelectors){}
    ngOnInit(){
        this.results = this.userSelectors.getBozzeList$();
        this.userSelectors.getBozzeList$()
            .pipe(takeUntil(this.destroyed$)).subscribe({
                next: data => this.results = data,
                error: err => console.log(err),
                complete: () => {}
            });
    }

    ngOnDestroy(){
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
