/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/ngx-loading/ngx-loading.ngfactory";
import * as i2 from "ngx-loading";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "../../components/asr-recap/asr-recap.component.ngfactory";
import * as i6 from "../../components/asr-recap/asr-recap.component";
import * as i7 from "../../logic/generic/generic.selectors";
import * as i8 from "../../logic/user/user.selectors";
import * as i9 from "./asr-home.component";
import * as i10 from "@ngrx/store";
import * as i11 from "../../logic/user/user.actions";
var styles_AsrHomeComponent = [];
var RenderType_AsrHomeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AsrHomeComponent, data: {} });
export { RenderType_AsrHomeComponent as RenderType_AsrHomeComponent };
function View_AsrHomeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "row mt-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "ngx-loading", [], null, null, null, i1.View_NgxLoadingComponent_0, i1.RenderType_NgxLoadingComponent)), i0.ɵdid(2, 114688, null, 0, i2.NgxLoadingComponent, [i2.NgxLoadingService, i0.ChangeDetectorRef], { show: [0, "show"], config: [1, "config"], template: [2, "template"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpod(4, { backdropBorderRadius: 0, fullScreenBackdrop: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.isLoading$)) === true); var currVal_1 = _ck(_v, 4, 0, "3px", true); var currVal_2 = _co.customLoadingTemplate; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AsrHomeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 40, "main", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 37, "section", [["class", "container mt-4 mb-2"], ["id", "grid-menu"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 36, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "col-12 col-md-12 col-lg-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [["class", "grid-menu_item d-flex align-items-center"], ["id", "nuova-struttura-link"], ["routerLink", "/new"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "i", [["class", "fa fa-plus-circle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" nuova struttura "])), (_l()(), i0.ɵeld(10, 0, null, null, 28, "div", [["class", "col-12 col-md-12 col-lg-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 27, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 9, "div", [["class", "col-12 col-md-12 col-lg-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 8, "div", [["class", "grid-menu_item d-flex align-items-center"], ["id", "modifica-struttura-link"], ["routerLink", "/edit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(14, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(15, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 0, "i", [["class", "fa fa-bed"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["modifica"])), (_l()(), i0.ɵeld(20, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" struttura "])), (_l()(), i0.ɵeld(22, 0, null, null, 9, "div", [["class", "col-12 col-md-12 col-lg-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 8, "div", [["class", "grid-menu_item d-flex align-items-center"], ["id", "modifica-impresa-link"], ["routerLink", "/edit_impresa"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 24).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(24, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(25, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 0, "i", [["class", "fa fa-building"]], null, null, null, null, null)), (_l()(), i0.ɵeld(27, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(28, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["modifica"])), (_l()(), i0.ɵeld(30, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" impresa "])), (_l()(), i0.ɵeld(32, 0, null, null, 6, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(33, 0, null, null, 5, "div", [["class", "grid-menu_item d-flex align-items-center"], ["id", "ricerca-link"], ["routerLink", "/search"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 34).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(34, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(35, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(36, 0, null, null, 0, "i", [["class", "fa fa-search"]], null, null, null, null, null)), (_l()(), i0.ɵeld(37, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" ricerca "])), (_l()(), i0.ɵeld(39, 0, null, null, 1, "asr-recap", [], null, null, null, i5.View_AsrRecapComponent_0, i5.RenderType_AsrRecapComponent)), i0.ɵdid(40, 49152, null, 0, i6.AsrRecapComponent, [i7.GenericSelectors, i8.UserSelectors], null, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AsrHomeComponent_1)), i0.ɵdid(42, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "/new"; _ck(_v, 5, 0, currVal_0); var currVal_1 = "/edit"; _ck(_v, 14, 0, currVal_1); var currVal_2 = "/edit_impresa"; _ck(_v, 24, 0, currVal_2); var currVal_3 = "/search"; _ck(_v, 34, 0, currVal_3); var currVal_4 = (i0.ɵunv(_v, 42, 0, i0.ɵnov(_v, 43).transform(_co.isLoading$)) === true); _ck(_v, 42, 0, currVal_4); }, null); }
export function View_AsrHomeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "asr-home", [], null, null, null, View_AsrHomeComponent_0, RenderType_AsrHomeComponent)), i0.ɵdid(1, 114688, null, 0, i9.AsrHomeComponent, [i10.Store, i11.UserActions, i7.GenericSelectors, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AsrHomeComponentNgFactory = i0.ɵccf("asr-home", i9.AsrHomeComponent, View_AsrHomeComponent_Host_0, {}, {}, []);
export { AsrHomeComponentNgFactory as AsrHomeComponentNgFactory };
