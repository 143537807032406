var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { SearchActions } from './search.actions';
import { SearchRequests } from './search.requests';
import { environment } from 'src/environments/environment';
import { getSearchResponse } from './search.mocks';
import { SearchSelectors } from './search.selectors';
import { merge } from 'rxjs';
import { GenericActions } from '../generic';
var SearchEffects = /** @class */ (function () {
    function SearchEffects(actions$, searchActions, searchRequest, searchSelector, genericActions) {
        var _this = this;
        this.actions$ = actions$;
        this.searchActions = searchActions;
        this.searchRequest = searchRequest;
        this.searchSelector = searchSelector;
        this.genericActions = genericActions;
        this.doSearch$ = merge(this.actions$.ofType(SearchActions.SEARCH_START), this.actions$.ofType(SearchActions.SEARCH_SET_FILTER), this.actions$.ofType(SearchActions.SEARCH_SORT), this.actions$.ofType(SearchActions.SEARCH_CHANGE_RESULTS_PAGE), this.actions$.ofType(SearchActions.SEARCH_CHANGE_RESULTS_PAGE_BY), this.actions$.ofType(SearchActions.SEARCH_SET_BOUNDING_BOX)).pipe(map(function (action) { return action['payload']; }), withLatestFrom(this.searchSelector.getSearchRequest$()), switchMap(function (_a) {
            var request = _a[1];
            return (environment.requests.search.mock ?
                of(_this.searchActions.searchSuccess(getSearchResponse().payload)) :
                _this.searchRequest.requestSearch$(request).pipe(switchMap(function (response) { return response['status'] === 'OK' ?
                    of(_this.searchActions.searchSuccess(response['payload'])) :
                    of(_this.searchActions.searchFail(response['payload']['message'])); }), catchError(function (err) { return [_this.genericActions.showErrorModal({
                        title: 'Errore',
                        message: 'Si è verificato un errore durante la ricerca',
                        buttons: [{ id: 'close', label: 'Chiudi', class: '', action: '', autoclose: true }]
                    }),
                    _this.searchActions.searchFail(err)]; })));
        }));
        this.doSearchFullText$ = merge(this.actions$.ofType(SearchActions.SEARCH_FULL_TEXT), this.actions$.ofType(SearchActions.SEARCH_CLEAR_FILTERS)).pipe(map(function (action) { return action['payload']; }), withLatestFrom(this.searchSelector.getFullTextSearchRequest$()), switchMap(function (_a) {
            var payload = _a[1];
            return (environment.requests.search.mock ?
                of(_this.searchActions.searchFullTextSuccess(getSearchResponse().payload)) :
                _this.searchRequest.requestSearch$(payload).pipe(switchMap(function (response) { return response['status'] === 'OK' ?
                    of(_this.searchActions.searchFullTextSuccess(response['payload'])) :
                    of(_this.searchActions.searchFullTextFail(response['payload']['message'])); }), catchError(function (err) { return [_this.genericActions.showErrorModal({
                        title: 'Errore',
                        message: 'Si è verificato un errore durante la ricerca',
                        buttons: [{ id: 'close', label: 'Chiudi', autoclose: true }], code: 1
                    }),
                    _this.searchActions.searchFail(err)]; })));
        }));
        /*do nothing*/
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SearchEffects.prototype, "doSearch$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SearchEffects.prototype, "doSearchFullText$", void 0);
    return SearchEffects;
}());
export { SearchEffects };
