import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { GenericState } from "./generic.interfaces";
import { getGenericState } from "..";

@Injectable()
export class GenericSelectors  {

    public generic$: Observable<GenericState>;

    constructor( private store: Store<any>) {
        this.generic$ = this.store.pipe(
            select(getGenericState),
            map(state => state)
        );
    }

    isLoading$(){
        return this.store.pipe(
            select(getGenericState),
            map(state => state.isLoading)
        );
    }

    getLoadingMessage$(){
        return this.store.pipe(
            select(getGenericState),
            map(state => state.loadingMessage)
        );
    }

    getGeneric$() {
        return this.store.pipe(
            select(getGenericState),
            map(state => state)
        );
    }

    getNumStrutture$() {
        return this.store.pipe(
            select(getGenericState),
            map(state => state.numStrutture)
        );
    }

    getNumImprese$() {
        return this.store.pipe(
            select(getGenericState),
            map(state => state. numImprese)
        );
    }

    getLoadingQueue$() {
        return this.store.pipe(
            select(getGenericState),
            map(state => state.loadingQueue)
        );
    }

    getEditModalResult$(){
        return this.store.pipe(
            select(getGenericState),
            map(state => state.editModalResult)
        );
    }

}