var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, Injector } from '@angular/core';
import { FormGeneratorController, LEAF_TYPES, CONTAINER_TYPES } from 'src/app/form-generator/form-generator';
import { takeUntil, distinctUntilChanged, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { getInsertModel } from '../../form-model/form.model';
import { GenericSelectors, GenericActions } from 'src/app/logic/generic';
import { UserActions } from 'src/app/logic/user';
import { Store } from '@ngrx/store';
import { FormGroup } from '@angular/forms';
import { AppFlowSelectors } from 'src/app/logic/app-flow/app-flow.selectors';
import { AppFlowActions } from 'src/app/logic/app-flow/app-flow.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/logic/user/user.service';
var AsrInsertComponent = /** @class */ (function (_super) {
    __extends(AsrInsertComponent, _super);
    function AsrInsertComponent(injector, genericActions, genericSelectors, store, flowSelectors$, flowActions, router, activatedRoute, userActions, userService) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.genericActions = genericActions;
        _this.genericSelectors = genericSelectors;
        _this.store = store;
        _this.flowSelectors$ = flowSelectors$;
        _this.flowActions = flowActions;
        _this.router = router;
        _this.activatedRoute = activatedRoute;
        _this.userActions = userActions;
        _this.userService = userService;
        _this.destroyed$ = new Subject();
        _this.state = {
            currentSection: null,
            currentComune: null,
            currentDenominazione: null,
            lastComune: null,
            lastDenominazione: null,
            showDuplicateModal: false,
        };
        _this.queueSubsectionInView = [];
        _this.subsectioNoMoreInView = false;
        _this.inViewportOptions = {
            rootMargin: '0px',
            threshold: 1.0
        };
        return _this;
    }
    AsrInsertComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.dispatch(this.genericActions.resetModal());
        this.dataEntryMap = {};
        this.events = {};
        this.isLoading$ = this.genericSelectors.isLoading$();
        this.model = getInsertModel(false);
        this.formGroup = new FormGroup({});
        this.initializeModel(this.model, this.formGroup);
        for (var fieldId in this.events) {
            if (this.events.hasOwnProperty(fieldId)) {
                this.onModelChange(this.containerMap[fieldId]);
            }
        }
        this.formGroup.markAsUntouched();
        this.flowSelectors$.getCurrentStep$().pipe(takeUntil(this.destroyed$)).subscribe({
            next: function (x) {
                _this.current_step = x;
                _this.router.navigate([], {
                    relativeTo: _this.activatedRoute,
                    queryParams: { step: x },
                    queryParamsHandling: 'merge'
                });
            },
            error: function (err) { },
            complete: function () { }
        });
        this.steps = Object.getOwnPropertyNames(this.model);
        this.store.dispatch(this.flowActions.setCurrentSection({ steps: this.steps, current_step: 'dati_prelim' }));
        this.genericSelectors.getEditModalResult$().pipe(takeUntil(this.destroyed$), distinctUntilChanged(), filter(function (x) { return x !== null; })).subscribe({
            next: function (x) {
                if (!!x && !!x['result'] && !!x['field']) {
                    var container = _this.containerMap[x['field']];
                    var handler = _this.controlHandlerFactory.getHandler(container.type);
                    handler.setValue(container, x['result']);
                }
            },
            error: function (error) { return console.log(error); },
            complete: function () { }
        });
    };
    AsrInsertComponent.prototype.ngOnDestroy = function () {
        this.destroyed$.next();
        this.destroyed$.complete();
    };
    AsrInsertComponent.prototype.changeSection = function (section) {
        if (this.updateDuplicateParams()) {
            this.store.dispatch(this.userActions.userCheckDuplicati({
                comune: this.state.currentComune,
                nome: this.state.currentDenominazione,
                postAction: this.flowActions.setCurrentStep(section)
            }));
            this.state.showDuplicateModal = false;
        }
        else {
            this.markSectionAsTouched(this.model[this.current_step]);
            this.store.dispatch(this.flowActions.setCurrentStep(section));
            this.resetAffix();
        }
    };
    AsrInsertComponent.prototype.goToPreviousSection = function () {
        var currentSection = this.model[this.current_step];
        var currentWeight = currentSection.weight;
        for (var sectionId in this.model) {
            if (this.model.hasOwnProperty(sectionId)) {
                var section = this.model[sectionId];
                if (section.weight + 1 === currentWeight) {
                    this.store.dispatch(this.flowActions.setCurrentStep(sectionId));
                    this.markSectionAsTouched(currentSection);
                    window.scrollTo(0, 0);
                    this.resetAffix();
                    break;
                }
            }
        }
    };
    AsrInsertComponent.prototype.markSectionAsTouched = function (element) {
        if (element.visible.getValue() === false) {
            return;
        }
        if (LEAF_TYPES.includes(element.type)) {
            var c = element.control;
            c.markAsTouched({ onlySelf: true });
        }
        else if (CONTAINER_TYPES.includes(element.type)) {
            var c = element.control;
            c.markAsTouched({ onlySelf: true });
            for (var child in element.children) {
                if (element.children.hasOwnProperty(child)) {
                    this.markSectionAsTouched(element.children[child]);
                }
            }
        }
    };
    AsrInsertComponent.prototype.getQueryParams = function () {
        return {
            step: this.current_step
        };
    };
    AsrInsertComponent.prototype.goToNextSection = function () {
        var currentSection = this.model[this.current_step];
        var currentWeight = currentSection.weight;
        for (var sectionId in this.model) {
            if (this.model.hasOwnProperty(sectionId)) {
                var section = this.model[sectionId];
                if (section.weight - 1 === currentWeight) {
                    this.store.dispatch(this.flowActions.setCurrentStep(sectionId));
                    this.markSectionAsTouched(currentSection);
                    window.scrollTo(0, 0);
                    this.resetAffix();
                }
            }
        }
    };
    AsrInsertComponent.prototype.openModal = function (targetKey) {
        var dataControl = this.containerMap[targetKey].control;
        this.store.dispatch(this.genericActions.showEditModal({ data: dataControl.value, field: targetKey }));
    };
    AsrInsertComponent.prototype.updateDuplicateParams = function () {
        var comuneKey = "dati_prelim-localizzazione-comune";
        var descrizioneKey = "dati_prelim-classificazione-descrizioneidras";
        var currentComuneValue = this.containerMap[comuneKey].control.value;
        var currentNomeValue = this.containerMap[descrizioneKey].control.value;
        if (!!currentComuneValue && !!currentComuneValue.istat && !!currentNomeValue) {
            this.state.currentComune = currentComuneValue.istat;
            this.state.currentDenominazione = currentNomeValue;
            if (this.state.lastComune === null && this.state.lastDenominazione === null) {
                this.state.lastComune = currentComuneValue.istat;
                this.state.lastDenominazione = currentNomeValue;
                this.state.showDuplicateModal = true;
            }
            else {
                if (this.state.lastComune !== this.state.currentComune || this.state.lastDenominazione !== this.state.currentDenominazione) {
                    this.state.lastComune = this.state.currentComune;
                    this.state.lastDenominazione = this.state.currentDenominazione;
                    this.state.showDuplicateModal = true;
                }
                else {
                    this.state.showDuplicateModal = false;
                }
            }
        }
        else {
            this.state.showDuplicateModal = false;
        }
        return this.state.showDuplicateModal;
    };
    AsrInsertComponent.prototype.submitData = function (flag) {
        var dataEntry = {};
        this.recoverDataEntry(this.model, dataEntry);
        var tipologia_struttura = dataEntry['tipologia_struttura'];
        if (typeof dataEntry['gestore'] !== 'undefined') {
            if (dataEntry['inserisci_info_gestore'] === 'cerca') {
                var idImpresaGestElement = this.containerMap['dati_struttura-dati_gestore-id_gestore'];
                var idImpresa = idImpresaGestElement.control.value;
                dataEntry['gestore']['idImpresa'] = idImpresa;
                var stesseInfoEl = this.containerMap['dati_struttura-dati_gestore-stesse_info_gestore'];
                dataEntry['stesse_info_gestore'] = stesseInfoEl.control.value;
                if (dataEntry['stesse_info_gestore'] === 'No' && dataEntry['inserisci_info_proprietario'] === 'cerca') {
                    var id_path = 'dati_struttura-dati_proprietario-id_proprietario';
                    var idImpresaPropElement = this.containerMap[id_path];
                    var idImpresaProp = idImpresaPropElement.control.value;
                    dataEntry['proprietario']['idImpresa'] = idImpresaProp;
                }
            }
        }
        // if (typeof dataEntry['proprietario'] !== 'undefined') {
        //   if (dataEntry['inserisci_info'] === 'no') {
        //     let idImpresaPropElement = this.containerMap['dati_struttura-dati_impresa_proprietaria-id_proprietario'];
        //     let idImpresa = idImpresaPropElement.control.value;
        //     dataEntry['proprietario']['idImpresa'] = idImpresa;
        //   }
        //   let stesseInfoEl = this.containerMap['dati_struttura-dati_impresa_proprietaria-stesse_info_gestore'];
        //   dataEntry['stesse_info_gestore'] = stesseInfoEl.control.value;
        //   if (dataEntry['stesse_info_gestore'] === 'No' && dataEntry['inserisci_info_gestore'] === 'cerca') {
        //     let idImpresaGestElement = this.containerMap['dati_struttura-dati_impresa_gestore-id_gestore'];
        //     let idImpresa = idImpresaGestElement.control.value;
        //     dataEntry['gestore']['idImpresa'] = idImpresa;
        //   }
        // }
        dataEntry['bozza'] = flag === true ? "1" : "0";
        dataEntry['idUtente'] = this.userService.getUserId();
        var action = this.userActions.insertStruttura(dataEntry);
        if (this.updateDuplicateParams()) {
            //TODO SHOW DUPLICATI ON CHANGE STEP
            this.store.dispatch(this.userActions.userCheckDuplicati({
                comune: this.state.currentComune,
                nome: this.state.currentDenominazione,
                postAction: action
            }));
            this.state.showDuplicateModal = false;
        }
        else {
            this.store.dispatch(action);
        }
    };
    AsrInsertComponent.prototype.onInViewportChange = function ($event, element) {
        var currentSectionWeight = 0;
        var elementSectionWeight = 0;
        if (this.currentSectionInView) {
            var elementParentId = element.id.split('-');
            elementParentId.pop();
            elementParentId = elementParentId.join('-');
            if (this.currentSectionInView !== elementParentId) {
                currentSectionWeight = this.containerMap[this.currentSectionInView]['weight'];
                elementSectionWeight = this.containerMap[elementParentId]['weight'];
            }
        }
        if ($event) {
            if (!this.currentSubsectionInView) {
                this.currentSubsectionInView = element;
                element.inViewport = true;
                this.subsectioNoMoreInView = false;
            }
            else {
                if (this.subsectioNoMoreInView) {
                    this.currentSubsectionInView.inViewport = false;
                    this.markSectionAsTouched(this.currentSubsectionInView);
                    this.currentSubsectionInView = undefined;
                    this.currentSubsectionInView = element;
                    this.currentSubsectionInView.inViewport = true;
                    this.subsectioNoMoreInView = false;
                }
                else if (elementSectionWeight < currentSectionWeight) {
                    this.currentSubsectionInView.inViewport = false;
                    this.markSectionAsTouched(this.currentSubsectionInView);
                    this.queueSubsectionInView.unshift(this.currentSubsectionInView);
                    this.currentSubsectionInView = undefined;
                    this.currentSubsectionInView = element;
                    this.currentSubsectionInView.inViewport = true;
                    this.subsectioNoMoreInView = false;
                }
                else if (element.weight < this.currentSubsectionInView.weight && elementSectionWeight === currentSectionWeight) {
                    this.currentSubsectionInView.inViewport = false;
                    this.markSectionAsTouched(this.currentSubsectionInView);
                    this.queueSubsectionInView.unshift(this.currentSubsectionInView);
                    this.currentSubsectionInView = undefined;
                    this.currentSubsectionInView = element;
                    this.currentSubsectionInView.inViewport = true;
                    this.subsectioNoMoreInView = false;
                }
                else {
                    this.queueSubsectionInView.push(element);
                }
            }
        }
        else {
            if (this.currentSubsectionInView && this.currentSubsectionInView === element) {
                if (this.queueSubsectionInView.length > 0) {
                    element.inViewport = false;
                    this.currentSubsectionInView = this.queueSubsectionInView.shift();
                    this.currentSubsectionInView.inViewport = true;
                    this.markSectionAsTouched(element);
                }
                else {
                    this.subsectioNoMoreInView = true;
                }
            }
            else {
                var index = this.queueSubsectionInView.indexOf(element);
                if (index > -1) {
                    this.queueSubsectionInView.splice(index, 1);
                }
            }
        }
        if (this.currentSubsectionInView) {
            var path = this.currentSubsectionInView.id.split('-');
            path.pop();
            path = path.join('-');
            this.currentSectionInView = path;
        }
    };
    AsrInsertComponent.prototype.resetAffix = function () {
        if (this.currentSubsectionInView) {
            this.currentSubsectionInView.inViewport = false;
            this.currentSubsectionInView = undefined;
        }
        this.queueSubsectionInView.forEach(function (element) {
            element.inViewport = false;
        });
        this.queueSubsectionInView = [];
        this.subsectioNoMoreInView = false;
    };
    return AsrInsertComponent;
}(FormGeneratorController));
export { AsrInsertComponent };
