<div class="container">
    <div class="modal-header">
        <h3 class="modal-title"> <strong>{{data?.title}}</strong></h3>
    </div>
    <div class="modal-body">
            <p>Attenzione la struttura <strong>{{data?.strut?.oldName}}</strong> sta per essere ridenominata in
            <strong>{{data?.strut?.currentName}}</strong>.</p>
            <p>Proseguire comunque?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary"
            [ngClass]=" modalButton.class ?  modalButton.class : ''" *ngFor="let modalButton of data?.buttons"
            (click)="clickedButton(modalButton)">{{modalButton.label}}</button>
    </div>
</div>