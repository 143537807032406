
import { Validators, FormControl, FormGroup, FormArray, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { first, takeUntil, distinctUntilChanged, debounceTime, switchMap, map, tap } from 'rxjs/operators';
import { ControlHandlerI, HttpService } from './control-handler.interface';
import { Injectable } from '@angular/core';
import { FormGeneratorController } from '../form-generator';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, Subject } from 'rxjs';
import * as L from 'leaflet';
import { Icon, icon, Marker, marker } from 'leaflet';
import { helpers } from 'src/app/helpers';
import { getAutocompleteImpresaResponse } from 'src/app/logic/generic';
export class InputHandler implements ControlHandlerI {

    initialize(element: any) {
        let validators = this.getValidators(element);
        element.control = new FormControl(element.default_value, validators);
        element.parentControl.addControl(element.id, element.control);
        element.enabled = true;
    }

    getValidators(element): ValidatorFn[] {
        let validators: ValidatorFn[] = [];
        if (element.required === true) {
            validators.push(Validators.required);
        }
        if (typeof element.validators !== 'undefined') {
            if (typeof element.validators.maxLength !== 'undefined') {
                validators.push(Validators.maxLength(element.validators.maxLength));
            }
            if (typeof element.validators.minLength !== 'undefined') {
                validators.push(Validators.minLength(element.validators.minLength));
            }
            if (typeof element.validators.pattern !== 'undefined') {
                let isValidRegExp = true;
                try {
                    let tmp = new RegExp(element.validators.pattern);
                } catch (e) {
                    isValidRegExp = false;
                }
                if (isValidRegExp) {
                    validators.push(Validators.pattern(element.validators.pattern));
                }
            }
        }
        return validators;
    }

    setValidators(element,validators){
        if(!element.notEdit || (!!element.notEdit && !!element.enabled)){
           const c: AbstractControl =  element.control;
           c.clearValidators();
           c.setValidators(validators);
        }
    }

    onChange(element, controller: FormGeneratorController) {
        let c: FormControl = element.control;
        c.valueChanges.pipe(takeUntil(controller.destroyed$), distinctUntilChanged()).subscribe({
            next: (x) => controller.onModelChange(element),
            error: (err) => (console.log('Error on control')),
            complete: () => { }
        });
    }

    reset(element: any, options?: any) {
        let c: FormControl = element.control;
        c.reset(element.default_value, options);
        c.markAsUntouched();
        c.markAsPristine();
    }

    setValue(element: any, value: any, options?: any) {
        let c: FormControl = element.control;
        c.setValue(value, options);
    }

    getValue(element: any) {
        let c: FormControl = element.control;
        return !!c.value ? c.value : null;
    }

    patchValue(element: any, value: any, options?: any) {
        this.setValue(element, value, options);
    }

    setState(element: any, value: boolean, options?: any) {
        let c: FormControl = element.control;
        if (value) {
            c.enable();
        } else {
            c.disable();
        }
    }

    updateFromDS(element: any, params?) {
    }
}

export class NumberHandler implements ControlHandlerI {

    initialize(element: any) {
        let validators = this.getValidators(element);
        element.control = new FormControl(element.default_value, validators);
        element.parentControl.addControl(element.id, element.control);
        element.enabled = true;
    }

    setValidators(element,validators){
        if(!element.notEdit || (!!element.notEdit && !!element.enabled)){
           const c: AbstractControl =  element.control;
           c.clearValidators();
           c.setValidators(validators);
        }
    }
    
    getValidators(element): ValidatorFn[] {
        let validators: ValidatorFn[] = [];
        if (element.required === true) {
            validators.push(Validators.required);
        }
        if (typeof element.validators !== 'undefined') {
            if (element.validators.max !== 'undefined') {
                validators.push(Validators.max(element.validators.max));
            }
            if (element.validators.min !== 'undefined') {
                validators.push(Validators.min(element.validators.min));
            }
        }
        return validators;
    }

    onChange(element, controller: FormGeneratorController) {
        let c: FormControl = element.control;
        c.valueChanges.pipe(takeUntil(controller.destroyed$), distinctUntilChanged()).subscribe({
            next: (x) => controller.onModelChange(element),
            error: (err) => (console.log('Error on control')),
            complete: () => { }
        });
    }

    reset(element: any, options?: any) {
        let c: FormControl = element.control;
        c.reset(element.default_value, options);
        c.markAsUntouched();
        c.markAsPristine();
    }

    setValue(element: any, value: any, options?: any) {
        let c: FormControl = element.control;
        if(typeof value === 'string' && !isNaN(parseInt(value))){
            c.setValue(parseInt(value), options);
        } else if(typeof value === 'number'){
            c.setValue(value, options);
        }
    }

    getValue(element: any) {
        let c: FormControl = element.control;
        let value = c.value;
        if(typeof value === 'string' && !isNaN(parseInt(value))){
            return parseInt(value);
        } else if(typeof value === 'number'){
            return value;
        } else{
            return null;
        }
    }

    patchValue(element: any, value: any, options?: any) {
        this.setValue(element, value, options);
    }

    setState(element: any, value: boolean, options?: any) {
        let c: FormControl = element.control;
        if(element.notEdit){
            c.clearValidators();
            if (value) {
                element.enabled = true;
                let validators = this.getValidators(element);
                c.setValidators(validators);
                c.markAsTouched();
            } else {
                element.enabled = false;
            }
            c.updateValueAndValidity();
            
        } else{
            if (value) {
                c.enable();
            } else {
                c.disable();
            }
        }
    }

    updateFromDS(element: any, params?) {
    }
}

export class SelectHandler implements ControlHandlerI {

    constructor(private apiService: HttpService) { }

    initialize(element: any) {
        let validators = this.getValidators(element);
        if (typeof element.options !== 'object') {
            element.options = {};
        }

        if (typeof element.fixedOptions !== 'object') {
            element.fixedOptions = {};
        }
        element.control = new FormControl(element.default_value, validators);
        element.parentControl.addControl(element.id, element.control);
        element.enabled = true;
    }


    setValidators(element,validators){
        if(!element.notEdit || (!!element.notEdit && !!element.enabled)){
           const c: AbstractControl =  element.control;
           c.clearValidators();
           c.setValidators(validators);
        }
    }

    getValidators(element): ValidatorFn[] {
        let validators: ValidatorFn[] = [];
        if (element.required === true) {
            validators.push(Validators.required);
        }
        if (typeof element.validators !== 'undefined') {
            // TODO add validators
        }
        return validators;
    }

    onChange(element, controller: FormGeneratorController) {
        let c: FormControl = element.control;
        c.valueChanges.pipe(takeUntil(controller.destroyed$)).subscribe({
            next: (x) => controller.onModelChange(element),
            error: (err) => (console.log('Error on control')),
            complete: () => { }
        });
    }

    reset(element: any, options?: any) {
        let c: FormControl = element.control;
        c.reset(element.default_value, options);
        c.markAsUntouched();
        c.markAsPristine();
    }

    setValue(element: any, value: any, options?: any) {
        let c: FormControl = element.control;
        if (element && element.key && value && value[element.key]) {
            c.setValue(value[element.key], options);
        } else {
            c.setValue(value, options);
        }
    }

    getValue(element: any) {
        let c: FormControl = element.control;
        if (element.value) {
            return element.options[c.value];
        }
        return c.value;
    }

    patchValue(element: any, value: any, options?: any) {
        this.setValue(element, value, options);
    }

    setState(element: any, value: boolean, options?: any) {
        let c: FormControl = element.control;
        if (value) {
            c.enable();
        } else {
            c.disable();
        }
    }

    updateFromDS(element, params?) {
        if (element.optionsUpdate) {
            this.apiService.get(element.optionsUpdate, params).pipe(first()).subscribe({
                next: (x) => {
                    element.options = x['payload']['response'];
                    if (element.options) {
                        let possibleValues = Object.getOwnPropertyNames(element.options);
                        let currentValue = element.control.value;
                        if (!possibleValues.includes(currentValue)) {
                            element.control.setValue(null);
                        }
                    }
                },
                error: (err) => console.log('Warning! Impossibile retrieve options ' + element.id, err),
                complete: () => { }
            });
        }
    }
}

export class ButtonHandler implements ControlHandlerI {

    initialize(element: any) {
        let validators = this.getValidators(element);
        element.control = new FormControl(false, validators);
        element.control.markAsUntouched();
        element.parentControl.addControl(element.id, element.control);
        element.enabled = true;
    }


    setValidators(element,validators){
       
    }

    getValidators(element): ValidatorFn[] {
        let validators: ValidatorFn[] = [];
        // if (element.required === true) {
        //     validators.push(Validators.required);
        // }
        if (typeof element.validators !== 'undefined') {
            // TODO add validators
        }
        return validators;
    }

    onChange(element, controller: FormGeneratorController) {
        // let c: FormControl = element.control;
        // c.valueChanges.pipe(takeUntil(controller.destroyed$)).subscribe({
        //     next: (x) => controller.onModelChange(element),
        //     error: (err) => (console.log('Error on control'), err),
        //     complete: () => { }
        // });
    }

    reset(element: any, options?: any) {
        let c: FormControl = element.control;
        c.reset(false, options);
        c.markAsPristine();
        c.markAsUntouched();
    }

    setValue(element: any, value: any, options?: any) {
        let c: FormControl = element.control;
        c.setValue(value, options);
        c.markAsUntouched();
        c.markAsPristine();
    }

    getValue(element: any) {
       return undefined;
    }

    patchValue(element: any, value: any, options?: any) {
        this.setValue(element, value, options);
    }

    setState(element: any, value: boolean, options?: any) {
        let c: FormControl = element.control;
        if (value) {
            c.enable();
        } else {
            c.disable();
        }
    }

    updateFromDS(element, params?) {
    }
}

export class RadioHandler implements ControlHandlerI {

    initialize(element: any) {
        let validators = this.getValidators(element);
        element.control = new FormControl(element.default_value, validators);
        element.parentControl.addControl(element.id, element.control);
        element.enabled = true;
    }


    setValidators(element,validators){
        if(!element.notEdit || (!!element.notEdit && !!element.enabled)){
           const c: AbstractControl =  element.control;
           c.clearValidators();
           c.setValidators(validators);
        }
    }

    getValidators(element): ValidatorFn[] {
        let validators: ValidatorFn[] = [];
        if (element.required === true) {
            validators.push(Validators.required);
        }
        if (typeof element.validators !== 'undefined') {
            // TODO add validators
        }
        return validators;
    }

    onChange(element, controller: FormGeneratorController) {
        let c: FormControl = element.control;
        c.valueChanges.pipe(takeUntil(controller.destroyed$)).subscribe({
            next: (x) => controller.onModelChange(element),
            error: (err) => (console.log('Error on control'), err),
            complete: () => { }
        });
    }

    reset(element: any, options?: any) {
        let c: FormControl = element.control;
        c.reset(element.default_value, options);
        c.markAsUntouched();
        c.markAsPristine();
    }

    setValue(element: any, value: any, options?: any) {
        let c: FormControl = element.control;
        c.setValue(value, options);
    }

    getValue(element: any) {
        let c: FormControl = element.control;
        return c.value;
    }

    patchValue(element: any, value: any, options?: any) {
        this.setValue(element, value, options);
    }

    setState(element: any, value: boolean, options?: any) {
        let c: FormControl = element.control;
        if (value) {
            c.enable();
        } else {
            c.disable();
        }
    }

    updateFromDS(element, params?) {
    }
}

export class CheckboxHandler implements ControlHandlerI {

    initialize(element: any) {
        let validators = this.getValidators(element);
        if (typeof element.options === 'undefined') {
            element.options = {};
        }
        let opt = {};
        for (const optkey in element.options) {
            if (element.options.hasOwnProperty(optkey)) {
                opt[optkey] = new FormControl(!!element.default_value && Array.isArray(element.default_value) && element.default_value.includes(optkey));
            }
        }
        let optFormGroup = new FormGroup(opt, validators);
        let optValues = Object.values(optFormGroup.value);
        let allSelected = optValues.every(optValue => optValue ? true : false);
        let selectAllControl = new FormControl(allSelected);
        let controls = {
            options: optFormGroup,
            selectall: selectAllControl
        };
        selectAllControl.valueChanges.subscribe(bool => {
            let optionsValue = optFormGroup.value;
            Object.keys(optionsValue).forEach(i => optionsValue[i] = bool);
            optFormGroup.patchValue(optionsValue, { emitEvent: false });
        });
        optFormGroup.valueChanges.subscribe(val => {
            let currentSelectAll = selectAllControl.value;
            let optValues = Object.values(val);
            let allSelected = optValues.every(optValue => optValue ? true : false);
            if (currentSelectAll !== allSelected) {
                selectAllControl.patchValue(allSelected, { emitEvent: false });
            }
        });
        element.control = new FormGroup(controls);
        element.parentControl.addControl(element.options, element.control);
        element.enabled = true;
    }

    setValidators(element,validators){
        if(!element.notEdit || (!!element.notEdit && !!element.enabled)){
           const c: AbstractControl =  element.control;
           c.clearValidators();
           c.setValidators(validators);
        }
    }

    getValidators(element): ValidatorFn[] {
        let validators: ValidatorFn[] = [];
        if (element.required === true) {
            validators.push(Validators.required);
        }
        if (typeof element.validators !== 'undefined') {
            // TODO add validators
        }
        return validators;
    }

    onChange(element, controller: FormGeneratorController) {
        let c: FormControl = element.control;
        c.valueChanges.pipe(takeUntil(controller.destroyed$)).subscribe({
            next: (x) => controller.onModelChange(element),
            error: (err) => (console.log('Error on control', err)),
            complete: () => { }
        });
    }

    reset(element: any, options?: any) {
        let c: FormGroup = element.control;
        this.setValue(element, element.default_value)
        c.markAsUntouched();
        c.markAsPristine();
    }

    setValue(element: any, value: String[] = [], options?: any) {
        let c: FormGroup = element.control;
        let optGroup: FormGroup = <FormGroup>c.get('options');
        for (const optKeyControl in optGroup.controls) {
            if (optGroup.controls.hasOwnProperty(optKeyControl)) {
                const optControl = optGroup.controls[optKeyControl];
                if (value.includes(optKeyControl)) {
                    optControl.setValue(true, options);
                }else {
                    optControl.setValue(false, options);
                }
            }
        }
    }

    getValue(element: any): String[] {
        let c: FormGroup = element.control;
        let optGroup: FormGroup = <FormGroup>c.get('options');
        let value: string[] = [];
        for (const optKeyControl in optGroup.controls) {
            if (optGroup.controls.hasOwnProperty(optKeyControl)) {
                const optControl = optGroup.controls[optKeyControl];
                if (optControl.value) {
                    value.push(optKeyControl);
                }
            }
        }
        return value;
    }

    patchValue(element: any, value: any = [], options?: any) {
        let c: FormGroup = element.control;
        let optGroup: FormGroup = <FormGroup>c.get('options');
        for (const optKeyControl in optGroup.controls) {
            if (optGroup.controls.hasOwnProperty(optKeyControl)) {
                const optControl = optGroup.controls[optKeyControl];
                if (value.includes(optKeyControl)) {
                    optControl.setValue(true, options);
                }
            }
        }
    }

    setState(element: any, value: boolean, options?: any) {
        let c: FormGroup = element.control;
        if (value) {
            c.enable();
        } else {
            c.disable();
        }
    }

    updateFromDS(element, params?) {
    }
}

export class DateRangeHandler implements ControlHandlerI {

    constructor(
        private formatter: NgbDateParserFormatter
    ) {
    }

    initialize(element: any) {
        let validators = this.getValidators(element);
        let group: FormGroup = new FormGroup({
            dal: new FormControl(),
            al: new FormControl()
        }, { validators: this.dateRangeValidator });
        element.control = new FormArray([group]);
        element.addItem = this.addItem;
        element.removeItem = this.removeItem;
        element.parentControl.addControl(element.id, element.control);
        let now = new Date();
        let str = '' + now.getDate() + '/' + (now.getMonth() + 1) + '/' + now.getFullYear();
        element.maxDate = this.formatter.parse(str);
        element.minDate = this.formatter.parse('01/01/1900');
        element.enabled = true;
    }


    setValidators(element,validators){
        if(!element.notEdit || (!!element.notEdit && !!element.enabled)){
           const c: AbstractControl =  element.control;
           c.clearValidators();
           c.setValidators(validators);
        }
    }

    addItem = (element, i) => {
        if (!element || !element.control || !(element.control instanceof FormArray)) {
            return;
        }
        let array: FormArray = element.control;
        if (!!element.maxRepeat && array.controls.length > element.maxRepeat) {
            return;
        }
        array.insert(i + 1, new FormGroup({
            dal: new FormControl(),
            al: new FormControl()
        }, { validators: this.dateRangeValidator }));
    }

    removeItem = (element, i) => {
        if (!element || !element.control || !(element.control instanceof FormArray)) {
            return;
        }
        let array: FormArray = element.control;
        array.removeAt(i);
    }

    getValidators(element): ValidatorFn[] {
        let validators: ValidatorFn[] = [];
        if (element.required === true) {
            validators.push(Validators.required);
        }
        if (typeof element.validators !== 'undefined') {
            // TODO add validators
        }
        return validators;
    }

    onChange(element, controller: FormGeneratorController) {
        let c: FormControl = element.control;
        c.valueChanges.pipe(takeUntil(controller.destroyed$)).subscribe({
            next: (x) => controller.onModelChange(element),
            error: (err) => (console.log('Error on control')),
            complete: () => { }
        });
    }

    reset(element: any, options?: any) {
        let c: FormControl = element.control;
        c.reset(element.default_value, options);
        c.markAsUntouched();
        c.markAsPristine();
    }

    setValue(element: any, value: any, options?: any) {
        let c: FormArray = element.control;
        this.clearFormArray(c);
        if (this.isDateRange(value)) {
            for (let i = 0; i < value.length; i++) {
                const element = value[i];
                let group = new FormGroup({
                    dal: new FormControl(this.formatter.parse(element.dal)),
                    al: new FormControl(this.formatter.parse(element.al)),
                }, { validators: this.dateRangeValidator });
                c.push(group);
            }
        } else {
            c.push(new FormGroup({
                dal: new FormControl(),
                al: new FormControl()
            }, { validators: this.dateRangeValidator }));
        }
        return c;
    }

    private clearFormArray = (formArray: FormArray) => {
        while (formArray.length !== 0) {
            formArray.removeAt(0)
        }
    }

    getValue(element: any) {
        let c: FormArray = element.control;
        let value = [];
        for (let i = 0; i < c.controls.length; i++) {
            let group: FormGroup = <FormGroup>c.controls[i];
            if (group.valid) {
                let groupControls = group.controls;
                let dal = this.formatter.format(groupControls.dal.value);
                let al = this.formatter.format(groupControls.al.value);
                if (typeof dal === 'string' && typeof al === 'string') {
                    value.push({ dal, al })
                }
            }
        }
        return value
    }

    patchValue(element: any, value: any, options?: any) {
        this.setValue(element, value, options);
    }

    setState(element: any, value: boolean, options?: any) {
        let c: FormControl = element.control;
        if (value) {
            c.enable();
        } else {
            c.disable();
        }
    }

    updateFromDS(element, params?) {
    }

    private isDateRange(value): boolean {
        if (!Array.isArray(value) || value.length === 0) {
            return false;
        }
        let check = true;
        for (let i = 0; i < value.length; i++) {
            const element = value[i];
            if (typeof element['dal'] !== 'string' && typeof element['al'] !== 'string') {
                check = false;
                break;
            }
        }
        return check;
    }
    private dateRangeValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
        const dal = control.get('dal');
        const al = control.get('al');
        let check = (!dal || !al) || (!dal.value && al.value) || (dal.value && !al.value) || (dal.invalid || al.invalid);

        return dal && al && check ? { 'dateRange': true } : null;
    };
}

export class TextfieldHandler implements ControlHandlerI {

    constructor(private http: HttpClient) { }

    initialize(element: any) {
        let validators = this.getValidators(element);
        element.control = new FormControl(element.default_value, validators);
        element.search = this.searchAutocompleteFactory(element.autocomplete, element.fixedOptions);
        element.formatter = this.formatterAutocompleteFactory(element.autocomplete);
        element.parentControl.addControl(element.id, element.control);
        element.enabled = true;
    }

    setValidators(element,validators){
        if(!element.notEdit || (!!element.notEdit && !!element.enabled)){
           const c: AbstractControl =  element.control;
           c.clearValidators();
           c.setValidators(validators);
        }
    }

    getValidators(element): ValidatorFn[] {
        let validators: ValidatorFn[] = element.autocomplete.local ? []: [this.customValidator()];
        if (element.required === true) {
            validators.push(Validators.required);
        }
        if (typeof element.validators !== 'undefined') {
            // TODO add validators
        }
        return validators;
    }

    onChange(element, controller: FormGeneratorController) {
        let c: FormControl = element.control;
        c.valueChanges.pipe(takeUntil(controller.destroyed$)).subscribe({
            next: (x) => controller.onModelChange(element),
            error: (err) => (console.log('Error on control')),
            complete: () => { }
        });
    }

    reset(element: any, options?: any): any {
        let c: FormControl = element.control;
        c.reset(element.default_value, options);
        c.markAsUntouched();
        c.markAsPristine();
    }

    setValue(element: any, value: any, options?: any) {
        let c: FormControl = element.control;
        c.setValue(value, options);
    }

    getValue(element: any) {
        let c: FormControl = element.control;
        return c.value;
    }

    patchValue(element: any, value: any, options?: any) {
        this.setValue(element, value, options);
    }

    setState(element: any, value: boolean, options?: any) {
        let c: FormControl = element.control;
        if (!!value) {
            c.enable();
        } else {
            c.disable();
        }
    }

    updateFromDS(element, params?) {
    }

    private searchAutocompleteFactory($param: any, fixedOptions: any): (text: Observable<string>) => Observable<any[]> {
        if (typeof $param === 'undefined' || (typeof $param.path !== 'string' && !$param.local)) {
            return (text$: Observable<string>) =>
                text$.pipe(
                    debounceTime(200),
                    distinctUntilChanged(),
                    switchMap(term => of([]))
                );
        }
        const debTime = $param.startDelay ? $param.startDelay : 200;
        return (text$: Observable<string>) =>
            text$.pipe(
                debounceTime(debTime),
                distinctUntilChanged(),
                switchMap(term => term === '' ? of({ status: 'OK', payload: { results: [] } }) :
                    $param.local ? of({ status: 'OK', payload: { results: this.filterResults($param.data, term) } }) :
                        this.requestAutocomplete$(environment.baseURL + $param.path, term, $param.staticParams)),
                map(res => !fixedOptions ? res['payload']['results'] : res['payload']['results'].concat(fixedOptions) )
            );
    }

    private filterResults = (results, term) => {
        return results.filter((v) => {
            if (typeof v === 'string') {
                let val = v.toLowerCase();
                let t = term.toLowerCase();
                return val.startsWith(t);
            } else { return false; }
        }).slice(0,10);
    }

    private formatterAutocompleteFactory(params) {
        if (typeof params === 'undefined' || typeof params.display === 'undefined' || !Array.isArray(params.display)) {
            return (result) => (result ? result.trim() : undefined);
        }
        return (result) => {
            let displayString;
            for (let i = 0; i < params.display.length; i++) {
                const element = params.display[i];
                if (typeof element === 'string') {
                    let str = helpers.getNestedProp(element.split('.'), result);
                    displayString = displayString ? displayString + str : str;
                }
            }
            return displayString;
        };
    }

    private requestAutocomplete$(path, term, staticParams) {
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            withCredentials: true
        };
        let paramString = '';
        if (typeof staticParams === 'object') {
            for (const paramKey in staticParams) {
                if (staticParams.hasOwnProperty(paramKey)) {
                    paramString = paramString + '&';
                    const paramKeyword = staticParams[paramKey];
                    paramString = paramString + paramKey + '=' + paramKeyword;
                }
            }
        }
        const url = path + '?'
            + 'lookupKey' + '=' + term + paramString;
        return this.http.get(url, httpOptions);
    }

    private customValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            let value = control.value;
            let forbidden = typeof value === 'string' && value !== '' ? true : false;
            return forbidden ? { 'invalidChoice': { value: control.value } } : null;
        };
    }
}

export class DateHandler implements ControlHandlerI {

    constructor(private formatter: NgbDateParserFormatter) {
    }

    initialize(element: any) {
        let validators = this.getValidators(element);
        element.control = new FormControl(element.default_value, validators);
        element.parentControl.addControl(element.id, element.control);
        let now = new Date();
        let str = '' + now.getDate() + '/' + (now.getMonth() + 1) + '/' + now.getFullYear();
        element.maxDate = this.formatter.parse(str);
        element.minDate = this.formatter.parse('01/01/1900');
        element.enabled = true;
    }


    setValidators(element,validators){
        if(!element.notEdit || (!!element.notEdit && !!element.enabled)){
           const c: AbstractControl =  element.control;
           c.clearValidators();
           c.setValidators(validators);
        }
    }

    getValidators(element): ValidatorFn[] {
        let validators: ValidatorFn[] = [];
        if (element.required === true) {
            validators.push(Validators.required);
        }
        if (typeof element.validators !== 'undefined') {
            // TODO add validators
        }
        return validators;
    }

    onChange(element, controller: FormGeneratorController) {
        let c: FormControl = element.control;
        c.valueChanges.pipe(takeUntil(controller.destroyed$)).subscribe({
            next: (x) => controller.onModelChange(element),
            error: (err) => (console.log('Error on control')),
            complete: () => { }
        });
    }

    reset(element: any, options?: any) {
        let c: FormControl = element.control;
        c.reset(element.default_value, options);
        c.markAsUntouched();
        c.markAsPristine();
    }

    setValue(element: any, value: any, options?: any) {
        let c: FormControl = element.control;
        let formattedValue = this.formatter.parse(value);
        c.setValue(formattedValue, options);
    }

    getValue(element: any) {
        let c: FormControl = element.control;
        return this.formatter.format(c.value);
    }

    patchValue(element: any, value: any, options?: any) {
        this.setValue(element, value, options);
    }

    setState(element: any, value: boolean, options?: any) {
        let c: FormControl = element.control;
        if (value) {
            c.enable();
        } else {
            c.disable();
        }
    }

    updateFromDS(element, params?) {
    }

}

export class SearchBarHandler implements ControlHandlerI {

    constructor(private http: HttpClient) { }

    initialize(element: any) {
        let validators = this.getValidators(element);
        element.control = new FormControl(undefined, validators);
        element.control.setValue(undefined);
        element.search = this.searchAutocompleteFactory(element.autocomplete);
        element.formatter = this.formatterAutocompleteFactory(element.autocomplete);
        element.parentControl.addControl(element.id, element.control);
        element.enabled = true;
    }


    setValidators(element,validators){
        if(!element.notEdit || (!!element.notEdit && !!element.enabled)){
           const c: AbstractControl =  element.control;
           c.clearValidators();
           c.setValidators(validators);
        }
    }

    getValidators(element): ValidatorFn[] {
        let validators: ValidatorFn[] = [this.customValidator()];
        if (element.required === true) {
            validators.push(Validators.required);
        }
        if (typeof element.validators !== 'undefined') {
            // TODO add validators
        }
        return validators;
    }

    onChange(element, controller: FormGeneratorController) {
        let c: FormControl = element.control;
        c.valueChanges.pipe(takeUntil(controller.destroyed$)).subscribe({
            next: (x) => { },
            error: (err) => (console.log('Error on control')),
            complete: () => { }
        });
    }

    reset(element: any, options?: any): any {
        let c: FormControl = element.control;
        c.setValue(undefined, options);
        c.markAsUntouched();
        c.markAsPristine();
    }

    setValue(element: any, value: any, options?: any) {
        let c: FormControl = element.control;
        c.setValue(value, options);
    }

    getValue(element: any) {
        let c: FormControl = element.control;
        return c.value;
    }

    patchValue(element: any, value: any, options?: any) {
        this.setValue(element, value, options);
    }

    setState(element: any, value: boolean, options?: any) {
        let c: FormControl = element.control;
        if (value) {
            c.enable();
        } else {
            c.disable();
        }
    }

    updateFromDS(element, params?) {
    }

    private searchAutocompleteFactory($param: any): (text: Observable<string>) => Observable<any[]> {
        if (typeof $param === 'undefined' || typeof $param.path !== 'string') {
            return (text$: Observable<string>) =>
                text$.pipe(
                    debounceTime(200),
                    distinctUntilChanged(),
                    switchMap(term => of([]))
                );
        }
        const debTime = $param.startDelay ? $param.startDelay : 200;
        return (text$: Observable<string>) =>
            text$.pipe(
                debounceTime(debTime),
                distinctUntilChanged(),
                switchMap(term => term === '' ? of({ status: 'ok', payload: { results: [] } })
                    : environment.requests.autocomplete.mock ? of(getAutocompleteImpresaResponse()) :
                        this.requestAutocomplete$(environment.baseURL + $param.path, term, $param.staticParams)),
                map(res => res['payload']['results'])
            );
    }

    private formatterAutocompleteFactory(params) {
        if (typeof params === 'undefined' || typeof params.display === 'undefined' || !Array.isArray(params.display)) {
            return (result) => (result ? result.trim() : undefined);
        }
        return (result) => {
            let displayString;
            for (let i = 0; i < params.display.length; i++) {
                const element = params.display[i];
                if (typeof element === 'string') {
                    let str = helpers.getNestedProp(element.split('.'), result);
                    displayString = displayString ? displayString + str : str;
                }
            }
            return displayString;
        };
    }

    private requestAutocomplete$(path, term, staticParams) {
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            withCredentials: true
        };
        let paramString = '';
        if (typeof staticParams === 'object') {
            for (const paramKey in staticParams) {
                if (staticParams.hasOwnProperty(paramKey)) {
                    paramString = paramString + '&';
                    const paramKeyword = staticParams[paramKey];
                    paramString = paramString + paramKey + '=' + paramKeyword;
                }
            }
        }
        const url = path + '?'
            + 'lookupKey' + '=' + term + paramString;
        return this.http.get(url, httpOptions);
    }

    private customValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            let value = control.value;
            let forbidden = typeof value === 'string' && value !== '' ? true : false;
            return forbidden ? { 'invalidChoice': { value: control.value } } : null;
        };
    }
}

export class ContainerHandler implements ControlHandlerI {

    initialize(element: any) {
        element.control = new FormGroup({});
        element.parentControl.addControl(element.id, element.control);
        if(element.optional){
            element.toggleState = new FormControl(true);
        }
        element.enabled = true;
    }

    setValidators(element,validators){
        if(!element.notEdit || (!!element.notEdit && !!element.enabled)){
           const c: AbstractControl =  element.control;
           c.clearValidators();
           c.setValidators(validators);
        }
    }

    getValidators(element): ValidatorFn[] {
        return [];
    }

    onChange(element, controller: FormGeneratorController) {
        let c: FormControl = element.control;
        if(element.optional){
            element.toggleState.valueChanges.pipe(takeUntil(controller.destroyed$),distinctUntilChanged()).subscribe({
                next: (x) => { 
                    this.setChildrenState(element, x, controller);
                },
                error: (err) => (console.log('Error on control')),
                complete: () => { }
            });
        }
    }

    setChildrenState(element, value, controller: FormGeneratorController){
        if(!element.children){
            return;
        }
        for (const childKey in element.children) {
            if (element.children.hasOwnProperty(childKey)) {
                const child = element.children[childKey];
               let handler = controller.controlHandlerFactory.getHandler(child.type);
               handler.setState(child,value);
               controller.onModelChange(child);
            }
        }
    }

    reset(element: any, options?: any) {
        let c: FormGroup = element.control;
        c.markAsPristine();
        c.markAsUntouched();
    }

    setValue(element: any, value: any, options?: any) {
        if(element.optional){
            element.toggleState.setValue(!value);
        }
    }

    getValue(element: any) {
        if(element.optional){
            return !element.toggleState.value
        }
        return true;
    }

    patchValue(element: any, value: any, options?: any) {
        if(element.optional){
            element.toggleState.setValue(!value);
        }
    }

    setState(element: any, value: boolean, options?: any) {
        let c: FormGroup = element.control;
        if (value) {
            c.enable();
        } else {
            c.disable();
        }
    }

    updateFromDS(element, params?) {
    }
}

export class MapHandler implements ControlHandlerI {

    constructor(private http: HttpClient) { }

    // Override default Icons
    private iconOptions = {
        iconSize: [25, 41],
        iconAnchor: [13, 41],
        iconUrl: 'leaflet/images/marker-icon.png',
        shadowUrl: 'leaflet/images/marker-shadow.png'
    };

    private defaultIcon: Icon = icon(this.iconOptions);
    initialize(element: any): void {
        const lat: FormControl = new FormControl(40.097262, [Validators.required]);
        const lng: FormControl = new FormControl(9.140745, [Validators.required]);
        lat.disable();
        lng.disable();
        element.control = new FormGroup({
            lat: lat,
            lng: lng,
        });
        element.toggle = new FormControl(!!element.geocoding);
        element.toggle.valueChanges.subscribe((x) => {
            if (!!element.map && !!element.map.eachLayer) {
                element.map.eachLayer((layer) => {
                    if (layer.options.id === 'geo-marker') {
                        if (!x) {
                            layer.dragging.enable();
                        } else {
                            layer.dragging.disable();
                        }
                    }
                });
            }
        });
        element._lastUpdates = new Subject();
        element._lastUpdates.pipe(
            debounceTime(1000),
            switchMap(val => of(val))).subscribe((val) => this.geocoding(val, element));
        element.mapOptions = () => {
            return {
                layers: [L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { attribution: '...' })],
                zoom: 7,
                center: new L.LatLng(40.097262, 9.140745),
            };
        };
        element._getLayers = () => {
            let currentLat = element.control.controls['lat'].value;
            let currentLng = element.control.controls['lng'].value;
            if (!element._marker) {
                let marker = L.marker(new L.LatLng(currentLat, currentLng), {
                    id: 'geo-marker',
                    icon: this.defaultIcon
                });
                marker.on('dragend', (e) => {
                    element.control.controls['lat'].setValue(marker.getLatLng().lat);
                    element.control.controls['lng'].setValue(marker.getLatLng().lng);
                });
                element._marker = marker;
            }
            element._marker.options.draggable = !element.toggle.value;
            return element._marker;
        };

        element._onMapReady = function (leafletMap: L.Map) {

            let currentLat = element.control.controls['lat'].value;
            let currentLng = element.control.controls['lng'].value;
            setTimeout(() => {
                leafletMap.invalidateSize();
            }, 0);
            element.map = leafletMap;
            leafletMap.setView(new L.LatLng(currentLat, currentLng), 18);
        };
    }

    getValidators(element: any): ValidatorFn[] {
        return [];
    }

    onChange(element: any, controller: FormGeneratorController): void {
    }

    reset(element: any, options?: any): void {
        const ctrl: FormControl = element.toggle;
        if (ctrl && ctrl.reset) {
            ctrl.reset(!!element.geocoding);
            ctrl.markAsUntouched();
            ctrl.markAsPristine();
        }
        this.setValue(element, { lat: 40.097262, lng: 9.140745 });
    }

    setValue(element: any, value: any, options?: any): void {
        if (value.lat && value.lng) {
            element.control.setValue(value, options);
            if (!!element._marker) {
                element._marker.setLatLng(new L.LatLng(value.lat, value.lng));
                element.map.setView(new L.LatLng(value.lat, value.lng), 18);
            }
        }
    }

    getValue(element: any): void {
        let c: FormControl = element.control;
        return c.value;
    }

    patchValue(element: any, value: any, options?: any): void {
        this.setValue(element, value, options);
    }

    setState(element: any, value: boolean, options?: any): void {
    }

    updateFromDS(element: any, params?: any): void {
        if (element.toggle.value && params) {
            let request = '';
            let comune = params[0];
            let indirizzo = params[1]
            if(!!indirizzo && !!comune && !!comune.comune){
                request += `${indirizzo}, ${comune.comune}`; 
                element._lastUpdates.next(request);
            }
        }
    }

    private geocoding(address: string, element) {
        if (!!address) {
            let request = environment.baseURL + environment.requests.geocode.path + '?q=' + address;
            this.http.get(request, { withCredentials: true }).subscribe((response) => {
                if (response['status'] === 'OK') {
                    let location = response['payload']['location'];
                    if (location.lat && location.lng) {
                        this.setValue(element, location);
                    }
                }
            });
        }
    }

    setValidators(element,validators){
        if(!element.notEdit || (!!element.notEdit && !!element.enabled)){
           const c: AbstractControl =  element.control;
           c.clearValidators();
           c.setValidators(validators);
        }
    }
}

export class SummaryHandler implements ControlHandlerI {

    initialize(element: any) {
        element.control = new FormGroup({});
        element.parentControl.addControl(element.id, element.control);
    }

    getValidators(element): ValidatorFn[] {
        return [];
    }

    onChange(element, controller: FormGeneratorController) {
    }

    reset(element: any, options?: any) {
    }

    setValue(element: any, value: any, options?: any) {
    }

    getValue(element: any) {
    }

    patchValue(element: any, value: any, options?: any) {
    }

    setState(element: any, value: boolean, options?: any) {
    }

    updateFromDS(element, params?) {
    }

    setValidators(element,validators){
    }
}

@Injectable()
export class ControlHandlerFactory {

    constructor(
        private http: HttpClient,
        private apiService: HttpService,
        private formatter: NgbDateParserFormatter) {
    }

    getHandler(type): ControlHandlerI {
        switch (type) {
            case 'button':
                return new ButtonHandler();
            case 'info':
            case 'textarea':
            case 'input':
                return new InputHandler();
            case 'number':
                return new NumberHandler();
            case 'select':
                return new SelectHandler(this.apiService);
            case 'radio':
                return new RadioHandler();
            case 'checkbox':
                return new CheckboxHandler();
            case 'textfield':
                return new TextfieldHandler(this.http);
            case 'date':
                return new DateHandler(this.formatter);
            case 'search_step':
                return new SearchBarHandler(this.http);
            case 'summary':
                return new SummaryHandler();
            case 'map':
                return new MapHandler(this.http);
            case 'date-range':
                return new DateRangeHandler(this.formatter);
            case 'container':
            case 'section':
                return new ContainerHandler();
            default:
                return undefined;
        }
    }
}

