var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, Injector } from '@angular/core';
import { FormGeneratorController, LEAF_TYPES, CONTAINER_TYPES } from 'src/app/form-generator/form-generator';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { GenericSelectors } from 'src/app/logic/generic';
import { UserActions } from 'src/app/logic/user';
import { Store } from '@ngrx/store';
import { FormGroup } from '@angular/forms';
import { AppFlowActions } from 'src/app/logic/app-flow/app-flow.actions';
import { AppFlowSelectors } from 'src/app/logic/app-flow/app-flow.selectors';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { getEditImpresaModel } from 'src/app/form-model';
import { UserService } from 'src/app/logic/user/user.service';
var AsrEditImpresaComponent = /** @class */ (function (_super) {
    __extends(AsrEditImpresaComponent, _super);
    function AsrEditImpresaComponent(injector, genericSelectors, flowSelectors$, flowActions, store, userActions, router, dateFormatter, activatedRoute, userService) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.genericSelectors = genericSelectors;
        _this.flowSelectors$ = flowSelectors$;
        _this.flowActions = flowActions;
        _this.store = store;
        _this.userActions = userActions;
        _this.router = router;
        _this.dateFormatter = dateFormatter;
        _this.activatedRoute = activatedRoute;
        _this.userService = userService;
        _this.queueSubsectionInView = [];
        _this.subsectioNoMoreInView = false;
        _this.inViewportOptions = {
            rootMargin: '0px',
            threshold: 1.0
        };
        return _this;
    }
    AsrEditImpresaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataEntryMap = {};
        this.events = {};
        this.isLoading$ = this.genericSelectors.isLoading$();
        this.model = getEditImpresaModel(false);
        this.formGroup = new FormGroup({});
        this.initializeModel(this.model, this.formGroup);
        for (var fieldId in this.events) {
            if (this.events.hasOwnProperty(fieldId)) {
                this.onModelChange(this.containerMap[fieldId]);
            }
        }
        this.flowSelectors$.getCurrentStep$().pipe(takeUntil(this.destroyed$)).subscribe({
            next: function (x) {
                _this.current_step = x;
                _this.router.navigate([], {
                    relativeTo: _this.activatedRoute,
                    queryParams: { step: x },
                    queryParamsHandling: 'merge'
                });
            },
            error: function (err) { },
            complete: function () { }
        });
        var id = this.activatedRoute.snapshot.queryParams['id'];
        var step = this.activatedRoute.snapshot.queryParams['step'] || 'ricerca';
        this.steps = Object.getOwnPropertyNames(this.model);
        if (!this.steps.includes(step)) {
            step = 'ricerca';
        }
        if (!id) {
            this.store.dispatch(this.flowActions.setCurrentSection({ steps: this.steps, current_step: 'ricerca' }));
        }
        else {
            this.store.dispatch(this.flowActions.loadImpresaAndMove({ id: id, current_step: step, steps: this.steps }));
        }
        var ricerca = this.model['ricerca'].control;
        ricerca.valueChanges.pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe({
            next: function (val) { _this.onSelectedImpresa(val); },
            error: function () { },
            complete: function () { }
        });
        this.flowSelectors$.getCurrentItem$().pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe({
            next: function (x) {
                _this.current_item = x;
                if (x === null) {
                    _this.resetModel();
                    _this.formGroup.markAsUntouched();
                    _this.formGroup.markAsPristine();
                }
                else {
                    // this.resetModel();
                    _this.patchModel(x, _this.dataEntryMap);
                    _this.markModelAsTouched(_this.model);
                    ricerca.patchValue({ dataEntry: { ragioneSociale: x['ragioneSociale'] } }, { emitEvent: false });
                    ricerca.markAsTouched();
                }
            },
            error: function (err) { },
            complete: function () { }
        });
    };
    AsrEditImpresaComponent.prototype.getQueryParams = function () {
        if (this.current_item) {
            return {
                id: this.current_item && this.current_item.idImpresa,
                step: this.current_step
            };
        }
    };
    AsrEditImpresaComponent.prototype.markSectionAsTouched = function (element) {
        if (element.visible.getValue() === false) {
            return;
        }
        if (LEAF_TYPES.includes(element.type)) {
            var c = element.control;
            c.markAsTouched({ onlySelf: true });
        }
        else if (CONTAINER_TYPES.includes(element.type)) {
            var c = element.control;
            c.markAsTouched({ onlySelf: true });
            for (var child in element.children) {
                if (element.children.hasOwnProperty(child)) {
                    this.markSectionAsTouched(element.children[child]);
                }
            }
        }
    };
    AsrEditImpresaComponent.prototype.markModelAsTouched = function (model) {
        for (var key in model) {
            if (model.hasOwnProperty(key)) {
                var element = model[key];
                this.markSectionAsTouched(element);
            }
        }
    };
    AsrEditImpresaComponent.prototype.ngOnDestroy = function () {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    };
    AsrEditImpresaComponent.prototype.onSelectedImpresa = function ($event) {
        if (typeof $event !== 'undefined') {
            var next_step = 'dati_impresa';
            this.store.dispatch(this.flowActions.loadImpresaAndMove({ id: $event.id, current_step: next_step, steps: this.steps }));
        }
        else {
            this.store.dispatch(this.flowActions.setCurrentItem(null));
        }
    };
    AsrEditImpresaComponent.prototype.changeSection = function (section) {
        if (this.current_item !== null) {
            this.markSectionAsTouched(this.model[this.current_step]);
            this.store.dispatch(this.flowActions.setCurrentStep(section));
            this.resetAffix();
        }
    };
    AsrEditImpresaComponent.prototype.goToPreviousSection = function () {
        if (this.current_item == null || this.current_step == null) {
            return;
        }
        var currentSection = this.model[this.current_step];
        var currentWeight = currentSection.weight;
        for (var sectionId in this.model) {
            if (this.model.hasOwnProperty(sectionId)) {
                var section = this.model[sectionId];
                if (section.weight + 1 === currentWeight) {
                    this.store.dispatch(this.flowActions.setCurrentStep(sectionId));
                    this.markSectionAsTouched(currentSection);
                    window.scrollTo(0, 0);
                    this.resetAffix();
                }
            }
        }
    };
    AsrEditImpresaComponent.prototype.submitData = function () {
        var dataEntry = {};
        this.recoverDataEntry(this.model, dataEntry);
        dataEntry['idImpresa'] = this.current_item.idImpresa;
        dataEntry['idUtente'] = this.userService.getUserId();
        if (dataEntry['ricerca']) {
            delete dataEntry['ricerca'];
        }
        this.store.dispatch(this.userActions.editImpresa(dataEntry));
    };
    AsrEditImpresaComponent.prototype.displayStrutModal = function (results) {
        if (typeof results === 'undefined') {
            return;
        }
        this.store.dispatch(this.userActions.getStrutByImpresaSuccess({ nome: this.current_item.ragioneSociale, results: results }));
    };
    AsrEditImpresaComponent.prototype.onInViewportChange = function ($event, element) {
        var currentSectionWeight = 0;
        var elementSectionWeight = 0;
        if (this.currentSectionInView) {
            var elementParentId = element.id.split('-');
            elementParentId.pop();
            elementParentId = elementParentId.join('-');
            if (this.currentSectionInView !== elementParentId) {
                currentSectionWeight = this.containerMap[this.currentSectionInView]['weight'];
                elementSectionWeight = this.containerMap[elementParentId]['weight'];
            }
        }
        if ($event) {
            if (!this.currentSubsectionInView) {
                this.currentSubsectionInView = element;
                element.inViewport = true;
                this.subsectioNoMoreInView = false;
            }
            else {
                if (this.subsectioNoMoreInView) {
                    this.currentSubsectionInView.inViewport = false;
                    this.markSectionAsTouched(this.currentSubsectionInView);
                    this.currentSubsectionInView = undefined;
                    this.currentSubsectionInView = element;
                    this.currentSubsectionInView.inViewport = true;
                    this.subsectioNoMoreInView = false;
                }
                else if (elementSectionWeight < currentSectionWeight) {
                    this.currentSubsectionInView.inViewport = false;
                    this.markSectionAsTouched(this.currentSubsectionInView);
                    this.queueSubsectionInView.unshift(this.currentSubsectionInView);
                    this.currentSubsectionInView = undefined;
                    this.currentSubsectionInView = element;
                    this.currentSubsectionInView.inViewport = true;
                    this.subsectioNoMoreInView = false;
                }
                else if (element.weight < this.currentSubsectionInView.weight && elementSectionWeight === currentSectionWeight) {
                    this.currentSubsectionInView.inViewport = false;
                    this.markSectionAsTouched(this.currentSubsectionInView);
                    this.queueSubsectionInView.unshift(this.currentSubsectionInView);
                    this.currentSubsectionInView = undefined;
                    this.currentSubsectionInView = element;
                    this.currentSubsectionInView.inViewport = true;
                    this.subsectioNoMoreInView = false;
                }
                else {
                    this.queueSubsectionInView.push(element);
                }
            }
        }
        else {
            if (this.currentSubsectionInView && this.currentSubsectionInView === element) {
                if (this.queueSubsectionInView.length > 0) {
                    element.inViewport = false;
                    this.currentSubsectionInView = this.queueSubsectionInView.shift();
                    this.currentSubsectionInView.inViewport = true;
                    this.markSectionAsTouched(element);
                }
                else {
                    this.subsectioNoMoreInView = true;
                }
            }
            else {
                var index = this.queueSubsectionInView.indexOf(element);
                if (index > -1) {
                    this.queueSubsectionInView.splice(index, 1);
                }
            }
        }
        if (this.currentSubsectionInView) {
            var path = this.currentSubsectionInView.id.split('-');
            path.pop();
            path = path.join('-');
            this.currentSectionInView = path;
        }
    };
    AsrEditImpresaComponent.prototype.resetAffix = function () {
        if (this.currentSubsectionInView) {
            this.currentSubsectionInView.inViewport = false;
            this.currentSubsectionInView = undefined;
        }
        this.queueSubsectionInView.forEach(function (element) {
            element.inViewport = false;
        });
        this.queueSubsectionInView = [];
        this.subsectioNoMoreInView = false;
    };
    return AsrEditImpresaComponent;
}(FormGeneratorController));
export { AsrEditImpresaComponent };
