import { Actions, Effect } from "@ngrx/effects";
import { Router, ActivatedRoute } from "@angular/router";
import { UserRequests, UserActions } from "../user";
import { AppFlowActions } from "./app-flow.actions";
import { Injectable } from "@angular/core";
import { map, switchMap, catchError, tap } from "rxjs/operators";
import { of, merge } from "rxjs";

@Injectable()
export class AppFlowEffects {
    constructor(
        private actions$: Actions,
        private userRequests$: UserRequests,
        private userActions$: UserActions,
        private flowActions: AppFlowActions,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    @Effect() loadData$ = this.actions$.ofType( AppFlowActions.LOAD_STRUT_AND_MOVE )
    .pipe(
        map( action => action['payload'] ),
        switchMap( payload => this.userRequests$.fetchDataEntryStruttura$( payload.id, payload.type )
            .pipe(
                switchMap( response => ( response['status'] === 'OK' && typeof response['payload']['dataEntry'] !== 'undefined' ) ?
                    of( this.flowActions.loadStrutAndMoveSuccess( { current_item: response['payload'], current_step: payload.current_step } ) ) :
                    of( this.flowActions.loadStrutAndMoveFail( 'Impossibile trovare la struttura selezionata.' ) )
                ),
                catchError( err => [this.flowActions.loadStrutAndMoveFail( 'Attenzione! Non è stato possibile caricare i dati della struttura.' )] )
            )
        ),
        catchError( err => [this.flowActions.loadStrutAndMoveFail( 'Impossibile trovare la struttura selezionata.' )] )
    );

    @Effect() loadImpresa$ = this.actions$.ofType( AppFlowActions.LOAD_IMPRESA_AND_MOVE )
    .pipe(
        map( action => action['payload'] ),
        switchMap( payload => this.userRequests$.fetchImpresa$( payload.id)
            .pipe(
                switchMap( response => ( response['status'] === 'OK' && typeof response['payload'] !== 'undefined' ) ?
                    of( this.flowActions.loadImpresaAndMoveSuccess( { current_item: response['payload'], current_step: payload.current_step } ) ) :
                    of( this.flowActions.loadImpresaAndMoveFail( 'Impossibile trovare la struttura selezionata.' ) )
                ),
                catchError( err => [this.flowActions.loadImpresaAndMoveFail( 'Attenzione! Non è stato possibile caricare i dati della struttura.' )] )
            )
        ),
        catchError( err => [this.flowActions.loadImpresaAndMoveFail( 'Impossibile trovare la struttura selezionata.' )] )
    );

    @Effect({dispatch: false}) updateStrutParams$ =
        this.actions$.ofType( AppFlowActions.LOAD_STRUT_AND_MOVE_SUCCESS).pipe(
        map(action => action['payload']),
        tap(action => {
            if (action) {
                let type = 'strut';
                if (action.current_item.dataEntry.idAgriturismo){
                    type = 'agr';
                }
                this.router.navigate(
                    [],
                    {
                      relativeTo: this.activatedRoute,
                      queryParams: { id: action.current_item.id, type:type, 'step': action.current_step },
                      queryParamsHandling: "merge"
                    });
            }
        })
    );

    @Effect({dispatch: false}) updateImpresaPrams$ =
        this.actions$.ofType( AppFlowActions.LOAD_IMPRESA_AND_MOVE_SUCCESS).pipe(
        map(action => action['payload']),
        tap(action => {
            if (action) {
                this.router.navigate(
                    [],
                    {
                    relativeTo: this.activatedRoute,
                    queryParams: { id: action.current_item.idImpresa, 'step': action.current_step },
                    queryParamsHandling: "merge"
                    });
            }
        })
    );

    @Effect({dispatch: false}) clearParams$ = merge(
        this.actions$.ofType( AppFlowActions.LOAD_STRUT_AND_MOVE_FAIL),
        this.actions$.ofType( AppFlowActions.LOAD_IMPRESA_AND_MOVE_FAIL),
        this.actions$.ofType( AppFlowActions.SET_CURRENT_ITEM)
    ).pipe(
        map(action => action['payload']),
        tap(action => {
                this.router.navigate(
                    [],
                    {
                      relativeTo: this.activatedRoute,
                      queryParams: {},
                      queryParamsHandling: ''
                    });
        })
    );

}