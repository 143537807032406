<footer class="container mb-5">
    <!-- <div class="d-flex justify-content-between">
      <div class="col-5">
        <a href="mailto:tur.areaoperatori@regione.sardegna.it">tur.areaoperatori@regione.sardegna.it</a>
      </div>
      <div class="col-6 text-right">
        <a href="/it/user/login">Area Riservata</a><br />
      </div>
    </div> -->
    <div class="row">
      <div class="col-12 text-center mt-2">
        © 2018 Regione autonoma della Sardegna<br />
        <a tabindex="-1" href="http://www.regione.sardegna.it/regione/assessorati/turismo/">Pagina a cura dell'Assessorato del Turismo Artigianato e Commercio</a><br />
      </div>
      <div class="col-12 mt-3 text-center" id="footer-brand">
        <a class="m-3" tabindex="-1" href="https://europa.eu/european-union/index_it" target="_blank" title="Sito Unione Europea">
          <img src="../assets/html-images/logo-europa.png" alt="Logo Regione Unione Europea">
        </a>
        <a class="m-3" tabindex="-1" href="http://www.governo.it/" target="_blank" title="Sito del Governo italiano">
          <img src="../assets/html-images/logo-repubblica-italiana.png" alt="Logo della Repubblica Italiana">
        </a>
        <a class="m-3" tabindex="-1" href="http://www.regione.sardegna.it/" target="_blank" title="Home page - Regione Autonoma della Sardegna">
          <img src="../assets/html-images/logo-ras.png" alt="Logo Regione Autonoma della Sardegna">
        </a>
      </div>
    </div>
  </footer>
