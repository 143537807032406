/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./components/asr-header/asr-header.component.ngfactory";
import * as i2 from "./components/asr-header/asr-header.component";
import * as i3 from "./components/asr-nav-bar/asr-nav-bar.component.ngfactory";
import * as i4 from "./components/asr-nav-bar/asr-nav-bar.component";
import * as i5 from "@angular/router";
import * as i6 from "./logic/user/user.selectors";
import * as i7 from "./logic/generic/generic.actions";
import * as i8 from "@ngrx/store";
import * as i9 from "./logic/user/user.actions";
import * as i10 from "./components/asr-footer/asr-footer.component.ngfactory";
import * as i11 from "./components/asr-footer/asr-footer.component";
import * as i12 from "./app.component";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "asr-header", [], null, null, null, i1.View_AsrHeaderComponent_0, i1.RenderType_AsrHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.AsrHeaderComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "asr-nav-bar", [], null, null, null, i3.View_AsrNavBarComponent_0, i3.RenderType_AsrNavBarComponent)), i0.ɵdid(3, 49152, null, 0, i4.AsrNavBarComponent, [i5.Router, i6.UserSelectors, i7.GenericActions, i8.Store, i9.UserActions], null, null), (_l()(), i0.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(5, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "asr-footer", [], null, null, null, i10.View_AsrFooterComponent_0, i10.RenderType_AsrFooterComponent)), i0.ɵdid(7, 49152, null, 0, i11.AsrFooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i12.AppComponent, [i8.Store, i7.GenericActions, i9.UserActions, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i12.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
