import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { UserActions } from 'src/app/logic/user';
import { GenericSelectors, GenericActions } from 'src/app/logic/generic';

@Component({
  selector: 'asr-home',
  templateUrl: './asr-home.component.html'
})
export class AsrHomeComponent implements OnInit {

  isLoading$;
  constructor(private store: Store<any>,
    private userActions: UserActions,
    private genericSelectors: GenericSelectors,
    private activatedRoute: ActivatedRoute ) {
      this.isLoading$ = this.genericSelectors.isLoading$();
    }

  ngOnInit() {
    let code = this.activatedRoute.snapshot.queryParamMap.get('code');
    if ( code !== null ) {
      this.store.dispatch(this.userActions.userLogin(code));
    }
  }
}
