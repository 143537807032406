import { Component } from '@angular/core';

@Component({
  selector: 'asr-footer',
  templateUrl: './asr-footer.component.html',
})

export class AsrFooterComponent {
  title = 'asr-footer';
}
