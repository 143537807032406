<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title text-uppercase h4">Anteprima struttura</p>
    <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div #reportContent class="modal-body" id="content" #content>
    <h3>{{data.nome}}</h3>
    <table>
      <tr>
        <td class="pr-4"><strong>Tipologia struttura</strong></td>
        <td>{{data?.tipoStruttura[0]?.descrizione}}</td>
      </tr>
      <tr>
        <td class="pr-4"><strong>Comune</strong></td>
        <td>{{data?.indirizzo?.comune?.comune}}</td>
      </tr>
      <tr>
        <td class="pr-4"><strong>Provincia</strong></td>
        <td>{{data?.indirizzo?.provincia?.provincia}}</td>
      </tr>
      <tr>
        <td class="pr-4"><strong>Indirizzo</strong></td>
        <td>{{data?.indirizzo?.indirizzo}}</td>
      </tr>
      <tr>
        <td class="pr-4"><strong>Stato </strong></td>
        <td>{{data?.stato?.descrizione}}</td>
      </tr>
      <tr>
        <td class="pr-4"><strong>IdRas</strong></td>
        <td>{{data?.idStrut}}</td>
      </tr>
      <tr *ngIf="data?.iun">
        <td class="pr-4"><strong>IUN </strong></td>
        <td>{{data.iun}}</td>
      </tr>
    </table>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-secondary mr-auto p-2" (click)="close()" data-dismiss="modal">Chiudi</button>
    <button pButton type="button" label="Pdf" (click)="downloadPdf()" class="btn btn-outline-primary"><i class="fas fa-file-pdf"></i> Export Pdf</button>
    <!-- <button type="button" class="btn btn-primary" (click)="print()" disabled><i class="fas fa-print"></i> Stampa</button> -->
  </div>
</div>
