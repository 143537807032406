import { UserSelectors } from "./user.selectors";
import * as i0 from "@angular/core";
import * as i1 from "./user.selectors";
var UserService = /** @class */ (function () {
    function UserService(userSelector$) {
        var _this = this;
        this.userSelector$ = userSelector$;
        this.ROLE_OPERATORE_ASR = "Operatore ASR";
        this.ROLE_SUPER_OPERATORE_ASR = "Super Operatore ASR";
        this.ROLE_AUTHENTICATED_USER = "authenticated user";
        this.ROLE_VALIDATED_USER = "validated user";
        this.authenticated = false;
        this.operatoreASR = false;
        this.superOperatoreAsr = false;
        this.userId = null;
        this.updateUser = function (user) {
            if (!!user) {
                _this.userId = user.id;
                _this.updateRoles(user.roles);
            }
            else {
                _this.resetUser();
            }
        };
        this.resetUser = function () {
            this.userId = null;
            this.superOperatoreAsr = false;
            this.operatoreASR = false;
        };
        this.userSelector$.getUser$().subscribe(function (user) { return _this.updateUser(user); });
        var user = localStorage.getItem('user');
        var roles = localStorage.getItem('roles');
        if (user && roles) {
            this.userId = user;
            this.updateRoles(JSON.parse(roles));
        }
    }
    UserService.prototype.isAuthenticated = function () {
        return this.authenticated;
    };
    UserService.prototype.isOperatoreAsr = function () {
        return this.operatoreASR;
    };
    UserService.prototype.isSuperOperatoreAsr = function () {
        return this.superOperatoreAsr;
    };
    UserService.prototype.getUserId = function () {
        return this.userId;
    };
    UserService.prototype.updateRoles = function (roles) {
        if (!roles) {
            this.resetUser();
        }
        var isAuthenticated = false;
        var isValidated = false;
        for (var roleKey in roles) {
            if (Object.prototype.hasOwnProperty.call(roles, roleKey)) {
                var role = roles[roleKey];
                if (role === this.ROLE_AUTHENTICATED_USER) {
                    isAuthenticated = true;
                    this.authenticated = isValidated && isAuthenticated;
                }
                if (role === this.ROLE_VALIDATED_USER) {
                    isValidated = true;
                    this.authenticated = isValidated && isAuthenticated;
                }
                if (role === this.ROLE_OPERATORE_ASR) {
                    this.operatoreASR = true;
                }
                if (role === this.ROLE_SUPER_OPERATORE_ASR) {
                    this.superOperatoreAsr = true;
                }
            }
        }
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.UserSelectors)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
