<main class="container">
  <section class="pt-4 pb-4 text-center" id="search-area">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-6">
        <h1>Ricerca</h1>
        <div class="search-container">
          <input class="form-control form-control-lg" [(ngModel)]="fullTextSearchString"  type="search" placeholder="Cerca nelle imprese"
            (keyup)="fullTextKeyUp($event)" />
        </div>
      </div>
    </div>
  </section>
  <div class="row mt-4" [hidden]="!(((this.isLoading$ | async) === false ) && (currentSearchState$ | async)?.status === 'ok' || (currentSearchState$ | async)?.status === 'error' )">

    <aside class="col-12 col-sm-12 col-lg-3 mb-3" id="filter-column">
      <div class="header mb-3">
        <h2 class="d-inline">Filtra</h2>
        <button class="btn btn-link float-right" type="button" (click)="doClearFilters()" [ngClass]="{'disabled': (searchIsFiltered$ | async)?.length === 0}"><i
            class="fa fa-trash"></i> elimina tutti</button>
      </div>
      <div class="accordion mb-3 facet" id="accordionExample" *ngFor="let facets of (listFacets$ | async) as listFacets">
        <div class="card bg-light shadow">
          <div class="card-header" id="headingOne" (click)="toggleCollapse(facets.facetName)" *ngIf="facets.facetResults.length < 10">
            <h5 class="mb-0 h4" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              {{facets.facetName}}
              <i class="fa float-right" [ngClass]="{ 'fa-angle-up': !(listCollapsed$ | async)[facets.facetName], 'fa-angle-down': (listCollapsed$ | async)[facets.facetName] }"></i>
            </h5>
          </div>
          <div class="card-header d-inline pl-2" id="headingOne"  *ngIf="facets.facetResults.length > 10">
              <input class="form-control text-capitalize" type="search"
              placeholder="{{facets.facetName}}"
              [editable]="false"
              (selectItem)="onSelectItem($event, facets.facetName)"
              [ngbTypeahead]="searchAutocompleteFactory(facets.facetResults)"
              [inputFormatter]="formatter"
              [resultFormatter]="formatter"
              />
              <!-- <i class="fa float-right col-2 mt-2" (click)="toggleCollapse(facets.facetName)" [ngClass]="{ 'fa-angle-up': !(listCollapsed$ | async)[facets.facetName], 'fa-angle-down': (listCollapsed$ | async)[facets.facetName] }"></i> -->
          </div>
          <div id="collapseOne" class="collapse" [ngClass]="{ 'show': !(listCollapsed$ | async)[facets.facetName]}"
            aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card-body pt-2 pb-2 pl-2 pr-2">
              <ul class="m-0 p-0 lista_custom">
                <li class=" border-0" *ngFor="let facet of facets.facetResults; let idx = index"  [attr.tabindex]="facets.facetResults.length > 10 ? -1 : 0"
                (click)="setFilter(facets.facetName, facet.value, facet.selected)"
                (keydown)="onKeydownFacetItem($event, facets.facetName, facet.value, facet.selected)"
                  [ngClass]="{ 'active': facet.selected===true }">
                  <div class="text pt-2 pb-2 pl-2 pr-2 d-flex justify-content-between align-items-start">
                    <span class="pr-1">{{facet.label}}</span>
                    <span class="badge badge-primary badge-pill float-right">{{facet.docCount}}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="border-0"
                (click)="toggleShownMore(facets.facetName)"
                [hidden]=" (listCollapsed$ | async)[facets.facetName] || facets.facetResults.length < 5 || (listShownMore$ | async)[facets.facetName] "
              >
              <div class="text pt-2 pb-2 pl-2 pr-2 btn-link">
                Mostra più elementi
              </div>
            </div> -->
          <!-- <div class="border-0"
                (click)="toggleShownMore(facets.facetName)"
                [hidden]=" (listCollapsed$ | async)[facets.facetName] || !(listShownMore$ | async)[facets.facetName] "
              >
              <div class="text pt-2 pb-2 pl-2 pr-2 btn-link">
                Mostra meno elementi
              </div>
            </div> -->
        </div>
      </div>
    </aside>
    <section class="col-12 col-sm-12 col-lg-9 mb-2" id="search-results">
      <div class="header mb-3">
        <h2 class="d-inline">{{ (resultsNum$ | async) }} Risultati</h2>
       </div>

      <div class="p-1 text-center placeholder" id="dati-riepilogo">
          <nav aria-label="Page navigation example ">
            <ul class="pagination pagination-sm justify-content-center mt-3">
              <li class="page-item" [ngClass]="{ 'disabled': (currentPage$ | async) + 1 === 1 }">
                <a class="page-link" tabindex="-1" (click)="movePageBy(-1)">Previous</a>
              </li>
              <li class="page-item" *ngFor=" let page of (pager$ | async) as pages;" [ngClass]="{ 'active': page.value - 1 ===  (currentPage$ | async),
              'disabled':page.value - 1 !==  (currentPage$ | async) && !page.clickable }">
                <a class="page-link" (click)="page.clickable ? goToPage(page.value-1) : ''">{{page.value}}</a>
              </li>
              <li class="page-item" [ngClass]="{ 'disabled': (currentPage$ | async) +1  === (numOfPages$ | async) }">
                <a class="page-link" (click)="movePageBy(1)">Next</a>
              </li>
            </ul>
          </nav>
        </div>

      <ng-container  *ngIf="(listResults$ | async).length">
        <!-- classe results-scroller per rendere la lista dei -->
        <ul #scrollerRef class="lista_custom lista--risultati lista--full-page gray-theme  p-0 m-0" [ngClass]="{'half-results-scroller': shownMap}"  >
          <li class="pt-1 pb-1 " *ngFor="let result of (listResults$ | async)  as results">
            <div class="p-2 d-flex align-items-baseline justify-content-between">
              <div class="text">
                <h4 class="mb-0">{{result.nome}}</h4>
                <p class="m-1 text-capitalize">{{result.tipologia}}</p>
                <p class="m-1">{{result.provincia}} {{result.comune}}</p>
                <p class="m-1">{{result.indirizzo}}</p>
              </div>
              <div class="actions float-right">
                <button class="btn btn-link" type="button" [routerLink]="['/edit_impresa']" [queryParams]="{'step':'dati_impresa', id: result.id }"><i class="fa fa-edit"></i> modifica</button>
                <span>|</span>
                <button class="btn btn-link" (click)="displayStrutModal(result.id, result.nome)" type="button"><i class="fa fa-bed"></i>  Strutture associate</button>
              </div>
            </div>
          </li>
        </ul>
      </ng-container>

      <div class="p-5 text-center placeholder" id="dati-riepilogo">
        <nav aria-label="Page navigation example ">
          <ul class="pagination pagination-sm justify-content-center mt-3">
            <li class="page-item" [ngClass]="{ 'disabled': (currentPage$ | async) + 1 === 1 }">
              <a class="page-link" tabindex="-1" (click)="movePageBy(-1)">Previous</a>
            </li>
            <li class="page-item" *ngFor=" let page of (pager$ | async) as pages;" [ngClass]="{ 'active': page.value - 1 ===  (currentPage$ | async),
            'disabled':page.value - 1 !==  (currentPage$ | async) && !page.clickable }">
              <a class="page-link" (click)="page.clickable ? goToPage(page.value-1) : ''">{{page.value}}</a>
            </li>
            <li class="page-item" [ngClass]="{ 'disabled': (currentPage$ | async) +1  === (numOfPages$ | async) }">
              <a class="page-link" (click)="movePageBy(1)">Next</a>
            </li>
          </ul>
        </nav>
      </div>
    </section>

  </div>
  <div class="p-5 text-center placeholder" *ngIf="((this.isLoading$ | async) === false ) && (currentSearchState$ | async)?.status === 'empty'" id="dati-riepilogo">
    <div>
      {{ (this.currentSearchState$ | async)?.message }}
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-12 riepilogo">
        <i class="fa fa-building mt-2"></i>
        <div class="dato">
          <strong>{{(this.numImprese$ | async)}}</strong>
          <span>Imprese<br />
            <small>tra cui cercare</small>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4" *ngIf="(this.isLoading$ | async) === true">
    <ngx-loading [show]="(this.isLoading$ | async) === true" [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop: true }" [template]="customLoadingTemplate"></ngx-loading>
  </div>

</main>
