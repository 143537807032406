/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./asr-map-container.component";
import * as i2 from "@ngrx/store";
import * as i3 from "../../logic/search/search.actions";
import * as i4 from "../../logic/generic/generic.actions";
import * as i5 from "@angular/router";
import * as i6 from "../../logic/search/search.selectors";
var styles_AsrMapContainer = [];
var RenderType_AsrMapContainer = i0.ɵcrt({ encapsulation: 2, styles: styles_AsrMapContainer, data: {} });
export { RenderType_AsrMapContainer as RenderType_AsrMapContainer };
export function View_AsrMapContainer_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["id", "leafletmap"]], null, null, null, null, null))], null, null); }
export function View_AsrMapContainer_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "asr-map-container", [], null, null, null, View_AsrMapContainer_0, RenderType_AsrMapContainer)), i0.ɵdid(1, 245760, null, 0, i1.AsrMapContainer, [i2.Store, i3.SearchActions, i4.GenericActions, i5.Router, i6.SearchSelectors], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AsrMapContainerNgFactory = i0.ɵccf("asr-map-container", i1.AsrMapContainer, View_AsrMapContainer_Host_0, { markers: "markers", boundingBox: "boundingBox" }, {}, []);
export { AsrMapContainerNgFactory as AsrMapContainerNgFactory };
