import { Component, OnInit, Input } from '@angular/core';
import { FormGeneratorInterface } from 'src/app/form-generator/form-generator.interface';
import { FormGeneratorController } from 'src/app/form-generator/form-generator';

@Component({
  selector: '[asr-summary]',
  templateUrl: './asr-summary.component.html',
})
export class AsrSummaryComponent implements OnInit, FormGeneratorInterface {

  @Input() model;
  @Input() parentController: FormGeneratorController;
  constructor() {
   }

  ngOnInit() {}

}