var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions } from "@ngrx/effects";
import { UserActions } from "./user.actions";
import { map, switchMap, catchError, tap, withLatestFrom, filter, delay } from "rxjs/operators";
import { UserRequests } from "./user.request";
import { of, merge } from "rxjs";
import { UserSelectors } from "./user.selectors";
import { Router } from "@angular/router";
import { GenericActions } from "../generic";
import { saveAs } from 'file-saver';
import { Location } from "@angular/common";
import { SearchActions } from "../search";
import { AppFlowActions } from '../app-flow/app-flow.actions';
var UserEffects = /** @class */ (function () {
    function UserEffects(actions$, userActions$, searchActions$, userRequests$, userSelectors$, genericActions$, appFlowActions$, router, location) {
        var _this = this;
        this.actions$ = actions$;
        this.userActions$ = userActions$;
        this.searchActions$ = searchActions$;
        this.userRequests$ = userRequests$;
        this.userSelectors$ = userSelectors$;
        this.genericActions$ = genericActions$;
        this.appFlowActions$ = appFlowActions$;
        this.router = router;
        this.location = location;
        this.login$ = this.actions$.ofType(UserActions.USER_LOGIN)
            .pipe(map(function (action) { return action['payload']; }), switchMap(function (code) { return _this.userRequests$.requestLogin$(code).
            pipe(map(function (response) { return response['status'] === 'OK' ?
            _this.userActions$.userLoginSuccess(response['payload']) :
            _this.userActions$.userLoginFail('Utente non trovato'); })); }), catchError(function (err) { return [_this.userActions$.userLoginFail('Errore durante la procedura di login.')]; }));
        this.checkLogin$ = this.actions$.ofType(UserActions.USER_CHECK_LOGIN)
            .pipe(map(function (action) { return action['payload']; }), switchMap(function () { return _this.userRequests$.requestIsLogged$()
            .pipe(map(function (response) {
            if (response['status'] === 'OK') {
                if (typeof response['payload'] !== 'undefined' && typeof response['payload'].message === 'undefined') {
                    return _this.userActions$.userCheckLoginSucess(response['payload']);
                }
                else if (typeof response['payload'] !== 'undefined' && typeof response['payload'].message !== 'undefined') {
                    return _this.userActions$.userCheckLoginFail('Utente non loggato.');
                }
                else {
                    return _this.genericActions$.showErrorModal({
                        title: 'Errore',
                        message: 'Impossibile ripristinare le sessione...',
                        buttons: [{ id: 'close', label: 'Chiudi', class: '', action: '', autoclose: true }]
                    });
                }
            }
            else {
                return _this.userActions$.userCheckLoginFail('Impossibile ripristinare la sessione. Ripeti la procedura di autenticazione');
            }
        })); }), catchError(function (err) { return [_this.userActions$.userCheckLoginFail('Errore durante la verifica della sessione.')]; }));
        this.logout$ = this.actions$.ofType(UserActions.USER_LOGOUT)
            .pipe(withLatestFrom(this.userSelectors$.getUser$()), switchMap(function (_a) {
            var user = _a[1];
            return _this.userRequests$.requestLogout$()
                .pipe(switchMap(function (response) { return response['status'] === 'OK' ?
                of(_this.userActions$.userLogoutSuccess()) :
                of(_this.userActions$.userLogoutFail(response)); }));
        }), catchError(function (err) { return [_this.userActions$.userLogoutFail('Errore durante la procedura di logout')]; }));
        this.loginSucces$ = this.actions$.ofType(UserActions.USER_LOGIN_SUCCESS)
            .pipe(map(function (action) { return action['payload']; }), tap(function (payload) { localStorage.setItem('user', payload['user']['name']); localStorage.setItem('roles', JSON.stringify(payload['user']['roles'])); }), tap(function () { return _this.router.navigate(['/home'], { queryParams: {} }); }));
        this.checkloginSucces$ = this.actions$.ofType(UserActions.USER_CHECK_LOGIN_SUCCESS)
            .pipe(map(function (action) { return action['payload']; }), tap(function (payload) { localStorage.setItem('user', payload['user']['name']); localStorage.setItem('roles', JSON.stringify(payload['user']['roles'])); }));
        this.loginFail$ = merge(this.actions$.ofType(UserActions.USER_LOGIN_FAIL), this.actions$.ofType((UserActions.USER_CHECK_LOGIN_FAIL))).pipe(map(function (action) { return action['payload']; }), tap(function () { localStorage.removeItem('user'); localStorage.removeItem('roles'); }));
        this.logoutSuccess$ = this.actions$.ofType(UserActions.USER_LOGOUT_SUCCESS)
            .pipe(tap(function (token) { localStorage.removeItem('user'); localStorage.removeItem('roles'); }), tap(function () { return _this.router.navigate(['/home'], { queryParams: {} }); }));
        this.userActionFails$ = merge(this.actions$.ofType(UserActions.USER_LOGOUT_FAIL), this.actions$.ofType(UserActions.USER_LOGIN_FAIL), this.actions$.ofType(UserActions.USER_DOWNLOAD_FILE_FAIL), this.actions$.ofType(UserActions.USER_UPLOAD_FILE_FAIL), this.actions$.ofType(UserActions.USER_EDIT_IMPRESA_FAIL), this.actions$.ofType(UserActions.EDIT_IMPRESA_FROM_MODAL_FAIL), this.actions$.ofType(UserActions.USER_INSERT_STRUTTURA_FAIL), this.actions$.ofType(UserActions.USER_EDIT_STRUTTURA_FAIL), this.actions$.ofType(UserActions.USER_CHECK_DUPLICATI_FAIL)).pipe(map(function (action) { return action['payload']; }), filter(function (payload) { return payload !== null && typeof payload !== 'undefined'; }), map(function (err) { return _this.genericActions$.showErrorModal({
            title: 'Errore',
            message: err,
            buttons: [{ id: 'close', label: 'Chiudi', class: '', action: '', autoclose: true }]
        }); }));
        this.userSessionExpired$ = merge(this.actions$.ofType(UserActions.USER_DOWNLOAD_FILE_FAIL), this.actions$.ofType(UserActions.USER_UPLOAD_FILE_FAIL), this.actions$.ofType(UserActions.USER_EDIT_IMPRESA_FAIL), this.actions$.ofType(UserActions.EDIT_IMPRESA_FROM_MODAL_FAIL), this.actions$.ofType(UserActions.USER_INSERT_STRUTTURA_FAIL), this.actions$.ofType(UserActions.USER_EDIT_STRUTTURA_FAIL), this.actions$.ofType(SearchActions.SEARCH_FAIL), this.actions$.ofType(SearchActions.SEARCH_FULL_TEXT_FAIL)).pipe(map(function (action) { return action['payload']; }), filter(function (payload) { return payload !== null && typeof payload === 'string' && payload.includes('autenticato'); }), map(function (err) { return _this.userActions$.userLogoutSuccess(); }));
        this.uploadEffect$ = this.actions$.ofType(UserActions.USER_UPLOAD_FILE)
            .pipe(map(function (action) { return action['payload']; }), switchMap(function (payload) { return _this.userRequests$.requestPostFile$(payload.data).
            pipe(switchMap(function (res) {
            if (res['status'] === 'OK') {
                return of(_this.userActions$.userUploadFileSuccess({ payload: res['payload'], ref: payload.ref }));
            }
            else if (res['status'] === 'NOK' && res['payload']['message'] === 'Sessione scaduta') {
                return of(_this.userActions$.userUploadFileFail('Sessione scaduta. Effettua nuovamente il login.'));
            }
            else {
                return of(_this.userActions$.userUploadFileFail('Impossibile eseguire l\'upload del file.'));
            }
        }), catchError(function (err) { return [_this.userActions$.userUploadFileFail('Impossibile eseguire l\'upload del file.')]; })); }));
        this.uploadSuccessEffect$ = this.actions$.ofType(UserActions.USER_UPLOAD_FILE_SUCCESS)
            .pipe(map(function (action) { return action['payload']; }), tap(function (payload) {
            if (payload.ref && payload.ref.type === 'file') {
                if (typeof payload.ref.value === 'undefined') {
                    payload.ref.value = {};
                }
                payload.ref.value[payload.payload.id] = payload.payload;
            }
        }));
        this.downloadEffect$ = this.actions$.ofType(UserActions.USER_DOWNLOAD_FILE)
            .pipe(map(function (action) { return action['payload']; }), switchMap(function (payload) { return _this.userRequests$.requestDownloadFile$(payload.id)
            .pipe(switchMap(function (res) {
            if (res['status'] === 'OK') {
                return of(_this.userActions$.userDownloadFileSuccess({ data: res, filename: payload.fileName }));
            }
            else if (res['status'] === 'NOK' && res['payload']['message'] === 'Sessione scaduta') {
                return of(_this.userActions$.userDownloadFileFail('Sessione scaduta. Effettua nuovamente il login.'));
            }
            else {
                return of(_this.userActions$.userDownloadFileFail('Errore durante il downoload del file.'));
            }
        }), catchError(function (err) { return [_this.userActions$.userDownloadFileFail('Errore durante il downoload del file.')]; })); }));
        this.downloadSuccess$ = this.actions$.ofType(UserActions.USER_DOWNLOAD_FILE_SUCCESS)
            .pipe(map(function (action) { return action['payload']; }), tap(function (payload) { return _this.downLoadFile(payload.data, 'text/plain', payload.filename); }));
        this.doNewStruttura$ = this.actions$.ofType(UserActions.USER_INSERT_STRUTTURA)
            .pipe(map(function (action) { return action['payload']; }), switchMap(function (payload) { return _this.userRequests$.requestInsertStruttura$(payload)
            .pipe(switchMap(function (res) {
            if (res['status'] === 'OK') {
                return of(_this.userActions$.insertStrutturaSuccess(res));
            }
            else if (res['status'] === 'NOK' && res['payload']['message'] === 'Utene non autenticato') {
                return of(_this.userActions$.insertStrutturaFail('Sessione scaduta. Effettua nuovamente il login.'));
            }
            else {
                return of(_this.userActions$.insertStrutturaFail('Errore nell\' inserimento della struttura'));
            }
        }), catchError(function (err) { return [_this.userActions$.insertStrutturaFail('Errore nell\' inserimento della struttura')]; })); }));
        this.doNewStrutturaSuccess$ = this.actions$.ofType(UserActions.USER_INSERT_STRUTTURA_SUCCESS)
            .pipe(map(function (action) { return action['payload']; }), tap(function (payload) { _this.router.navigate(['/edit'], { queryParams: { id: payload.payload.id, type: 'strut', step: 'riepilogo' } }); }));
        this.doEditStruttura$ = this.actions$.ofType(UserActions.USER_EDIT_STRUTTURA)
            .pipe(map(function (action) { return action['payload']; }), switchMap(function (payload) { return _this.userRequests$.requestEditStruttura$(payload)
            .pipe(switchMap(function (res) {
            if (res['status'] === 'OK') {
                return of(_this.userActions$.editStrutturaSuccess(res));
            }
            else if (res['status'] === 'NOK' && res['payload']['message'] === 'Utente non autenticato') {
                return of(_this.userActions$.editStrutturaFail('Sessione scaduta. Effettua nuovamente il login.'));
            }
            else {
                return of(_this.userActions$.editStrutturaFail('Errore durante la modifica della struttura'));
            }
        }), catchError(function (err) { return [_this.userActions$.editStrutturaFail('Errore durante la modifica della struttura')]; })); }));
        this.doEditImpresa$ = this.actions$.ofType(UserActions.USER_EDIT_IMPRESA)
            .pipe(map(function (action) { return action['payload']; }), switchMap(function (payload) { return _this.userRequests$.requestEditImpresa$(payload)
            .pipe(switchMap(function (res) {
            if (res['status'] === 'OK') {
                return of(_this.userActions$.editImpresaSuccess(res['payload']));
            }
            else if (res['status'] === 'NOK' && res['payload']['message'] === 'Utente non autenticato') {
                return of(_this.userActions$.editImpresaFail('Sessione scaduta. Effettua nuovamente il login.'));
            }
            else {
                return of(_this.userActions$.editImpresaFail('Errore nella modifica dell\' impresa'));
            }
        }), catchError(function (err) { return [_this.userActions$.editImpresaFail('Errore nella modifica dell\' impresa')]; })); }));
        this.doEditFromModal$ = this.actions$.ofType(UserActions.EDIT_IMPRESA_FROM_MODAL)
            .pipe(map(function (action) { return action['payload']; }), switchMap(function (payload) { return _this.userRequests$.requestEditImpresa$(payload.dataEntry)
            .pipe(switchMap(function (res) {
            if (res['status'] === 'OK') {
                return of(_this.userActions$.editImpresaFromModalSuccess({ ref: payload.ref, result: { dataEntry: payload.dataEntry, id: payload.dataEntry.idImpresa }, field: payload.field }));
            }
            else if (res['status'] === 'NOK' && res['payload']['message'] === 'Utente non autenticato') {
                return of(_this.userActions$.editImpresaFromModalFail('Sessione scaduta. Effettua nuovamente il login.'));
            }
            else {
                return of(_this.userActions$.editImpresaFromModalFail('Errore nella modifica dell\' impresa'));
            }
        }), catchError(function (err) { console.log(err); return [_this.userActions$.editImpresaFromModalFail('Errore nella modifica dell\' impresa.')]; })); }));
        this.doEditFromModalSuccess$ = this.actions$.ofType(UserActions.EDIT_IMPRESA_FROM_MODAL_SUCCESS)
            .pipe(map(function (action) { return action['payload']; }), map(function (res) { return _this.genericActions$.hideEditModal(res); }));
        this.doSoftDelete$ = this.actions$.ofType(UserActions.USER_DELETE_STRUTTURA)
            .pipe(map(function (action) { return action['payload']; }), switchMap(function (payload) { return _this.userRequests$.requesDeleteStruttura$(payload).pipe(switchMap(function (res) {
            if (res['status'] === 'OK') {
                return of(_this.userActions$.deleteStrutturaSuccess());
            }
            else if (res['status'] === 'NOK' && res['payload']['message'] === 'Utente non autenticato') {
                return of(_this.userActions$.deleteStrutturaFail('Sessione scaduta. Effettua nuovamente il login.'));
            }
            else {
                return of(_this.userActions$.deleteStrutturaFail('Impossibile cancellare la struttura.'));
            }
        }), delay(4000)); }));
        this.restartSearch$ = this.actions$.ofType(UserActions.USER_DELETE_STRUTTURA_SUCCESS)
            .pipe(map(function (action) { return _this.searchActions$.searchStart(); }));
        this.doEditImpresaSuccess$ = this.actions$.ofType(UserActions.USER_EDIT_IMPRESA_SUCCESS)
            .pipe(map(function (action) { return action['payload']; }), 
        // map(payload => this.appFlowActions$.setCurrentStep('riepilogo'))
        tap(function (payload) { return _this.router.navigateByUrl('/').then(function () {
            return _this.router.navigate(['edit_impresa'], { queryParams: { step: 'dati_impresa', id: payload['id'] } });
        }); }));
        this.doEditStrutturaSuccess$ = this.actions$.ofType(UserActions.USER_EDIT_STRUTTURA_SUCCESS)
            .pipe(map(function (action) { return action['payload']; }), 
        // map(payload => this.appFlowActions$.setCurrentStep('riepilogo'))
        tap(function (payload) { return _this.router.navigateByUrl('/').then(function () {
            return _this.router.navigate(['/edit'], { queryParams: { type: "strut", step: 'riepilogo', id: payload.payload.id } });
        }); }));
        // @Effect({dispatch: false}) doReload$ =
        //     this.actions$.ofType(UserActions.USER_EDIT_STRUTTURA_SUCCESS).pipe(
        //         map(action => action['payload']),
        //         tap(payload => window.location.assign('/edit?type=strut&step=riepilogo&id=' + payload.payload.id))
        //     );
        this.doCheckDuplicati$ = this.actions$.ofType(UserActions.USER_CHECK_DUPLICATI)
            .pipe(map(function (action) { return action['payload']; }), switchMap(function (payload) { return _this.userRequests$.fetchDuplicati$(payload.comune, payload.nome)
            .pipe(switchMap(function (res) { return (res['status'] === 'OK' && res['payload'] && res['payload']['resultsFound'] && res['payload']['resultsFound'].length) ?
            of(_this.userActions$.userCheckDuplicatiSuccess({ results: res['payload']['resultsFound'], action: payload.postAction })) :
            of(_this.userActions$.userCheckDuplicatiFail(null), payload.postAction); }), catchError(function (err) { return [_this.userActions$.userCheckDuplicatiFail('Attenzione! Non è stato possibile controllare i possibili duplicati.')]; })); }));
        this.doDuplicatiSuccess$ = this.actions$.ofType(UserActions.USER_CHECK_DUPLICATI_SUCCCESS)
            .pipe(map(function (action) { return action['payload']; }), map(function (payload) {
            return _this.genericActions$.showDuplicatesModal({
                title: 'Attenzione possibile struttura duplicata',
                results: payload.results,
                buttons: [{ id: 'close', label: 'Annulla', class: '', autoclose: true },
                    { id: 'insert', label: 'Prosegui con l\'inserimento', class: '', action: payload.action, autoclose: true }],
                size: 'large'
            });
        }));
        this.dispatchBozze$ = merge(this.actions$.ofType(UserActions.USER_LOGIN_SUCCESS), this.actions$.ofType(UserActions.USER_CHECK_LOGIN_SUCCESS), this.actions$.ofType(UserActions.USER_EDIT_STRUTTURA_SUCCESS), this.actions$.ofType(UserActions.USER_INSERT_STRUTTURA_SUCCESS)).pipe(withLatestFrom(this.userSelectors$.getUser$()), filter(function (_a) {
            var user = _a[1];
            return user !== null;
        }), map(function (action) { return _this.userActions$.userGetBozze(); }));
        this.setBozzeEffect$ = this.actions$.ofType(UserActions.SET_USER)
            .pipe(map(function (action) { return _this.userActions$.userGetBozze(); }));
        this.doGetBozze$ = this.actions$.ofType(UserActions.USER_GET_BOZZE)
            .pipe(map(function (action) { return action['payload']; }), withLatestFrom(this.userSelectors$.getUser$()), filter(function (_a) {
            var user = _a[1];
            return user !== null;
        }), switchMap(function (_a) {
            var user = _a[1];
            return _this.userRequests$.getBozze$(user['id'])
                .pipe(switchMap(function (res) { return res[status] !== 'OK' ?
                of(_this.userActions$.userGetBozzeSucess(res['payload']['dataEntries'])) :
                of(_this.userActions$.userGetBozzeFail('Impossibile recuperare le bozze.')); }));
        }), catchError(function (err) { return [_this.userActions$.userGetBozzeFail('Errore durante il recupero delle bozze.')]; }));
        this.doGetStruttureByImpresa$ = this.actions$.ofType(UserActions.GET_STRUT_BY_IMPRESA)
            .pipe(map(function (action) { return action['payload']; }), switchMap(function (payload) { return _this.userRequests$.getStruttureByImpresa$(payload.id)
            .pipe(switchMap(function (res) { return res[status] !== 'OK' ?
            of(_this.userActions$.getStrutByImpresaSuccess({ results: res['payload']['results'], nome: payload.nome })) :
            of(_this.userActions$.getStrutByImpresaFail('Impossibile recuperare le strutture associate all\'impresa.')); })); }), catchError(function (err) { return [_this.userActions$.getStrutByImpresaFail('Impossibile recuperare le strutture associate all\'impresa.')]; }));
        this.displayStrutModal$ = this.actions$.ofType(UserActions.GET_STRUT_BY_IMPRESA_SUCCESS)
            .pipe(map(function (action) { return action['payload']; }), map(function (payload) { return _this.genericActions$.showStruttureModal({
            title: payload.nome,
            results: payload.results,
            buttons: [{ id: 'close', label: 'Chiudi', class: '', autoclose: true }],
            size: 'large'
        }); }));
    }
    UserEffects.prototype.downLoadFile = function (data, type, fileName) {
        var blob = new Blob([data], { type: type });
        return saveAs(blob, fileName);
    };
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "login$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "checkLogin$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "logout$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "loginSucces$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "checkloginSucces$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "loginFail$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "logoutSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "userActionFails$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "userSessionExpired$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "uploadEffect$", void 0);
    __decorate([
        Effect({ 'dispatch': false }),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "uploadSuccessEffect$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "downloadEffect$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "downloadSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "doNewStruttura$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "doNewStrutturaSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "doEditStruttura$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "doEditImpresa$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "doEditFromModal$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "doEditFromModalSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "doSoftDelete$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "restartSearch$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "doEditImpresaSuccess$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "doEditStrutturaSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "doCheckDuplicati$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "doDuplicatiSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "dispatchBozze$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "setBozzeEffect$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "doGetBozze$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "doGetStruttureByImpresa$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "displayStrutModal$", void 0);
    return UserEffects;
}());
export { UserEffects };
