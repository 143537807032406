<main class="container">
    <button type="button" class="close mt-1" (click)="close()">
        <span >&times;</span>
    </button>
  <div class="row">
    <h2 class="col-12 mt-3 ml-4">{{current_item?.ragioneSociale}}</h2>
    <section class="col-12 col-sm-12 col-lg-12" id="form">
      <div class="button_area col-12 mb-5">
        <div asr-section
          class="{{this.model[current_step]?.attributes ? this.model[current_step].attributes.class ? this.model[current_step].attributes.class  : '' : '' }}"
          [(model)]="this.model[current_step]" [parentController]="this"></div>
        <span class="float-right pb-2">
            <button type="button" class="btn btn-primary mr-2" (click)="close()"><i class="fas fa-times-circle"></i>
            Annulla</button>
            <button type="button" class="btn btn-primary" (click)="submitData(false)"
              *ngIf="current_step === 'dati_impresa'" [disabled]="this.formGroup.invalid"><i class="fas fa-check-circle"></i>
              Salva modifiche</button>
        </span>
      </div>
    </section>
  </div>
</main>
