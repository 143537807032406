import { GenericState } from './generic/generic.interfaces';
import { genericReducer} from './generic/generic.reducer';
import { userReducer} from './user/user.reducer';
import { UserState} from './user/user.interface';
import { SearchState } from './search/search.interfaces';
import { searchReducer } from './search/search.reducer';
import { ActionReducerMap, MetaReducer, createSelector } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { FlowState } from './app-flow/app-flow.interface';
import { flowReducer } from './app-flow/app-flow.reducer';
import { SearchImpresaState } from './search-impresa/search-impresa.interfaces';
import { searchImpresaReducer } from './search-impresa/search-impresa.reducer';

//states della app
export interface AppState {
    search: SearchState;
    generic: GenericState;
    user: UserState;
    flow: FlowState;
    search_impresa: SearchImpresaState;
  }

//mapping reducers
 export const reducers: ActionReducerMap<AppState> = {
  search: searchReducer,
  generic: genericReducer,
  user: userReducer,
  flow: flowReducer,
  search_impresa: searchImpresaReducer
  };

//metareducer
 export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];

//selectors
export const selectSearch = (state: AppState) => state.search;
export const getSearchState = createSelector(
  selectSearch,
  (state: SearchState) => state
);

export const selectSearchImpresa = (state: AppState) => state.search_impresa;
export const getSearchImpresaState = createSelector(
  selectSearchImpresa,
  (state: SearchImpresaState) => state
);

export const selectUser = (state: AppState) => state.user;
export const getUserState = createSelector(
  selectUser,
  (state: UserState) => state
);

export const selectGeneric = (state: AppState) => state.generic;
export const getGenericState = createSelector(
  selectGeneric,
  (state: GenericState) => state
);

export const selectFlow = (state: AppState) => state.flow;
export const getFlowState = createSelector(
  selectFlow,
  (state: FlowState) => state
);



