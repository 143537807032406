import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class GenericRequests {

    httpOptions: {};
    constructor(private http: HttpClient) {
        this.httpOptions = {headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),  withCredentials: true };
    }

    _postRequest$( url, data ) {
        return this.http.post( url, data, this.httpOptions );
    }

    _getRequest$( url, parameters?) {
        if ( typeof parameters !== 'undefined' ) {
            var params = new HttpParams();
            for ( const key in parameters ) {
                if ( parameters.hasOwnProperty( key ) ) {
                    params = params.set( key, parameters[key] );
                }
            }
            return this.http.get( url, { headers: { 'Content-Type': 'application/json' } , params: params, withCredentials: true } );
        } else {
            return this.http.get( url, this.httpOptions );
        }
    }

    requestStats$() {
        return this._getRequest$(environment.baseURL + environment.requests.generic.path);
    }

    fetchStruttura$( id, type ) {
        const path = environment.baseURL + environment.requests.fetchStruttura.path;
        let param = new HttpParams();

        switch ( type ) {
            case 'agr':
                param = param.set( 'idAgriturismo', id );
                break;
            default:
                param = param.set( 'idStrut', id );
                break;
        }
        param = param.set( 'index', 'struttura' );
        return this.http.get( path, { params: param , withCredentials: true} );
    }
}
