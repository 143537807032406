import { ActionWithPayload } from '../../app.interfaces';
import { Injectable } from '@angular/core';

@Injectable()
export class SearchActions {

    static SEARCH_START = '[Search] Start';
    searchStart(): ActionWithPayload {
        return {
            type: SearchActions.SEARCH_START
        };
    }

    static SEARCH_SUCCESS = '[Search] SEARCH_SUCCESS';
    searchSuccess(rsp): ActionWithPayload {
        return {
            type: SearchActions.SEARCH_SUCCESS,
            payload: rsp
        };
    }

    static SEARCH_FAIL = '[Search] SEARCH_FAIL';
    searchFail(err): ActionWithPayload {
        return {
            type: SearchActions.SEARCH_FAIL,
            payload: err
        };
    }

    static SEARCH_SET_FILTER = '[Search] SEARCH_SET_FILTER';
    searchSetFilter(value): ActionWithPayload {
        return {
            type: SearchActions.SEARCH_SET_FILTER,
            payload: value
        };
    }

    static SEARCH_SORT = '[Search] SEARCH_SORT';
    searchSort(value): ActionWithPayload {
        return {
            type: SearchActions.SEARCH_SORT,
            payload: value
        };
    }

    static SEARCH_CLEAR_FILTERS  = '[Search] SEARCH_CLEAR_FILTERS';
    searchClearFilters(): ActionWithPayload {
        return {
            type: SearchActions.SEARCH_CLEAR_FILTERS
        };
    }

    static SEARCH_TOGGLE_SHOWN_MORE = '[Search] SEARCH_TOGGLE_SHOWN_MORE';
    searchToggleShowMore(value): ActionWithPayload {
        return {
            type: SearchActions.SEARCH_TOGGLE_SHOWN_MORE,
            payload: value
        };
    }

    static SEARCH_TOGGLE_COLLAPSE = '[Search] SEARCH_TOGGLE_COLLAPSE';
    searchToggleCollapse(value): ActionWithPayload {
        return {
            type: SearchActions.SEARCH_TOGGLE_COLLAPSE,
            payload: value
        };
    }

    static SEARCH_FULL_TEXT = '[Search] SEARCH_SEARCH_FULL_TEXT';
    searchFullText(query): ActionWithPayload {
        return {
            type: SearchActions.SEARCH_FULL_TEXT,
            payload: (typeof query === 'undefined')  ? '' : query
        };
    }

    static SEARCH_FULL_TEXT_SUCCESS = '[Search] SEARCH_FULL_TEXT_SUCCESS';
    searchFullTextSuccess(res): ActionWithPayload {
        return {
            type: SearchActions.SEARCH_FULL_TEXT_SUCCESS,
            payload: res
        };
    }

    static SEARCH_FULL_TEXT_FAIL = '[Search] SEARCH_FULL_TEXT_FAIL';
    searchFullTextFail(err): ActionWithPayload {
        return {
            type: SearchActions.SEARCH_FULL_TEXT_FAIL,
            payload: err
        };
    }

    static SEARCH_CHANGE_RESULTS_PAGE = '[Search] SEARCH_CHANGE_RESULTS_PAGE';
    searchChangeResultsPage(page): ActionWithPayload {
        return {
            type: SearchActions.SEARCH_CHANGE_RESULTS_PAGE,
            payload: page
        };
    }

    static SEARCH_CHANGE_RESULTS_PAGE_BY = '[Search] SEARCH_CHANGE_RESULTS_PAGE_BY';
    searchChangeResultsPageBy(num): ActionWithPayload {
        return {
            type: SearchActions.SEARCH_CHANGE_RESULTS_PAGE_BY,
            payload: num
        };
    }

    static SEARCH_TOGGLE_MAP = '[Search] SEARCH_TOGGLE_MAP';
    searchToggleMap(): ActionWithPayload {
        return {
            type: SearchActions.SEARCH_TOGGLE_MAP
        };
    }

    static SEARCH_TOGGLE_GEO_SEARCH = '[Search] SEARCH_TOGGLE_GEO SEARCH'
    searchToggleGeoSearch(): ActionWithPayload {
        return {
            type: SearchActions.SEARCH_TOGGLE_GEO_SEARCH
        };
    }

    static SEARCH_SET_BOUNDING_BOX = '[Search] SEARCH_SET_BOUNDING_BOX'
    searchSetBoundingBox(coords): ActionWithPayload {
        return {
            type: SearchActions.SEARCH_SET_BOUNDING_BOX,
            payload: coords
        };
    }

}
