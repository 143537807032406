var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, Injector } from '@angular/core';
import { takeUntil, filter, distinctUntilChanged } from 'rxjs/operators';
import { FormGeneratorController, LEAF_TYPES, CONTAINER_TYPES } from 'src/app/form-generator';
import { getEditModel } from '../../form-model/form.model';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericSelectors, GenericActions } from 'src/app/logic/generic';
import { UserActions } from 'src/app/logic/user';
import { Store } from '@ngrx/store';
import { FormGroup } from '@angular/forms';
import { AppFlowActions } from 'src/app/logic/app-flow/app-flow.actions';
import { AppFlowSelectors } from 'src/app/logic/app-flow/app-flow.selectors';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/logic/user/user.service';
var AsrEditComponent = /** @class */ (function (_super) {
    __extends(AsrEditComponent, _super);
    function AsrEditComponent(injector, genericSelectors, genericActions, flowSelectors$, flowActions, store, userActions, router, dateFormatter, activatedRoute, userService) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.genericSelectors = genericSelectors;
        _this.genericActions = genericActions;
        _this.flowSelectors$ = flowSelectors$;
        _this.flowActions = flowActions;
        _this.store = store;
        _this.userActions = userActions;
        _this.router = router;
        _this.dateFormatter = dateFormatter;
        _this.activatedRoute = activatedRoute;
        _this.userService = userService;
        _this.iunUrl = environment.iunBaseURL + '/';
        _this.queueSubsectionInView = [];
        _this.subsectioNoMoreInView = false;
        _this.inViewportOptions = {
            rootMargin: '0px',
            threshold: 1.0
        };
        return _this;
    }
    AsrEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.dispatch(this.genericActions.resetModal());
        this.dataEntryMap = {};
        this.events = {};
        this.isLoading$ = this.genericSelectors.isLoading$();
        this.model = getEditModel(this.userService.isSuperOperatoreAsr());
        this.formGroup = new FormGroup({});
        this.initializeModel(this.model, this.formGroup);
        for (var fieldId in this.events) {
            if (this.events.hasOwnProperty(fieldId)) {
                this.onModelChange(this.containerMap[fieldId]);
            }
        }
        this.flowSelectors$.getCurrentStep$().pipe(takeUntil(this.destroyed$)).subscribe({
            next: function (x) {
                _this.current_step = x;
                _this.router.navigate([], {
                    relativeTo: _this.activatedRoute,
                    queryParams: { step: x },
                    queryParamsHandling: 'merge'
                });
            },
            error: function (err) { },
            complete: function () { }
        });
        var id = this.activatedRoute.snapshot.queryParams['id'];
        var step = this.activatedRoute.snapshot.queryParams['step'] || 'ricerca';
        var type = this.activatedRoute.snapshot.queryParams['type'] || 'strut';
        this.steps = Object.getOwnPropertyNames(this.model);
        if (!this.steps.includes(step)) {
            step = 'ricerca';
        }
        if (!id) {
            this.store.dispatch(this.flowActions.setCurrentSection({ steps: this.steps, current_step: 'ricerca' }));
        }
        else {
            this.store.dispatch(this.flowActions.loadStrutAndMove({ id: id, type: type, current_step: step, steps: this.steps }));
        }
        var ricerca = this.model['ricerca'].control;
        ricerca.valueChanges.pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe({
            next: function (val) { _this.onSelectedStruct(val); },
            error: function () { },
            complete: function () { }
        });
        this.flowSelectors$.getCurrentItem$().pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe({
            next: function (x) {
                _this.current_item = x;
                if (x === null) {
                    _this.resetModel();
                    _this.formGroup.markAsUntouched();
                    _this.formGroup.markAsPristine();
                }
                else {
                    // this.resetModel();
                    _this.denominazioneStruttura = x['dataEntry']['descrizioneidras'];
                    _this.patchModel(x['dataEntry'], _this.dataEntryMap);
                    _this.markModelAsTouched(_this.model);
                    ricerca.patchValue({ nome: x['dataEntry']['descrizioneidras'] }, { emitEvent: false });
                    ricerca.markAsTouched();
                }
            },
            error: function (err) { },
            complete: function () { }
        });
        this.genericSelectors.getEditModalResult$().pipe(takeUntil(this.destroyed$), distinctUntilChanged(), filter(function (x) { return x !== null; })).subscribe({
            next: function (x) {
                if (!!x && !!x['result'] && !!x['field']) {
                    var container = _this.containerMap[x['field']];
                    var control = container.control;
                    control.setValue(x['result']);
                }
            },
            error: function (error) { return console.log(error); },
            complete: function () { }
        });
    };
    AsrEditComponent.prototype.getQueryParams = function () {
        if (this.current_item) {
            return {
                type: this.current_item && this.current_item.dataEntry && this.current_item.dataEntry.idAgriturismo ? 'agr' : 'strut',
                id: this.current_item && this.current_item.dataEntry && this.current_item.id,
                step: this.current_step
            };
        }
    };
    AsrEditComponent.prototype.ngOnDestroy = function () {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    };
    AsrEditComponent.prototype.onSelectedStruct = function ($event) {
        if (typeof $event !== 'undefined') {
            var id = void 0;
            var type = void 0;
            var next_step = 'dati_prelim';
            if (typeof $event.idAgriturismo === 'undefined') {
                id = $event.id;
                type = 'strut';
            }
            else {
                id = $event.idAgriturismo;
                type = 'agr';
            }
            this.store.dispatch(this.flowActions.loadStrutAndMove({ id: id, type: type, current_step: next_step, steps: this.steps }));
        }
        else {
            this.store.dispatch(this.flowActions.setCurrentItem(null));
        }
    };
    AsrEditComponent.prototype.changeSection = function (section) {
        if (this.current_item !== null) {
            this.markSectionAsTouched(this.model[this.current_step]);
            this.store.dispatch(this.flowActions.setCurrentStep(section));
            this.resetAffix();
        }
    };
    AsrEditComponent.prototype.goToNextSection = function () {
        if (this.current_item == null || this.current_step == null) {
            return;
        }
        var currentSection = this.model[this.current_step];
        var currentWeight = currentSection.weight;
        for (var sectionId in this.model) {
            if (this.model.hasOwnProperty(sectionId)) {
                var section = this.model[sectionId];
                if (section.weight - 1 === currentWeight) {
                    this.store.dispatch(this.flowActions.setCurrentStep(sectionId));
                    this.markSectionAsTouched(currentSection);
                    window.scrollTo(0, 0);
                    this.resetAffix();
                }
            }
        }
    };
    AsrEditComponent.prototype.goToPreviousSection = function () {
        if (this.current_item == null || this.current_step == null) {
            return;
        }
        var currentSection = this.model[this.current_step];
        var currentWeight = currentSection.weight;
        for (var sectionId in this.model) {
            if (this.model.hasOwnProperty(sectionId)) {
                var section = this.model[sectionId];
                if (section.weight + 1 === currentWeight) {
                    this.store.dispatch(this.flowActions.setCurrentStep(sectionId));
                    this.markSectionAsTouched(currentSection);
                    window.scrollTo(0, 0);
                    this.resetAffix();
                }
            }
        }
    };
    AsrEditComponent.prototype.openModal = function (targetKey) {
        var dataControl = this.containerMap[targetKey].control;
        this.store.dispatch(this.genericActions.showEditModal({ data: dataControl.value, field: targetKey, disableValidation: this.userService.isSuperOperatoreAsr() }));
    };
    AsrEditComponent.prototype.markSectionAsTouched = function (element) {
        if (element.visible.getValue() === false) {
            return;
        }
        if (LEAF_TYPES.includes(element.type)) {
            var c = element.control;
            c.markAsTouched({ onlySelf: true });
        }
        else if (CONTAINER_TYPES.includes(element.type)) {
            var c = element.control;
            c.markAsTouched({ onlySelf: true });
            for (var child in element.children) {
                if (element.children.hasOwnProperty(child)) {
                    this.markSectionAsTouched(element.children[child]);
                }
            }
        }
    };
    AsrEditComponent.prototype.markModelAsTouched = function (model) {
        for (var key in model) {
            if (model.hasOwnProperty(key)) {
                var element = model[key];
                this.markSectionAsTouched(element);
            }
        }
    };
    AsrEditComponent.prototype.submitData = function (flag) {
        var dataEntry = {};
        this.recoverDataEntry(this.model, dataEntry);
        var tipologia_struttura = dataEntry['tipologia_struttura'];
        if (typeof dataEntry['gestore'] !== 'undefined') {
            if (dataEntry['inserisci_info_gestore'] === 'cerca') {
                var idImpresaGestElement = this.containerMap['dati_struttura-dati_gestore-id_gestore'];
                var idImpresa = idImpresaGestElement.control.value;
                dataEntry['gestore']['idImpresa'] = idImpresa;
                var stesseInfoEl = this.containerMap['dati_struttura-dati_gestore-stesse_info_gestore'];
                dataEntry['stesse_info_gestore'] = stesseInfoEl.control.value;
                if (dataEntry['stesse_info_gestore'] === 'No' && dataEntry['inserisci_info_proprietario'] === 'cerca') {
                    var id_path = 'dati_struttura-dati_proprietario-id_proprietario';
                    var idImpresaPropElement = this.containerMap[id_path];
                    var idImpresaProp = idImpresaPropElement.control.value;
                    dataEntry['proprietario']['idImpresa'] = idImpresaProp;
                }
            }
        }
        var user = this.userService.getUserId();
        dataEntry['bozza'] = flag === true ? '1' : '0';
        dataEntry['idUtente'] = user;
        dataEntry['idStrut'] = this.current_item.id;
        delete dataEntry['ricerca'];
        delete dataEntry['riepilogo'];
        var data = {
            oldName: this.denominazioneStruttura,
            currentName: dataEntry['descrizioneidras']
        };
        if (!flag && this.denominazioneStruttura !== dataEntry['descrizioneidras']) {
            this.store.dispatch(this.genericActions.showRenameModal({
                title: 'Ridenominazione di una struttura',
                strut: data,
                buttons: [{ id: 'close', label: 'Annulla', class: '', autoclose: true },
                    { id: 'insert', label: 'Salva modifiche', class: '', action: this.userActions.editStruttura(dataEntry), autoclose: true }],
                size: 'large'
            }));
        }
        else {
            this.store.dispatch(this.userActions.editStruttura(dataEntry));
        }
    };
    AsrEditComponent.prototype.onInViewportChange = function ($event, element) {
        var currentSectionWeight = 0;
        var elementSectionWeight = 0;
        if (this.currentSectionInView) {
            var elementParentId = element.id.split('-');
            elementParentId.pop();
            elementParentId = elementParentId.join('-');
            if (this.currentSectionInView !== elementParentId) {
                currentSectionWeight = this.containerMap[this.currentSectionInView]['weight'];
                elementSectionWeight = this.containerMap[elementParentId]['weight'];
            }
        }
        if ($event) {
            if (!this.currentSubsectionInView) {
                this.currentSubsectionInView = element;
                element.inViewport = true;
                this.subsectioNoMoreInView = false;
            }
            else {
                if (this.subsectioNoMoreInView) {
                    this.currentSubsectionInView.inViewport = false;
                    this.markSectionAsTouched(this.currentSubsectionInView);
                    this.currentSubsectionInView = undefined;
                    this.currentSubsectionInView = element;
                    this.currentSubsectionInView.inViewport = true;
                    this.subsectioNoMoreInView = false;
                }
                else if (elementSectionWeight < currentSectionWeight) {
                    this.currentSubsectionInView.inViewport = false;
                    this.markSectionAsTouched(this.currentSubsectionInView);
                    this.queueSubsectionInView.unshift(this.currentSubsectionInView);
                    this.currentSubsectionInView = undefined;
                    this.currentSubsectionInView = element;
                    this.currentSubsectionInView.inViewport = true;
                    this.subsectioNoMoreInView = false;
                }
                else if (element.weight < this.currentSubsectionInView.weight && elementSectionWeight === currentSectionWeight) {
                    this.currentSubsectionInView.inViewport = false;
                    this.markSectionAsTouched(this.currentSubsectionInView);
                    this.queueSubsectionInView.unshift(this.currentSubsectionInView);
                    this.currentSubsectionInView = undefined;
                    this.currentSubsectionInView = element;
                    this.currentSubsectionInView.inViewport = true;
                    this.subsectioNoMoreInView = false;
                }
                else {
                    this.queueSubsectionInView.push(element);
                }
            }
        }
        else {
            if (this.currentSubsectionInView && this.currentSubsectionInView === element) {
                if (this.queueSubsectionInView.length > 0) {
                    element.inViewport = false;
                    this.currentSubsectionInView = this.queueSubsectionInView.shift();
                    this.currentSubsectionInView.inViewport = true;
                    this.markSectionAsTouched(element);
                }
                else {
                    this.subsectioNoMoreInView = true;
                }
            }
            else {
                var index = this.queueSubsectionInView.indexOf(element);
                if (index > -1) {
                    this.queueSubsectionInView.splice(index, 1);
                }
            }
        }
        if (this.currentSubsectionInView) {
            var path = this.currentSubsectionInView.id.split('-');
            path.pop();
            path = path.join('-');
            this.currentSectionInView = path;
        }
    };
    AsrEditComponent.prototype.resetAffix = function () {
        if (this.currentSubsectionInView) {
            this.currentSubsectionInView.inViewport = false;
            this.currentSubsectionInView = undefined;
        }
        this.queueSubsectionInView.forEach(function (element) {
            element.inViewport = false;
        });
        this.queueSubsectionInView = [];
        this.subsectioNoMoreInView = false;
    };
    return AsrEditComponent;
}(FormGeneratorController));
export { AsrEditComponent };
